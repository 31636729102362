import { WhiteList } from "@chatpay/common"

import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Table } from "semantic-ui-react"
import { formatPhoneNumber } from "utils/formatPhoneNumber"
import { NameNotRegistered } from "./FreeMembersStyledComponents"

interface IProps {
  member: Partial<WhiteList>
  isDesktop: boolean
  setDocumentIdToDelete: React.Dispatch<React.SetStateAction<string | undefined>>
  setComfirmPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const FreeMembersTableRow: React.FunctionComponent<IProps> = (props) => {
  const { member, isDesktop, setDocumentIdToDelete, setComfirmPopup } = props

  const { t } = useTranslation()

  const getContact = (user: Partial<WhiteList>): string => {
    if (user?.ims?.whatsapp) {
      return formatPhoneNumber(`+${user?.ims?.whatsapp}`)
    } else if (user?.ims?.telegram) {
      return formatPhoneNumber(`+${user?.ims?.telegram}`)
    } else {
      return t("activity.noData")
    }
  }

  /**
   * @description
   * When the user click on the button remove, open the confirmation popup and
   * set the current user represented from @param documentId to be deleted.
   */
  const handleRemoveClick = (documentId: string) => {
    setDocumentIdToDelete(documentId)
    setComfirmPopup(true)
  }

  return (
    <Table.Row key={member.id} className="cp-table-cell-hover">
      <Table.Cell textAlign="left">
        {member.name ?? <NameNotRegistered>{`(${t("FreeMembers.errors.nameNotRegistered")})`}</NameNotRegistered>}
      </Table.Cell>
      <Table.Cell textAlign="left">{getContact(member)}</Table.Cell>
      <Table.Cell textAlign={isDesktop ? "right" : "left"}>
        {member?.id && <Button content="REMOVER" size="small" onClick={() => handleRemoveClick(member.id ?? "")} />}
      </Table.Cell>
    </Table.Row>
  )
}

export type { IProps as IPropsFreeMembersTableRow }
