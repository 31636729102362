import React from "react"
import { Modal, ModalProps } from "semantic-ui-react"
import { stopPropagation } from "../../utils/Clicks"

export interface BasicProps extends ModalProps {}

export const Basic: React.FunctionComponent<BasicProps> = (props) => {
  return (
    <Modal
      {...props}
      closeOnDimmerClick={props.closeOnDimmerClick ?? true}
      closeOnEscape={props.closeOnEscape ?? true}
      onClick={stopPropagation}
      size={props.size ?? "small"}
    >
      <div style={{ margin: "50px 20px" }}>{props.children}</div>
    </Modal>
  )
}
