import * as React from "react"
import { Image } from "semantic-ui-react"
import { Document } from "@chatpay/common"

interface IProps {
  group: Document.Group
  style?: React.CSSProperties
}

export const Header: React.FunctionComponent<IProps> = (props) => {
  return (
    <div style={props.style}>
      <Image
        src={props.group?.picture}
        style={{
          maxWidth: "157px",
          maxHeight: "90px",
          borderRadius: "0.5em",
          margin: "auto",
          marginBottom: "1em",
        }}
      />
      <div className="cp text bold huge center black" style={{ margin: "auto" }}>
        {props.group.name}
      </div>
      <div className="cp text large black center">
        <i>{props.group.owner?.firstName + " " + props.group.owner?.lastName}</i>
      </div>
    </div>
  )
}
