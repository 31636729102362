import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "@chatpay/components"

export enum ValidateWhatsappActionTypes {
  OPEN = "VALIDATE_WHATSAPP_OPEN",
  CLOSE = "VALIDATE_WHATSAPP_CLOSE",
  VALIDATE = "VALIDATE_WHATSAPP_VALIDATE",
  LOADING = "VALIDATE_WHATSAPP_LOADING",
  VALIDATE_SUCCESS = "VALIDATE_WHATSAPP_VALIDATE_SUCCESS",
  VALIDATE_FAILURE = "VALIDATE_WHATSAPP_VALIDATE_FAILURE",
}

export interface IOpenAction {
  type: ValidateWhatsappActionTypes.OPEN
}

export interface ICloseAction {
  type: ValidateWhatsappActionTypes.CLOSE
}

export interface IValidateAction {
  type: ValidateWhatsappActionTypes.VALIDATE
}

export interface IValidateSuccessAction {
  type: ValidateWhatsappActionTypes.VALIDATE_SUCCESS
}

export interface IValidateFailureAction {
  type: ValidateWhatsappActionTypes.VALIDATE_FAILURE
  reason: string
}

export const openWhatsappValidation = (): IOpenAction => ({ type: ValidateWhatsappActionTypes.OPEN })

export const closeWhatsappValidation = (): ICloseAction => ({ type: ValidateWhatsappActionTypes.CLOSE })

export const validateWhatsapp =
  (phoneNumber: string, redirectTo?: string, callBack?: () => any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({ type: ValidateWhatsappActionTypes.VALIDATE })
    try {
      await new API.User().updateWppId({ phoneNumber: phoneNumber.trim() })
      dispatch(validateSuccess(redirectTo, callBack))
    } catch (e) {
      console.error(e)
      dispatch(validateFailure(e))
    }
  }

export const validateSuccess =
  (redirectTo?: string, callBack?: () => any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: ValidateWhatsappActionTypes.VALIDATE_SUCCESS,
    })
    if (redirectTo) {
      window.open(redirectTo)
    }
    if (callBack) {
      callBack()
    }
    dispatch(closeWhatsappValidation())
  }

export const validateFailure = (reason: string): IValidateFailureAction => {
  return {
    type: ValidateWhatsappActionTypes.VALIDATE_FAILURE,
    reason,
  }
}

export type ValidateWhatsappAction =
  | IOpenAction
  | ICloseAction
  | IValidateAction
  | IValidateSuccessAction
  | IValidateFailureAction
