"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _Models = require("../../../../Models");

var _Common = require("../../Common");

var _IConfig = _interopRequireDefault(require("./IConfig"));

var _IIdentifier = _interopRequireDefault(require("./IIdentifier"));

var _IValidation = _interopRequireDefault(require("./IValidation"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var accountFixture = function accountFixture(args) {
  return _objectSpread(_objectSpread(_objectSpread({}, (0, _IIdentifier["default"])()), (0, _IValidation["default"])()), {}, {
    name: faker.fake("{{name.firstName}} {{name.lastName}}"),
    document: faker.datatype.number({
      min: 11111111111,
      max: 99999999999
    }).toString(),
    type: faker.random.arrayElement(Object.keys(_Common.Type)),
    description: faker.lorem.paragraph(),
    accessToken: faker.random.alphaNumeric(64),
    tokenType: faker.random.arrayElement(Object.keys(_Common.TokenType)),
    currency: faker.random.arrayElement(Object.keys(_Models.Currency)),
    country: faker.random.arrayElement(Object.keys(_Models.Country)),
    isLive: faker.datatype["boolean"](),
    token: faker.random.alphaNumeric(32),
    chargeLimit: faker.random.arrayElement([1500, 30000, 35000]),
    config: (0, _IConfig["default"])(),
    operations: {
      subscriptions: {
        earlierBillingDays: faker.datatype.number(30),
        trialPeriodDays: faker.datatype.number(30)
      },
      invoiceReturnURL: faker.internet.url(),
      ownerEmails: faker.internet.email(),
      replyTo: faker.internet.email()
    },
    scope: faker.lorem.word(),
    refreshToken: faker.random.alphaNumeric(16),
    publishableKey: faker.internet.password()
  }, args);
};

var _default = accountFixture;
exports["default"] = _default;