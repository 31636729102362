"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "couponFixture", {
  enumerable: true,
  get: function get() {
    return _Coupon["default"];
  }
});
Object.defineProperty(exports, "documentFixture", {
  enumerable: true,
  get: function get() {
    return _Document["default"];
  }
});
Object.defineProperty(exports, "exportRequestFixture", {
  enumerable: true,
  get: function get() {
    return _ExportRequest["default"];
  }
});
Object.defineProperty(exports, "firebaseIdFixture", {
  enumerable: true,
  get: function get() {
    return _utils.firebaseIdFixture;
  }
});
Object.defineProperty(exports, "gatewayFixture", {
  enumerable: true,
  get: function get() {
    return _Gateway["default"];
  }
});
Object.defineProperty(exports, "groupFixture", {
  enumerable: true,
  get: function get() {
    return _Group["default"];
  }
});
Object.defineProperty(exports, "groupResourceFixture", {
  enumerable: true,
  get: function get() {
    return _GroupResource["default"];
  }
});
Object.defineProperty(exports, "groupTaxInvoiceFixture", {
  enumerable: true,
  get: function get() {
    return _TaxInvoice.groupTaxInvoiceFixture;
  }
});
Object.defineProperty(exports, "integrationsFixture", {
  enumerable: true,
  get: function get() {
    return _Integrations["default"];
  }
});
Object.defineProperty(exports, "iuguIdFixture", {
  enumerable: true,
  get: function get() {
    return _utils.iuguIdFixture;
  }
});
Object.defineProperty(exports, "memberFixture", {
  enumerable: true,
  get: function get() {
    return _Member["default"];
  }
});
Object.defineProperty(exports, "notificationGroupFixture", {
  enumerable: true,
  get: function get() {
    return _NotificationGroup["default"];
  }
});
Object.defineProperty(exports, "offerMemberFixture", {
  enumerable: true,
  get: function get() {
    return _OfferMember["default"];
  }
});
Object.defineProperty(exports, "planFixture", {
  enumerable: true,
  get: function get() {
    return _Plan["default"];
  }
});
Object.defineProperty(exports, "postFixture", {
  enumerable: true,
  get: function get() {
    return _Post["default"];
  }
});
Object.defineProperty(exports, "receiverFixture", {
  enumerable: true,
  get: function get() {
    return _Receiver["default"];
  }
});
Object.defineProperty(exports, "refundFixture", {
  enumerable: true,
  get: function get() {
    return _Refund["default"];
  }
});
Object.defineProperty(exports, "subscriptionFixture", {
  enumerable: true,
  get: function get() {
    return _Subscription["default"];
  }
});
Object.defineProperty(exports, "tagFixture", {
  enumerable: true,
  get: function get() {
    return _Tag["default"];
  }
});
Object.defineProperty(exports, "taskFixture", {
  enumerable: true,
  get: function get() {
    return _Task["default"];
  }
});
Object.defineProperty(exports, "taxInvoiceFixture", {
  enumerable: true,
  get: function get() {
    return _TaxInvoice.taxInvoiceFixture;
  }
});
Object.defineProperty(exports, "transactionFixture", {
  enumerable: true,
  get: function get() {
    return _Transaction["default"];
  }
});
Object.defineProperty(exports, "transactionTaxInvoiceFixture", {
  enumerable: true,
  get: function get() {
    return _TaxInvoice.transactionTaxInvoiceFixture;
  }
});
Object.defineProperty(exports, "transactionToIItemFixture", {
  enumerable: true,
  get: function get() {
    return _Transaction.transactionToIItemFixture;
  }
});
Object.defineProperty(exports, "userFixture", {
  enumerable: true,
  get: function get() {
    return _User["default"];
  }
});
Object.defineProperty(exports, "userTaxInvoiceFixture", {
  enumerable: true,
  get: function get() {
    return _TaxInvoice.userTaxInvoiceFixture;
  }
});
Object.defineProperty(exports, "withdrawFixture", {
  enumerable: true,
  get: function get() {
    return _Withdraw["default"];
  }
});

var _Coupon = _interopRequireDefault(require("./Coupon"));

var _Document = _interopRequireDefault(require("./Document"));

var _ExportRequest = _interopRequireDefault(require("./ExportRequest"));

var _Gateway = _interopRequireDefault(require("./Gateway"));

var _Group = _interopRequireDefault(require("./Group"));

var _GroupResource = _interopRequireDefault(require("./GroupResource"));

var _Integrations = _interopRequireDefault(require("./Integrations"));

var _Member = _interopRequireDefault(require("./Member"));

var _NotificationGroup = _interopRequireDefault(require("./NotificationGroup"));

var _OfferMember = _interopRequireDefault(require("./OfferMember"));

var _Plan = _interopRequireDefault(require("./Plan"));

var _Post = _interopRequireDefault(require("./Post"));

var _Receiver = _interopRequireDefault(require("./Receiver"));

var _Refund = _interopRequireDefault(require("./Refund"));

var _Subscription = _interopRequireDefault(require("./Subscription"));

var _Tag = _interopRequireDefault(require("./Tag"));

var _Task = _interopRequireDefault(require("./Task"));

var _TaxInvoice = require("./TaxInvoice");

var _Transaction = _interopRequireWildcard(require("./Transaction"));

var _User = _interopRequireDefault(require("./User"));

var _utils = require("./utils");

var _Withdraw = _interopRequireDefault(require("./Withdraw"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }