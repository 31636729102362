import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { Spacer } from "components"
import { PhoneField } from "components/Phone/PhoneField"
import { PhoneNumberType, PhoneNumberUtil } from "google-libphonenumber"
import i18n from "i18n"
import React, { useState } from "react"
import { Button, Form } from "semantic-ui-react"
import { Headers } from "../Headers"
import { Basic, BasicProps } from "./Basic"

interface IProps extends BasicProps {
  header: { title: string; subtitle: string }
  onFinished?: (imid: string) => any
}

export const WhatsAppVerification: React.FunctionComponent<IProps> = (props) => {
  const { header, onFinished, ...rest } = props
  return (
    <Basic {...rest}>
      <WhatsAppVerificationForm onFinished={onFinished} header={header} />
    </Basic>
  )
}

function validatePhoneInput(phoneNumber: string, callback: (phoneNumber: string, valid: boolean) => any) {
  const phoneUtil = new PhoneNumberUtil()
  let valid = false
  try {
    const pn = phoneUtil.parseAndKeepRawInput(phoneNumber)
    valid = phoneUtil.isValidNumber(pn) && phoneUtil.isPossibleNumberForType(pn, PhoneNumberType.MOBILE)
    callback(phoneNumber, valid)
  } catch {
    callback(phoneNumber, valid)
  }
}

const WhatsAppVerificationForm: React.FunctionComponent<IProps> = ({ onFinished, header }) => {
  const [state, setState] = useState<{ phoneNumber?: string; valid?: boolean }>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()

  const onSubmit = async () => {
    setLoading(true)
    try {
      const user: Document.User = await new API.User().updateWppId({ phoneNumber: state!.phoneNumber!.trim() })
      onFinished?.(user.ims?.whatsapp!)
    } catch (e) {
      console.error(e)
      setLoading(false)
      setError(e)
    }
  }

  if (state?.valid === undefined) {
    validatePhoneInput(Service.Firebase.currentUser?.phoneNumber ?? "", (phoneNumber: string, valid: boolean) =>
      setState({ phoneNumber, valid }),
    )
  }

  return (
    <>
      {Headers.Basic(header.title, header.subtitle)}
      <Form>
        <PhoneField
          data-testid={"whatsapp-verification-phone-input"}
          value={Service.Firebase.currentUser?.phoneNumber ?? undefined}
          label={i18n.t("phone.enterWpp")}
          disabled={loading}
          onChange={(values) =>
            validatePhoneInput(values, (phoneNumber: string, valid: boolean) => setState({ phoneNumber, valid }))
          }
        />
      </Form>
      <Spacer />
      <Button
        color="green"
        fluid={true}
        loading={loading}
        onClick={onSubmit}
        content={i18n.t("WhatsAppVerification.button")}
        disabled={!state?.valid || loading}
      />
      <Spacer />
      {error ? <Service.ErrorMessage error={error} /> : null}
    </>
  )
}
