import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "@chatpay/components"

export enum WithdrawActionTypes {
  OPEN = "WITHDRAW_OPEN",
  CLOSE = "WITHDRAW_CLOSE",
  LOAD = "WITHDRAW_LOAD",
  LOADING = "WITHDRAW_LOADING",
  LOAD_SUCCESS = "WITHDRAW_LOAD_SUCCESS",
  LOAD_FAILURE = "WITHDRAW_LOAD_FAILURE",
  REQUEST = "WITHDRAW_REQUEST",
  REQUEST_SUCCESS = "WITHDRAW_REQUEST_SUCCESS",
  REQUEST_FAILURE = "WITHDRAW_REQUEST_FAILURE",
}

export interface IOpenAction {
  type: WithdrawActionTypes.OPEN
}

export interface ICloseAction {
  type: WithdrawActionTypes.CLOSE
}

export interface IRequestAction {
  type: WithdrawActionTypes.REQUEST
}

export interface IRequestSuccessAction {
  type: WithdrawActionTypes.REQUEST_SUCCESS
}

export interface IRequestFailureAction {
  type: WithdrawActionTypes.REQUEST_FAILURE
  reason: string
}

export const openWithdraw = (): IOpenAction => ({ type: WithdrawActionTypes.OPEN })

export const closeWithdraw = (): ICloseAction => ({ type: WithdrawActionTypes.CLOSE })

export const requestWithdraw = (
  amount: number,
  onResult?: (error?: Error | null) => void,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({ type: WithdrawActionTypes.REQUEST })

  new API.Withdraw()
    .create({ amount })
    .then(() => {
      if (onResult) {
        onResult()
      }
      dispatch(requestSuccess())
    })
    .catch((error) => {
      if (onResult) {
        onResult(error)
      }
      dispatch(requestFailure(error))
    })
}

export const requestSuccess = (): IRequestSuccessAction => {
  return {
    type: WithdrawActionTypes.REQUEST_SUCCESS,
  }
}

export const requestFailure = (reason: string): IRequestFailureAction => {
  return {
    type: WithdrawActionTypes.REQUEST_FAILURE,
    reason,
  }
}

export type WithdrawAction = IOpenAction | ICloseAction | IRequestAction | IRequestSuccessAction | IRequestFailureAction
