import { Document } from "@chatpay/common"
import * as Fields from "components/Fields"
import { IFileAsset } from "components/Interfaces/IFileAsset"
import { TextArea } from "components/TextArea"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "semantic-ui-react"

type keys = "picture" | "cover" | "title" | "subtitle" | "type"

interface IForm {
  picture: IFileAsset
  cover: IFileAsset
  title: string
  subtitle: string
  productCategory: Document.ProductCategory
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const Info: React.FunctionComponent<IProps> = (props) => {
  const { disabled, value, onChange } = props

  const [form, setForm] = useState<Partial<IForm>>()

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = { ...form, [name]: value }
    const valid = checkValidity(newData)
    setForm(newData)
    if (onChange) {
      onChange(newData, valid)
    }
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.title || data.title !== (undefined || "")
  }

  return (
    <Form data-testid="info-form">
      <Fields.InputLabel title={t("GroupForm.info.picture")}>
        <Fields.InputCover
          value={form?.picture}
          onChange={(file) => setFormValue("picture", file)}
          aspectRatio={0.52}
        />
      </Fields.InputLabel>
      <Fields.InputLabel title={t("GroupForm.info.cover")}>
        <Fields.InputCover
          value={form?.cover}
          helperText={t("InputCover.coverSize")}
          onChange={(file) => setFormValue("cover", file)}
          aspectRatio={0.52}
        />
      </Fields.InputLabel>
      <Fields.InputLabel title={t("GroupForm.info.title")}>
        <Form.Input
          disabled={disabled?.title}
          value={form?.title ?? ""}
          onChange={(_, data) => setFormValue("title", data.value)}
          maxLength={70}
        />
      </Fields.InputLabel>
      <Fields.InputLabel title={t("GroupForm.info.subtitle")}>
        <TextArea
          disabled={disabled?.subtitle}
          value={form?.subtitle ?? ""}
          onChange={(_, data) => setFormValue("subtitle", String(data.value))}
        />
      </Fields.InputLabel>
      <Fields.InputLabel title={t("GetStarted.Info.title")}>
        <Form.Select
          type="hidden"
          id="Form"
          value={form?.productCategory ?? undefined}
          placeholder={t("GetStarted.Info.placeholder")}
          fluid={true}
          options={Object.values(Document.ProductCategory).map((category, index) => ({
            key: index,
            text: t(`GetStarted.Info.${Document.ProductCategory[category]}`),
            value: category,
          }))}
          closeOnBlur={true}
          onChange={(_, data) => setFormValue(Document.Buckets.productCategory, data.value)}
        />
      </Fields.InputLabel>
    </Form>
  )
}

export { Info as Form }
export type { IProps, IForm }
