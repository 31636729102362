import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Document, Interface, GroupStepHelpers } from "@chatpay/common"
import { Container, Divider, Grid, Modal } from "semantic-ui-react"
import { API, Service } from "@chatpay/components"
import "./GroupNextActionButton.scss"
import { routes } from "@chatpay/common"
import { useHistory } from "react-router-dom"
import {
  ButtonContainer,
  MaybeLaterLink,
  ModalContent,
  ModalTitle,
  ModalDescription,
} from "../GroupStatusButton/GroupStatusButtonStyledComponents"
import { Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import { GroupPublishButton } from "../GroupPublishButton"
import { GroupPublishButtonOriginPage } from "../GroupPublishButton/GroupPublishButton"
import { Button } from "@hub-la/soad"

interface IProps {
  group: Document.Group
  product: Document.Product
  statusChanged: () => any
}

const getNextAction = (currentStatus: Document.GroupStepStatus) => {
  switch (currentStatus) {
    case Document.GroupStepStatus.isPublished: {
      return Document.GroupStepStatus.isWaitlisted
    }
    case Document.GroupStepStatus.isSelling: {
      return Document.GroupStepStatus.isWaitlisted
    }
    case Document.GroupStepStatus.isWaitlisted: {
      return Document.GroupStepStatus.isSelling
    }
    case Document.GroupStepStatus.isNotEnabled: {
      return Document.GroupStepStatus.isSelling
    }
    case Document.GroupStepStatus.isNotSelling: {
      return Document.GroupStepStatus.isSelling
    }
    default: {
      return Document.GroupStepStatus.isSelling
    }
  }
}

const GroupNextActionButton: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { group, product, statusChanged } = props
  const groupStepHelpers = new GroupStepHelpers()

  const [isLoading, setIsLoading] = useState(false)

  const [isWaitlistWarning, setIsWaitlistWarning] = useState<boolean>(false)
  const [isGroupActivationWarning, setIsGroupActivationWarning] = useState<boolean>(false)

  const [isPublish, setIsPublish] = useState<boolean>(false)
  const [isPublishCohort, setIsPublishCohort] = useState<boolean>(false)
  const [isWaitlisted, setIsWaitlisted] = useState<boolean>(false)

  const isDesktop = window.matchMedia("(min-width: 992px)").matches

  async function handleChangeStatus(status: Document.GroupStepStatus) {
    setIsLoading(true)
    await new API.Group().changeStatus({
      groupId: group.id,
      status: status,
    })
    setIsLoading(false)
    statusChanged()
  }

  async function handleExceptions(status: Document.GroupStepStatus) {
    const currentStatus = Service.Firebase.currentUser?.defaultGateway?.status ?? 0

    // show waitlist alert Warning
    if (
      status === Document.GroupStepStatus.isWaitlisted &&
      product?.type === Interface.Product.CommunityType.cohort &&
      group.isSelling &&
      group.botsId.length
    ) {
      setIsWaitlistWarning(true)
      setIsLoading(false)
      return
    }

    if (status === Document.GroupStepStatus.isNotSelling && product?.type === Interface.Product.CommunityType.cohort) {
      setIsLoading(false)
      return
    }

    // handle group activation
    const shouldRenderActivateWarning = currentStatus === Interface.Gateway.Common.Status.accepted
    if (
      shouldRenderActivateWarning &&
      (status === Document.GroupStepStatus.isSelling || status === Document.GroupStepStatus.isWaitlisted)
    ) {
      if (status === Document.GroupStepStatus.isSelling) {
        if (product?.type === Interface.Product.CommunityType.cohort) {
          setIsPublishCohort(true)
        } else {
          setIsPublish(true)
        }
      }
      if (status === Document.GroupStepStatus.isWaitlisted) {
        setIsWaitlisted(true)
      }
      setIsGroupActivationWarning(true)
      setIsLoading(false)
      return
    }

    handleChangeStatus(status)
  }

  return (
    <>
      {isGroupActivationWarning && (
        <GroupPublishButton
          product={product}
          group={group}
          originPage={GroupPublishButtonOriginPage.productsPageSalesActivationButton}
          statusChanged={() => {
            setIsGroupActivationWarning(false)
            setIsWaitlistWarning(false)
            setIsPublish(false)
            setIsPublishCohort(false)
            setIsWaitlisted(false)
            statusChanged()
          }}
          closed={() => {
            setIsGroupActivationWarning(false)
            setIsWaitlistWarning(false)
            setIsPublish(false)
            setIsPublishCohort(false)
            setIsWaitlisted(false)
            statusChanged()
          }}
          justModal={isGroupActivationWarning}
          isPublish={isPublish}
          isPublishCohort={isPublishCohort}
          isWaitlisted={isWaitlisted}
        />
      )}

      <Button
        color="primary"
        variant="outlined"
        size="medium"
        className="group-next-action-button rounded"
        loading={isLoading}
        disabled={isLoading}
        style={{ fontWeight: 700 }}
        onClick={async () => {
          setIsLoading(true)
          if (groupStepHelpers.getGroupStepStatus(group) === Document.GroupStepStatus.isDraft) {
            history.push(`${routes.EDIT_PRODUCT.LINK}/${group.productId}/#description`)
            return
          } else {
            handleExceptions(getNextAction(groupStepHelpers.getGroupStepStatus(group)))
          }
        }}
      >
        {t(`myGroups.GroupStatus.${groupStepHelpers.getGroupStepStatus(group)}.next`)}
      </Button>

      <Modal open={isWaitlistWarning}>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }} title={t("close")}>
            <CloseIcon onClick={() => setIsWaitlistWarning(false)} />
          </div>
          <Spacer size={isDesktop ? "big" : "small"} />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitle>{t("GroupStatusButton.offerWaitlistWarning.modal.title")}</ModalTitle>
              <Spacer size="small" />
              <ModalDescription
                dangerouslySetInnerHTML={{ __html: t("GroupStatusButton.offerWaitlistWarning.modal.description") }}
              />
              <Spacer size={isDesktop ? "big" : "small"} />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={12} mobile={16}>
                  <ButtonContainer>
                    <MaybeLaterLink onClick={() => setIsWaitlistWarning(false)}>
                      {t("GroupStatusButton.offerWaitlistWarning.modal.buttons.maybeLater")}
                    </MaybeLaterLink>
                    <Button
                      size="medium"
                      color="primary"
                      variant="contained"
                      onClick={async () => await handleChangeStatus(Document.GroupStepStatus.isWaitlisted)}
                      loading={isLoading}
                    >
                      {t(`GroupStatusButton.offerWaitlistWarning.modal.buttons.createWaitlist`)}
                    </Button>
                  </ButtonContainer>
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </ModalContent>
      </Modal>
    </>
  )
}

export default GroupNextActionButton
