import "reflect-metadata"
import { Analytics } from "@chatpay/components"
import * as React from "react"
import { CookiesProvider } from "react-cookie"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import configureStore from "./base/Store"
import App from "./components/App"
import "./i18n"
import "./components/Theme/index.scss"
import * as serviceWorker from "./serviceWorker"
import * as OfflinePluginRuntime from "offline-plugin/runtime"
import Cookies from "js-cookie"
import { QueryClientProvider } from "react-query"
import { ThemeProvider, theme } from "@hub-la/soad"
import { FlagsProvider } from "hooks"
import { stackdriver } from "./logging"
import { queryClient } from "query-client"
import { ContainerProvider } from "hooks/use-container"
import { ContainerProvider as GlobalContainerProvider, container as globalContainer } from "@hub-la/fe-container"
import container from "container"
import { VideosUploader, VideosUploaderProvider } from "@hub-la/fe-post"
import { ThemeProvider as DsThemeProvider, theme as dsTheme } from "@hub-la/design-system"

const store = configureStore()
Analytics.init()

ReactDOM.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalContainerProvider container={globalContainer}>
        <ContainerProvider container={container}>
          <Provider store={store}>
            <ThemeProvider theme={theme.light}>
              <FlagsProvider>
                <VideosUploaderProvider>
                  <App />

                  <DsThemeProvider theme={dsTheme.light}>
                    <VideosUploader />
                  </DsThemeProvider>
                </VideosUploaderProvider>
              </FlagsProvider>
            </ThemeProvider>
          </Provider>
        </ContainerProvider>
      </GlobalContainerProvider>
    </QueryClientProvider>
  </CookiesProvider>,
  document.getElementById("root"),
)
serviceWorker.unregister()

OfflinePluginRuntime.install()

if (process.env.REACT_APP_STACKDRIVER_APIKEY) {
  stackdriver.start({
    key: process.env.REACT_APP_STACKDRIVER_APIKEY,
    projectId: process.env.REACT_APP_PROJECT_ID,
  })

  const authUser = localStorage.getItem("authUser")
  const userId = authUser ? JSON.parse(authUser).id : Cookies.get("_ga")
  stackdriver.setUser(userId)

  const defaultConsoleError = console.error
  console.error = (...params) => {
    stackdriver.report(...params)
    defaultConsoleError(...params)
  }
}
