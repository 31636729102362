import { Data } from "@chatpay/common"

// let Cards = [
//   "378282246310005",     // American Express
//   "371449635398431",     // American Express
//   "5078601870000127985", // Aura
//   "5078601800003247449", // Aura
//   "30569309025904",      // Diners Club
//   "38520000023237",      // Diners Club
//   "6011111111111117",    // Discover
//   "6362970000457013",    // Elo
//   "6062825624254001",    // Hipercard
//   "5555555555554444",    // MasterCard
//   "5105105105105100",    // MasterCard
//   "6759649826438453",    // Maestro
//   "6799990100000000019", // Maestro
//   "4111111111111111",    // Visa
//   "4012888888881881",    // Visa
// ]

export class Type {
  public id: string
  public name: string
  public regex: RegExp

  constructor(dict: Data) {
    this.id = (dict.id as string) ?? ""
    this.name = (dict.name as string) ?? ""
    this.regex = dict.regex as RegExp
  }
}

class Validators {
  public static shared<T>(this: new () => T): T {
    return new this()
  }

  public get types(): Type[] {
    return this.typesData.map((d) => new Type(d))
  }

  public typeById(id: string): Type | null {
    return this.types.find((t) => t.id === id || t.id.includes(id)) ?? null
  }

  public type(str: string): Type | null {
    for (const i in this.types) {
      if (this.types.hasOwnProperty(i)) {
        const type = this.types[i]
        if (type.regex.test(str.numbers())) {
          return type
        }
      }
    }
    return null
  }

  private validateNumber(str: string): boolean {
    const numbers = str.numbers()
    if (numbers.length < 9) {
      return false
    }

    const reversedString = str.split("").reverse().join("")
    let oddSum = 0
    let evenSum = 0
    for (let i = 0; i < reversedString.length; i++) {
      const s = reversedString[i]
      const digit = parseInt(s, 10)

      if (i % 2 === 0) {
        evenSum += digit
      } else {
        oddSum += digit / 5 + ((2 * digit) % 10)
      }
    }
    return (oddSum + evenSum) % 10 === 0
  }

  public validate(str: string, type?: Type | null): boolean {
    if (type) {
      return this.type(str)?.id === type.id
    }
    return this.validateNumber(str)
  }

  public holder(str: string): boolean {
    return str.split(" ").filter((n) => n.trim().length).length > 1
  }

  public expiration(str: string): boolean {
    try {
      const [month, y] = str.split("/").map((d) => parseInt(d, 10))

      let year = y
      const now = new Date()
      if (year >= 0 && year < 100) {
        const fullYear = now.getFullYear()
        const shortYear = fullYear % 100
        const m1 = fullYear - shortYear
        const m2 = m1 - 100

        const opt1 = year + m1
        const opt2 = year + m2

        year = Math.abs(fullYear - opt1) < Math.abs(fullYear - opt2) ? opt1 : opt2
      }

      const date = new Date(`${year}/${month}/${new Date(year, month, 0).getDate()}`)
      if (!(date instanceof Date) || isNaN(date.getTime()) || date.getTime() < now.getTime()) {
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }

  public CVV(str: string, type?: Type | null): boolean {
    if (type?.id === "amex" && str.length < 4) {
      return false
    }
    if (str.length < 3) {
      return false
    }
    return true
  }

  private typesData: Data[] = [
    {
      id: "amex",
      name: "American Express",
      regex: /^3[47][0-9]{13}$/,
    },
    {
      id: "visa",
      name: "Visa",
      regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
    },
    {
      id: "master",
      name: "MasterCard",
      regex: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
    },
    {
      id: "diners",
      name: "Diners Club",
      regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    },
    {
      id: "elo",
      name: "ELO",
      regex: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    },
    // {
    //   id: "hipercard",
    //   name: "Hipercard",
    //   regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/
    // }
  ]
}

export const Validator = new Validators()
