import { Interface } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { IAppState } from "base/AppState"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import { useDashboard } from "pages/User/Dashboard/useDashboard"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Form, Message } from "semantic-ui-react"
import { Groups } from "store"
import styled from "styled-components"
import { CheckResourcesHasGroupIM } from "modules/check-resources-has-group-im/usecases/check-resources-has-group-im"

export interface IForm {
  linkedGroups: string[]
}

export interface IProps {
  communityType: Interface.Product.CommunityType
  groupId: string
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>) => any
}

const CohortTypeMessage = styled(Message)`
  .header {
    font-family: var(--secondary-font) !important;
  }
`

const LinkedGroup: React.FunctionComponent<IProps> = (props) => {
  const { value, communityType, groupId, onChange } = props

  const [form, setForm] = useState<Partial<IForm>>()
  const [loading, setLoading] = useState<boolean>(true)

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { status, currentUser } = useSelector((state: IAppState) => state.GroupsState)
  const { groups } = useDashboard()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  useEffect(() => {
    switch (status) {
      case "idle":
        dispatch(Groups.Actions.fetchGroups(Service.Firebase.currentUser!))
        break
      case "loading":
        setLoading(true)
        break
      case "succeeded":
        if (currentUser?.id !== Service.Firebase.currentUser?.id) {
          dispatch(Groups.Actions.fetchGroups(Service.Firebase.currentUser!))
        }
        setLoading(false)
        break
      case "failed":
        break
    }
  }, [status, dispatch, currentUser])

  const updateLinkedGroups = (data: string[]) => {
    const newData: Partial<IForm> = { linkedGroups: data }
    if (onChange) {
      onChange(newData)
    }
    setForm(newData)
  }

  const groupName = groups.find((it) => it.id === groupId)?.name

  return (
    <Form>
      <CohortTypeMessage>
        <CohortTypeMessage.Header>{t("editGroup.linkedGroup.disabledByCohortType")}</CohortTypeMessage.Header>
      </CohortTypeMessage>
      <Spacer />
      <Fields.InputLabel title={t("GroupForm.linkedGroup.linkedGroups")}>
        <Form.Dropdown
          fluid={true}
          disabled={communityType === Interface.Product.CommunityType.cohort}
          value={form?.linkedGroups ?? []}
          loading={loading}
          multiple={true}
          selection={true}
          placeholder={t("GroupForm.linkedGroup.choose")}
          options={groups
            .filter((group) => new CheckResourcesHasGroupIM().execute(group.groupResourcesType))
            .map((group) => {
              return {
                id: group.id,
                text: group.name,
                value: group.id,
                productSettings: group.productSettings,
              }
            })
            .filter(
              (it) => it.id !== groupId && it.productSettings.communityType !== Interface.Product.CommunityType.cohort,
            )}
          onChange={(_, data) => updateLinkedGroups(data.value as string[])}
        />
      </Fields.InputLabel>
      <div>{t("GroupForm.linkedGroup.text1", { group_name: groupName })}</div>
      <Spacer />
      <div>
        {" "}
        <b>{t("GroupForm.linkedGroup.text2") + ": "}</b>
        {t("GroupForm.linkedGroup.text3")}
      </div>
    </Form>
  )
}

export { LinkedGroup as Form }
