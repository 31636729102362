import { Interface } from "@chatpay/common"
import { API } from "@chatpay/components"
import * as Fields from "components/Fields"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Text, Title } from "typography"
import { Spacer } from "components"
import { Button, Form, InputOnChangeData, Grid, Message, Segment, Label, Icon, Dropdown } from "semantic-ui-react"
import i18n from "i18n"
import { Trans } from "react-i18next"
import { PartialDeep } from "type-fest"

enum State {
  none,
  editing,
  configuring,
  removing,
}

export interface IForm {
  isLoading?: boolean
  state: State
  isVerifyAttempted?: boolean
  isValidDomainName?: boolean
  hasDomainValidation?: boolean
  savedValue?: Interface.Trackers.Facebook | null
  initialValue?: Interface.Trackers.Facebook | null
  value?: Interface.Trackers.Facebook | null
}

export interface IProps {
  value?: Interface.Trackers.Facebook | null
  saved?: boolean
  onChange?: (data?: Interface.Trackers.Facebook | null) => any
}

export const Facebook: React.FunctionComponent<IProps> = (props) => {
  const { value: receivedValue, saved, onChange } = props

  const [form, setForm] = useState<IForm | null>()

  useEffect(() => {
    if (JSON.stringify(receivedValue) !== JSON.stringify(form?.value)) {
      setForm({
        ...form,
        value: receivedValue ? { ...receivedValue } : undefined,
        state: saved ? State.none : form?.state ?? State.none,
        savedValue: saved ? null : form?.savedValue,
        hasDomainValidation: receivedValue?.domain ? true : form?.hasDomainValidation,
        initialValue: (!form?.initialValue || saved) && receivedValue ? { ...receivedValue } : undefined,
      })
    } else if (saved && form?.state !== State.none) {
      setForm({
        ...form,
        value: receivedValue ? { ...receivedValue } : undefined,
        savedValue: null,
        state: State.none,
        hasDomainValidation: receivedValue?.domain ? true : form?.hasDomainValidation,
        initialValue: receivedValue ? { ...receivedValue } : undefined,
      })
    } else if (form?.state === undefined || form?.state === null) {
      setForm({
        ...form,
        state: State.none,
        savedValue: null,
      })
    }
  }, [receivedValue, saved, form])

  const updateForm = (data: PartialDeep<IForm>) => {
    const newData = _.merge({ ...(form ?? {}), state: data.state ?? form?.state ?? State.none }, data)
    setForm(newData)
    if (onChange) {
      onChange(newData?.value)
    }
  }

  const updateFacebookId = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const result = data.value.replace(/[^\d,]+/g, "") // Allowed comma to add multiple pixels
    updateForm({ value: { id: result } })
    event.currentTarget.value = result
  }

  const updateDomain = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const result = data.value
    updateForm({ value: { domain: result, isVerified: false }, isVerifyAttempted: false })
    event.currentTarget.value = result
  }

  const domainValidationChanged = (value: boolean) => {
    updateForm({
      hasDomainValidation: value,
      savedValue: !value ? { ...(form?.value ?? {}) } : null,
      value: !value ? { domain: "", isVerified: false } : { ...(form?.savedValue ?? {}) },
    })
  }

  const onValidateClick = async () => {
    const domain = form?.value?.domain
    if (!domain) {
      return
    }

    updateForm({ isLoading: true, isVerifyAttempted: false })

    let isValid = false
    try {
      isValid = await new API.Group().verifyDomain({ domain })
    } catch (e) {
      isValid = false
      console.error(e)
    }

    updateForm({ isLoading: false, isVerifyAttempted: true, value: { isVerified: isValid } })
  }

  const { initialValue, value, isLoading, state, hasDomainValidation, isVerifyAttempted } = form ?? {}

  const { domain = "", isVerified = false } = value ?? {}

  const isInvalid = !isVerified && domain && (state !== State.none || isVerifyAttempted) && !form?.isLoading
  const isValid = isVerified && domain && !form?.isLoading
  const isValidDomainName =
    !domain.length ||
    (hasDomainValidation && /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain))

  const getTestId = (preffix: string) => `${preffix}-${hasDomainValidation ? "showing" : "hidden"}`

  return (
    <Grid
      style={{
        padding: "0em 1.0em",
        margin: "-1.0em -1.0em 1em -1.0em",
        borderRadius: "4px",
      }}
    >
      {initialValue?.id && (state === State.none || state === State.configuring) ? (
        <Grid.Row>
          <Title.Small>{i18n.t("editGroup.trackers.facebook.pixel")}</Title.Small>
          <Spacer size="tiny" />
          <Text.Tiny>{initialValue.id}</Text.Tiny>
          <Spacer size="small" />
          <Title.Small>{i18n.t("editGroup.trackers.facebook.domain")}</Title.Small>
          <Spacer size="tiny" />
          <Text.Tiny>
            {initialValue?.domain ? initialValue.domain : i18n.t("editGroup.trackers.facebook.notSetled")}
          </Text.Tiny>
          {initialValue.domain ? (
            <Message
              size="tiny"
              error={!initialValue.isVerified}
              positive={initialValue.isVerified}
              content={
                initialValue.isVerified
                  ? i18n.t("editGroup.trackers.facebook.verified")
                  : i18n.t("editGroup.trackers.facebook.notVerified")
              }
              style={{ padding: "0.3em 0.6em", margin: "-0.3em 0.5em" }}
            />
          ) : null}
          <Label
            attached="top right"
            size="large"
            style={{ backgroundColor: "transparent", margin: "0 -1em 0 0", padding: "0.8em 0.8em 0 0" }}
          >
            <Dropdown
              icon={<Icon name="ellipsis vertical" fitted={true} style={{ padding: "0 0 0 1em" }} />}
              closeOnBlur={true}
              closeOnEscape={true}
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Item value="edit" text={`Edit`} onClick={() => updateForm({ state: State.editing })} />
                <Dropdown.Item
                  value="remove"
                  text={i18n.t("editGroup.trackers.facebook.remove")}
                  onClick={() => updateForm({ state: State.removing, value: null })}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Label>
          {!initialValue?.domain || !initialValue?.isVerified ? (
            <>
              <Spacer size="small" />
              <Text.Tiny>
                <Button
                  as="a"
                  size="mini"
                  compact={true}
                  labelPosition="right"
                  style={{ backgroundColor: "transparent", margin: "0", padding: "0 1.2em 0 0" }}
                  onClick={() =>
                    updateForm({
                      state: state !== State.configuring ? State.configuring : State.none,
                      savedValue:
                        !initialValue?.domain && state === State.configuring ? { ...(form?.value ?? {}) } : null,
                      value:
                        !initialValue?.domain && state === State.configuring
                          ? { domain: "", isVerified: false }
                          : { ...(form?.savedValue ?? form?.value ?? {}) },
                      hasDomainValidation: state !== State.configuring || (form?.hasDomainValidation ?? false),
                    })
                  }
                >
                  {!initialValue?.domain
                    ? i18n.t("editGroup.trackers.facebook.configureDomain")
                    : i18n.t("editGroup.trackers.facebook.configurationDetail")}
                  <Icon
                    name={state === State.configuring ? "chevron up" : "chevron down"}
                    style={{ margin: "0 0 0 0.3em" }}
                  />
                </Button>
              </Text.Tiny>
            </>
          ) : null}
        </Grid.Row>
      ) : null}
      {state !== State.none || !initialValue?.id ? (
        <>
          {state !== State.configuring || !initialValue?.domain ? (
            <Grid.Row>
              {initialValue?.domain || state !== State.configuring ? (
                <>
                  <Fields.InputLabel title={i18n.t("editGroup.trackers.facebook.pixel")}>
                    <Form.Input
                      value={value?.id ?? ""}
                      disabled={isLoading}
                      placeholder={`${i18n.t("editGroup.trackers.facebook.ie")} 12345678910`}
                      onChange={(event, data) => updateFacebookId(event, data)}
                    />
                  </Fields.InputLabel>
                  <Spacer size="small" />
                </>
              ) : null}
              <span className="cp text medium bold">{i18n.t("editGroup.trackers.facebook.texts.apple")}</span>
              <Spacer size="tiny" />
              {initialValue?.domain || state !== State.configuring ? (
                <Fields.InputLabel style={{ paddingBottom: "0.5" }}>
                  <Form.Checkbox
                    toggle={true}
                    disabled={isLoading}
                    checked={hasDomainValidation}
                    onChange={() => domainValidationChanged(!hasDomainValidation)}
                    data-testid="facebook-pixel-toggle-domain"
                  />
                </Fields.InputLabel>
              ) : null}
              {state === State.editing || state === State.removing ? (
                <Label
                  attached="top right"
                  size="small"
                  as="a"
                  content={
                    state === State.editing
                      ? i18n.t("editGroup.trackers.facebook.cancel")
                      : i18n.t("editGroup.trackers.facebook.undo")
                  }
                  style={{ backgroundColor: "transparent", margin: "1em 0 0 0", paddingRight: 0 }}
                  onClick={() => updateForm({ value: initialValue, state: State.none })}
                />
              ) : null}
            </Grid.Row>
          ) : null}
          <Grid.Row
            style={{ display: hasDomainValidation ? undefined : "none", padding: "0" }}
            data-testid={getTestId("facebook-pixel-domain")}
          >
            <Text.Small>
              {i18n.t("editGroup.trackers.facebook.texts.1.1")}
              <span className="cp text bold"> {i18n.t("editGroup.trackers.facebook.texts.1.2")}</span>
              {"."}
            </Text.Small>
            <Spacer size="tiny" />
            <Text.Tiny>
              {i18n.t("editGroup.trackers.facebook.texts.2.1")}{" "}
              <span className="cp text bold">{i18n.t("editGroup.trackers.facebook.texts.2.2")}</span>
              {". "}
              {i18n.t("editGroup.trackers.facebook.texts.2.3")}
            </Text.Tiny>
            <Spacer size="tiny" />
            <Fields.InputLabel
              errorText={!isValidDomainName ? i18n.t("editGroup.trackers.facebook.invalidDomain") : undefined}
            >
              <Form.Input
                value={domain}
                disabled={isLoading}
                loading={isLoading}
                error={!isValidDomainName}
                placeholder={`${i18n.t("editGroup.trackers.facebook.ie")} ${i18n.t(
                  "editGroup.trackers.facebook.yourdomain",
                )}`}
                onChange={(event, data) => updateDomain(event, data)}
                maxLength={50}
                data-testid="facebook-pixel-domain"
              />
            </Fields.InputLabel>
            <Spacer size="small" />
            <Text.Small>
              {i18n.t("editGroup.trackers.facebook.texts.3.1")}{" "}
              <span className="cp text bold">{i18n.t("editGroup.trackers.facebook.texts.3.2")}</span>
              {"."}
            </Text.Small>
            <Spacer size="tiny" />
            <Text.Tiny>{i18n.t("editGroup.trackers.facebook.texts.4")}</Text.Tiny>
            <div>
              <Segment style={{ padding: "0.8em 1.0em", margin: "0.5em 0em" }} className="cp text medium">
                {i18n.t("editGroup.trackers.facebook.texts.5")}
              </Segment>
              <Segment style={{ padding: "0.8em 1.0em", margin: "0.5em 0em" }} className="cp text medium">
                {i18n.t("editGroup.trackers.facebook.texts.6.1")}{" "}
                <span className="cp text bold">{`pixel.${
                  domain?.length ? domain : i18n.t("editGroup.trackers.facebook.yourdomain")
                }`}</span>{" "}
                {i18n.t("editGroup.trackers.facebook.texts.6.2")} <span className="cp text bold">pixel.hub.la</span>
              </Segment>
              <Segment style={{ padding: "0.8em 1.0em", margin: "0.5em 0em" }} className="cp text medium">
                {i18n.t("editGroup.trackers.facebook.texts.7")}
              </Segment>
            </div>
          </Grid.Row>
          <Grid.Row style={{ display: hasDomainValidation ? undefined : "none" }}>
            <Text.Tiny>{i18n.t("editGroup.trackers.facebook.texts.8")}</Text.Tiny>
            <Spacer size="tiny" />
            <Text.Small>{i18n.t("editGroup.trackers.facebook.texts.9")}</Text.Small>
            <Spacer size="tiny" />
            <Text.Tiny>{i18n.t("editGroup.trackers.facebook.texts.10")}</Text.Tiny>
          </Grid.Row>
          <Grid.Row
            style={{ display: hasDomainValidation ? undefined : "none" }}
            columns={isInvalid || isValid ? "equal" : undefined}
          >
            <Grid.Column>
              <Button
                disabled={!domain.length || !isValidDomainName || isLoading}
                loading={isLoading ?? false}
                size="small"
                color="green"
                content={i18n.t("editGroup.trackers.facebook.verify")}
                onClick={() => onValidateClick()}
                data-testid="facebook-pixel-domain-verify"
              />
            </Grid.Column>
            {isInvalid ? (
              <Grid.Column width={12}>
                <Message
                  size="small"
                  error={true}
                  content={
                    isVerifyAttempted ? (
                      <Trans i18nKey="editGroup.trackers.facebook.domainNotFound">
                        Domain pixel.{{ domain }} not found in CNAME`
                      </Trans>
                    ) : (
                      i18n.t("editGroup.trackers.facebook.notVerified")
                    )
                  }
                  style={{ padding: ".78571429em 1.5em .78571429em" }}
                  data-testid="facebook-pixel-validation-error"
                />
              </Grid.Column>
            ) : null}
            {isValid ? (
              <Grid.Column width={12}>
                <Message
                  size="small"
                  positive={true}
                  content={`${i18n.t("editGroup.trackers.facebook.verified")} : ${domain}`}
                  style={{ padding: ".78571429em 1.5em .78571429em" }}
                  data-testid="facebook-pixel-validation-success"
                />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </>
      ) : null}
    </Grid>
  )
}
