import { Session } from "@chatpay/components"
import React from "react"
import { Modal } from "semantic-ui-react"

const Phone = React.lazy(() => import("components/Auth"))

interface IProps {
  open?: boolean
  onLoginDone?: () => void
  onClose?: () => void
}
export const Auth: React.FunctionComponent<IProps> = (props) => {
  return (
    <Session.Context.Consumer>
      {(state) => (
        <Modal
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={props.onClose}
          open={props.open && (state.authUser ? false : true)}
          size="large"
          dimmer={true}
          content={<Phone onComplete={props.onLoginDone} onClose={props.onClose} />}
        />
      )}
    </Session.Context.Consumer>
  )
}
