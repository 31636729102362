import { Document } from "@chatpay/common"
import { Service } from "@chatpay/components"

import * as Fields from "components/Fields"
import { InputLabel } from "components/Fields/InputLabel"
import * as Forms from "components/Forms"
import * as Base from "components/Forms/Base"
import * as CreditCard from "components/Helpers/CreditCard"
import i18n from "i18n"
import * as React from "react"
import { Translation } from "react-i18next"
import { DropdownItemProps, Grid, Image, Input, Select } from "semantic-ui-react"

import "./CreditCard.scss"

interface IData {
  number: string
  holder: string
  expiration: string
  cvv: string
  type: CreditCard.Type | null
  installments?: number | null
}

export type Data = IData

export const EmptyData: IData = {
  number: "",
  holder: "",
  expiration: "",
  cvv: "",
  type: null,
  installments: null,
}

export type Installment = {
  key: string
  text: string
  value: number
  onClick: () => void
}

interface IProps<I extends Data> extends Base.Props<I> {
  installments?: Document.Installments | null
  prices?: number[] | null
  onInstallmentsChange?: (installments: number | undefined) => any
}

interface IState<T extends Data> extends Base.State<T> {}

export class CreditCardForm extends Base.Form<IProps<Data>, IState<Data>> {
  protected formGridRef = React.createRef<Forms.Grid>()
  protected documentFieldRef = React.createRef<Fields.InputDocument>()

  private installments(price: number, installments: Installment[]): Installment[] {
    const minPrice = parseInt(process.env.REACT_APP_IUGU_MIN_INSTALLMENT_PRICE ?? "5", 10) * 100
    return price && price > 0 ? installments.filter((i) => (price * 100) / parseInt(i.key, 10) >= minPrice) : []
  }

  public shouldComponentUpdate(nextProps: IProps<Data>, nextState: IState<Data>) {
    if (nextProps.prices !== this.props.prices && nextProps.installments && this.state.data.installments) {
      const maxInstallments = this.makeIntallments(nextProps.prices ?? [0], nextProps.installments).length
      if (this.state.data.installments > maxInstallments) {
        this.fieldChanged("installments", maxInstallments === 0 ? null : maxInstallments)
        this.props.onInstallmentsChange?.(nextProps.installments)
        return true
      }
    }
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      JSON.stringify(nextState) !== JSON.stringify(this.state)
    )
  }

  public static getDerivedStateFromProps(props: IProps<any>, state: IState<any>) {
    const st = super.getDerivedStateFromProps(props, state)

    if (st?.data?.number?.trim().length) {
      st.data.number = CreditCard.Formatter.cardNumber(st.data.number)
    }
    if (st?.data?.number?.trim().length && !st?.data?.type) {
      st.data.type = CreditCard.Validator.type(st.data.number)
    }
    if (st?.data?.cvv?.trim().length) {
      st.data.cvv = CreditCard.Formatter.CVV(st.data.cvv, st.data.number)
    }
    if (st?.data?.expiration?.trim().length) {
      st.data.expiration = CreditCard.Formatter.expirationDate(st.data.expiration)
    }

    return st
  }

  protected validateField(name: string, value: any, isValid: boolean = true): { name: string; isValid: boolean } {
    let valid: boolean = isValid

    if (name === "number") {
      valid = this.state.data.type !== null && CreditCard.Validator.validate(value, this.state.data.type) && isValid
    } else if (name === "holder") {
      valid = CreditCard.Validator.holder(value) && isValid
    } else if (name === "expiration") {
      valid = CreditCard.Validator.expiration(value) && isValid
    } else if (name === "cvv") {
      valid = this.state.data.type !== null && CreditCard.Validator.CVV(value, this.state.data.type) && isValid
    }

    return { name, isValid: valid }
  }

  protected formatField(
    name: string,
    value: any,
    isValid: boolean = true,
    field?: HTMLInputElement | HTMLTextAreaElement,
  ): any {
    if (name === "number") {
      field!.value = CreditCard.Formatter.cardNumber(value)
    } else if (name === "expiration") {
      field!.value = CreditCard.Formatter.expirationDate(value)
    } else if (name === "cvv") {
      field!.value = CreditCard.Formatter.CVV(value, this.state.data.number)
    }

    return value
  }

  protected onNumberFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    event.persist()

    if (name === "number") {
      this.fieldDidChange({
        target: event.target,
        onComplete: () => {
          this.setState(
            {
              data: {
                ...this.state.data,
                type: CreditCard.Validator.type(value),
              },
            },
            () => {
              this.onFieldChange(event)
            },
          )
        },
      })
      return
    }
    this.onFieldChange(event)
  }

  private onInstallmentFieldChange = (_, data: DropdownItemProps) => {
    this.fieldChanged("installments", data.value === 0 ? null : data.value, true)
    if (this.props.onInstallmentsChange) {
      this.props.onInstallmentsChange(data.value === 0 ? undefined : (data.value as number))
    }
  }

  private makeIntallments(prices: number[], count: number) {
    const installments: any[] = []
    const price = prices[count - 1]

    for (let i = 1; i <= count; i++) {
      if (i === 1) {
        installments.push({
          key: 0,
          text: i18n.t("checkout.SinglePayment"),
          value: 0,
          onClick: this.onInstallmentFieldChange,
        })
        continue
      }

      const installment = prices[i - 1] / i
      installments.push({
        key: i,
        text: `${i}x de ${Service.Global.Price.currencyFormat(installment, "BRL")}`,
        value: i,
        onClick: this.onInstallmentFieldChange,
      })
    }

    return this.installments(price, installments)
  }

  public render() {
    const { data, isDisabled } = this.state
    const { prices, installments } = this.props

    let installmentsData: any[] = []
    if (installments && prices?.length) {
      installmentsData = this.makeIntallments(prices, installments)
    }

    return (
      <Translation>
        {(t) => (
          <div className="CreditCardForm">
            <Forms.Grid ref={this.formGridRef}>
              <Grid.Row>
                <Grid.Column>
                  <InputLabel title={t("checkout.CardNumber")}>
                    <div className="number-input">
                      <div className="carriers">
                        {CreditCard.Validator.types.map((t) => (
                          <Image
                            key={t.id}
                            wrapped={true}
                            className={`carrier-icon${data?.type?.id === t.id ? " active" : ""}`}
                            src={require(`assets/icons/creditcards/${t.id}.png`)}
                          />
                        ))}
                      </div>
                      <Input
                        fluid={true}
                        error={!this.isFieldValid("number")}
                        name="number"
                        pattern="[\d| ]{16,22}"
                        required={true}
                        disabled={isDisabled}
                        onChange={this.onNumberFieldChange}
                        onBlur={this.onFieldBlur}
                        type="tel"
                        value={data?.number ?? ""}
                        className="cohere-block"
                      />
                    </div>
                  </InputLabel>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <InputLabel title={t("checkout.CardName")} errorText={t("checkout.CardNameError")}>
                    <Input
                      fluid={true}
                      name="holder"
                      required={true}
                      disabled={isDisabled}
                      error={!this.isFieldValid("holder")}
                      onChange={this.onFieldChange}
                      onBlur={this.onFieldBlur}
                      value={data?.holder ?? ""}
                      className="cohere-block"
                    />
                  </InputLabel>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <InputLabel title={t("checkout.CardExpires")} errorText={t("checkout.CardExpiresError")}>
                    <Input
                      fluid={true}
                      type="tel"
                      name="expiration"
                      pattern="\d\d/\d\d"
                      required={true}
                      error={!this.isFieldValid("expiration")}
                      disabled={isDisabled}
                      onChange={this.onFieldChange}
                      onBlur={this.onFieldBlur}
                      value={data?.expiration ?? ""}
                      className="cohere-block"
                    />
                  </InputLabel>
                </Grid.Column>
                <Grid.Column>
                  <InputLabel title={t("checkout.CardCVV")}>
                    <Input
                      fluid={true}
                      type="tel"
                      name="cvv"
                      error={!this.isFieldValid("cvv")}
                      required={true}
                      pattern={`\\d{${data.type?.id === "amex" ? 4 : 3}}`}
                      disabled={isDisabled}
                      onChange={this.onFieldChange}
                      onBlur={this.onFieldBlur}
                      value={data?.cvv ?? ""}
                      className="cohere-block"
                    />
                  </InputLabel>
                </Grid.Column>
              </Grid.Row>

              {installmentsData.length > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <Fields.InputLabel title={t("checkout.Installments")}>
                      <Select
                        fluid={true}
                        compact={true}
                        disabled={isDisabled}
                        options={installmentsData}
                        value={data?.installments ?? 0}
                      />
                    </Fields.InputLabel>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Forms.Grid>
          </div>
        )}
      </Translation>
    )
  }
}
