import { Data, Interface, Model } from "@chatpay/common"
import * as Fields from "components/Fields"
import * as Forms from "components/Forms"
import * as Base from "components/Forms/Base"
import i18n from "i18n"
import * as React from "react"
import { DropdownProps, Grid, Input, Select } from "semantic-ui-react"

// tslint:disable-next-line: no-empty-interface
export interface IData extends Interface.Gateway.Common.BankAccount.IBankAccount {}

interface IProps<I extends IData> extends Base.Props<I> {
  personType?: Interface.Gateway.Common.Account.Type
  isLoading?: boolean
}
interface IState<T extends IData> extends Base.State<T> {
  operation?: string
}

export class BankAccountForm extends Base.Form<IProps<IData>, IState<IData>> {
  protected formGridRef = React.createRef<Forms.Grid>()
  protected bankFieldRef = React.createRef<Fields.InputBank>()

  public static getDerivedStateFromProps(props: IProps<IData>, state: IState<IData>) {
    const country = state.data?.country ?? props.data?.country ?? Model.Country.br
    if (!state.data.account && props.data?.account && state.data.bank !== props.data.bank) {
      return {
        ...state,
        operation: BankAccountForm.extractOperationFromAccount(props.data.bank, props.data.account, props.data.type),
        data: {
          ...props.data,
          country,
        },
      }
    }
    if (!state.data.bank && !props.data?.bank && !props.isDisabled) {
      const bank =
        country === Model.Country.br
          ? Object.keys(Model.BrazilianBank.Model).sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))[0]
          : undefined
      return {
        ...state,
        data: {
          ...state.data,
          country,
          bank,
        },
      }
    }
    return null
  }

  private get accountWithoutOperation(): string | undefined {
    const { data, operation } = this.state
    if (!data.account || !operation || data.country !== Model.Country.br) {
      return data.account
    }

    const template = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, data.account)
      : undefined
    const operations = template?.operations
    if (!operations || !template) {
      return data.account
    }

    const diff = data.account.length - template.account.format.length
    const op = diff <= 0 ? data.account.slice(0, diff) : undefined
    return op && operations[op] ? data.account.slice(op.length) : data.account
  }

  public componentDidUpdate() {
    this.extractOperationFromAccountIfNeeded()
  }

  public static extractOperationFromAccount(bank: string, account: string, type?: string) {
    const template = Model.BrazilianBank.Format(bank as keyof Model.BrazilianBank.Bank, account)
    const operations = template?.operations
    if (!operations) {
      return
    }

    const diff = account.length - template.account.format.length
    const op = account.slice(0, Math.abs(diff))
    return operations[op] ? op : type ? Object.keys(operations).find((k) => operations[k]?.includes(type)) : undefined
  }

  private extractOperationFromAccountIfNeeded() {
    const { data, operation } = this.state
    if (!data.account || !data.bank || data.country !== Model.Country.br) {
      return
    }

    const op = BankAccountForm.extractOperationFromAccount(data.bank, data.account, data.type)
    if (!op || operation === op) {
      return
    }

    this.setState({ operation: op }, () => {
      this.updateFields({
        account: this.accountWithoutOperation,
      })
    })
  }

  private updateOperationOnAccountIfNeeded(old?: string, onComplete?: () => void) {
    const { data, operation } = this.state
    let account = data.account
    if (old && data.account?.slice(0, old.length) === old) {
      account = data.account.replace(old, "")
    }

    const template = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, data.account)
      : undefined
    if (operation && account && template) {
      const accountFormat = template.account
      account = operation + (account.length > accountFormat.format.length ? account.slice(operation.length) : account)
    }

    this.fieldChanged("account", account, account !== undefined && account.length > 0, null, false, onComplete, true)
  }

  private updateOperationIfNeeded() {
    const { data, operation } = this.state
    const template = data?.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, data.account)
      : undefined
    const operations = template?.operations
    if (!operations) {
      this.setState({ operation: undefined }, () => {
        this.updateOperationOnAccountIfNeeded(operation)
      })
      return
    }

    let currentOperation: string | undefined
    const op = Object.keys(operations).find((o) => {
      const name = operations && operations[o]
      return !!name?.includes(`${data.type}-${this.props.personType ?? ""}`)
    })
    currentOperation = op ?? Object.keys(operations)[0]
    this.setState({ operation: currentOperation }, () => {
      this.updateOperationOnAccountIfNeeded(operation)
    })
  }

  private onBankFieldChange = (component: Fields.InputBank, input: HTMLSelectElement) => {
    if (this.state.data?.bank === component.value || this.props.isDisabled) {
      return
    }
    this.updateFields(
      {
        agency: "",
        account: "",
      },
      () => {
        this.fieldChanged("bank", component.value, true, null, undefined, () => {
          this.updateOperationIfNeeded()
        })
      },
    )
  }

  private onInputTypeValueChange = (input: Fields.InputOption) => {
    const { name, value } = input
    if (value === this.state.data.type) {
      return
    }
    this.fieldChanged(
      name!,
      value,
      Object.keys(Interface.Gateway.Common.BankAccount.Type).includes(value!),
      null,
      false,
      () => {
        this.updateOperationIfNeeded()
      },
    )
  }

  private onOperationChange = (_, data: DropdownProps) => {
    const operation = data.value as string
    this.setState({ operation }, () => {
      this.updateOperationOnAccountIfNeeded()
    })
  }

  private format = (value: string, name: string, field?: HTMLInputElement | HTMLTextAreaElement): string => {
    const { data } = this.state
    if (data.country !== Model.Country.br) {
      return value
    }

    const template = (data?.bank && Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, value))?.[
      name
    ]
    if (!template?.format) {
      return value
    }

    const [, number, hyphen = "", digit] = template.format.match(/([#]+)(-)?([D]*)/)
    const numberLength = number.length
    const digitLength = digit?.length ?? 0

    let newValue = value.numbers()
    if (!newValue.length) {
      return ""
    }
    const valueWithoutDigit = value.replace(/-/g, "")
    if (name === "account") {
      newValue = `${valueWithoutDigit.slice(
        0,
        value.includes("-") || !hyphen ? template.format.length : numberLength + digitLength,
      )}`
    } else if (
      valueWithoutDigit.length > numberLength ||
      !hyphen ||
      (value.length === numberLength && !value.includes("-"))
    ) {
      const digit = valueWithoutDigit.slice(numberLength, numberLength + digitLength)
      newValue = `${newValue.slice(0, numberLength)}`
      if (digitLength) {
        newValue += `${hyphen}${digit.length ? digit.toUpperCase() : ""}`
      }
    }

    return newValue
  }

  protected validateField(name: string, value: any, isValid: boolean = true): { name: string; isValid: boolean } {
    if (name !== "account") {
      return super.validateField(name, value, isValid)
    }
    const { data } = this.state

    const template = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, value)
      : undefined
    if (!template?.account.pattern) {
      return super.validateField(name, value, isValid)
    }

    return {
      name,
      isValid: this.accountWithoutOperation !== undefined,
    }
  }

  protected formatField(
    name: string,
    value: any,
    isValid: boolean = true,
    field?: HTMLInputElement | HTMLTextAreaElement,
  ): any {
    const { data } = this.state
    if (data.country !== Model.Country.br) {
      return value
    }

    let newValue = value
    if (name === "agency" || name === "account") {
      newValue = this.format(value, name, field)
    }

    return newValue
  }

  protected fieldDidBlur({
    target,
    isValid = true,
    onComplete,
  }: {
    target: HTMLInputElement | HTMLTextAreaElement
    isValid?: boolean
    onComplete?: () => void
  }) {
    const { data } = this.state
    if (data.country !== Model.Country.br) {
      super.fieldDidBlur({
        target,
        isValid,
        onComplete,
      })
      return
    }
    const { name, value } = target

    if (name !== "account") {
      super.fieldDidBlur({ target, isValid })
      return
    }

    const format = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, value)
      : undefined
    const template = format?.[name]
    const valueWithoutDigit = value.replace(/-/g, "")
    const valueLength = valueWithoutDigit.length

    if (!template?.format || !valueLength) {
      super.fieldDidBlur({ target, isValid })
      return
    }

    const [, , hyphen = "", digit] = template.format.match(/([#]+)(-)?([D]*)/) ?? []
    const digitLength = digit?.length ?? 0

    const newValue = `${hyphen}${valueWithoutDigit.slice(valueLength - digitLength, valueLength)}`

    target.value = newValue
    super.fieldDidBlur({
      target,
      isValid,
      onComplete: () =>
        this.fieldChanged(
          name,
          `${this.makeZeroPadding(data, value, name)}${newValue}`,
          isValid,
          target,
          true,
          () => this.updateOperationOnAccountIfNeeded(undefined, onComplete),
          true,
        ),
    })
  }

  private makeZeroPadding(data: Data<IData>, value: string, name: string) {
    const valueWithoutDigit = value.replace(/-/g, "")
    const valueLength = valueWithoutDigit.length

    const format = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, value)
      : undefined
    const template = format?.[name]
    if (!template?.format || !valueLength) {
      return ""
    }

    const [, number, , digit] = template.format.match(/([#]+)(-)?([D]*)/)
    const pad = number.replace(/#/g, "0")
    const digitLength = digit?.length ?? 0

    let num = valueWithoutDigit.slice(0, valueLength - digitLength)
    return pad.length ? (pad + num).slice(-pad.length) : num
  }

  public render() {
    const { data, isDisabled, operation } = this.state

    const template = data.bank
      ? Model.BrazilianBank.Format(data.bank as keyof Model.BrazilianBank.Bank, data.account)
      : undefined
    const operations = Object.keys(template?.operations ?? {})
      .map((key, idx) => ({
        key: idx,
        text: `${key}`,
        value: key,
      }))
      .sort((a, b) => {
        if (a.value > b.value) {
          return 1
        }
        if (a.value === b.value) {
          return 0
        }
        return -1
      })

    let account = data.account
    if (operation && account?.slice(0, operation.length) === operation) {
      account = account.replace(operation, "")
    }
    const [, accNumber, hyphen = "", accCheck = ""] = account?.match(/([\d]+)(-)?([\dx]*)/i) ?? []
    account = accNumber ? `${parseInt(accNumber, 10)}${hyphen}${accCheck}` : ""

    const placeholder = {
      agency: template?.agency?.format?.replace(/#/g, "0"),
      account: template?.account?.format?.replace(/#/g, "0"),
    }

    return (
      <div className="BankAccountForm">
        <Forms.Grid ref={this.formGridRef}>
          <Grid.Row>
            {data.country === Model.Country.br ? (
              <Grid.Column>
                <Fields.InputBank
                  ref={this.bankFieldRef}
                  value={data?.bank}
                  account={account ?? ""}
                  disabled={isDisabled || this.props.isDisabled}
                  onChange={this.onBankFieldChange}
                />
              </Grid.Column>
            ) : (
              <Grid.Column>
                <Fields.InputLabel title={i18n.t("AccountBankAccount.bank")}>
                  <Input
                    fluid={true}
                    required={true}
                    error={!this.isFieldValid("bank")}
                    name="bank"
                    loading={this.props.isLoading}
                    disabled={isDisabled || this.props.isDisabled}
                    onChange={this.onFieldChange}
                    onBlur={this.onFieldBlur}
                    value={data?.bank ?? ""}
                  />
                </Fields.InputLabel>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row columns={"equal"}>
            <Grid.Column>
              <Fields.InputLabel
                title={i18n.t(`AccountBankAccount.agency${placeholder.agency?.includes("D") ? "AndCheck" : ""}`)}
              >
                <Input
                  data-testid={"input-agency-bank-account"}
                  fluid={true}
                  required={true}
                  error={!this.isFieldValid("agency")}
                  name="agency"
                  placeholder={placeholder.agency}
                  pattern={template?.agency.pattern}
                  loading={this.props.isLoading}
                  disabled={isDisabled || this.props.isDisabled}
                  onChange={this.onFieldChange}
                  onBlur={this.onFieldBlur}
                  value={data?.agency ?? ""}
                />
              </Fields.InputLabel>
            </Grid.Column>
            {operations.length ? (
              <Grid.Column width={4}>
                <Fields.InputLabel title={i18n.t("AccountBankAccount.operation")}>
                  <Select
                    fluid={true}
                    compact={true}
                    loading={this.props.isLoading}
                    disabled={isDisabled || this.props.isDisabled}
                    onChange={this.onOperationChange}
                    options={operations}
                    value={operation ?? ""}
                  />
                </Fields.InputLabel>
              </Grid.Column>
            ) : null}
            <Grid.Column>
              <Fields.InputLabel
                title={i18n.t(`AccountBankAccount.account${placeholder.account?.includes("D") ? "AndCheck" : ""}`)}
              >
                <Input
                  data-testid={"input-bank-account-account"}
                  fluid={true}
                  required={true}
                  error={!this.isFieldValid("account")}
                  name="account"
                  type="tel"
                  placeholder={placeholder.account}
                  loading={this.props.isLoading}
                  disabled={isDisabled || this.props.isDisabled}
                  onChange={this.onFieldChange}
                  onBlur={this.onFieldBlur}
                  value={account ?? ""}
                />
              </Fields.InputLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Fields.InputLabel title={i18n.t("AccountBankAccount.accountType")}>
                <Fields.InputOption
                  key={data.type ?? 5}
                  name="type"
                  label={i18n.t("AccountBankAccount.checking")}
                  disabled={isDisabled || this.props.isDisabled}
                  value={Interface.Gateway.Common.BankAccount.Type.cc}
                  onChange={this.onInputTypeValueChange}
                  selected={!data.type || data.type === Interface.Gateway.Common.BankAccount.Type.cc}
                  fluid={true}
                />
              </Fields.InputLabel>
            </Grid.Column>
            <Grid.Column>
              <div style={{ width: "100%", height: "24px" }} />
              <Fields.InputOption
                key={data.type ?? 6}
                name="type"
                value={Interface.Gateway.Common.BankAccount.Type.cp}
                onChange={this.onInputTypeValueChange}
                label={i18n.t("AccountBankAccount.savings")}
                fluid={true}
                selected={data.type === Interface.Gateway.Common.BankAccount.Type.cp}
                disabled={isDisabled || this.props.isDisabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Forms.Grid>
      </div>
    )
  }
}
