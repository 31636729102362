import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "@chatpay/components"

export enum ValidateTelegramActionTypes {
  OPEN = "VALIDATE_TELEGRAM_OPEN",
  CLOSE = "VALIDATE_TELEGRAM_CLOSE",
  LOAD = "VALIDATE_TELEGRAM_LOAD",
  LOADING = "VALIDATE_TELEGRAM_LOADING",
  LOAD_SUCCESS = "VALIDATE_TELEGRAM_LOAD_SUCCESS",
  LOAD_FAILURE = "VALIDATE_TELEGRAM_LOAD_FAILURE",
}

export interface IOpenAction {
  type: ValidateTelegramActionTypes.OPEN
}

export interface ICloseAction {
  type: ValidateTelegramActionTypes.CLOSE
}

export interface ILoadAction {
  type: ValidateTelegramActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: ValidateTelegramActionTypes.LOAD_SUCCESS
  code: string
}

export interface ILoadFailureAction {
  type: ValidateTelegramActionTypes.LOAD_FAILURE
  reason: string
}

export const openTelegramValidation = (): IOpenAction => ({ type: ValidateTelegramActionTypes.OPEN })

export const closeTelegramValidation = (): ICloseAction => ({ type: ValidateTelegramActionTypes.CLOSE })

export const loadTelegramValidation = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({ type: ValidateTelegramActionTypes.LOAD })

  new API.User()
    .telegramCode()
    .then((code) => dispatch(loadSuccess(code!)))
    .catch((error) => dispatch(loadFailure(error)))
}

export const loadSuccess = (code: string): ILoadSuccessAction => {
  return {
    type: ValidateTelegramActionTypes.LOAD_SUCCESS,
    code,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: ValidateTelegramActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type ValidateTelegramAction = IOpenAction | ICloseAction | ILoadAction | ILoadSuccessAction | ILoadFailureAction
