import { Fields, Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import i18n from "i18n"
import React, { useState } from "react"
import { Button, Input, Modal } from "semantic-ui-react"
import { youtubeEmbed, youtubeParser, youtubeThumbnail, YOUTUBE_REGEX } from "utils/YoutubeUtils"
import * as Yup from "yup"

const schema = Yup.string()
  .url(i18n.t("editGroup.assets.youtube.notValid"))
  .matches(YOUTUBE_REGEX, {
    message: i18n.t("editGroup.assets.youtube.mustBeYoutube"),
    excludeEmptyString: true,
  })

export interface YoutubeObject {
  id: string
  thumbnail: string
  embed: string
  url: string
}

interface IProps {
  onFinish?: (youtubeObject: YoutubeObject) => any
  onClosed?: () => any
  isOpen: boolean
}

export const GetYoutubeUrl: React.FC<IProps> = (props) => {
  const { onFinish, onClosed, isOpen } = props
  const [value, setValue] = useState<string>("")

  const onFinished = () => {
    const youtubeObject: YoutubeObject = {
      id: String(youtubeParser(value)),
      thumbnail: youtubeThumbnail(value),
      embed: youtubeEmbed(value),
      url: value,
    }
    setValue("")
    onFinish?.(youtubeObject)
  }

  const validateField = (data: string) => {
    let error: string = ""
    try {
      schema.validateSync(data)
    } catch (e: any) {
      error = !e.path ? e.message : ""
    }
    return error
  }

  return (
    <Modal
      basic={true}
      size="small"
      dimmer={"inverted"}
      onClose={onClosed}
      open={isOpen}
      closeIcon={<CloseIcon />}
      content={
        <React.Fragment>
          <Spacer />
          <Fields.InputLabel title={i18n.t("editGroup.assets.youtube.title")} errorText={validateField(value)}>
            <Input
              error={!!validateField(value)}
              fluid={true}
              value={value}
              onChange={(_, data) => setValue(data.value.trim())}
              placeholder="e.g.: https://www.youtube.com/watch?v=PcWra6DsmrY"
              autoFocus={true}
            />
          </Fields.InputLabel>
          <Spacer />
          <div>
            <Button
              disabled={!!validateField(value) || !value}
              content={i18n.t("editGroup.assets.youtube.done")}
              floated="right"
              size="small"
              color="green"
              onClick={() => onFinished()}
            />
          </div>
        </React.Fragment>
      }
    />
  )
}
