"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WPP = exports.WEB = exports.WAITLIST = exports.USER = exports.UNKNOWN_MEMBER = exports.TRACKING_REDIRECT = exports.TERMS_OF_USE_VIEWED = exports.TELEGRAM = exports.SUBSCRIPTION = exports.REVIEW_VIEWED = exports.REFUND = exports.REFERRAL = exports.RECEIVER_CREATED = exports.RECEIVER = exports.PURCHASE = exports.PRODUCT = exports.PRIVACY_POLICY_VIEWED = exports.PAYMENT = exports.MISSING_PAGE_VIEWED = exports.MEMBER_DASHBOARD = exports.MAINTENANCE_VIEWED = exports.INVOICE = exports.GROUP_MANAGEMENT = exports.GROUP_FROM_CHAT_NOT_FOUND = exports.GROUP = exports.EventsSent = exports.EXPORT_VIEWED = exports.ESCROW = exports.DASHBOARD_EXPORT_DATA = exports.CREATOR_DASHBOARD = exports.CREATOR = exports.CONTENT = exports.CHECK_LOG_VIEWED = exports.CHECKOUT_PAYMENT_VIEWED = exports.BOT = exports.BECOME_A_CREATOR = exports.AUTHENTICATION = void 0;
var CREATOR = {
  VIEWED: "CreatorPageViewed",
  BUY_CLICKED: "CreatorBuyClicked",
  LOGIN_CLICKED: "CreatorLoginClicked",
  CREATE_GROUP_CLICKED: "CreatorCreateGroupClicked",
  ONBOARDING_INFO_VIEWED: "CreatorOnboardingInfoViewed",
  ONBOARDING_PROFILE_VIEWED: "CreatorOnboardingProfileViewed",
  ONBOARDING_LOCATION_VIEWED: "CreatorOnboardingLocationViewed",
  ONBOARDING_SEGMENTATION_VIEWED: "CreatorOnboardingSegmentationViewed",
  ONBOARDING_PROFILE_CLICKED: "CreatorOnboardingProfileClicked",
  ONBOARDING_CREATE_NEW_GROUP_CLICKED: "CreatorOnboardingCreateNewGroupClicked",
  ONBOARDING_MIGRATE_GROUP_CLICKED: "CreatorOnboardingMigrateGroupClicked",
  ONBOARDING_SKIP_CLICKED: "CreatorOnboardingSkipClicked"
};
exports.CREATOR = CREATOR;
var PRODUCT = {
  COMMUNITY_TYPE_CLICKED: "ProductCreationTypeSubmitted",
  CREATE_CLICKED: "ProductCreateClicked",
  DELETE_CLICKED: "ProductDetailDeleteClicked",
  DELETED: "ProductDelete",
  HIDE_CLICKED: "ProductDetailHideClicked",
  SHOW_CLICKED: "ProductDetailShowClicked",
  SHOWED: "ProductShow",
  DETAIL_CLICKED: "ProductDetailSeeClicked",
  STATUS_CLICKED: "ProductStatusClicked",
  CREATE_SUCCESSUL: "ProductCreateSuccessful"
};
exports.PRODUCT = PRODUCT;
var GROUP = {
  VIEWED: "ProductPageViewed",
  BUY_CLICKED: "ProductBuyClicked",
  COPY_CLICKED: "ResourceCopyClicked",
  PAGE_CLICKED: "ResourcePageClicked",
  LOGIN_CLICKED: "GroupLoginClicked",
  CREATE_GROUP_CLICKED: "GroupCreateGroupClicked",
  PUBLISH_SUCCESSFUL: "GroupPublishSuccessful",
  WHITE_LIST_CLICKED: "ResourceFreeMembersClicked",
  MEMBER_DETAIL_CLICKED: "ProductMemberDetailClicked",
  MEMBER_CONTACT_CLICKED: "ProductMemberContactClicked",
  MEMBER_REFRESH_CLICKED: "ProductMemberRefreshClicked",
  JOIN_CLICKED: "ResourceJoinClicked",
  CREATE_NEXT_STEP_CLICKED: "ProductCreateNextClicked",
  REMOVE_USER_CLICKED: "ResourceRemoveUserClicked",
  FILTER_MEMBER_CLICKED: "ProductMemberFilterClicked",
  ONBOARDING_ADVANTAGES_CLICKED: "ProductOnboardingAdvantagesClicked",
  ONBOARDING_INFO_CLICKED: "ProductOnboardingInfoClicked",
  ONBOARDING_FAQ_CLICKED: "ProductOnboardingFAQClicked",
  ONBOARDING_ASSETS_CLICKED: "ProductOnboardingAssetsClicked",
  CREATE_REVIEW: "GroupCreateReview",
  POST_VIEWED: "ProductPostViewed",
  NOTIFICATIONS_VIEWED: "GroupNotificationsViewed",
  CREATED: "GroupCreated",
  UPDATED: "GroupUpdated",
  DELETE: "GroupDelete",
  SHOW: "GroupShow",
  HIDE: "GroupHide",
  ENABLE_BOT: "GroupEnableBot",
  DISABLE_BOT: "GroupDisableBot",
  USE_WHITED_LISTED_BY_ADMIN: "UseWhitedlistedByAdmin",
  MEMBER_JOINED: "MemberJoinedGroup",
  MEMBER_IDENTIFIED_IN: "MemberIdentifiedInGroup",
  ADMIN_JOINED: "AdminJoinedGroup",
  RESOURCE_FREE_MEMBER_CLICKED: "ResourceAddFreeMemberClicked"
};
exports.GROUP = GROUP;
var GROUP_MANAGEMENT = {
  VERIFY_ACCOUNT_CLICKED: "GroupManagementVerifyAccountClicked"
};
exports.GROUP_MANAGEMENT = GROUP_MANAGEMENT;
var CREATOR_DASHBOARD = {
  PAGE_VIEWED: "CreatorDashboardPageViewed",
  LEFT_NAV_DASHBOARD_VIEWED: "CreatorDashboardLeftNavDashboardViewed",
  LEFT_NAV_MEMBERS_VIEWED: "CreatorDashboardLeftNavMembersViewed",
  LEFT_NAV_SUBSCRIBERS_VIEWED: "CreatorDashboardLeftNavSubscribersViewed",
  LEFT_NAV_AFFILIATES_VIEWED: "CreatorDashboardLeftNavAffiliatesViewed",
  LEFT_NAV_COUPONS_VIEWED: "CreatorDashboardLeftNavCouponsViewed",
  LEFT_NAV_REFUNDS_VIEWED: "CreatorDashboardLeftNavRefundsViewed",
  LEFT_NAV_WALLET_VIEWED: "CreatorDashboardLeftNavWalletViewed",
  LEFT_NAV_REPORTS_VIEWED: "CreatorDashboardLeftNavReportsViewed",
  MY_GROUPS_CLICKED: "CreatorDashboardMyProductsClicked",
  MY_GROUPS_ARTICLE_CLICKED: "CreatorDashboardMyGroupsArticleClicked",
  REFERRAL_CARD_CLICKED: "CreatorDashboardReferralCardClicked",
  GROUP_STATUS_CLICKED: "CreatorDashboardProductStatusClicked",
  GROUP_STATUS_SUMITTED: "CreatorDashboardGroupStatusSubmitted",
  PRODUCT_STATUS_FILTER: "CreatorDashboardProductStatusFilterClicked",
  WAITLIST_CLICKED: "CreatorDashboardWaitlistClicked",
  WAITLIST_VIEWED: "CreatorDashboardWaitlistViewed",
  GROUP_WAITLIST_CLICKED: "CreatorDashboardGroupWaitlistClicked",
  WAITLIST_FILTER_CLICKED: "CreatorDashboardWaitlistFilterClicked",
  WAITLIST_EXPORT_CLICKED: "CreatorDashboardWaitlistExportClicked",
  OPERATION_DASHBOARD_VIEWED: "CreatorDashboardSubMenuOperationViewed",
  // -- Offers pane ------------------------------------------------------------
  OFFER_VIEWED: "CreatorDashboardOfferViewed",
  // Details about upsell events are in go/upsell-events.
  UPSELL_TOGGLE_CLICKED: "CreatorOfferUpsellToggleClicked",
  UPSELL_PAGINATION_CLICKED: "CreatorOfferUpsellPaginationClicked",
  UPSELL_CANCEL_CLICKED: "CreatorOfferUpsellCancelClicked",
  UPSELL_NEXT_CLICKED: "CreatorOfferUpsellNextClicked",
  UPSELL_CREATE_CLICKED: "CreatorOfferUpsellCreateClicked",
  UPSELL_EDIT_CLICKED: "CreatorOfferUpsellEditClicked",
  UPSELL_DELETE_CLICKED: "CreatorOfferUpsellDeleteClicked",
  UPSELL_DELETE_CONFIRM_CLICKED: "CreatorOfferUpsellDeleteConfirmClicked",
  UPSELL_ERROR_SHOWED: "CreatorOfferUpsellErrorShowed",
  // Details about order bump events are in go/order-bump-events.
  ORDER_BUMP_TOGGLE_CLICKED: "CreatorOfferOrderBumpToggleClicked",
  ORDER_BUMP_PAGINATION_CLICKED: "CreatorOfferOrderBumpPaginationClicked",
  ORDER_BUMP_CANCEL_CLICKED: "CreatorOfferOrderBumpCancelClicked",
  ORDER_BUMP_NEXT_CLICKED: "CreatorOfferOrderBumpNextClicked",
  ORDER_BUMP_CREATE_CLICKED: "CreatorOfferOrderBumpCreateClicked",
  ORDER_BUMP_EDIT_CLICKED: "CreatorOfferOrderBumpEditClicked",
  ORDER_BUMP_DELETE_CLICKED: "CreatorOfferOrderBumpDeleteClicked",
  ORDER_BUMP_DELETE_CONFIRM_CLICKED: "CreatorOfferOrderBumpDeleteConfirmClicked",
  ORDER_BUMP_ERROR_SHOWED: "CreatorOfferOrderBumpErrorShowed"
};
exports.CREATOR_DASHBOARD = CREATOR_DASHBOARD;
var MEMBER_DASHBOARD = {
  PAGE_VIEWED: "MemberDashboardPageViewed",
  GROUP_PAGE_VIEWED: "MemberDashboardGroupPageViewed",
  USE_CREATOR_MODE_CLICKED: "MemberDashboardUseCreatorModeClicked",
  GROUP_NAME_CLICKED: "MemberDashboardProductNameClicked",
  ACCESS_CONTENT_CLICKED: "MemberDashboardContentClicked",
  ENTER_GROUP_CLICKED: "MemberDashboardEnterGroupClicked",
  CHANGE_PAYMENT_METHOD_CLICKED: "MemberDashboardChangePaymentMethodClicked",
  CHANGE_PLAN_CLICKED: "MemberDashboardChangePlanClicked",
  CHANGE_PLAN_SUBMITTED: "MemberDashboardChangePlanSubmitted",
  CHANGE_PAYMENT_METHOD_SUBMITTED: "MemberDashboardChangePaymentMethodSubmitted",
  CHANGE_PLAN_SUCCESS: "MemberDashboardChangePlanSuccesful",
  CHANGE_PLAN_FAILED: "MemberDashboardChangePlanRejected"
};
exports.MEMBER_DASHBOARD = MEMBER_DASHBOARD;
var AUTHENTICATION = {
  AUTH_EMAIL_CODE_PAGE: "AuthEmailCodeViewed",
  AUTH_EMAIL_PAGE: "AuthEmailPageViewed",
  AUTH_EMAIL_VERIFICATION_PAGE: "AuthEmailPasswordViewed",
  AUTH_PHONE_PAGE: "AuthPhoneViewed",
  AUTH_CODE_PAGE: "AuthCodeViewed",
  SUCCESSFUL: "AuthLoginSuccessful",
  LOGIN_ERROR: "AuthLoginError",
  RECEIVE_CODE: "AuthReceiveCodeSubmitted",
  LOGIN_SUBMITTED: "AuthLoginSubmitted",
  LOGIN_WITH_LINK_SUCCESSFUL: "AuthLoginWithLinkSuccessful",
  LOGIN_WITH_LINK_FAILED: "AuthLoginWithLinkFailed",
  UPDATED: "UserAuthUpdated",
  PASSWORD_UPDATED: "UserPasswordUpdated"
};
exports.AUTHENTICATION = AUTHENTICATION;
var ESCROW = {
  AMOUNT_ADDED: "EscrowAmountAdded",
  // escrowed
  AMOUNT_RELEASED: "EscrowAmountReleased",
  // released
  AMOUNT_REVERTED: "EscrowAmountReverted" // reverted

};
exports.ESCROW = ESCROW;
var WAITLIST = {
  CREATOR_DASHBOARD_GROUP_STATUS_SUMITTED: "CreatorDashboardGroupStatusSubmitted",
  CLICKED: "WaitlistClicked",
  VIEWED: "WaitlistViewed",
  CREATOR_DASHBOARD_GROUP_WAITLIST_CLICKED: "CreatorDashboardGroupWaitlistClicked",
  FILTER_CLICKED: "WaitlistFilterClicked",
  EXPORT_CLICKED: "WaitlistExportClicked",
  CONTACT_CLICKED: "WaitlistContactClicked"
};
exports.WAITLIST = WAITLIST;
var WEB = {
  MAIN_MENU_VERIFY_BANK_ACCOUNT_CLICKED: "WebMainMenuVerifyBankAccountClicked",
  MAIN_MENU_TIPS_CLICKED: "WebMainMenuTipsClicked",
  MAIN_MENU_PROFILE_VIEWED: "WebMainMenuProfileViewed",
  MAIN_MENU_VERIFY_BANK_ACCOUNT_VIEWED: "WebMainMenuVerifyBankAccountViewed",
  MAIN_MENU_TIPS_VIEWED: "WebMainMenuTipsViewed",
  MAIN_MENU_FAQ_VIEWED: "WebMainMenuFAQViewed",
  MAIN_MENU_BLOG_VIEWED: "WebMainMenuBlogViewed",
  MAIN_MENU_GUIDE_VIEWED: "WebMainMenuGuideViewed",
  MAIN_MENU_CREATE_GROUP_CLICKED: "WebMainMenuCreateProductClicked",
  FOOTER_GUIDE_CLICKED: "WebFooterGuideClicked",
  FOOTER_MARKETPLACE_CLICKED: "WebFooterMarketplaceClicked"
};
exports.WEB = WEB;
var SUBSCRIPTION = {
  CANCEL_CLICKED: "SubscriptionCancelClicked",
  CANCEL_CONFIRMED_CLICKED: "SubscriptionCancelConfirmedClicked",
  RECEIPT_CLICKED: "SubscriptionReceiptClicked",
  PLAN_INTERVAL_VIEWED: "SubscriptionPlanIntervalViewed",
  ADMIN_ACTIVATE: "SubscriptionAdminActivated",
  USER_ACTIVATE: "SubscriptionUserActivated",
  ADMIN_SUSPENDED: "SubscriptionAdminSuspended",
  USER_SUSPEND: "SubscriptionUserSuspend",
  CANCELED: "SubscriptionCanceled"
};
exports.SUBSCRIPTION = SUBSCRIPTION;
var INVOICE = {
  PROTESTED: "InvoiceProtested",
  CHARGED_BACK: "InvoiceChargedBack",
  REINSTATED: "InvoiceReinstated"
};
exports.INVOICE = INVOICE;
var PAYMENT = {
  COLLECTED: "CreatorPaymentSuccesful",
  RECEIVED: "UserPaymentReceived",
  FAILED: "UserPaymentRejected"
};
exports.PAYMENT = PAYMENT;
var UNKNOWN_MEMBER = {
  ADDED: "UnknownMemberAdded",
  IGNORED: "UnknownMemberIgnored",
  AUTHORIZED: "UnknownMemberAuthorized",
  REMOVED: "UnknownMemberRemoved",
  WHITE_LISTED: "UnknownMemberWhitelisted"
};
exports.UNKNOWN_MEMBER = UNKNOWN_MEMBER;
var CONTENT = {
  MEMBER_CONTENT_PAGE_VIEWED: "MemberContentPageViewed",
  MY_CONTENT_PAGE_VIEWED: "MyContentPageViewed",
  CONTENT_CREATE_CLICKED: "ContentCreateClicked",
  CONTENT_POST_SUBMITTED: "ContentPostSubmitted",
  CONTENT_PAGE_CANCEL_LOADING_DIALOG_CLICKED: "ContentPageCancelLoadingDialogClicked",
  CONTENT_PAGE_POST_PUBLISHED_VIEWED: "ContentPagePostPublishedViewed",
  CONTENT_PAGE_ALERT_VIEWED: "ContentPageAlertViewed",
  CONTENT_PAGE_ERROR_VIEWED: "ContentPageErrorViewed"
};
exports.CONTENT = CONTENT;
var PURCHASE = {
  FINISH_PURCHASE_STARTED: "CheckoutPurchaseStarted",
  FINISH_PURCHASE_REJECTED: "CheckoutPurchaseRejected",
  FINISH_PURCHASE_SUCCESSFUL: "CheckoutPurchaseSuccessful",
  FINISH_PURCHASE_FAILED: "CheckoutPurchaseFailed",
  FINISH_PURCHASE_SUBMITTED: "CheckoutPurchaseSubmitted"
};
exports.PURCHASE = PURCHASE;
var USER = {
  CREDIT_CARD_VIEWED: "UserCreditCardViewed",
  REFERRAL_VIEWED: "UserReferralViewed",
  REFUND_VIEWED: "UserRefundViewed",
  REFERRAL_CREATED: "UserReferralCreated"
};
exports.USER = USER;
var BOT = {
  USER_REMOVED: "BotUserRemoved",
  ADMIN_PROMOTED: "BotAdminPromoted",
  TEAM_ADMIN_PROMOTION_SCHEDULED: "BotTeamAdminPromotionScheduled",
  NOTIFICATION_ADMIN_PROMOTED: "BotNotificationAdminPromoted",
  JOIN_CHAT: "BotJoinChat",
  USER_REMOVAL_NOTIFICATION_RECEIVED: "BotUserRemovalNotificationReceived",
  WELCOME_MESSAGE_RECEIVED: "BotWelcomeMessageReceived",
  RENEWAL_MESSAGE_RECEIVED: "BotRenewalMessageReceived",
  SEND_NOTIFICATION_MESSAGE: "BotSendNotificationMessage",
  CREATE_GROUP_REQUEST: "BotCreateGroupRequest",
  CREATE_GROUP_DONE: "BotCreateGroupDone",
  USER_LEFT_GROUP: "BotUserLeftGroup",
  USER_JOINED_GROUP: "BotUserJoinedGroup",
  USER_JOINED_GROUP_OUT_OF_HUBLA: "BotUserJoinedGroupOutOfHubla",
  NOTIFICATION_GROUP_CREATED: "BotNotificationGroupCreated",
  NOTIFICATION_GROUP_NOT_FOUND: "BotNotificationGroupNotFound"
};
exports.BOT = BOT;
var TELEGRAM = {
  GROUP_CREATION_REQUEST: "BotTelegramGroupCreationRequest",
  GROUP_CREATION_DONE: "BotTelegramGroupCreationDone",
  VERIFICATION_START: "BotTelegramVerificationStart"
};
exports.TELEGRAM = TELEGRAM;
var WPP = {
  GROUP_CREATION_REQUEST: "BotWppGroupCreationRequest",
  GROUP_CREATION_DONE: "BotWppGroupCreationDone",
  MESSAGE_IN_CHAT: "BotMessageInChat",
  NOTIFICATION_ERROR: "BotWhatsAppNotificationError"
};
exports.WPP = WPP;
var REFUND = {
  REACTIVATED: "RefundReactivated",
  CANCELED: "RefundCanceled",
  REJECTED: "RefundRejected",
  ACCEPTED: "RefundSuccessful",
  REQUESTED: "RefundSubmitted"
};
exports.REFUND = REFUND;
var BECOME_A_CREATOR = {
  INFO_PAGE_VIEWED: "BecomeACreatorInfoPageViewed",
  ASSETS_PAGE_VIEWED: "BecomeACreatorAssetsPageViewed",
  DESCRIPTION_PAGE_VIEWED: "BecomeACreatorDescriptionPageViewed",
  ADVANTAGES_PAGE_VIEWED: "BecomeACreatorAdvantagesPageViewed",
  FAQ_PAGE_VIEWED: "BecomeACreatorFaqPageViewed"
};
exports.BECOME_A_CREATOR = BECOME_A_CREATOR;
var REFERRAL = {
  SOCIAL_MEDIA_BUTTON_CLICKED: "ReferralSocialMediaButtonClicked",
  INVITE_COPY_BUTTON_CLICKED: "ReferralInviteCopyButtonClicked",
  INFO_WRAPPER_CLICKED: "ReferralInfoWrapperClicked",
  REFERRER_NEW_REFERRAL: "ReferrerReferralCreated",
  REFERRED_FIRST_SALE: "ReferredFirstSale"
};
exports.REFERRAL = REFERRAL;
var RECEIVER = {
  INVITED_SUCCESSFUL: "ReceiverInvitedSuccessful",
  INVITED_REJECTED: "ReceiverInvitedRejected",
  INVITED_EMAIL_SENT: "ReceiverInviteEmailSent",
  EDIT_INVITE_EMAIL_SENT: "ReceiverEditInviteEmailSent",
  INVITE_ACCEPT_SUCCESSFUL: "ReceiverInviteAcceptSuccessful",
  INVITE_ACCEPT_REJECTED: "ReceiverInviteAcceptRejected",
  INVITE_REJECT_SUCCESSFUL: "ReceiverInviteRejectSuccessful",
  RECEIVER_INVITED_CLICKED: "ReceiverInviteClicked",
  RECEIVER_INVITED_ACCEPTED_CLICKED: "ReceiverInvitedAcceptedClicked",
  RECEIVER_INVITED_REJECTED_CLICKED: "ReceiverInvitedRejectedClicked",
  PARTNERSHIP_VIEWED: "PartnershipViewed",
  RECEIVER_INVITE_SUBMITTED: "ReceiverInviteSubmitted",
  CANCELLATION_REQUEST_SUBMITTED: "CancellationRequestSubmitted",
  ACCEPT_CANCELLATION_REQUEST_SUBMITTED: "AcceptCancellationRequestSubmitted",
  REJECT_CANCELLATION_REQUEST_SUBMITTED: "RejectCancellationRequestSubmitted",
  CANCEL_CANCELLATION_REQUEST_SUBMITTED: "CancelCancellationRequestSubmitted",
  RECEIVER_INVITE_CLICKED: "ReceiverInviteClicked",
  RECEIVER_INVITE_VIEWED: "ReceiverInviteViewed",
  RECEIVER_INVITE_CARD_CLICKED: "ReceiverInviteCardClicked",
  RECEIVER_PARTNERS_TAB_VIEWED: "ReceiverPartnersTabViewed",
  PARTNER_EDIT_INVITE_CLICKED: "PartnerEditInviteClicked",
  PARTNERSHIP_EDIT_CLICKED: "PartnershipEditClicked",
  CANCELED: "ReceiverCanceledSuccessful",
  CANCELLATION_REQUESTED: "ReceiverCancellationRequestedSuccessful",
  CANCELLATION_REJECTED: "ReceiverCancellationRejectedSuccessful",
  CANCELLATION_REQUEST_CANCELED: "ReceiverCancellationRequestCanceledSuccessful"
};
exports.RECEIVER = RECEIVER;
var MISSING_PAGE_VIEWED = "MissingPageViewed";
exports.MISSING_PAGE_VIEWED = MISSING_PAGE_VIEWED;
var CHECK_LOG_VIEWED = "CheckLogViewed";
exports.CHECK_LOG_VIEWED = CHECK_LOG_VIEWED;
var CHECKOUT_PAYMENT_VIEWED = "CheckoutPaymentViewed";
exports.CHECKOUT_PAYMENT_VIEWED = CHECKOUT_PAYMENT_VIEWED;
var EXPORT_VIEWED = "ExportViewed";
exports.EXPORT_VIEWED = EXPORT_VIEWED;
var MAINTENANCE_VIEWED = "MaintenanceViewed";
exports.MAINTENANCE_VIEWED = MAINTENANCE_VIEWED;
var PRIVACY_POLICY_VIEWED = "PrivacyPolicyViewed";
exports.PRIVACY_POLICY_VIEWED = PRIVACY_POLICY_VIEWED;
var REVIEW_VIEWED = "ReviewViewed";
exports.REVIEW_VIEWED = REVIEW_VIEWED;
var TERMS_OF_USE_VIEWED = "TermsOfUseViewed";
exports.TERMS_OF_USE_VIEWED = TERMS_OF_USE_VIEWED;
var TRACKING_REDIRECT = "TrackingRedirect";
exports.TRACKING_REDIRECT = TRACKING_REDIRECT;
var DASHBOARD_EXPORT_DATA = "DashboardExportData";
exports.DASHBOARD_EXPORT_DATA = DASHBOARD_EXPORT_DATA;
var RECEIVER_CREATED = "ReceiverCreated";
exports.RECEIVER_CREATED = RECEIVER_CREATED;
var GROUP_FROM_CHAT_NOT_FOUND = "GroupFromChatNotFound";
exports.GROUP_FROM_CHAT_NOT_FOUND = GROUP_FROM_CHAT_NOT_FOUND;
var EventsSent = [];
exports.EventsSent = EventsSent;