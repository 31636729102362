import { Reducer } from "redux"
import { ValidateTelegramAction, ValidateTelegramActionTypes } from "./ValidateTelegramActions"
import { IValidateTelegramState } from "./ValidateTelegramState"

const initialValidateTelegramState: IValidateTelegramState = {
  isOpen: false,
  isLoading: false,
  code: null,
  error: null,
}

export const ValidateTelegramReducer: Reducer<IValidateTelegramState, ValidateTelegramAction> = (
  state: IValidateTelegramState = initialValidateTelegramState,
  action: ValidateTelegramAction,
) => {
  switch (action.type) {
    case ValidateTelegramActionTypes.OPEN:
      return {
        ...state,
        isOpen: true,
      }
    case ValidateTelegramActionTypes.CLOSE:
      return {
        ...state,
        isOpen: false,
      }
    case ValidateTelegramActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
        code: null,
        error: null,
      }
    case ValidateTelegramActionTypes.LOAD_SUCCESS:
      const hasCode = action.code && action.code !== ""
      return {
        ...state,
        isLoading: false,
        code: hasCode ? action.code : null,
        error: !hasCode ? "Código indisponível. Entre em contato com o suporte." : null,
      }
    case ValidateTelegramActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        code: undefined,
        error: action.reason,
      }
    default:
      return state
  }
}
