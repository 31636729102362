import { Document } from "@chatpay/common"
import { CheckResourcesHasGroupIM } from "../../../check-resources-has-group-im/exports/check-resources-has-group-im"
import { useCheckProductHasContent } from "../../../check-product-has-content/exports/use-check-produt-has-content"

type Props = {
  productId: string
  offerResourcesType: Document.ResourceType[] | undefined
  isOfferDraft: boolean
  isOfferWaitlisted: boolean
  isOfferSelling: boolean
  isOfferPublished: boolean
}

export const useCheckProductHasContentOrOfferHasGroupIMResource = ({
  productId,
  offerResourcesType,
  isOfferDraft,
  isOfferWaitlisted,
  isOfferSelling,
  isOfferPublished,
}: Props) => {
  const { data } = useCheckProductHasContent({ id: productId })
  const productHasContent = data

  const offerHasGroupIMResource = new CheckResourcesHasGroupIM().execute(offerResourcesType)

  /**
   * Validation rules
   *  (
   *    isOfferDraft (this is the first step after create the product) OR
   *    isOfferWaitlisted (when the creator change offer status to waitlisted) OR
   *    !isOfferSelling && isOfferPublished (the offer is not selling but already had active sales, when the creator changes the offer status to disable sales)
   *  )
   *  AND
   *  (
   *    productHasContent (when the creator has at least one post created on the product) OR
   *    offerHasGroupIMResource(when offer resource has group IM)
   *  )
   */
  return (
    (isOfferDraft || isOfferWaitlisted || (!isOfferSelling && isOfferPublished)) &&
    (productHasContent || offerHasGroupIMResource)
  )
}
