import { Document, Events, Helper, Interface } from "@chatpay/common"
import { Analytics, API, Service } from "@chatpay/components"
import {
  Button,
  MenuItem,
  Modal,
  ModalActions,
  ModalDescription,
  ModalTitle,
  Radio,
  Select,
  Stack,
  Text,
  TextField,
  theme,
  ThemeProvider,
  useMediaQuery,
} from "@hub-la/design-system"
import { DotsLoading } from "components"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Popup } from "semantic-ui-react"
import { getGroupUrl } from "utils/SessionUtils"
import isSellingIcon from "../../../assets/icons/waitlist/isSelling.svg"
import isWaitlistedIcon from "../../../assets/icons/waitlist/isWaitlisted.svg"
import { Option } from "./Option"
import { useShowMessageActivateSalesResourceType } from "modules/product-header/presentation/hooks/use-show-message-activate-sales-resource-type"
import { CheckResourcesHasGroupIM } from "modules/check-resources-has-group-im/usecases/check-resources-has-group-im"

type Props = {
  product: Document.Product
  group: Document.Group
  statusChanged: () => any
  originPage: GroupPublishButtonOriginPage
  closed?: () => any
  justModal?: boolean
  isPublishCohort?: boolean
  isPublish?: boolean
  isWaitlisted?: boolean
  resourceDisabled?: string
  fetchData?: () => void
}

export enum GroupPublishButtonOriginPage {
  productsPageSalesActivationButton = "productsPageSalesActivationButton",
  productStatusButton = "productStatusButton",
  boxAlert = "boxAlert",
}

enum SaleLimitOption {
  limited = "limited",
  unlimited = "unlimited",
}

export const GroupPublishButton: React.FC<Props> = (props) => {
  const {
    product,
    group,
    statusChanged,
    closed,
    justModal,
    isPublish,
    isPublishCohort,
    isWaitlisted,
    resourceDisabled,
    fetchData,
  } = props
  const [selectedStatus, setSelectedStatus] = useState<Document.GroupStepStatus>()
  const [error, setError] = useState<Error>()
  const [open, setOpen] = useState<boolean>(justModal ? true : false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [succeeded, setSucceeded] = useState<boolean>(false)
  const [groupCommunitySettingsModalOpen, setGroupCommunitySettingsModalOpen] = useState<boolean>(
    isPublishCohort ? true : false,
  )
  const [resourceMinMemberCount, setResourceMinMemberCount] = useState<number>(0)
  const [resourceMaxMemberCount, setResourceMaxMemberCount] = useState<number>(0)
  const [limitMaximumSalesOptions, setLimitMaximumSalesOptions] =
    useState<{ key: number; text: string; value: number }[]>()
  const [limitMaximumSales, setLimitMaximumSales] = useState<number>()
  const [limitMembersGroup, setLimitMembersGroup] = useState<number>()
  const [limitMembersGroupError, setLimitMembersGroupError] = useState<{ status: boolean; message?: string }>({
    status: false,
  })
  const [membersCount, setMembersCount] = useState<string>()
  const [saleLimitOptionSelected, setSaleLimitOptionSelected] = useState<SaleLimitOption | undefined>(
    SaleLimitOption.unlimited,
  )
  const [showModalResourceDisabled, setShowModalResourceDisabled] = useState<boolean>(false)
  const { t } = useTranslation()
  const isDesktop = useMediaQuery("(min-width: 768px)")

  const hasAVerifiedAccount =
    Service.Firebase.currentUser?.defaultGateway?.status === Interface.Gateway.Common.Status.accepted
  const showMessageActivateSalesResourceType = useShowMessageActivateSalesResourceType({ group, selectedStatus })
  const offerHasResourceIM = new CheckResourcesHasGroupIM().execute(group.groupResourcesType)

  const getResourceTypeName = (groupResourceType: Document.ResourceType) => {
    switch (groupResourceType) {
      case Document.ResourceType.whatsapp:
        return Interface.Resource.IResourceName.whatsapp
      case Document.ResourceType.telegram:
      case Document.ResourceType.telegram_channel:
        return Interface.Resource.IResourceName.telegram
    }
  }

  const getResourceType = (groupResourceType: Document.ResourceType) => {
    switch (groupResourceType) {
      case Document.ResourceType.whatsapp:
      case Document.ResourceType.telegram:
        return Interface.Resource.IResourceType.group
      case Document.ResourceType.telegram_channel:
        return Interface.Resource.IResourceType.channel
    }
  }

  const handleLimitMembersGroup = (value) => {
    const limit = Number(value.replace(/[^0-9]/g, ""))
    if (isNaN(limit) || limit === 0 || limit < 0 || limit < resourceMinMemberCount) {
      setLimitMembersGroupError({
        status: true,
        message: `Número inválido. O limite mínimo é de ${Helper.formatMembersCount(
          resourceMinMemberCount,
        )} e o máximo ${Helper.formatMembersCount(resourceMaxMemberCount)} pessoas por grupo.`,
      })
    } else if (Number(limit) > resourceMaxMemberCount) {
      setLimitMembersGroupError({
        status: true,
        message: `Número inválido. O limite máximo é de ${Helper.formatMembersCount(
          resourceMaxMemberCount,
        )} pessoas por grupo.`,
      })
    } else {
      setLimitMembersGroupError({ status: false })
    }

    setMembersCount(String(limit))
    setSaleLimitOptionSelected(undefined)
  }

  const onSubmit = useCallback(async () => {
    if (!selectedStatus) {
      return
    }

    if (selectedStatus === Document.GroupStepStatus.isWaitlisted) {
      Analytics.track(Events.CREATOR_DASHBOARD.GROUP_STATUS_SUMITTED, {
        groupId: group.id,
        creatorId: group.owner?.id,
        resourceType: group.groupResourcesType,
        productType: product.type,
        newStatus: selectedStatus,
      })
    }

    setIsLoading(true)

    //  start selling
    if (selectedStatus === Document.GroupStepStatus.isSelling) {
      // publish product
      if (product.groupsId?.length === 1) {
        try {
          localStorage.setItem(group.productId, new Date().toString())

          await new API.Product().publish({
            id: group.productId,
            status: Interface.Product.Status.ready,
          })
        } catch (error) {
          setIsLoading(false)
          setError(error as Error)
          return false
        }
      }
    }

    // update status group
    try {
      let changeStatusData: Interface.Group.Function.IChangeStatus = {
        groupId: group.id,
        status: selectedStatus,
      }
      // update productSettings from offer
      if (
        selectedStatus === Document.GroupStepStatus.isSelling &&
        product.type === Interface.Product.CommunityType.cohort &&
        offerHasResourceIM
      ) {
        changeStatusData.productSettings = {
          limitMaximumSales: saleLimitOptionSelected === SaleLimitOption.unlimited ? 0 : (limitMaximumSales as number),
          limitMembersGroup: Number(membersCount),
        }
      }
      await new API.Group().changeStatus(changeStatusData)
      setSucceeded(true)
    } catch (e: any) {
      console.error(e)
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [
    group.groupResourcesType,
    group.id,
    group.owner?.id,
    group.productId,
    limitMaximumSales,
    membersCount,
    offerHasResourceIM,
    product,
    saleLimitOptionSelected,
    selectedStatus,
  ])

  const handleModalClose = useCallback(() => {
    setSelectedStatus(undefined)
    setError(undefined)
    setOpen(false)
    closed && closed()
    setIsLoading(false)
    setSucceeded(false)
    setGroupCommunitySettingsModalOpen(false)
    setLimitMaximumSales(undefined)
    setLimitMembersGroup(undefined)
    setLimitMembersGroupError({ status: false })
    fetchData && fetchData()
  }, [closed, fetchData])

  const handleSubmit = useCallback(async () => {
    if (resourceDisabled && selectedStatus === Document.GroupStepStatus.isSelling) {
      handleModalClose()
      setShowModalResourceDisabled(true)
      return
    }
    if (
      product.type === Interface.Product.CommunityType.cohort &&
      selectedStatus === Document.GroupStepStatus.isSelling &&
      offerHasResourceIM
    ) {
      setGroupCommunitySettingsModalOpen(true)
    } else {
      onSubmit()
    }
  }, [handleModalClose, offerHasResourceIM, onSubmit, product, resourceDisabled, selectedStatus])

  const onFinish = async () => {
    setOpen(false)
    closed && closed()
    statusChanged()
    if (selectedStatus === Document.GroupStepStatus.isSelling) {
      window.open(getGroupUrl(group.id), "_blank")
    }
  }

  const getLimitMaximumSalesOptions = useCallback(
    (value: number) => {
      if (limitMembersGroup) {
        const options: { key: number; text: string; value: number }[] = []
        for (let i = 1; i < 101; i++) {
          const limitValue = i * value
          options.push({
            key: i,
            text: `${i} grupo${i > 1 ? "s" : ""} - ${Helper.formatMembersCount(limitValue)} vendas`,
            value: limitValue,
          })
        }
        const initialValue = options[0].value ?? 0
        if (initialValue) {
          setLimitMaximumSales(Number(initialValue))
        }
        setLimitMaximumSalesOptions(options)
      }
    },
    [limitMembersGroup],
  )

  const limitMaximumSalesChangeHandler = (value: string) => {
    setLimitMaximumSales(Number(value))
  }

  const handleSaleLimitOption = (option: SaleLimitOption) => {
    if (option === SaleLimitOption.limited) {
      setLimitMaximumSales(undefined)
    }

    if (option === SaleLimitOption.unlimited) {
      setLimitMaximumSales(resourceMaxMemberCount)
    }

    if (membersCount) {
      getLimitMaximumSalesOptions(Number(membersCount))
    } else {
      getLimitMaximumSalesOptions(resourceMaxMemberCount)
    }
    setLimitMembersGroup(resourceMaxMemberCount)
    setSaleLimitOptionSelected(option)
    setLimitMembersGroupError({ status: false })
  }

  useEffect(() => {
    const getResourceMinMemberCount = (type: Document.ResourceType): number => {
      switch (type) {
        case Document.ResourceType.whatsapp:
          return Document.ResourceMinMemberCount.whatsapp
        case Document.ResourceType.telegram:
          return Document.ResourceMinMemberCount.telegram
        case Document.ResourceType.telegram_channel:
          return Document.ResourceMinMemberCount.telegram_channel
      }
    }

    const getResourceMaxMemberCount = (type: Document.ResourceType): number => {
      switch (type) {
        case Document.ResourceType.whatsapp:
          return Document.ResourceMaxMemberCount.whatsapp
        case Document.ResourceType.telegram:
          return Document.ResourceMaxMemberCount.telegram
        case Document.ResourceType.telegram_channel:
          return Document.ResourceMaxMemberCount.telegram_channel
      }
    }

    if (group.groupResourcesType.length > 0) {
      const resourceType = group.groupResourcesType[0]
      getLimitMaximumSalesOptions(getResourceMaxMemberCount(resourceType))

      setResourceMinMemberCount(getResourceMinMemberCount(resourceType))
      setResourceMaxMemberCount(getResourceMaxMemberCount(resourceType))
      setLimitMembersGroup(getResourceMaxMemberCount(resourceType))
      setLimitMaximumSales(getResourceMaxMemberCount(resourceType))
      setMembersCount(getResourceMaxMemberCount(resourceType).toString())
    }
  }, [getLimitMaximumSalesOptions, group])

  // Modal control to skip product activation or waiting list.
  // These props are sent by the GroupStatusButton
  useEffect(() => {
    if (justModal) {
      if (isWaitlisted) {
        setSelectedStatus(Document.GroupStepStatus.isWaitlisted)
      }
      if (isPublish || isPublishCohort) {
        setSelectedStatus(Document.GroupStepStatus.isSelling)
      }
      handleSubmit()
    }
  }, [justModal, isPublish, isPublishCohort, isWaitlisted, handleSubmit])

  if (succeeded) {
    return (
      <ThemeProvider theme={theme.light}>
        <Modal
          size={isDesktop ? "medium" : "full"}
          close={
            (selectedStatus! && selectedStatus === Document.GroupStepStatus.isSelling) ||
            (selectedStatus! && selectedStatus === Document.GroupStepStatus.isWaitlisted)
              ? handleModalClose
              : undefined
          }
          open
        >
          <ModalDescription>
            <Stack width="100%" direction="column" spacing={3}>
              <img
                alt="community"
                src={require("assets/images/Community.png")}
                style={{ width: "140px", alignSelf: "center" }}
              />
              <Text variant="h3">
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.activeSales")
                  : t("editGroup.publishButton.activeWaitlist")}
              </Text>
              <Text>
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.doneSales")
                  : t("editGroup.publishButton.doneWaitlist")}
              </Text>
              <Button
                data-testid={"publish-group-button"}
                hierarchy="primary"
                variant="filled"
                onClick={onFinish}
                loading={isLoading}
              >
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.buttonAccessProductPage")
                  : t("editGroup.publishButton.buttonWaitlist")}
              </Button>
            </Stack>
          </ModalDescription>
        </Modal>
      </ThemeProvider>
    )
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme.light}>
        <Modal size={isDesktop ? "medium" : "full"} disableEscapeKeyDown open>
          <ModalDescription>
            <Stack direction="column" spacing={3} paddingTop={7} width="100%" textAlign="center">
              <DotsLoading />
              <Text variant="h3">
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.activateSales")
                  : t("editGroup.publishButton.activateWaitList")}
              </Text>
              {showMessageActivateSalesResourceType && (
                <Text>
                  {t("editGroup.publishButton.activateSalesResourceType", {
                    resourceType: t(
                      `editGroup.publishButton.activateSalesResourceTypeLabel.${getResourceType(
                        group.groupResourcesType[0],
                      )}`,
                    ),
                    resourceName: getResourceTypeName(group.groupResourcesType[0]),
                  })}
                </Text>
              )}
            </Stack>
          </ModalDescription>
        </Modal>
      </ThemeProvider>
    )
  }

  if (showModalResourceDisabled) {
    return (
      <ThemeProvider theme={theme.light}>
        <Modal size={isDesktop ? "medium" : "full"} onClose={handleModalClose} open>
          <React.Fragment>
            <ModalTitle>
              <Text paddingTop={7} variant="h3">
                {t("GroupStatusButton.resourceDisabled.modal.title", { resource_name: resourceDisabled })}
              </Text>
            </ModalTitle>
            <ModalDescription>
              <Text variant="body2">
                {t("GroupStatusButton.resourceDisabled.modal.description", {
                  resource_name: resourceDisabled,
                })}
              </Text>
            </ModalDescription>
            <ModalActions align="right">
              <Button hierarchy="primary" variant="filled" onClick={() => setShowModalResourceDisabled(false)}>
                Fechar
              </Button>
            </ModalActions>
          </React.Fragment>
        </Modal>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme.light}>
      <Modal size={isDesktop ? "medium" : "full"} close={handleModalClose} open={open}>
        <React.Fragment>
          {!groupCommunitySettingsModalOpen && (
            <>
              <ModalTitle>
                <Text paddingTop={7} variant="h3">
                  {t("editGroup.publishButton.activateModalTitle")}
                </Text>
              </ModalTitle>
              <ModalDescription>
                <Stack direction="column" spacing={2}>
                  <Text paddingBottom={4} variant="body2">
                    {t("editGroup.publishButton.activateModalSubtitle")}
                  </Text>
                  <Option
                    data-testid={"publish-group-sales-button"}
                    active={selectedStatus! && selectedStatus === Document.GroupStepStatus.isSelling}
                    onClick={() => setSelectedStatus(Document.GroupStepStatus.isSelling)}
                    title={t("editGroup.publishButton.salesOptionTitle")}
                    text={t("editGroup.publishButton.salesOptionSubtitle")}
                    icon={isSellingIcon}
                  />
                  <Option
                    data-testid={"publish-group-waitlist-button"}
                    active={selectedStatus! && selectedStatus === Document.GroupStepStatus.isWaitlisted}
                    onClick={() => setSelectedStatus(Document.GroupStepStatus.isWaitlisted)}
                    title={t("editGroup.publishButton.waitlistOptionTitle")}
                    text={t("editGroup.publishButton.waitlistOptionSibtitle")}
                    icon={isWaitlistedIcon}
                  />
                  <Service.ErrorMessage error={error} />
                </Stack>
              </ModalDescription>
              <ModalActions align="right">
                <Button marginRight={4} hierarchy="primary" variant="text" onClick={handleModalClose}>
                  {t("editGroup.publishButton.maybeLater")}
                </Button>
                <Button
                  disabled={!selectedStatus}
                  data-testid="submit-group-publish"
                  hierarchy="primary"
                  variant="filled"
                  onClick={handleSubmit}
                >
                  {t("editGroup.publishButton.activateMyProduct")}
                </Button>
              </ModalActions>
            </>
          )}

          {groupCommunitySettingsModalOpen &&
            product.type === Interface.Product.CommunityType.cohort &&
            offerHasResourceIM && (
              <>
                <ModalTitle>
                  <Text paddingTop={7} variant="h3">
                    {t("editGroup.publishButton.activateModalCohortSettingsTitle")}
                  </Text>
                </ModalTitle>
                <ModalDescription>
                  <Stack direction="column" spacing={3}>
                    <Text paddingBottom={4} variant="body2">
                      {t("editGroup.publishButton.activateModalCohortSettingsSubtitle")}
                    </Text>
                    <TextField
                      type="number"
                      label={t("editGroup.publishButton.activateCohortSettingsTotalMembersLimitTitle")}
                      error={limitMembersGroupError.status}
                      value={
                        membersCount ??
                        Helper.formatMembersCount(Number(membersCount)) ??
                        Helper.formatMembersCount(resourceMaxMemberCount)
                      }
                      onChange={(event) => handleLimitMembersGroup(event.target.value)}
                    />
                    {limitMembersGroupError.status && (
                      <Text paddingTop={3} variant="body2" color="error">
                        {limitMembersGroupError.message}
                      </Text>
                    )}
                    <Text paddingTop={4} variant="body2" color="onSurfaceVariant">
                      {t("editGroup.publishButton.activateCohortSettingsTotalSalesLimitInfo")}
                    </Text>
                    <Stack direction="column" spacing={1} paddingBottom={4}>
                      <Text>{t("editGroup.publishButton.activateCohortSettingsTotalSalesLimitTitle")}</Text>
                      <Radio
                        label={`${t(
                          "editGroup.publishButton.activateCohortSettingsTotalSalesLimitOptions.withoutTotalSalesLimit",
                        )}`}
                        checked={saleLimitOptionSelected === SaleLimitOption.unlimited}
                        onClick={() => handleSaleLimitOption(SaleLimitOption.unlimited)}
                      />
                      <Radio
                        label={`${t(
                          "editGroup.publishButton.activateCohortSettingsTotalSalesLimitOptions.withTotalSalesLimit",
                        )}`}
                        checked={saleLimitOptionSelected === SaleLimitOption.limited}
                        onClick={() => handleSaleLimitOption(SaleLimitOption.limited)}
                      />
                    </Stack>
                    {saleLimitOptionSelected === SaleLimitOption.limited && (
                      <>
                        <Select
                          label={t("editGroup.publishButton.defineTotalLimitSales.label")}
                          onChange={(event) => limitMaximumSalesChangeHandler(event.target.value as string)}
                          placeholder={Helper.formatMembersCount(Number(limitMaximumSales))}
                          value={limitMaximumSales}
                        >
                          {limitMaximumSalesOptions?.map((it) => (
                            <MenuItem key={it.key} value={it.value}>
                              {it.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                    <Service.ErrorMessage error={error} />
                  </Stack>
                </ModalDescription>
                <ModalActions align="right">
                  <Stack flexDirection={["column-reverse", "row"]} width={["100%", "auto"]}>
                    <Button marginRight={4} hierarchy="primary" variant="text" onClick={handleModalClose}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      data-testid={"publish-group-activate-cohort"}
                      disabled={!saleLimitOptionSelected || limitMembersGroupError.status}
                      hierarchy="primary"
                      variant="filled"
                      onClick={onSubmit}
                    >
                      {t("editGroup.publishButton.activeteCohortSettingsButton")}
                    </Button>
                  </Stack>
                </ModalActions>
              </>
            )}
        </React.Fragment>
      </Modal>

      {/** @help Não consegui simular o comportamento desse Popup para substituí-lo pelo design-system */}
      {!justModal && (
        <Popup
          eventsEnabled={false}
          content={t("editGroup.publishButton.verifyAccount")}
          disabled={hasAVerifiedAccount}
          trigger={
            <Button
              data-testid={"group-publish-button-activate-button"}
              variant="filled"
              hierarchy="primary"
              disabled={!hasAVerifiedAccount}
              onClick={() => setOpen(true)}
            >
              {t("editGroup.publishButton.activateButton")}
            </Button>
          }
        />
      )}
    </ThemeProvider>
  )
}
