import { API, Service } from "@chatpay/components"
import { Firebase } from "@chatpay/components/lib/Service"
import { ReactComponent as IconTelegramCheck } from "assets/icons/icon-telegram-check.svg"
import { Spacer } from "components"
import i18n from "i18n"
import React, { useCallback, useEffect, useState } from "react"
import { Button } from "semantic-ui-react"
import { stopPropagation } from "utils/Clicks"
import { Headers } from "../Headers"
import { Basic, BasicProps } from "./Basic"

interface IProps extends BasicProps {
  header: { title: string; subtitle: string }
  onFinished?: (imid: string) => any
}
export const TelegramVerification: React.FunctionComponent<IProps> = (props) => {
  return (
    <Basic {...props}>
      <TelegramVerificationForm onFinished={props.onFinished} header={props.header} />
    </Basic>
  )
}

interface ITelegramVerificationProps {
  header: { title: string; subtitle: string }
  onFinished?: (imid: string) => any
}

const TelegramVerificationForm: React.FunctionComponent<ITelegramVerificationProps> = ({ onFinished, header }) => {
  const [telegramCode, setTelegramCode] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()

  const telegramId = Firebase.currentUser?.ims?.telegram

  const fetchTelegramCode = useCallback(async () => {
    try {
      setLoading(true)
      const code = await new API.User().telegramCode()
      setTelegramCode(code)
    } catch (e) {
      console.error(e)
      setError(e as Error)
    } finally {
      setLoading(false)
    }
  }, [setTelegramCode])

  useEffect(() => {
    fetchTelegramCode()
  }, [fetchTelegramCode])

  useEffect(() => {
    if (telegramId !== undefined && telegramId !== null) {
      onFinished?.(telegramId)
    }
  }, [telegramId, onFinished])

  const onSubmit = (event) => {
    stopPropagation(event)
    window.open(`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}?start=${telegramCode}`, "_blank")
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <IconTelegramCheck />
      </div>
      {Headers.Basic(header.title, header.subtitle)}
      <Spacer />
      <Button
        color="blue"
        fluid={true}
        loading={loading}
        onClick={(event) => onSubmit(event)}
        content={i18n.t("TelegramVerification.button")}
        disabled={loading}
      />
      <Spacer />
      {error ? <Service.ErrorMessage error={error} /> : null}
    </>
  )
}
