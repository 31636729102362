import { Document } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { copyToClipboard } from "components/Helpers/CopyToClipboard"
import { Spacer } from "components"
import i18n from "i18n"
import * as React from "react"
import { Button, Icon } from "semantic-ui-react"
import ActionPopup from "../../Dialogs/ActionPopup"
import "./PaymentBankSlip.scss"

interface IProps {
  group?: Document.Group | null
  transaction?: Document.Transaction | null
}

class PaymentBankSlip extends React.Component<IProps, {}> {
  public render() {
    const { transaction, group } = this.props
    const user = Service.Firebase.currentUser
    const options = { year: "numeric", month: "short", day: "numeric" } as Intl.DateTimeFormatOptions
    return (
      <div style={{ maxWidth: "375px", margin: "auto" }}>
        <div
          style={{
            backgroundColor: "rgba(239, 214, 81, 0.34)",
          }}
          className="group-detail-status-box"
        >
          <div className="cp text big black bold">
            <Icon name="exclamation circle" />
            {i18n.t("paymentModals.bankSlip.title")}
          </div>
          <Spacer size="small" />
          <li className="cp text">{i18n.t("paymentModals.bankSlip.subtitle")}</li>
          {group?.acceptsBankSlipReceipt ? (
            <li className="cp text">{i18n.t("paymentModals.bankSlip.acceptsReceipt")}</li>
          ) : null}
          <Spacer size="small" />
        </div>
        <Spacer size="small" />
        {transaction?.expiresAt ? (
          <div className="cp text large center">
            Vencimento: {transaction?.expiresAt.toDate().toLocaleDateString(undefined, options)}
          </div>
        ) : null}
        <Spacer size="small" />
        <div className="cp text large center">{i18n.t("paymentModals.bankSlip.sentTo")}</div>
        <div className="cp text large bold black center">{user?.email}</div>
        <Spacer />
        <div className="cp text big bold center black" style={{ wordBreak: "break-word" }}>
          {transaction?.identification}
        </div>
        <Spacer size="small" />
        <div style={{ textAlign: "center" }}>
          <ActionPopup text={i18n.t("paymentModals.codeCopied")}>
            <Button
              basic={true}
              icon="copy outline"
              content={i18n.t("paymentModals.copyCode")}
              size="tiny"
              onClick={() => copyToClipboard(transaction?.identification ?? "")}
            />
          </ActionPopup>
        </div>
        <Spacer />
        <div style={{ maxWidth: "265px", margin: "auto" }}>
          <Button
            color="green"
            fluid={true}
            content={i18n.t("paymentModals.bankSlip.download")}
            onClick={() => window.open(transaction?.pdf!, "_blank")}
          />
        </div>
      </div>
    )
  }
}

export default PaymentBankSlip
