import { BillingAPI, Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import * as Fields from "components/Fields"
import { routes } from "@chatpay/common"
import i18n from "i18n"
import React, { createRef } from "react"
import { Translation } from "react-i18next"
import { DayRange } from "react-modern-calendar-datepicker"
import { Button, Checkbox, Divider, Dropdown, DropdownItemProps, Modal, Ref } from "semantic-ui-react"
import "./Export.scss"

interface IProps {
  data: "invoice" | "subscription"
  status?: BillingAPI.IDashboard.DashboardStatus | Document.SubscriptionStatus
  groupFilter?: string
  query?: string
  range?: [Date, Date]
  groups?: Document.Group[]
}

interface IState {
  status?: BillingAPI.IDashboard.DashboardStatus | Document.SubscriptionStatus
  statusFilterText: string
  query?: boolean
  groupFilter?: string
  groupFilterText?: string
  range?: [Date, Date]
  type?: Document.ExportTypes
  isOpen: boolean
  isLoading: boolean
  error?: Error
}

class ExportModal extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    status: undefined,
    statusFilterText: i18n.t("adminDashboard.all"),
    query: undefined,
    range: undefined,
    type: undefined,
    isOpen: false,
    isLoading: false,
  }

  private modal = createRef<HTMLElement>()

  constructor(props) {
    super(props)
    this.onDateChange = this.onDateChange.bind(this)
  }

  private onOpen = () => {
    this.setState({
      isOpen: true,
      status: this.props.status,
      statusFilterText: this.handleStatusFilterText(this.props.status),
      groupFilter: this.props.groupFilter,
      groupFilterText: this.props.groupFilter ? this.getGroupName(this.props.groupFilter) : i18n.t("activity.all"),
      query: this.props.query ? true : false,
      range: this.props.range,
      type: Document.ExportTypes.xlsx,
    })
  }

  private options() {
    if (this.props.data === "subscription") {
      return [
        {
          key: 1,
          text: i18n.t("adminDashboard.all"),
          value: undefined,
        },
        {
          key: 2,
          text: i18n.t("subscription.activated"),
          value: Document.SubscriptionStatus.activated,
        },
        {
          key: 3,
          text: i18n.t("subscription.expired"),
          value: Document.SubscriptionStatus.expired,
        },
        {
          key: 4,
          text: i18n.t("subscription.suspended"),
          value: Document.SubscriptionStatus.suspended,
        },
      ]
    } else {
      return [
        {
          key: 1,
          text: i18n.t("adminDashboard.all"),
          value: undefined,
        },
        {
          key: 2,
          text: i18n.t("invoice.paid"),
          value: BillingAPI.IDashboard.DashboardStatus.paid,
        },
        {
          key: 3,
          text: i18n.t("invoice.pending"),
          value: BillingAPI.IDashboard.DashboardStatus.pending,
        },
        {
          key: 4,
          text: i18n.t("invoice.expired"),
          value: BillingAPI.IDashboard.DashboardStatus.canceled,
        },
      ]
    }
  }

  private handleStatusFilter = (
    status: Document.SubscriptionStatus | BillingAPI.IDashboard.DashboardStatus | undefined,
  ) => {
    this.setState({ status, statusFilterText: this.handleStatusFilterText(status) })
  }
  private handleStatusFilterText = (
    statusFilter: Document.SubscriptionStatus | BillingAPI.IDashboard.DashboardStatus | undefined,
  ) => {
    if (statusFilter) {
      return i18n.t(`${this.props.data}.${statusFilter}`)
    } else {
      return i18n.t("adminDashboard.all")
    }
  }

  private onDateChange(range: DayRange) {
    if (range.from && range.to) {
      const createdAt = [
        new Date(range.from?.year, range.from?.month - 1, range.from?.day),
        new Date(range.to?.year, range.to?.month - 1, range.to?.day),
      ]
      const startDate = createdAt[0]
      startDate.setHours(0, 0, 0, 0)
      const endDate = createdAt[1]
      endDate.setHours(23, 59, 59, 999)
      const createdAtFilter = [startDate, endDate] as [Date, Date]
      this.setState({
        range: createdAtFilter,
      })
    } else if (!range.from && !range.to) {
      this.setState({ range: undefined })
    }
  }

  private handleQuery = (checked: boolean | undefined) => {
    this.setState({ query: checked })
  }

  private handleType = (type: Document.ExportTypes | undefined) => {
    this.setState({ type })
  }

  public handleGroupChange = (e, { value }) => {
    this.setState({
      groupFilter: value,
      groupFilterText: this.getGroupName(value),
    })
  }

  private handleExport = async () => {
    const { type, status, range, query, groupFilter } = this.state
    this.setState({ isLoading: true })
    try {
      const response =
        this.props.data === "subscription"
          ? await new API.ExportRequest().requestSubscriptionsExport(
              type!,
              JSON.stringify({
                status,
                createdAt: range,
                query: query ? this.props.query : undefined,
                sortedBy: { createdAt: "DESC" },
              }),
            )
          : await new API.ExportRequest().requestInvoicesExport(
              type!,
              JSON.stringify({
                status,
                createdAt: range,
                query: query ? this.props.query : undefined,
                groupId: groupFilter,
              }),
            )
      window.open(routes.EXPORT.LINK + "/" + response.id)
      this.setState({ isLoading: false, isOpen: false })
    } catch (e) {
      console.error(e)
      this.setState({ isLoading: false })
    }
  }

  private getGroupName(id: string): string | undefined {
    return (this.props.groups ?? []).find((it) => it.id === id)?.name ?? i18n.t("activity.all")
  }

  render() {
    const { isOpen, isLoading, error } = this.state
    const { data } = this.props
    const groupOptions = () => {
      const groupOptions: DropdownItemProps[] = []

      groupOptions.push({
        key: "All",
        text: i18n.t("activity.all"),
        value: undefined,
      })

      this.props.groups?.forEach((it) => {
        groupOptions.push({ key: it.id, text: it.name, value: it.id! })
      })

      return groupOptions
    }
    return (
      <Ref innerRef={this.modal}>
        <Modal
          trigger={
            <Button
              content={i18n.t("adminDashboard.export")}
              basic={true}
              size="tiny"
              labelPosition="right"
              style={{ marginTop: "5px" }}
              icon="external alternate"
            />
          }
          onClose={() => this.setState({ isOpen: false })}
          onOpen={this.onOpen}
          closeOnDimmerClick={false}
          open={isOpen}
          basic={true}
          id="export-modal"
          size="small"
          dimmer="inverted"
          closeIcon={<CloseIcon />}
          content={
            <Translation>
              {(t, { i18n }) => (
                <div>
                  <div className="cp text large black bold">
                    {t("adminDashboard.export")} {t(`adminDashboard.${data}`)}
                  </div>
                  <Spacer size="small" />
                  <Divider
                    style={{
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      margin: "0",
                    }}
                  />
                  <Spacer size="small" />
                  <span style={{ marginRight: "5px" }}>
                    {t("adminDashboard.export")} {t(`adminDashboard.${data}`)}
                  </span>
                  <Dropdown
                    disabled={isLoading}
                    text={this.state.statusFilterText}
                    className="border"
                    options={this.options()}
                    item={true}
                    onChange={(_, { value }) => this.handleStatusFilter(value as Document.SubscriptionStatus)}
                    clearable={true}
                  />
                  <Spacer size="small" />
                  {this.props.groups && (
                    <>
                      <span style={{ marginRight: "5px" }}>{t("adminDashboard.exportProduct")}</span>
                      <Dropdown
                        size="small"
                        text={this.state.groupFilterText}
                        className="border"
                        options={groupOptions()}
                        item={true}
                        onChange={(e, { value }) => this.handleGroupChange(e, { value })}
                        clearable={true}
                      />
                      <Spacer size="small" />
                    </>
                  )}

                  <div style={{ display: "flex" }}>
                    <span style={{ margin: "12px 5px 0 0" }}>{t("adminDashboard.betweenDates")}</span>

                    <Fields.InputDate
                      onDateChange={this.onDateChange}
                      initialState={last30Days()}
                      onClickInput={() => {
                        if (!this.modal.current) {
                          return
                        }

                        this.modal.current.scrollTo({
                          top: 1000,
                          behavior: "smooth",
                        })
                      }}
                      disabled={isLoading}
                      defaultValue="lastMonth"
                      size="mini"
                      clearable={false}
                    />
                  </div>

                  {this.props.query && (
                    <>
                      <Spacer size="small" />
                      <div>
                        <Checkbox
                          name="query"
                          label={t("adminDashboard.textFilter", { query: this.props.query })}
                          checked={this.state.query ?? false}
                          onChange={(_, { checked }) => this.handleQuery(checked)}
                        />
                      </div>
                    </>
                  )}

                  <Spacer size="small" />

                  <div>
                    <span style={{ marginRight: "5px" }}>{t("adminDashboard.fileType")}</span>
                    <Dropdown
                      disabled={isLoading}
                      name="type"
                      size="small"
                      defaultValue="xlsx"
                      options={[
                        {
                          key: 1,
                          text: "xlsx",
                          value: Document.ExportTypes.xlsx,
                        },
                        {
                          key: 2,
                          text: "csv",
                          value: Document.ExportTypes.csv,
                        },
                        {
                          key: 3,
                          text: "json",
                          value: Document.ExportTypes.json,
                        },
                      ]}
                      item={true}
                      className="border"
                      onChange={(_, { value }) => this.handleType(value as Document.ExportTypes)}
                    />
                  </div>
                  {error ? <Service.ErrorMessage error={error} /> : null}
                  <Spacer />
                  <div style={{ float: "right" }}>
                    <Button
                      content={i18n.t("adminDashboard.cancel")}
                      basic={true}
                      size="tiny"
                      onClick={() => this.setState({ isOpen: false })}
                    />
                    <Button
                      content={i18n.t("adminDashboard.export")}
                      color="green"
                      size="tiny"
                      loading={isLoading}
                      onClick={() => this.handleExport()}
                    />
                  </div>
                </div>
              )}
            </Translation>
          }
          actions={[]}
        />
      </Ref>
    )
  }
}

export default ExportModal

function last30Days() {
  var start = new Date()
  start.setDate(start.getDate() - 30)
  start.setHours(0, 0, 0, 0)
  var end = new Date()
  end.setHours(23, 59, 59, 999)
  return [start, end] as [Date, Date]
}
