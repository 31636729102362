import { Document } from "@chatpay/common"
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider, IAnalyticsTrackableItem } from "./Tracker"

export class HotjarProvider implements IAnalyticsProvider {
  identify = (user: Document.User) => {
    try {
      ;(window as any).hj("identify", user?.id, {
        user_id: user.id,
      })
    } catch (e) {
      console.error("Hotjar is not setup up correctly")
    }
  }

  identifyFB(user: firebase.default.User, providers?: AnalyticsProviderType[]): void {}

  init(): void {}

  page(name: string, params?: AnalyticsEventParams, providers?: AnalyticsProviderType[]): void {}

  reset(providers?: AnalyticsProviderType[]): void {}

  track(
    event: string,
    params?: AnalyticsEventParams,
    entities?: IAnalyticsTrackableItem[],
    providers?: AnalyticsProviderType[],
  ): void {}

  type(): AnalyticsProviderType {
    return AnalyticsProviderType.HOTJAR
  }
}
