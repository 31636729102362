import { Validator, Type } from "./Validator"

export const cardNumber = (value: string, type?: Type): string => {
  const typeId = type?.id ?? Validator.type(value)?.id
  const clearValue = value.numbers()
  let nextValue: string

  switch (typeId) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
      break
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19,
      )}`
      break
  }

  return nextValue.trim()
}

export const maskedNumber = (last4: string, type: Type): string => {
  const fakeNumber = cardNumber(`9999 9999 9999 9999`, type).replace(/9/g, "•")

  return `${fakeNumber.slice(0, fakeNumber.length - 4)}${last4}`
}

export const CVV = (value: string, numbers?: string): string => {
  const clearValue = value.numbers()
  let maxLength = 3
  if (numbers) {
    const type = Validator.type(numbers)
    maxLength = type?.id === "amex" ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export const expirationDate = (value: string): string => {
  const clearValue = value.numbers()
  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}
