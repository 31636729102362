import { Service } from "@chatpay/components"
import { Reducer } from "redux"
import { RefundAction, RefundActionTypes } from "./RefundActions"
import { IRefundState } from "./RefundState"

const initialRefundState: IRefundState = {
  isLoading: true,
  error: null,
  boughtGroups: [],
  refunds: [],
}

export const RefundReducer: Reducer<IRefundState, RefundAction> = (
  state: IRefundState = initialRefundState,
  action: RefundAction,
) => {
  switch (action.type) {
    case RefundActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
      }
    case RefundActionTypes.LOAD_SUCCESS:
      const boughtGroups = action.groups.filter((it) => {
        const userId = typeof it.owner === "string" ? it.owner : it.owner?.id
        return userId !== Service.Firebase.currentUser?.id!
      })

      return {
        ...state,
        isLoading: false,
        boughtGroups,
        refunds: action.refunds,
      }
    case RefundActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case RefundActionTypes.REFUND:
      return {
        ...state,
        isLoading: true,
      }
    case RefundActionTypes.CANCEL:
      return {
        ...state,
        isLoading: true,
      }
    case RefundActionTypes.REACTIVATE:
      return {
        ...state,
        isLoading: true,
      }
    case RefundActionTypes.ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case RefundActionTypes.ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.reason,
      }
    default:
      return state
  }
}
