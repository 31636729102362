import { GroupResource, Product, Resource, WhiteList } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Container, Divider, Modal, ModalContent, Table } from "semantic-ui-react"
import { RouteComponentProps } from "react-router-dom"
import { useMedia } from "react-use"
import { Spacer } from "components"
import { Text } from "typography"
import { TableHeadCollumn } from "pages/EditGroup/Waitlist/WaitlistStyledComponents"
import { TableLoader } from "components/CPLoader"
import { FreeMembersTableRow } from "./FreeMembersTableRow"
import { FreeMembersHead } from "./FreeMembersHead"
import { CloseIcon } from "components/CloseIcon"

interface ITableHeader {
  index: string
  align: "center" | "left" | "right"
  width?: number
}

interface IProps extends RouteComponentProps {}

export const FreeMembersPage: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const isDesktop: boolean = useMedia("(min-width: 768px)")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [freeMembers, setFreeMembers] = useState<WhiteList[]>()
  const [product, setProduct] = useState<Product>()
  const [resource, setResource] = useState<Resource>()
  const [groupResource, setGroupResource] = useState<GroupResource>()
  const [error, setError] = useState<Error>()
  const groupResourceId = props.match.params["groupResourceId"]
  const productId = props.match.params["productId"]
  const groupId = props.match.params["groupId"]
  const [documentIdToDelete, setDocumentIdToDelete] = useState<string>()
  const [comfirmPopup, setComfirmPopup] = useState<boolean>()

  const tableHeaderItems: Array<ITableHeader> = [
    {
      index: "Name",
      align: "left",
      width: 195,
    },
    {
      index: "PhoneNumber",
      align: "left",
    },
    {
      index: "Action",
      align: "right",
    },
  ]

  const fetchData = useCallback(async () => {
    const productAPI = new API.Product()
    const groupResourceAPI = new API.GroupResource()
    const resourceAPI = new API.Resource()
    const groupWhitelistAPI = new API.GroupWhitelist()

    try {
      const [groupResourceData, resourcesData, productData] = await Promise.all([
        groupResourceAPI.get({ id: groupResourceId }),
        resourceAPI.getAllResources(),
        productAPI.get({ id: productId }),
      ])

      if (groupResourceData) {
        setGroupResource(groupResourceData)
      }

      if (productData) {
        setProduct(productData)
      }

      if (resourcesData) {
        setResource(
          resourcesData.resources.find((r) => {
            return r.type === groupResourceData.resourceType
          }),
        )
      }

      if (groupResourceId) {
        setIsLoading(true)
        try {
          const response = await groupWhitelistAPI.listMembersByGroupResourceId({
            groupId: groupId,
            groupResourceId: groupResourceId,
          })
          setFreeMembers(response)
        } catch (error) {
          setError(error as Error)
        }

        setIsLoading(false)
      }
    } catch (err) {
      setError(error as Error)
    }
  }, [error, groupId, groupResourceId, productId])

  /**
   * @description
   * If the user comfirm the removal we will remove it from the DB.
   */
  const handleComfirmRemoveClick = async () => {
    await removeUser(groupId, documentIdToDelete ?? "")

    setDocumentIdToDelete(undefined)
    setComfirmPopup(false)
  }

  const removeUser = async (groupId: string, documentId: string) => {
    const groupWhitelistAPI = new API.GroupWhitelist()
    setIsLoading(true)
    try {
      await groupWhitelistAPI.remove({ groupId, id: documentId })
      await fetchData()
      setIsLoading(false)
    } catch (err) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    ;(async function asyncFetchData() {
      await fetchData()
    })()
  }, [fetchData])

  return (
    <React.Fragment>
      <Container>
        <Spacer />
        <FreeMembersHead
          isLoading={isLoading}
          product={product}
          resource={resource}
          setIsLoading={setIsLoading}
          groupId={groupId}
          groupResource={groupResource}
        />
        {isDesktop && <Spacer />}
        <Divider />
        <Text.Big data-testid="free-members-component">{t("FreeMembers.title")}</Text.Big>
        <Table sortable={false} celled={true}>
          {isDesktop && (
            <Table.Header>
              <Table.Row>
                {tableHeaderItems.map((item) => (
                  <Table.HeaderCell key={item.index} textAlign={item.align}>
                    <TableHeadCollumn className="cp text small" width={item?.width}>
                      {t(`FreeMembers.table.${item.index}`)}
                    </TableHeadCollumn>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {isLoading && <TableLoader columns={3} rows={5} />}
            {!isLoading &&
              freeMembers &&
              freeMembers.map &&
              freeMembers.map((member) => (
                <FreeMembersTableRow
                  key={member.id}
                  member={member}
                  isDesktop={isDesktop}
                  setComfirmPopup={setComfirmPopup}
                  setDocumentIdToDelete={setDocumentIdToDelete}
                />
              ))}
          </Table.Body>
        </Table>
        <Service.ErrorMessage error={error} />
        <Modal
          open={comfirmPopup}
          dimmer="inverted"
          basic={true}
          size="small"
          closeIcon={<CloseIcon onClick={() => setComfirmPopup(false)} />}
        >
          <ModalContent>
            <h2>{t(`FreeMembers.modal.remove.title`)}</h2>
            <p style={{ opacity: "0.54" }}>{t(`FreeMembers.modal.remove.description`)}</p>
            <Spacer />
            <Button
              content={t(`FreeMembers.modal.remove.buttons.cancel`)}
              basic={true}
              color="black"
              size="small"
              onClick={() => {
                setComfirmPopup(false)
                setDocumentIdToDelete(undefined)
              }}
            />
            <Button
              content={t(`FreeMembers.modal.remove.buttons.remove`)}
              color="red"
              size="small"
              onClick={async () => handleComfirmRemoveClick()}
              loading={isLoading}
            />
          </ModalContent>
        </Modal>
      </Container>
    </React.Fragment>
  )
}

export type { IProps as IPropsFreeMembers }
