import { Reducer } from "redux"
import { CheckLogAction, CheckLogActionTypes } from "./CheckLogActions"
import { ICheckLogState } from "./CheckLogState"

const initialCheckLogState: ICheckLogState = {
  isLoading: false,
  error: null,
  myCheckLogs: [],
  group: undefined,
}

export const CheckLogReducer: Reducer<ICheckLogState, CheckLogAction> = (
  state: ICheckLogState = initialCheckLogState,
  action: CheckLogAction,
) => {
  switch (action.type) {
    case CheckLogActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
      }
    case CheckLogActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myCheckLogs: action.myCheckLogs,
        group: action.group,
      }
    case CheckLogActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
