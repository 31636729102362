import { Document, PaymentHelper, Type } from "@chatpay/common"
import { Service } from "@chatpay/components"
import i18n from "i18n"
import * as Prices from "components/Helpers/Prices"
import * as React from "react"
import { Image } from "semantic-ui-react"
import "./GroupSaleItem.scss"

interface IProps {
  discount?: number
  group?: Document.Group | null
  plan?: Document.Plan | null
  installments?: number | null
  isLoading?: boolean
  showPrice?: boolean
  prices?: Type.Sellable.Prices
}

export class GroupSaleItem extends React.Component<IProps, {}> {
  public render() {
    const { group, plan, installments, discount, prices } = this.props

    const [price, , hasMembership, membershipFee] = Prices.treat(
      prices,
      plan ?? group,
      plan?.membershipFee ?? group?.membershipFee,
      installments,
    )

    const hasPlanMembership = membershipFee?.days === "plan"

    let priceDetailText: string | undefined
    if (plan) {
      priceDetailText = ` / ${plan.intervalDescription.name}`
    } else if (group?.installments) {
      priceDetailText = `${
        installments !== undefined
          ? installments
            ? `${installments}x ${i18n.t("checkout.Of")} ${Service.Global.Price.currencyFormat(
                PaymentHelper.applyDiscount(price, discount ?? 0) / installments,
                group.currency as string,
              )}`
            : i18n.t("checkout.SinglePayment")
          : `${i18n.t("checkout.UpTo")} ${group?.installments}x`
      }`
    }

    return (
      <>
        <div className="group-sale-item">
          <div className="group-sale-item-group">
            <Image
              src={group?.picture ?? require("assets/images/placeholder-image.svg")}
              style={{
                width: "3em",
                height: "3em",
                borderRadius: "1em",
                objectFit: "cover",
                objectPosition: "center",
                marginRight: "1em",
              }}
            />
            <div>
              <div className="cp text black compact bold big">{group?.name ?? ""}</div>
              <div className="cp text">{i18n.t("Payments.x1")}</div>
            </div>
          </div>
          {this.props.showPrice ?? true ? (
            <>
              <div className="group-sale-item-price" style={{ paddingTop: "1em" }}>
                <div style={{ flex: 1 }}>
                  <div className="cp text large">
                    {`${
                      plan?.invoiceDescription
                        ? `${
                            i18n.t(`Plan`) +
                            " " +
                            i18n.t(`Plans.${plan.name.toLowerCase()}`).replace(/^\w/, (c) => c.toUpperCase())
                          }`
                        : i18n.t("Payments.m4")
                    }`}
                  </div>
                </div>

                <div className="cp text black large">
                  {hasMembership ? (
                    <React.Fragment>
                      <Show show={discount !== undefined}>
                        <span className="text small">
                          <del>{Service.Global.Price.currencyFormat(price, group?.currency as string) + " "}</del>
                        </span>
                        {Service.Global.Price.currencyFormat(
                          PaymentHelper.applyDiscount(price, discount!),
                          group?.currency as string,
                        )}
                      </Show>
                      <Show show={discount === undefined}>
                        {Service.Global.Price.currencyFormat(price, group?.currency as string)}
                      </Show>{" "}
                      <span className="text small">{i18n.t("Payments.then")}</span>{" "}
                      {Service.Global.Price.currencyFormat(price, group?.currency as string)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Show show={discount !== undefined}>
                        <span className="text small">
                          <del>{Service.Global.Price.currencyFormat(price, group?.currency as string) + " "}</del>
                        </span>
                        {Service.Global.Price.currencyFormat(
                          PaymentHelper.applyDiscount(price, discount!),
                          group?.currency as string,
                        )}
                        <Show show={group?.hasPlans ?? false}>
                          <span className="text small">{i18n.t("Payments.then")}</span>
                          {Service.Global.Price.currencyFormat(price, group?.currency as string)}
                        </Show>
                      </Show>
                      <Show show={discount === undefined}>
                        {Service.Global.Price.currencyFormat(price, group?.currency as string)}
                      </Show>
                    </React.Fragment>
                  )}
                  <span className="text small">{" " + (priceDetailText ?? i18n.t("Payments.onetime"))}</span>
                </div>
              </div>
              {hasMembership && plan ? (
                <div className="cp text small" style={{ marginTop: "1em" }}>
                  {hasPlanMembership
                    ? ` (${i18n.t("Payments.m3") + " " + Document.Plan.descriptionForInterval(plan.interval).name})`
                    : ` (${
                        i18n.t("Payments.m3") +
                        " " +
                        Document.Plan.descriptionForInterval(Document.PlanInterval.monthly).name
                      })`}
                </div>
              ) : null}
            </>
          ) : null}
          <div className="sale-item-label-wraper">
            <div className="triangle-1" />
            <div className="triangle-2" />
            <div className="cp text white compact sale-item-label" style={{ textAlign: "right" }}>
              {i18n.t("Payments.safe")}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const Show: React.FunctionComponent<{ show: boolean }> = (props: any) => {
  return props.show ? props.children : null
}
