import i18n from "i18next"
import moment, { unitOfTime } from "moment-timezone"
import "moment/locale/pt-br"

export function dateDiff(first: number, second: number) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.ceil((second - first) / (1000 * 60 * 60 * 24))
}

export function diff(from: Date, date?: Date, unit: unitOfTime.Diff = "days", timeZone = "America/Sao_Paulo"): number {
  return moment(from)
    .tz(timeZone)
    .diff(moment(date ?? new Date()).tz(timeZone), unit)
}

export function timeDiff(first: number, second: number) {
  const firstDate = new Date(first)
  const secondDate = new Date(second)
  const diffMs = secondDate.getTime() - firstDate.getTime() // milliseconds between now & Christmas
  const diffDays = Math.floor(diffMs / 86400000) // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
  if (diffDays <= 0 && diffHrs <= 0 && diffMins <= 0) {
    return ""
  }
  return `${diffDays > 0 ? diffDays + "d, " : ""} ${diffHrs > 0 ? diffHrs + "h, " : ""} ${
    diffMins > 0 ? diffMins + "m" : ""
  }`
}

export function formatBasedOnLocale(date: moment.MomentInput) {
  return moment(date).format(i18n.languages[0] === "en" ? "MM/DD" : "DD/MM")
}

export const last30Days = () => {
  const start = new Date()
  start.setDate(start.getDate() - 30)
  start.setHours(0, 0, 0, 0)
  const end = new Date()
  end.setHours(23, 59, 59, 999)
  return [start, end] as [Date, Date]
}

export function formatPostCreatedAt(date: moment.MomentInput, short?: boolean) {
  const today = moment()
  const dateDiff = today.diff(date, "days")

  const lang = i18n?.language?.toLowerCase() ?? "pt-br"
  let formatOptions = "DD/MM/YY [at] HH:mm"

  if (lang === "pt" || lang === "pt-br") {
    formatOptions = short ? "DD/MM/YY [às] HH:mm" : "DD[,] MMM [de] YYYY [às] HH:mm"

    moment.locale("pt-br")
    moment.updateLocale("pt-br", {
      relativeTime: {
        past: "%s atrás",
        s: "alguns segundos",
        m: "1 minuto",
        mm: "%d minutos",
        h: "1 hora",
        hh: "%d horas",
        d: "1 dia",
        dd: "%d dias",
      },
      monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    })
  } else {
    moment.locale("en")
    moment.updateLocale("en", {
      relativeTime: {
        past: "%s ago",
        s: "a few seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
      },
    })
  }

  if (dateDiff > 7) {
    return moment(date).format(formatOptions)
  }
  return moment(date).fromNow()
}
