import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Card, Radio, RadioGroup, Switch, Text, TextField } from "@hub-la/design-system"
import { InputAdornment } from "@mui/material"

export interface IForm {
  isEnabled: boolean
  commission: number
  recurrCommission: number
  public: boolean
}

export interface IProps {
  isRecurrent: boolean
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
  action?: JSX.Element
}

interface IErrors {
  commission: string
  recurrCommission: string
}

const insert = (value: string) => [value.slice(0, 2), ".", value.slice(2)].join("")

/**
 * Provides a decoration for TextFields, showing percentage symbol at the end of
 * the input field, like this:
 *
 *   -----------
 *   | 40    % |
 *   -----------
 *           ^
 */
const INPUT_ADORNMENT = {
  endAdornment: <InputAdornment position="end">%</InputAdornment>,
}

const Affiliates: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, isRecurrent, action } = props

  const { t } = useTranslation()

  const [form, setForm] = useState<Partial<IForm>>()
  const [errors, setErrors] = useState<Partial<IErrors>>()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }

    const verifyCommission = (commission: number | string | undefined, min = 1): string | undefined => {
      if (
        commission === undefined ||
        (newData.isEnabled && commission < min) ||
        (typeof commission === "string" && commission.length === 0)
      ) {
        return t("GroupForm.affiliates.errorMin", { min })
      } else if (commission && commission > 70) return t("GroupForm.affiliates.errorMax")
    }
    const { commission, recurrCommission } = newData
    const errors = {
      commission: verifyCommission(commission ?? 0),
      recurrCommission: isRecurrent ? verifyCommission(recurrCommission ?? 0, 0) : undefined,
    }
    setErrors(errors)
    const valid = errors.commission === undefined && errors.recurrCommission === undefined

    setForm(newData)
    if (onChange) {
      onChange(newData, valid)
    }
  }

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: "commission" | "recurrCommission",
  ) => {
    const value = event.target.value
    if (value.startsWith(".")) return
    if (value.includes(".") && value.split(".")[0].length === 1 && value.length > 4) return
    if (value.length < 6) {
      const shouldFormat = value.length > 2 && !value.includes(".")
      setFormValue(fieldName, shouldFormat ? insert(value) : value)
    }
  }

  return (
    <Card variant="filled" hasHover={false}>
      <Switch
        data-testid="checkout-affiliate-enabled"
        checked={form?.isEnabled ?? false}
        disabled={false}
        onChange={() => setFormValue("isEnabled", !form?.isEnabled)}
        label={t("GroupForm.affiliates.title")}
        direction="left"
        ml={1}
        mb={3}
      />

      <Text variant="body2" color="onSurfaceVariant">
        {t("GroupForm.affiliates.subtitle")}
      </Text>

      <TextField
        marginTop={4}
        label={t("GroupForm.affiliates.sales")}
        data-testid="input-affiliate-commission"
        error={errors?.commission !== undefined}
        helperText={errors?.commission ? errors.commission : ""}
        placeholder="0%"
        value={form?.commission ?? 0}
        type="number"
        onChange={(event) => handleOnChange(event, "commission")}
        InputProps={INPUT_ADORNMENT}
      />

      {isRecurrent && (
        <>
          <TextField
            marginTop={4}
            data-testid="input-affiliate-recurr-commission"
            error={errors?.recurrCommission !== undefined}
            helperText={errors?.recurrCommission ? errors.recurrCommission : ""}
            label={t("GroupForm.affiliates.recurrentSales")}
            disabled={!form?.isEnabled}
            placeholder="0%"
            value={form?.recurrCommission ?? 0}
            type="number"
            onChange={(event) => handleOnChange(event, "recurrCommission")}
            InputProps={INPUT_ADORNMENT}
          />
        </>
      )}

      <RadioGroup marginTop={4} label={t("GroupForm.affiliates.affiliatesSetting")} row={false}>
        <Radio
          label={t("GroupForm.affiliates.anyone")}
          labelPlacement="end"
          checked={form?.public}
          onClick={() => setFormValue("public", true)}
        />
        <Radio
          label={t("GroupForm.affiliates.onlyMembers")}
          labelPlacement="end"
          checked={!form?.public}
          onClick={() => setFormValue("public", false)}
        />
      </RadioGroup>

      {action}
    </Card>
  )
}

export { Affiliates as Form }
