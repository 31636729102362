"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Models = require("../../Models");

var _Gateway = require("../Gateway/Gateway");

var _ = require("../");

var _Plan = require("../Plan");

var _Refund = require("../Refund");

var _Document = _interopRequireDefault(require("./Document"));

var _User = _interopRequireDefault(require("./User"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function generateStatusAt(status) {
  var statusAt = {
    started: _defineProperty({}, status, _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.past(1))),
    ended: _defineProperty({}, status, undefined)
  };
  return statusAt;
}

var refundFixture = function refundFixture(args) {
  var _data;

  var status = _faker["default"].random.objectElement(_Refund.Status);

  var from = (0, _User["default"])().toReference();
  var receiversIds = [from.id, (0, _utils.firebaseIdFixture)(), (0, _utils.firebaseIdFixture)(), (0, _utils.firebaseIdFixture)()];
  return new _.Refund.Document(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    from: from,
    to: (0, _User["default"])().toReference(),
    amount: _faker["default"].datatype.number(),
    currency: _faker["default"].random.objectElement(_Models.Currency),
    gateway: _faker["default"].random.objectElement(_Gateway.Carrier),
    status: status,
    transfer: status === _Refund.Status.accepted ? {
      master: {
        id: (0, _utils.firebaseIdFixture)(),
        amount: _faker["default"].datatype.number()
      },
      receivers: {
        ids: receiversIds,
        data: (_data = {}, _defineProperty(_data, receiversIds.first(), {
          id: receiversIds.at(1),
          amount: _faker["default"].datatype.number()
        }), _defineProperty(_data, receiversIds.last(), {
          id: receiversIds.at(2),
          amount: _faker["default"].datatype.number()
        }), _data)
      }
    } : undefined,
    statusAt: generateStatusAt(status),
    description: _faker["default"].lorem.sentence(),
    feedback: _faker["default"].lorem.sentence(),
    transaction: {
      id: (0, _utils.firebaseIdFixture)(),
      invoiceId: (0, _utils.firebaseIdFixture)(),
      item: {
        id: (0, _utils.firebaseIdFixture)(),
        name: _faker["default"].lorem.words(),
        type: "Group",
        picture: _faker["default"].image.imageUrl()
      },
      paidAt: _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.past(1)),
      isRenewing: _faker["default"].datatype["boolean"](),
      paymentMethod: _faker["default"].random.objectElement(_.PaymentMethod),
      subscription: {
        id: (0, _utils.firebaseIdFixture)(),
        plan: {
          id: (0, _utils.firebaseIdFixture)(),
          name: _faker["default"].random.word(),
          interval: _faker["default"].random.objectElement(_Plan.Interval)
        }
      }
    },
    isAutoAccepted: _faker["default"].datatype["boolean"](),
    error: status === _Refund.Status.failed ? _faker["default"].lorem.sentence() : undefined,
    autoWithdraw: _faker["default"].datatype["boolean"]()
  }, args));
};

var _default = refundFixture;
exports["default"] = _default;