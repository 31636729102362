/**
 * Formats currency according to the given locale.
 */
export const FORMATTER = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

/**
 * Backend URL.
 *
 * This is in the form: protocol:domain/api/v1
 */
const ENDPOINT_URL = process.env.REACT_APP_HUB_API

const FUNNEL_STRATEGY_BASE_URL = `${ENDPOINT_URL}/funnel-strategy`

/**
 * Facilitates preparing the URLs for creating funnel strategies.
 *
 * IMPORTANT NOTE: this is NOT our current standard. DO NOT replicate this. This
 * should be using the use cases idea from our new modules structure.
 */
export const FUNNEL_STRATEGY_URL = {
  /**
   * POST request that expects a payload in the format:
   *
   *  {
   *    "mainOfferId": "string",
   *    "extraOfferId": "string",
   *    "type": {
   *      "name": "UPSELL",
   *      "mediaURL": "string"
   *    },
   *    "plans": {
   *      "name": "RECURRING",
   *      "items": [
   *        {
   *          "priceInCents": 27000,
   *          "interval": "MONTHLY"
   *        },
   *        {
   *          "priceInCents": 7200,
   *          "interval": "QUARTERLY"
   *        },
   *       {
   *         "priceInCents": 13400,
   *         "interval": "SEMIANNUALLY"
   *       }
   *     ]
   *   }
   * }
   */
  CREATE: FUNNEL_STRATEGY_BASE_URL,

  /**
   * GET request that returns a complete offer configuration, in the following
   * format:
   *
   *  {
   *    "id": "string",
   *    "mainOfferId": "string",
   *    "extraOfferId": "string",
   *    "type": {
   *      "name": "UPSELL",
   *      "mediaURL": "string"
   *    },
   *    "plans": {
   *      "name": "ONE_TIME",
   *      "priceInCents": 0,
   *      "maxInstallments": 0
   *    },
   *    "isEnabled": true,
   *    "warnings": "EXTRA_OFFER_ID_IS_NOT_AVAILABLE"
   *  }
   */
  READ: (type: "upsell" | "order-bump", mainOfferId: string) =>
    `${FUNNEL_STRATEGY_BASE_URL}/${type}?mainOfferId=${mainOfferId}`,

  /**
   * PATCH request that expects the same payload as
   * {@link FUNNEL_STRATEGY_URL.CREATE}.
   */
  UPDATE: (id: string) => `${FUNNEL_STRATEGY_BASE_URL}/${id}`,

  /**
   * DELETE request that expects the funnel strategy ID in the URL. Use this
   * function to build it.
   */
  DELETE: (id: string) => `${FUNNEL_STRATEGY_BASE_URL}/${id}`,
}
