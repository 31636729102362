import { BillingAPI } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum SubscriptionsActionTypes {
  LOAD_SUBSCRIPTIONS = "DASHBOARD_LOAD_SUBSCRIPTIONS_SUBSCRIPTIONS",
  LOAD_SUBSCRIPTIONS_SUCCESS = "DASHBOARD_LOAD_SUBSCRIPTIONS_SUBSCRIPTIONS_SUCCESS",
  LOAD_SUBSCRIPTIONS_FAILURE = "DASHBOARD_LOAD_SUBSCRIPTIONS_SUBSCRIPTIONS_FAILURE",
  LOAD_CHART = "DASHBOARD_LOAD_CHART_SUBSCRIPTIONS",
  LOAD_CHART_SUCCESS = "DASHBOARD_LOAD_CHART_SUBSCRIPTIONS_SUCCESS",
  LOAD_CHART_FAILURE = "DASHBOARD_LOAD_CHART_SUBSCRIPTIONS_FAILURE",
  CHANGE_PAGE = "DASHBOARD_CHANGE_SUBSCRIPTIONS_PAGE",
  CHANGE_PAGE_SUCCESS = "DASHBOARD_CHANGE_SUBSCRIPTIONS_PAGE_SUCCESS",
  CHANGE_PAGE_FAILURE = "DASHBOARD_CHANGE_SUBSCRIPTIONS_PAGE_FAILURE",
}

export interface ILoadSubscriptionsAction {
  type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS
}

export interface ILoadSubscriptionsSuccessAction {
  type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_SUCCESS
  subscriptionsData: BillingAPI.ISubscription.ListSubscriptionsOutput
}

export interface ILoadSubscriptionsFailureAction {
  type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_FAILURE
  reason: string
}

export interface ILoadChartAction {
  type: SubscriptionsActionTypes.LOAD_CHART
}

export interface ILoadChartSuccessAction {
  type: SubscriptionsActionTypes.LOAD_CHART_SUCCESS
  chartData: BillingAPI.ISubscription.ChartOutput
}

export interface ILoadChartFailureAction {
  type: SubscriptionsActionTypes.LOAD_CHART_FAILURE
  reason: string
}

export interface IChangeSubscriptionsPageAction {
  type: SubscriptionsActionTypes.CHANGE_PAGE
  query: string
}

export interface IChangeSubscriptionsPageSuccessAction {
  type: SubscriptionsActionTypes.CHANGE_PAGE_SUCCESS
  subscriptionsData: BillingAPI.ISubscription.ListSubscriptionsOutput
  query: string
}

export interface IChangeSubscriptionsPageFailureAction {
  type: SubscriptionsActionTypes.CHANGE_PAGE_FAILURE
  reason: string
}

const last30Days = () => {
  var startDate = new Date()
  startDate.setDate(startDate.getDate() - 30)
  startDate.setHours(0, 0, 0, 0)
  var endDate = new Date()
  endDate.setHours(23, 59, 59, 999)
  return [startDate, endDate] as [Date, Date]
}

export const loadSubscriptions = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS,
  })
  const userApi = new API.User()
  const subscriptionApi = new API.Subscription()
  try {
    // This healthCheck is just to ensure that user has AccessToken loaded
    await userApi.getById(Service.Firebase.currentUser?.id!) // HealthCheck for AccessToken

    const subscriptionData = await subscriptionApi.list(1, 10, "active", last30Days())
    dispatch(loadSubscriptionsSuccess(subscriptionData!))
  } catch (e) {
    dispatch(loadSubscriptionsFailure(e))
  }
}

export const loadSubscriptionsSuccess = (
  subscriptionsData: BillingAPI.ISubscription.ListSubscriptionsOutput,
): ILoadSubscriptionsSuccessAction => {
  return {
    type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_SUCCESS,
    subscriptionsData,
  }
}

export const loadSubscriptionsFailure = (reason: string): ILoadSubscriptionsFailureAction => {
  return {
    type: SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_FAILURE,
    reason,
  }
}

export const loadChart = (from: Date, to: Date): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: SubscriptionsActionTypes.LOAD_CHART,
  })

  const subscriptionApi = new API.Subscription()
  try {
    const chartData = await subscriptionApi.chart(from, to)
    dispatch(loadChartSuccess(chartData))
  } catch (e) {
    dispatch(loadChartFailure(e))
  }
}

export const loadChartSuccess = (chartData: BillingAPI.ISubscription.ChartOutput): ILoadChartSuccessAction => {
  return {
    type: SubscriptionsActionTypes.LOAD_CHART_SUCCESS,
    chartData,
  }
}

export const loadChartFailure = (reason: string): ILoadChartFailureAction => {
  return {
    type: SubscriptionsActionTypes.LOAD_CHART_FAILURE,
    reason,
  }
}

export const changePage = (
  clickedPage: number,
  status: "active" | "all",
  createdAt: [Date, Date],
  query?: string,
  sortedBy?: "ASC" | "DESC",
  groupId?: string,
  isChartUpdated?: boolean,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  const startDate = createdAt ? createdAt![0] : undefined
  if (startDate) {
    startDate.setHours(0, 0, 0, 0)
  }
  const endDate = createdAt ? createdAt![1] : undefined
  if (endDate) {
    endDate.setHours(23, 59, 59, 999)
  }
  const createdAtFilter = [startDate, endDate] as [Date, Date]

  dispatch({
    type: SubscriptionsActionTypes.CHANGE_PAGE,
    clickedPage,
    query: JSON.stringify({ clickedPage, status: status, createdAt: createdAtFilter, query, sortedBy }),
  })

  try {
    new API.Subscription()
      .list(clickedPage, 10, status, createdAtFilter, query, sortedBy, groupId)
      .then((subscriptionsData) =>
        dispatch(
          changePageSuccess(
            subscriptionsData!,
            JSON.stringify({ clickedPage, status, createdAt, query, sortedBy, groupId }),
          ),
        ),
      )
      .catch((err) => changePageFailure(err))

    if (isChartUpdated) {
      new API.Subscription()
        .chart(createdAtFilter[0], createdAtFilter[1], groupId)
        .then((chartData) => dispatch(loadChartSuccess(chartData!)))
        .catch((err) => loadChartFailure(err))
    }
  } catch (e) {
    dispatch(changePageFailure(e))
  }
}

export const changePageSuccess = (
  subscriptionsData: BillingAPI.ISubscription.ListSubscriptionsOutput,
  query: string,
): IChangeSubscriptionsPageSuccessAction => {
  return {
    type: SubscriptionsActionTypes.CHANGE_PAGE_SUCCESS,
    subscriptionsData,
    query,
  }
}

export const changePageFailure = (reason: string): IChangeSubscriptionsPageFailureAction => {
  return {
    type: SubscriptionsActionTypes.CHANGE_PAGE_FAILURE,
    reason,
  }
}

export type SubscriptionsAction =
  | ILoadSubscriptionsAction
  | ILoadSubscriptionsSuccessAction
  | ILoadSubscriptionsFailureAction
  | ILoadChartAction
  | ILoadChartSuccessAction
  | ILoadChartFailureAction
  | IChangeSubscriptionsPageAction
  | IChangeSubscriptionsPageSuccessAction
  | IChangeSubscriptionsPageFailureAction
