import { Document } from "@chatpay/common"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import React, { useEffect, useState } from "react"
import { TextArea } from "components"
import { Button, Checkbox, Form, InputOnChangeData, TextAreaProps } from "semantic-ui-react"
import { Text } from "typography"
import * as Yup from "yup"

type keys = "faq"

interface IForm {
  faq: Document.GroupAboutQuestion[]
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const schema = Yup.object().shape({
  faq: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        question: Yup.string().min(5, "Pergunta muito curta!").max(50, "Pergunta muito longa!"),
        answer: Yup.string().min(3, "Resposta muito curta!").max(700, "Resposta muito longa!"),
      }),
    )
    .min(1, "Você precisa adicionar pelo menos um item")
    .max(8, "O máximo de itens é 8"),
})

const defaultFaqs = [
  {
    question: "Como entrar no grupo?",
    answer:
      "Assim que seu pagamento for confirmado, sempre que precisar acessar o produto que comprou, entre em Meus Produtos, no canto superior direito da página da Hubla e lá você irá encontrar o botão para acessar o produto.",
  },
  {
    question: "Vocês cobram recorrente no próximo mês?",
    answer:
      "As cobranças são feitas no dia da aprovação do primeiro pagamento da sua assinatura. Por exemplo, em uma assinatura mensal, se o primeiro pagamento foi aprovado em 01/01/2020, a próxima mensalidade será dia 01/02/2020.",
  },
]

const validateField = (path: string, data: any) => {
  let error: string = ""
  try {
    data && Yup.reach(schema, "faq" + path).validateSync(data)
  } catch (e: any) {
    error = !e.path ? e.message : ""
  }
  return error
}

const hasErrors = (data: Partial<IForm>) => {
  let error: boolean = false
  try {
    data.faq && Yup.reach(schema, "faq").validateSync(data.faq)
  } catch (e: any) {
    error = !!e.message
  }
  return error
}

const FAQ: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  const [newQuestions, setNewQuestions] = useState<Document.GroupAboutQuestion[]>()
  const [newDefaultQuestions, setNewDefaultQuestions] = useState<Document.GroupAboutQuestion[]>()

  useEffect(() => {
    setForm(value)
    if (!newQuestions) {
      setNewQuestions(
        (value?.faq ?? [{ question: "", answer: "" }]).filter(
          (it) => !defaultFaqs.map((it) => it.question).includes(it.question),
        ),
      )
    }
    if (!newDefaultQuestions) {
      setNewDefaultQuestions(
        (value?.faq ?? [{ question: "", answer: "" }]).filter((it) =>
          defaultFaqs.map((it) => it.question).includes(it.question),
        ),
      )
    }
  }, [value, newQuestions, newDefaultQuestions])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    onChange?.(newData, valid)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.faq || ((data.faq ?? []).length > 1 && (data.faq ?? []).length < 7 && !hasErrors(data))
  }

  // WRITTEN QUESTIONS // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  const updateNewQuestions = (action: (newFaq: any[]) => void) => {
    const newFaq = (newQuestions ?? []).map((a) => ({ ...a }))
    action(newFaq)
    setNewQuestions(newFaq)
    setFormValue("faq", newFaq?.concat(newDefaultQuestions ?? []))
  }

  const onFaqQuestionUpdate = (data: InputOnChangeData, idx: number) => {
    updateNewQuestions((faq) => (faq![idx].question = String(data.value)))
  }

  const onFaqAnswerUpdate = (data: TextAreaProps, idx: number) => {
    updateNewQuestions((faq) => (faq![idx].answer = String(data.value)))
  }

  const onRemoveItem = (idx: number) => updateNewQuestions((faq) => faq?.splice(idx, 1))

  const onAddItem = () => updateNewQuestions((faq) => faq?.push({ question: "", answer: "" }))

  // DEFAULT QUESTIONS // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  const updateNewDefaultQuestions = (
    action: (newFaq: Document.GroupAboutQuestion[]) => Document.GroupAboutQuestion[],
  ) => {
    const newFaq = action((newDefaultQuestions ?? []).map((a) => ({ ...a })))
    setNewDefaultQuestions(newFaq)
    setFormValue("faq", (newQuestions ?? []).concat(newFaq))
  }

  const onUnshiftItem = (item: Document.GroupAboutQuestion) => {
    updateNewDefaultQuestions((faq) => [{ question: item.question, answer: item.answer }, ...(faq ?? [])])
  }

  const onPopItem = (item: Document.GroupAboutQuestion) => {
    updateNewDefaultQuestions((faq) => faq.filter((it) => it.question !== item.question))
  }

  return (
    <React.Fragment>
      <Form>
        <Fields.InputLabel title="Adicione perguntas frequentes">
          <Spacer />
          {(newQuestions ?? []).map((it, idx) => {
            return (
              <React.Fragment key={idx}>
                <Form.Input
                  id="faq"
                  value={it.question ?? ""}
                  placeholder="Escreva sua pergunta aqui"
                  onChange={(_, data) => onFaqQuestionUpdate(data, idx)}
                />
                <div style={{ textAlign: "right", marginBottom: "1em" }}>
                  <Text.Tiny color="red">{validateField(`[${idx}].question`, it.question)}</Text.Tiny>
                </div>
                <TextArea
                  value={it.answer ?? ""}
                  placeholder="Escreva sua resposta aqui"
                  onChange={(_, data) => onFaqAnswerUpdate(data, idx)}
                />
                <div style={{ textAlign: "right", marginBottom: "1em" }}>
                  <Text.Tiny color="red">{validateField(`[${idx}].answer`, it.answer)}</Text.Tiny>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Button icon="trash alternate" basic={true} size="small" onClick={() => onRemoveItem(idx)} />
                </div>
                <Spacer />
              </React.Fragment>
            )
          })}
          <Text.Tiny color="red">{validateField("", form?.faq)}</Text.Tiny>
          <div style={{ textAlign: "right" }}>
            {(form?.faq?.length ?? 0) < 8 && (
              <Button content="Adicionar" basic={true} size="small" onClick={onAddItem} />
            )}
          </div>
          <Spacer />
          {defaultFaqs.map((it, idx) => {
            return (
              <React.Fragment key={idx}>
                <Fields.InputLabel title={it.question}>
                  <Text.Small>{it.answer}</Text.Small>
                </Fields.InputLabel>
                <Spacer size="small" />
                <Checkbox
                  label="Incluir"
                  color="black"
                  checked={(form?.faq ?? []).map((it) => it.question).includes(it.question)}
                  onChange={(_, data) => (data.checked ? onUnshiftItem(it) : onPopItem(it))}
                />
                <Spacer />
              </React.Fragment>
            )
          })}
        </Fields.InputLabel>
      </Form>
    </React.Fragment>
  )
}

export { FAQ as Form }
export type { IProps, IForm }
