export const GroupForm = {
  affiliates: {
    salesCommission: "Comissão por venda",
    recurrentSalesCommission: "Comissão por recorrência",
    anyone: "Qualquer um pode se afiliar ao produto",
    onlyMembers: "Somente membros do produto podem afiliar",
    error: "Tem que ser menor que 70%",
    errorMax: "Tem que ser menor que 70%",
    errorMin: "Tem que ser maior ou igual a {{min}}%",
    title: "Afiliados",
    subtitle: "Ofereça uma comissão para pessoas que queiram promover seu produto.",
    sales: "% de comissão por venda",
    recurrentSales: "% de comissão por recorrência",
    affiliatesSetting: "Preferências de afiliados:",
    cancelButton: "Cancelar",
    saveButton: "Salvar",
  },
  entryFee: {
    entryFee: "Taxa de adesão",
    installments: {
      disabled: "Desabilitado",
      upTo: "em até",
    },
    text: "Por exemplo, se sua assinatura mensal custa R$ 59,00/mês e uma taxa de adesão de R$ 100,00 seus clientes vão pagar R$ 100,00 na primeira compra e depois R$ 59,00 nos meses seguintes.",
    chooseInstallments: "Parcelamento",
    renewalTolerance: {
      text: "Quantidade de dias que o usuário poderá voltar ao grupo sem precisar pagar a taxa de adesão novamente. Exemplo 1: Usuário pagou R$ 100,00 de taxa de adesão em um grupo de assinatura de R$ 10,00/mês. Se a carência for definida em 7 dias, ele pagará apenas R$ 10,00 para retornar ao grupo até 7 dias após sua saída e R$ 100,00 após este período. Exemplo 2: Usuário paga atualmente R$ 10,00 reais por mês e o administrador do grupo aumentou o preço da assinatura para R$ 20,00/mês.Se a carência for definida em 7 dias, ele poderá retornar ao grupo pagando R$ 10,00/mês em até 7 dias e após este período, deverá pagar R$ 20,00/mês.",
      textMembershipFee:
        "Configure se deseja cobrar uma taxa diferenciada para o primeiro mês de acesso ao seu produto. Cada membro só pagará esta taxa uma vez, mesmo que cancele a assinatura e volte a assinar novamente no futuro.",
      title: "Quantidade de dias de carência",
      disabled: "Desabilitado",
    },
    warnings: {
      entryFeeWithOrderBump:
        "Não é possível configurar a estratégia de order bump simultaneamente com a estratégia de taxa de adesão.",
    },
  },
  info: {
    cover: "Plano de fundo da página",
    picture: "Capa do produto",
    title: "Título",
    subtitle: "Subtitulo",
    type: "Tipo",
    remaining: "caracteres sobrando",
    placeholder1: "e.g Curso de violão básico",
    placeholder2: "e.g Nos próximos meses você estará tocando na sua roda de amigos.",
  },
  assets: {
    remaining: "mídias sobrando",
    editMedia: "Editar mídias",
    editOrder: "Reordenar mídias",
  },
  linkedGroup: {
    linkedGroups: "Produto(s) filho(s)",
    choose: "Escolha grupos que você deseja atrelar",
    text1:
      "Quando seu usuário comprar o produto {{group_name}} ele automaticamente ganhará acesso aos grupos selecionados produto acima.",
    text2: "Observação",
    text3:
      "Caso não encontre um produto acima, lembre que precisa publicar o produto antes que possa atrela-lo como grupo filho.",
  },
  Trackers: {
    title: "Editar página de vendas",
    pixel: "Choose the pixel you want to add.",
  },
  welcome: {
    label: "Escreva uma mensagem de boas vindas",
    placeholder: "Escreva algo",
  },
  warning: "💡 Você pode alterar essas informações depois, antes de publicar seu grupo.",
}
