import * as React from "react"
import { Image } from "semantic-ui-react"

import "./Avatar.scss"
import i18n from "i18n"

interface IProps {
  name?: string
  picture?: string | null
  createdAt?: string
  detail?: string | JSX.Element
  size?: "medium" | "small"
}

interface IState {
  isLoaded: boolean
  isError: boolean
}

class GroupAvatar extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    isLoaded: false,
    isError: false,
  }

  public render() {
    const defaultImage = require("assets/images/placeholder-image.svg")
    const { isLoaded, isError } = this.state
    const { size, name, detail, picture, createdAt } = this.props

    return (
      <div className="GroupAvatar">
        {!isLoaded && picture ? (
          <Image
            src={picture}
            hidden={true}
            onLoad={() => this.setState({ isLoaded: true })}
            onError={() => this.setState({ isError: true })}
          />
        ) : null}
        <Image
          wrapped={true}
          avatar={true}
          size={size}
          verticalAlign="middle"
          spaced="right"
          src={isLoaded && !isError && picture ? picture : defaultImage}
        />
        <div className="avatar-content">
          <div className="cp text black big" style={{ paddingBottom: "12px" }}>
            {name}
          </div>
          {createdAt ? (
            <div>
              <span className="text black">{i18n.t("Created")}</span>
              <span className="text"> {i18n.t("At")} </span>
              <span className="text">{createdAt}</span>
            </div>
          ) : null}
          {detail ? <div className="text">{detail}</div> : null}
        </div>
      </div>
    )
  }
}

export default GroupAvatar
