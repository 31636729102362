import React, { useState, useEffect } from "react"
import deleteIcon from "../../../assets/icons/community/delete.svg"
import hideIcon from "../../../assets/icons/community/hide.svg"
import showIcon from "../../../assets/icons/community/show.svg"
import { Loader, Popup } from "semantic-ui-react"
import { Analytics, API } from "@chatpay/components"
import { GroupQuickActionModal } from "./GroupQuickActionModal"
import { EditGroupQuickActionButton } from "./GroupQuickActionStyled"
import { Events, GroupStepStatus, Product } from "@chatpay/common"
import { routes } from "@chatpay/common"

export type GroupButtonType = {
  type: "delete" | "hide" | "show"
}

export interface IProps extends GroupButtonType {
  groupId: string
  description: string
  product?: Product
}

const handleProductDetailEvents = (event: string, product: Product | undefined) => {
  const isDesktop = window.matchMedia("(min-width: 992px)").matches
  Analytics.track(event, {
    device: isDesktop ? "desktop" : "mobile",
    userId: product?.owner?.id,
    productId: product?.id,
    productType: product?.type,
    resourceType: product?.groupResourcesType,
  })
}

const GroupQuickActionButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { groupId, type, description, product } = props
  const isDesktop = window.matchMedia("(min-width: 992px)").matches
  const [loading, setLoading] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const groupApi = API.Group.factory()

  const deleteGroup = async () => {
    handleProductDetailEvents(Events.PRODUCT.DELETE_CLICKED, product)
    setLoading(true)
    const deletedGroup = await groupApi.changeStatus({ groupId, status: GroupStepStatus.isDeleted })
    handleProductDetailEvents(Events.PRODUCT.DELETED, product)
    setLoading(false)
    window.location.replace(routes.DASHBOARD.LINK)
    return deletedGroup
  }

  const hide = async () => {
    handleProductDetailEvents(Events.PRODUCT.HIDE_CLICKED, product)
    setLoading(true)
    const HidedGroup = await groupApi.hide(groupId)
    setLoading(false)
    window.location.replace(routes.DASHBOARD.LINK)
    return HidedGroup
  }

  const show = async () => {
    handleProductDetailEvents(Events.PRODUCT.SHOW_CLICKED, product)
    setLoading(true)
    const ShowedGroup = await groupApi.show(groupId)
    handleProductDetailEvents(Events.PRODUCT.SHOWED, product)
    setLoading(false)
    window.location.replace(routes.DASHBOARD.LINK)
    return ShowedGroup
  }

  const getPropsByType = () => {
    switch (type) {
      case "delete":
        return {
          icon: deleteIcon,
          handleModalSubmit: async () => await deleteGroup(),
          modalNode: "deleteModal",
        }
      case "hide":
        return {
          icon: hideIcon,
          handleModalSubmit: async () => await hide(),
          modalNode: "hideModal",
        }
      case "show":
        return {
          icon: showIcon,
          handleModalSubmit: async () => await show(),
          modalNode: "showModal",
        }
    }
  }

  const { icon, modalNode, handleModalSubmit } = getPropsByType()

  useEffect(() => {}, [showConfirmModal, setShowConfirmModal])

  const IcoContent = () => {
    return (
      <>
        {loading === true ? (
          <Loader className="main-icon" size="small">
            Loading
          </Loader>
        ) : (
          <img src={icon} alt={"group icon"} className="main-icon status-icon" />
        )}
      </>
    )
  }

  return (
    <>
      <Popup
        trigger={
          <div>
            <EditGroupQuickActionButton
              size="small"
              onClick={() => setShowConfirmModal(true)}
              icon={<IcoContent />}
              basic
            />
          </div>
        }
        content={description}
        position="bottom right"
        offset={[0, 5]}
        open={!isDesktop ? false : undefined}
      />
      <GroupQuickActionModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        handleSubmit={handleModalSubmit}
        modalNodeName={modalNode}
        type={type}
        loading={loading}
      />
    </>
  )
}

export { GroupQuickActionButton }
