import { Model } from "@chatpay/common"
import Firebase from "../Firebase"

function userCurrency(): string | undefined {
  return Firebase.currentUser?.currency ?? undefined
}

function userCurrencyFormat(n: number, options?: { symbol?: boolean }): string {
  return currencyFormat(n, userCurrency() ?? Model.Currency.usd, options)
}

function userCurrencySymbol(): string {
  return currencySymbol(userCurrency() ?? "default")
}

function currencyFormat(n: number, code: string, options: { symbol?: boolean } = { symbol: true }): string {
  return new Intl.NumberFormat(
    "pt-BR",
    options.symbol ? { style: "currency", currency: code, minimumFractionDigits: 2 } : { minimumFractionDigits: 2 },
  ).format(n)
}

function currencySymbol(code: string): string {
  return Model.Symbols[code]
}

const Price = {
  currencySymbol,
  currencyFormat,
  userCurrency,
  userCurrencyFormat,
  userCurrencySymbol,
}

export default Price
