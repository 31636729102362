import {
  AutomaticAuthErrors,
  createGroup,
  EditNotification,
  NotificationGroup,
  TelegramVerification,
  WhatsAppVerification,
  Product,
  refundPage,
  Receiver,
  Sales,
} from "./en/index"

import { Dashboard } from "./en/Dashboard"
import { adminDashboard } from "./en/AdminDashboard"
import { activity } from "./en/Activity"
import { checkout } from "./en/Checkout"
import { purchaseDetail } from "./en/PurchaseDetail"
import { intervalDescription } from "./en/IntervalDescription"
import { aboutUs } from "./en/AboutUs"
import { landingPage } from "./en/LandingPage"
import { groupDetail } from "./en/GroupDetail"
import { paymentModals } from "./en/PaymentModals"
import { GetStarted } from "./en/GetStarted"
import { myGroups } from "./en/MyGroups"
import { migrationRequest } from "./en/MigrationRequest"
import { couponForm } from "./en/CouponForm"
import { AccountVerification } from "./en/AccountVerification"
import { EditAbout } from "./en/EditAbout"
import { subscription } from "./pt/Subscription"
import { editGroup } from "./en/EditGroup"
import { GroupForm } from "./en/GroupForm"
import { Review } from "./en/Review"
import { EmptyStates } from "./en/EmptyStates"
import { ProductForm } from "./en/ProductForm"
import { GroupStatusButton } from "./en/GroupStatusButton"
import { FreeMembers } from "./en/FreeMembers"
import { DashboardWallet } from "./en/DashboardWallet"

const navBar = {
  Login: "Login",
  Tips: "Tips & Resources",
  MyProducts: "My Products",
  MyGroups: "My Groups",
  MySales: "My Sales",
  Hello: "Hello",
  FAQ: "Help",
  Learn: "Learn",
  Pricing: "Pricing",
  Mode: {
    Creator: "Creator",
    Member: "Member",
  },
  ChangeMode: {
    Creator: "Creator Mode",
    Member: "Member Mode",
  },
}

const navBarMenu = {
  Dashboard: "Dashboard",
  Affiliates: "Affiliates",
  TipsAndResources: "Tips and resources",
  OurBlog: "Our blog",
  FAQ: "Help",
  Profile: "Profile",
  EditProfile: "Edit profile",
  AccountVerified: "Account Verified",
  BankAccount: "Bank Account",
  VerifyAccount: "Verify Account",
  Invite: "Invite a creator",
  Logout: "Logout",
}

const cookies = {
  Title: "Our  website uses cookies to improve the user experience.",
  Button: "Ok, I got it!",
}

const pages = {
  Home: "Home",
  Blog: "Blog",
  Pricing: "Pricing",
  Team: "Team",
  Faq: "Help Central",
  TermsOfUse: "Terms Of Use",
  Refunds: "Refunds",
  Invite: "Invite a Creator",
  Careers: "Careers",
  HowTo: "Guide",
  AboutUs: "About Us",
}

const auth = {
  title: "Turn your audience into a profitable business.",
}

const common = {
  actives: "Actives",
  inactives: "Inactive",
  sales: "Sales",
  coupons: "Coupons",
  wallet: "Wallet",
}

const general = {
  becomeCreator: "Become a creator",
  CreateGroup: "Create Group",
  createCoupon: "New coupon",
  ContactSales: "Contact Sales",
  JoinGroup: "Join Group",
  Join: "Join",
  LinkCopied: "Copied link!",
  CopyLink: "Copy Link",
  CopyProfileLink: "Copy Profile Link",
  Share: "Share",
  Groups: "groups",
  Next: "next",
  Save: "save",
  Saved: "saved!",
  Price: "Price",
  ImageFile: "Image",
  PdfFile: "pdf",
  InsertFile: "Insert or drop",
  ChangeFile: "Change",
  AddFile: "Add",
  Monthly: "monthly",
  Quarterly: "quarterly",
  Semiannually: "semiannually",
  Annually: "annually",
  Month: "month",
  Quater: "quater",
  Semianual: "semianual",
  Anual: "year",
  Plan: "Plan",
  Publish: "Publish",
  Available: "Available",
  WhatsApp: "WhatsApp",
  Created: "Created",
  At: "at",
  Thanks: "Thank you",
  Payment: "Payment",
  Paid: "paid",
  Copy: "Copy",
  PhoneNumber: "Phone Number",
  Today: "Today",
  New: "New",
  Confirm: "Confirm",
  Cancel: "Cancel",
  Discover: "Discover",
  group: "Group",
  Sales: "Sales",
  Edit: "Edit",
  Delete: "Delete",
  Advantages: "Advantages",
  SeeAllMedias: "See all medias",
  maybeLater: "Maybe later",
  View: "View",
  close: "Close",
}

const userDocument = {
  Personal: "CPF",
  Company: "CNPJ",
}

const helpPopup = {
  HelpPopup1: "This is the balance of your sales that will be released for withdrawal within 30 days.",
  HelpPopup2: "This is the amount that will be deducted from your balance by selling your groups through affiliates.",
  HelpPopup3: "This is the balance that you can withdraw.",
  HelpPopup4: "This is the balance of your sales as an affiliate that will be available for withdrawal within 30 days.",
  HelpPopup5:
    "This is your balance available for sales as an affiliate, however you need to verify your account to withdraw it.",
  HelpPopup6: "This is your credit available on the platform, however you need to verify your account to withdraw it.",
  HelpPopup7:
    "This is the balance that will be deposited in your registered account. This process usually takes a maximum of 48 hours.",
  HelpPopup8:
    "The phone number registered on the platform must be the same as the one you use on your WhatsApp or Telegram, this is necessary for our verification within the groups.",
  HelpPopup9: "These are your sales at Hubla, the values do not discount our fees.",
  HelpPopup10: "Here you can follow the status of your group's subscribers.",
  HelpPopup11: "You can enter multiple email addresses by separating them with a comma.",
  HelpPopup12: "Your invitation link is unique and it is important that your guest registers from it.",
}

const bankStatements = {
  withdrawsHistory: "Withdrawals History",
  referralCommission: "Referral Commission",
  amountTransfer: "Transfers",
  refund: "Refund",
  affiliateCommission: "Affiliate Commission",
  bankSlipReturnCommission: "Bank Slip Refund Commission",
  withdrawFee: "Withdrawal Fee",
  withdrawalRequest: "Withdrawal Request",
  value: "Value",
  date: "Date",
  status: "Status",
  feedback: "Feedback",
  reason: "Reason",
  fees: "Fees",
  from: "From",
  to: "To",
}

const AccountBankAccount = {
  title: "Your bank account",
  subtitle: "If you want, you can edit your bank account data",
  button: "update",
  success: "Account updated sccessfully",
  bradescoHint: "For Bradesco customers, it is necessary to know the digit of your branch. You can find it by clicking",
  bradescoHintHere: "here.",
  caixaHint: "For Caixa Econômica customers, the account check can be: a number, 0 or X. i.e: 00000000-0 or 00000000-X",
  bank: "Bank",
  agency: "Agency",
  agencyAndCheck: "Agency and Check",
  account: "Account",
  accountAndCheck: "Account and Check",
  operation: "Operation",
  accountType: "Account Type",
  checking: "Checking",
  savings: "Savings",
}

const wallet = {
  Balance: "Balance:",
  Receivable: "Available soon",
  Commissionspayable: "Commissions payable:",
  Available: "Available for withdrawal",
  AffiliatesReceivable: "Affiliates Receivable:",
  AffiliatesAvailable: "Affiliates Available:",
  Credit: "Credit:",
  InTransit: "On the way to your bank",
  Withdraw: "Request withdrawal",
  Text: "Your balance receivable can take up to 30 days to be available for withdrawal. With the exception of payments by bank slip.",
}

const paymentMethod = {
  Title: "Payment Method",
  credit_card: "Credit Card",
  debit_card: "Debt Card",
  cash_tranfer: "Bank Wire Transfer",
  bank_slip: "Bank Slip",
  pix: "PIX",
  cash: "Cash",
}

const termsOfUse = {
  title: "Our terms of use have changed!",
  subtitle: "By clicking I accept, you accept and agree with ours",
  term: "Terms of Use",
  accept: "I accept",
}

const invoice = {
  paid: "Paid",
  pending: "Pending",
  expired: "Expired",
  canceled: "Canceled",
  credit_card: "Credit Card",
  bank_slip: "Bank Slip",
  pix: "PIX",
  refunded: "Refunded",
  notFound: "Invoice not found",
  /** STATUS_UNSPECIFIED - Default value, do not use. */
  STATUS_UNSPECIFIED: "Unspecified",
  /** STATUS_DRAFT - Draft. Amounts, items and quantities can be modified. */
  STATUS_DRAFT: "Draft",
  /** STATUS_UNPAID - Invoice is ready to be charged and will not be modified anymore. */
  STATUS_UNPAID: "Unpaid",
  /** STATUS_PAID - Invoice was paid. */
  STATUS_PAID: "paid",
  /** STATUS_PARTIALLY_PAID - Invoice was paid, but the paid amount was lower than amount_due_cents. */
  STATUS_PARTIALLY_PAID: "Partially paid",
  /** STATUS_OVERDUE - Invoice wasn't paid before the due_date. */
  STATUS_OVERDUE: "Overdue",
  /**
   * STATUS_VOID - Invoice canceled/discarded. Should not be paid. If, however, the payer manages to paid it, money will be automatically
   * returned (invoice status will continue to be INVOICE_STATUS_VOID). This status is final.
   */
  STATUS_VOID: "Canceled/Discarded",
  /** STATUS_RETURNED - Invoice was paid but the full amount was returned to the payer. */
  STATUS_RETURNED: "Returned",
  /** STATUS_PARTIALLY_RETURNED - Invoice was paid but part of the money was returned to the payer. */
  STATUS_PARTIALLY_RETURNED: "Partially returned",
}

const invoicePaymentMethod = {
  PAYMENT_METHOD_PIX: "PIX",
  PAYMENT_METHOD_CARD: "Credit Card",
  PAYMENT_METHOD_BOLETO: "Boleto",
}

const refund = {
  avaliation: "In Analysis",
  accepted: "Refunded",
  rejected: "Rejected",
  failed: "Failed",
  processing: "Processing",
}

const affiliateDashboard = {
  Title: "Affiliates",
  Empty: "You are not yet affiliating any products",
  Sale: "sale",
  Sales: "Sales",
  AsAffiliate: "as affiliate",
  LiquidVolume: "Net Amount",
  StepsTitle: "How to affiliate a product?",
  Step1Title: "Copy your affiliate link",
  Step1Text: "You have a unique link for each product. Copy the link of the product you want to promote.",
  Step2Title: "Promote your link",
  Step2Text: "For people to be able to buy the spot in your product, share this link.",
  Step3Title: "You receive the bonus for confirmed purchase",
  Step3Text: "Remembering that the purchase has to be made using your link.",
  Step4Title: "See your balance in this panel",
  Step4Text:
    "Your sales by successful affiliates will be displayed on this panel. You can cash out after 30 days of the sale.",
}

const Buttons = {
  EditPostButton: {
    edit: "Edit",
    delete: "Delete",
  },
  GroupButton: {
    config: "Settings",
    edit: "Edit",
    disableSales: "Disable sales",
    hideProfile: "Hide profile",
    showProfile: "Show profile",
    deleteGroup: "Delete",
    publishGroup: "Publish",
    continueCreation: "Continue creation",
    enableSales: "Enable sales",
    cancelSubscription: "Cancel subscription",
    extraInvoice: "New invoice",
    cancelMembership: "Cancel membership",
    payInvoice: "Pay Invoice",
    whitelist: "Free member",
    exportMembers: "Export members",
    exportMembersTip: "You need to select a group to be able to export members",
    reload: "Reload",
  },
}

const intervals = {
  mensal: "monthly",
  trimestral: "quarterly",
  semestral: "semiannually",
  anual: "annually",
  mês: "month",
  trimestre: "trimester",
  semestre: "semester",
  ano: "year",
}

const payment = {
  Waiting: "Waiting for payment",
}

const join = {
  Error: "We had an error. Please reload the page.",
  NoNumber: "We have not identified your phone number. Click here to talk to the support",
  Join: "join group",
  JoinBasic: "join",
  FinishedSales: "sales finished",
  Unavailable: "unavailable",
  Full: "group full",
  Waiting: "waiting",
  Pending: "Pending",
  Config: "configuration",
  draftGroup: "This group is still in draft.",
  Payment: "payment",
  Renew: "renew subscription",
  Buy: "buy",
  UpTo: "up to",
  From: "from",
  Or: "or",
  Month: "month",
  modalTitle: "Remember to pay your bankslip",
  modalSubtitle:
    "If your bankslip is not paid today, it will not be accepted tomorrow and you will be removed from the group.",
  WelcomeMessageTitle: "This product gives you access to several groups",
}

const phone = {
  phoneHint: "Enter your phone number",
  phoneConfirm: "Confirm your phone number",
  phoneLabel: "WhatsApp or Telegram number",
  phonePopupText:
    "Your phone registered in the plataform must be the same you use for WhatsApp or Telegram. It is needed for verifying your membership in groups and channels.",
  accountHeader: "Join several creators who have already made millions with Hubla selling their digital communities.",
  modalHeader: "We need your phone number to continue",
  noAccountHeader: "Verify phone",
  accountSubHeader: "",
  noAccountSubHeader: "We need your phone\nto give you access to your group.",
  noAccountButtonHeader: "We will send you an SMS to verify your number.",
  preTextReCaptcha: "This site is protected by reCAPTCHA and the Google ",
  middleTextReCaptcha: " and ",
  postTextReCaptcha: " apply.",
  privacyPolicyReCaptcha: "Privacy Policy",
  termsOfServiceReCaptcha: "Terms of Service",
  termsOfUsePreText: "By clicking you agree to our ",
  termsOfUse: "Terms of use.",
  codeHeader: "Type the code sent to your phone ",
  code: "Code",
  timer: "Wait {{time}} seconds to resend the code",
  resendCode: "Resend code",
  continue: "continue",
  continueWithEmail: "continue with email",
  continueWithPhone: "continue with phone number",
  done: "done",
  enterWpp: "Insert your phone number to join",
  numberHint: "For your security, we need to verify your phone number.",
  receiveWpp: "Receive code via WhatsApp",
  receiveSms: "Receive code via SMS",
  typeCode: "Enter the code",
  codeSentTo: "We sent a code to",
  changeNumber: "Change number",
  phoneFail: "Couldn't you verify by phone?",
  loginWithEmail: "Sign in by email",
}

const email = {
  emailHint: "Enter your email",
  emailLabel: "Email",
  emailPopupText:
    "O número de telefone registrado na plataforma deve ser o mesmo que você utiliza em seu WhatsApp ou Telegram, isso se dá necessário pela nossa verificação dentro dos grupos e canais.",
  passwordHint: "Enter your password",
  passwordLabel: "Password",
  passwordLabelChange: "Enter Your New Password Here",
  passwordHeader: "Enter your password to continue",
  passwordHeaderChange: "Set a password",
  passwordCreate: "I don't have a password yet",
  passwordForgot: "I forgot my password",
  passwordEmail: "Check your email",
  passwordEmailSubtitle1: "We sent an email to",
  passwordEmailSubtitle2: "with a verification code for you to set your password.",
  passwordEmailHint1: "Did not get the email? Check for spam or",
  passwordEmailHint2: "try again.",
  code: "Verification Code",
  continue: "continue",
  done: "ready",
  suggestion: "Did you mean:",
  passwordError: "(Password does not meet the requirements)",
  invalidPasswordError: "(Invalid password)",
  back: "Back to",
  change: "Change",
  passwordErrorHint: "Your password must contain at least 6 characters, one uppercase and one lowercase character.",
  invalidEmail: "The email address is invalid.",
  startSession: "Join several creators who have already made millions with Hubla selling their digital communities.",
}

const coupon = {
  name: "Coupons",
  table: {
    status: "Status",
    code: "Code",
    discount: "Discount",
    plans: "Plan",
    products: "Products",
    used: "Used",
    expiration: "Expires",
    recurrent: "Recurrent",
  },
  status: {
    active: "Active",
    inactive: "Inactive",
    outOf: "Out-of",
    expired: "Expired",
  },
  delete: {
    title: "Delete Coupon?",
    warning: "This action is irreversible. It will not be possible to recover the coupon.",
  },
  buttons: {
    create: "New Coupon",
    edit: "EDIT",
    delete: "DELETE",
    cancel: "CANCEL",
  },
  input: {
    placeholder: "Enter your coupon",
  },
  header: {
    add: "Add a coupon",
    create: "Create a new coupon",
  },
  error: {
    add: "Couldn't add your coupon. Please try again.",
    migratedUser: "We had a problem but the support team can help you",
  },
  link: {
    question: "Do you have a coupon?",
    add: "Add your coupon here.",
    remove: "Remove coupon",
  },
  success: "Coupon successfully added",
  allGroups: "All groups",
}

const team = {
  title: "Our team",
  subtitle: "Meet who's behind Hubla",
  angeltitle: "Angel investor",
  angelsubtitle: "Investor and entrepreneur with solid results.",
}

const subscriptionPlanInterval = {
  title: "Change plan",
  creditCard: {
    title: "Your Credit Cards",
  },
  couponCode: {
    title: "Coupon Code",
  },
  error: {
    subscriptionNotFound: "Token subscription is not valid",
  },
  button: {
    apply: "apply",
    remove: "remove",
    submit: "update plan",
    addNewCreditCard: "Add new credit card",
  },
}

const subscriptionPaymentMethod = {
  title: "Change payment method",
  subtitle:
    "Updating your payment information or payment method does not activate, upgrade or extend your subscription.",
  linkText1: "Change",
  linkText2: "Add",
  linkText3: "credit card",
  info1:
    "When you update your payment method to bank slip, it will not be issued until the date of the next payment on your subscription.",
  info2:
    "If your payment is due today, an invoice will be issued and you can access your payment slip on the previous page (My Groups> Subscriptions> Detail)",
  pixinfo1:
    "When you update your payment method to pix, it will not be issued until the date of the next payment on your subscription.",
  pixinfo2:
    "If your payment is due today, an invoice will be issued and you can access your pix on the previous page (My Groups> Subscriptions> Detail)",
  button: "update",
}

const exportRequest = {
  report: "Report",
  affiliates: "of Affiliates",
  invoices: "of Invoices",
  subscriptions: "of Subscriptions",
  members: "of Members",
  refunds: "of Refunds",
  complete: "Download",
  error: "Error",
  pending: "Processing...",
  status: "Status",
  type: "Type",
  createdAt: "Created At",
}

const maintenance = {
  title: "We are under maintenance.",
  subtitle: "We are working hard to get back with the service as soon as possible!",
  button: "back",
}

const userGroups = {
  groups: "Groups",
  edit: "Edit groups order",
  firstGroup: "Lets create your first group?",
  noGroups: "This person does not currently have any groups for sale.",
  letsGo: "Let's go!",
}

const groupsOrder = {
  title: "Groups order",
  subtitle: "Hold and drag groups in the order you want them to appear on your profile.",
  error: "Error fetching groups. Please reload the page.",
  noGroups: "You haven't created any groups yet.",
}

const missingPage = {
  title: "We lost that page.",
  subtitle:
    "We searched everywhere, but we didn't find what you were looking for. Let's take you to a more interesting page.",
  button: "go to home page",
}

const userProfile = {
  createdGroups: "created groups",
}

const myGroupSubscription = {
  details: "Details",
  resolve: "Resolve pendency",
  buyAgain: "Buy Again",
  suspended: "Subscription suspended.",
  canceled: "Subscription canceled.",
  subscription: "Subscription",
  day: "day",
  days: "day",
  expired: "Subscription expired.",
  nextBilling: "Your next charge will be at",
  nextBillingAlternative: "Your next charge will be on the day",
  onPrice: ", worth",
  helpPopup1: "In case of credit card, the renewal is done automatically.",
  error: "Error loading subscription status",
  daysLeft: "days left",
  dayLeft: "day left",
  paymentPending: "Pending payment",
  payInvoice: "Pay invoice",
  renewSubscription: "Renew subscription",
  expiredAt: "Your subscription expired at",
  bankSlipText1: "Your bank slip for renewal will be available",
  bankSlip5Days: "5 days",
  bankSlipText2: "before your subscription expires at",
  pixText1: "The renewal pix will be available",
  pix5Days: "5 days",
  pixText2: "before the expiration of your day subscription",
  creditCardText1: "Your next charge will be made automatically on the day",
  creditCardText2: "in the amount of",
  paymentFailed: "Payment failed",
  paymentFailedText1: "Failed attempt to charge your card.",
  paymentFailedText2: "Subscribe again",
  paymentFailedText3: "to re-establish your access.",
  modal: {
    credit_card: {
      header: "Pay with credit card",
      buttonAction: "Pay",
    },
    bankslipPending: {
      header: "There's already a bankslip",
      text: "Number",
    },
    bankslip: {
      header: "Pay with bankslip",
      text: "The bank slip is issued every month 5 days before your subscription expires. Only during this period will you be able to pay for the renewal.",
      buttonAction: "Update",
    },
    pix: {
      header: "Pay with PIX",
      text: "The PIX key is issued every month 5 days before your subscription expires. Only during this period will you be able to pay for the renewal.",
      buttonAction: "Update",
    },
    changeMethod: {
      text: "Change payment method?",
      credit_card: " (With credit card)",
      bankslip: " (With bankslip)",
      pix: " (With PIX)",
    },
  },
}

const withdraw = {
  accepted: "Transfer accepted",
  pending: "Transfer pending",
  rejected: "Transfer refused",
  title: "Request Withdrawal",
  subtitle: "Enter the amount you want to withdraw",
  available: "Available Balance:",
  value: "Value",
  tax: "Fee",
  text1: "Withdrawal speed varies and can take up to 3 business days. A fee of ",
  text2: " is charged for the transfer.",
  withdraw: "Withdraw",
  error: {
    title: "There was a problem.",
    subtitle: "Please contact support",
  },
  success: {
    title: "Success! Your serve is in progress.",
    subtitle: "Withdrawal speed varies and can take up to 3 business days.",
  },
}

const settings = {
  title: "Something is still missing",
  subtitle: "Please complete your information to continue using our system",
  firstName: "First Name",
  lastName: "Last Name",
  bio: "Bio",
  email: {
    title: "Confirm your E-mail",
    error: "Email doesn't match",
  },
}

const pictureEdit = {
  edit: "Change",
  add: "Add profile picture",
  remove: "Remove",
}

const countryHeader = "Choose your country"

const GetAffiliateContainer = {
  error: "Error with affiliate link",
  tryagain: "Please, try again or contact support",
  perSales: "per sales",
  perRenewedSubscription: "per renewed subscription",
  shareLink: "Share your link to affiliate to this group",
  upTo: "up to",
  loadingLink: "Loading affiliate link",
  accountVerification: {
    title: "We need your bank account to get you paid",
    subtitle: "Before becoming an affiliate, please add your bank account",
  },
}

const AffiliateTransaction = {
  transactionReceipt: "Transaction",
  buyDate: "Buy Date",
  paymentDate: "Payment Date",
  noInfo: "No information",
  status: "Status",
  group: "Group",
  plan: "Plan",
  paymentMethod: "Payment Method",
  price: "Price",
  commission: "Commission",
  coupon: "Coupon",
  totalCommission: "Total Commission",
  transactionReleased: "Transaction Released",
  releasedAt: "Released at",
}

const Common = {
  Active: "Active",
  Draft: "Draft",
  Disabled: "Disabled",
  ViewPage: "Preview product",
  at: "at",
  created: "created",
  day: "day",
  upTo: "up to",
  walletHeader: "wallet",
}

const TextArea = {
  atLeast: `You need to write at least {{minLength}} characters`,
  charactersLeft: `{{length}} of {{maxLength}} characters left`,
  writeMore: `Write more {{length}} characters`,
}

const Payments = {
  m1: "We will automatically charge you through your selected payment method for your subscription. You can cancel your subscription at any time.",
  safe: "100% Safe  ",
  onetime: "one time payment",
  m3: "will only be charged after first",
  m4: "One time Payment",
  x1: "1x Access to this Group",
  then: "then",
}

const UserInfo = {
  name: "First name",
  lastname: "Last name",
  email: "Email",
}

const Plans = {
  monthly: "Monthly",
  mensal: "Monthly",
  Mensal: "Monthly",
  quarterly: "Quarterly",
  trimestral: "Quarterly",
  Trimestral: "Quarterly",
  semiannually: "Semiannually",
  semestral: "Semiannually",
  Semestral: "Semiannually",
  annually: "Annually",
  anual: "Annually",
  Anual: "Annually",
  single: "One time payment",
  all: "All plans",
}

const InputPlan = {
  errors: {
    lower: "Price must be lower than",
    higher: "Price must be higher than",
  },
  onetime: "One time payment",
  subscription: "Subscription",
  installments: "Installments",
  price: "Price",
  monthly: "Monthly",
  quarterly: "Quarterly",
  semiannually: "Semiannually",
  annually: "Annually",
  plan: "Plan",
  totalPrice: "Total Price",
}

const InstallmentOptions = {
  upTo: "up to ",
  disabled: "Disabled",
}

const InputFiles = {
  files: "Files",
  add: "Add file",
}

const InputDate = {
  months: {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
  },
  weekDays: {
    Sunday: "Sunday",
    shortSunday: "S",
    Monday: "Monday",
    shortMonday: "M",
    Tuesday: "Tuesday",
    shortTuesday: "T",
    Wednesday: "Wednesday",
    shortWednesday: "W",
    Thursday: "Thursday",
    shortThursday: "T",
    Friday: "Friday",
    shortFriday: "F",
    Saturday: "Saturday",
    shortSaturday: "S",
  },
  nextMonth: "Next Month",
  previousMonth: "Previous Month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select...",
  filterDate: "Filter by date",
  all: "All",
  today: "Today",
  lastWeek: "Last 7 days",
  lastMonth: "Last 30 days",
  last15Days: "Last 15 days",
  yesterday: "Yesterday",
  thisMonth: "This month",
}

const UserForm = {
  profile: {
    title: "Profile",
    subtitle:
      "Your profile is an important part of your business. Put a name, description and a photo to give more credibility.",
    picture: "Picture",
    firstName: "First Name",
    lastName: "Last Name",
    bio: "Bio",
    site: "Site",
  },
  credentials: {
    title: "Personal information",
    subtitle: "Edit your personal information on the platform. They are unique to each user.",
  },
  locale: {
    title: "Country",
    subtitle: "Your country of choice determines the currency in which you will sell your groups",
    m1: "You can only choose your country once",
  },
  integrations: {
    eNotas: {
      title: "eNotas: automatic tax invoices",
      subtitle1:
        "By integrating with eNotas, automatic tax invoices will be issued to all customers who buy the groups selected beside.",
      subtitle2: "Create an eNotas account, get your API Key and insert here.",
      tutorial: "Tutorial",
      apiKeyLabel: "eNotas API Key",
      integrateButton: "Integrate",
      groupsLabel: "Groups for automatically issue",
      dropDownAllLabel: "All",
      removePopUpTitle: "Remove eNotas integration",
      removePopUpMessage:
        "Are you sure you want to remove your integration with eNotas? Your groups will no longer issue automatic invoices.",
    },
    zapier: {
      title: "Zapier API Integration (Beta) ",
      description:
        "You can integrate Hubla with your existing tools like ActiveCampaign, Hubspot, MailChimp and more using Zapier.",
      documentation: "Documentation",
      apiKeyTitle: "Zapier API Key",
      buttonRefreshAPIToken: "Refresh API token",
      buttonRequestAPIToken: "Request API token",
      buttonDeleteAPIToken: "Delete token",
      buttonCopyAPIToken: "Copy token",
      actionPopup: {
        tokenCopied: "Token Copied!",
      },
    },
    webhookEvents: {
      title: "Webhooks from Hubla",
      description:
        "You can receive Hubla webhooks with event specific information (sales, subscriptions, cancels and etc.)",
      documentation: "Documentation",
      apiKeyTitle: "Hubla API Key",
      buttonRefreshAPIToken: "Refresh API token",
      buttonRequestAPIToken: "Request API token",
      buttonDeleteAPIToken: "Delete token",
      buttonCopyAPIToken: "Copy token",
      actionPopup: {
        tokenCopied: "Token Copied!",
      },
    },
    userApi: {
      title: "Authorization Key",
      description: "You can generate a unique authorization key to perform queries in our API.",
      documentation: "Documention",
      labelTitle: "Bearer Token",
      buttonRefreshToken: "Refresh token",
      buttonRequestToken: "Request token",
      buttonDeleteToken: "Delete token",
      buttonCopyToken: "Copy token",
      actionPopup: {
        tokenCopied: "Token Copied!",
      },
    },
    publicApi: {
      title: "Public API Integration (Beta) ",
      description: "You can integrate Hubla with your existing tools.",
      documentation: "Documentation",
      apiKeyTitle: "Public API Key",
      buttonRefreshAPIToken: "Refresh API token",
      buttonRequestAPIToken: "Request API token",
      buttonDeleteAPIToken: "Delete token",
      buttonCopyAPIToken: "Copy token",
      actionPopup: {
        tokenCopied: "Token Copied!",
      },
    },
  },
}

const AV = {
  notCreated: {
    m1: "You're almost there! ",
    m2: " in order to publish your groups and receive from them.",
    l1: "Verify your account",
  },
  pending: {
    m1: "Your account is ",
    m2: "being verified. ",
    m3: "We'll notify you over email and in this page when it's ready. ",
    l1: "click here.",
  },
  accepted: {
    m1: "Your account was ",
    m2: "successfully verified! ",
    m3: "Now you can publish your groups and start selling.",
  },
  rejected: {
    m1: "We couldn't verify your account with the information provided. Please,  ",
    l1: "try again",
    m2: " or",
    l2: "contact support.",
    m3: "%20I%20can't%20verify%20my%20account.%20",
  },
}

const Authentication = {
  Profile: {
    title: "Create your profile",
    subtitle: "Your information will be used to access your account.",
  },
  CreateGroup: {
    title: "Create your first group",
    subtitle1: "You don't need you access Whatsapp or Telegram to create a group. Chatpay creates it for you!",
    subtitle2: "If you already have an active group and would like to migrate it into Chatpay, ",
    subtitleAction: "click here",
    observation:
      "Observation: You don't need to create a group or a channel on WhatsApp or Telegram. Once you create it in Chatpay, we will automatically create a group for you.",
    button: "Create",
  },
  backTo: "Back to",
}

const InputCover = {
  pictureSize: "Recommended size: 819 x 438 pixels",
  coverSize: "Recommended size: 1440 x 1024 pixels",
}

const GroupCreation = {
  myGroups: "My Products",
  create: "Create your product",
}

const ActivityDetail = {
  activity: "Activity",
  info: "Info",
  history: "History",
  product: "Product",
  name: "Name",
  email: "Email",
  contact: "Contact",
  whitelist: "Free member",
  remove: "Remove",
  cancel: "Cancel",
  linkedGroups: {
    header: "It seems that you have groups linked to this one, which ones would you like to take action on?",
  },
}

const errors = {
  auth: {
    phonenumberInvalid: "This phone number is invalid. Please enter another number and try again.",
    whatsappInvalid: "This WhatsApp number is invalid. Please enter another number and try again.",
    unknownError: "We couldn't verify your number. Reload our website and try again.",
    disabledUser: "There was a problem with your account, contact support to find out more.",
  },
  groupCreation: {
    shortTitle: "Title must be bigger than 3 characters",
    longTitle: "Title must be smaller than 70 characters",
    required: "Required",
    longSubtitle: "Subtitle must be smaller than 700 characters",
  },
}

const groupNotifications = {
  header: "Your notification group is ready!",
  subheader: "Click the button below to join it.",
  button: "Join",
}

const productDoesNotHaveGroupIMOrContentDialog = {
  title: "Create content or add a group to start selling",
  subtitle: "It is mandatory to have at least one content or group created to your product before start selling.",
  buttonLabel: "Ok",
}

const OffersPane = {
  EntryFeeSwitch: {
    title: "It charges a differentiated fee from its members for the first month of access to the product.",
    subtitle:
      "For example, if your product has a monthly subscription of R$59.90/month and a fee of " +
      "BRL 100.00 membership, your members will only pay the membership fee at the time " +
      "of the purchase, and after 30 calendar days, the monthly subscription fee for the following months.",
  },
  UpsellSwitch: {
    title: "1-Click Upsell",
    subtitle:
      "Offer a new product on your flagship product's thank you page, i.e. " +
      "enable your customer to make a new purchase with just one click, taking advantage of the " +
      "personal and payment information provided at first purchase.",
    deleteUpsell: "Do you want to delete your 1-click upsell strategy?",
    deleteUpsellButtonText: "Delete Upsell",
    priceStartingFrom: "From {{startingPrice}}/month",
  },
  UpsellModal: {
    ChooseProduct: {
      step: "Step 1 of 3",
      title: "Add Product",
      subtitle: "Select a new product that will be offered as a 1-click upsell of your main product.",
      noProduct: {
        altText: "Person holding megaphone with closed eyes",
        title: "Nothing here :(",
        subtitle: "You don't have any eligible products to set up as a 1-click upsell yet.",
      },
      table: {
        products: "Products",
        price: "Price",
      },
      priceStartingFrom: "From {{startingPrice}}/month",
    },
    SetupOffer: {
      step: "Step 2 of 3",
      stepEditing: "Step 1 of 2",
      title: "Configure offer",
      subtitle: "Edit or confirm the price that will be charged for the product according to your upsell strategy.",
      price: "Price",
      oneTimePayment: {
        installments: "Installation options",
        installmentsDisabled: "Disabled",
        error: {
          shouldBeNumbersOnly: "Please use numbers only",
          priceTooLow: "Price must be greater than R$ 10.00",
        },
        splitInXTimes: "in up to {{times}}x",
      },
      subscription: {
        monthly: "Monthly Subscription",
        quarterly: "Quarterly subscription",
        semiannually: "Semi-annual Subscription",
        annually: "Annual subscription",
      },
      equivalentTo: "Equivalent to",
      pricePerMonth: "{{price}}/month",
    },
    AddMedia: {
      step: "Step 3 of 3",
      stepEditing: "Step 2 of 2",
      title: "Custom 1-click upsell",
      subtitle:
        "Personalize your upsell offer with a video. Adding a video can increase " +
        "significantly the conversion of your offer, in addition to showing more credibility.",
      mediaUrl: "YouTube Video URL",
      error: {
        invalidYoutubeUrl: "Please provide a valid YouTube URL.",
      },
      createUpsell: "Create upsell",
      updateUpsell: "Save Changes",
    },
    cancelButton: "Cancel",
    nextButton: "Next",
  },
}

export default {
  ...Common,
  ...general,
  Receiver,
  EditAbout,
  TextArea,
  AutomaticAuthErrors,
  EditNotification,
  NotificationGroup,
  errors,
  GroupCreation,
  GetStarted,
  InputCover,
  Authentication,
  AV,
  UserForm,
  InstallmentOptions,
  InputFiles,
  InputPlan,
  GroupForm,
  editGroup,
  Plans,
  UserInfo,
  Payments,
  AffiliateTransaction,
  GetAffiliateContainer,
  countryHeader,
  bankStatements,
  common,
  AccountBankAccount,
  AccountVerification,
  Dashboard,
  Buttons,
  userDocument,
  navBar,
  navBarMenu,
  cookies,
  auth,
  helpPopup,
  wallet,
  adminDashboard,
  affiliateDashboard,
  groupDetail,
  createGroup,
  myGroups,
  invoice,
  invoicePaymentMethod,
  refund,
  withdraw,
  subscription,
  TelegramVerification,
  payment,
  join,
  checkout,
  phone,
  email,
  paymentMethod,
  coupon,
  couponForm,
  ...intervals,
  landingPage,
  ...pages,
  team,
  subscriptionPaymentMethod,
  subscriptionPlanInterval,
  exportRequest,
  maintenance,
  userGroups,
  refundPage,
  groupsOrder,
  missingPage,
  userProfile,
  myGroupSubscription,
  settings,
  pictureEdit,
  activity,
  ActivityDetail,
  InputDate,
  groupNotifications,
  WhatsAppVerification,
  purchaseDetail,
  intervalDescription,
  aboutUs,
  termsOfUse,
  paymentModals,
  migrationRequest,
  Review,
  EmptyStates,
  ProductForm,
  Product,
  GroupStatusButton,
  FreeMembers,
  DashboardWallet,
  Sales,
  productDoesNotHaveGroupIMOrContentDialog,
  OffersPane,
}
