import { Document } from "@chatpay/common"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import { IUploadStatus } from "components/Fields/SelectGroupAsset/SelectGroupAsset"
import { GetYoutubeUrl } from "components/Modals/GetYoutubeUrl"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Form, Image } from "semantic-ui-react"
import { Text } from "typography"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import "./Assets.scss"
import arrayMove from "array-move"

const SortableItem = SortableElement(({ value }) => (
  <div className="media-picker" style={{ cursor: "grab" }}>
    <Image wrapped={true} src={value} />
  </div>
))

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="media-picker-wrapper">
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </div>
  )
})

type keys = "assets"

interface IForm {
  assets: Document.GroupAboutAsset[]
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const Assets: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props
  const [askForYoutubeUrl, setAskForYoutubeUrl] = useState<boolean>(false)
  const [reordering, setReordering] = useState<boolean>(false)
  const [form, setForm] = useState<Partial<IForm>>()

  const { t } = useTranslation()

  const getRemainingAssets = (array: Array<any>): number => {
    const remaining = 12 - (array?.length ?? 0)
    return remaining < 0 ? 0 : remaining
  }

  useEffect(() => {
    setForm(value)
  }, [value])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    onChange?.(newData, valid)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.assets || (data.assets ?? []).length <= 12
  }

  const updateAssets = (action: (newAssets: any[]) => void) => {
    const newAssets = (form?.assets ?? []).map((a) => ({ ...a }))
    action(newAssets)
    setFormValue("assets", newAssets)
  }

  const onRemoveItem = (idx: number) => updateAssets((assets) => assets?.splice(idx, 1))

  const onAddItem = (item: Document.GroupAboutAsset) => updateAssets((assets) => assets?.push(item))

  const onMaterialsUpdate = (status: IUploadStatus, material: Document.Material | undefined, idx: number) => {
    switch (status) {
      case IUploadStatus.started:
        return onAddItem({ name: "in_progress" })
      case IUploadStatus.finished:
        return updateAssets((assets) => (assets![idx] = convertMaterialToGroupAsset(material!)))
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newAssets = arrayMove(form?.assets!, oldIndex, newIndex)
    setFormValue("assets", newAssets)
  }
  const inProgress = !!form?.assets?.find((it) => it.name === "in_progress")

  if (reordering) {
    return (
      <>
        <SortableList
          items={form?.assets?.map((it) => (it.type === Document.GroupAboutAssetType.image ? it.url : it.thumbnailUrl))}
          onSortEnd={onSortEnd}
          axis="xy"
        />
        <Spacer />
        <div style={{ textAlign: "right" }}>
          <Button basic size="small" content={t("GroupForm.assets.editMedia")} onClick={() => setReordering(false)} />
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <Form>
        <div className="media-picker-wrapper">
          {(form?.assets?.concat({}) ?? []).map((it, idx) => {
            return idx < 12 ? (
              <Fields.SelectGroupAsset
                key={idx}
                asset={it}
                materialsCallback={(status: IUploadStatus, asset: Document.Material | undefined) =>
                  onMaterialsUpdate(status, asset!, idx)
                }
                removeCallback={() => onRemoveItem(idx)}
                youtubeCallback={() => setAskForYoutubeUrl(true)}
              />
            ) : null
          })}
        </div>
        <Spacer />
      </Form>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Text.Tiny style={{ opacity: 0.55, margin: 0, flex: 1 }}>
          {`${getRemainingAssets(form?.assets ?? [])} ${t("GroupForm.assets.remaining")}`}
        </Text.Tiny>
        <Button
          basic
          size="small"
          disabled={inProgress}
          content={t("GroupForm.assets.editOrder")}
          onClick={() => setReordering(true)}
        />
      </div>
      <GetYoutubeUrl
        isOpen={askForYoutubeUrl}
        onClosed={() => setAskForYoutubeUrl(false)}
        onFinish={(youtubeObject) => {
          setAskForYoutubeUrl(false)
          onAddItem({
            id: youtubeObject.id,
            thumbnailUrl: youtubeObject.thumbnail,
            url: youtubeObject.embed,
            type: Document.GroupAboutAssetType.youtube,
          })
        }}
      />
    </React.Fragment>
  )
}

export { Assets as Form }
export type { IProps, IForm }

function convertMaterialToGroupAsset(material: Document.Material): Document.GroupAboutAsset {
  const materialData = material.data as Document.VideoMaterialData
  const isVideo = material?.type !== Document.MaterialFormat.image
  return {
    id: isVideo ? materialData.assetId : material.id ?? "",
    name: material.name,
    url: material.url,
    thumbnailUrl: isVideo ? materialData.thumbnailUrl : undefined,
    type: isVideo ? Document.GroupAboutAssetType.video : Document.GroupAboutAssetType.image,
  }
}
