import { Events } from "@chatpay/common"
import { Service, Session } from "@chatpay/components"
import { Analytics } from "@chatpay/components"
import { CookieConsent } from "components/CookieConsent"
import TermsDialog from "components/User/Terms/TermsDialog"
import { routes } from "@chatpay/common"
import { createBrowserHistory } from "history"
import { FreeMembersPage } from "pages/FreeMembers/FreeMembers"
import WhitelistPage from "pages/User/Whitelist"
import * as React from "react"
import { Translation } from "react-i18next"
import { Route, Router, Switch } from "react-router-dom"
import "utils/Date"
import { getSiteUrl, isDebug } from "utils/SessionUtils"

import "./App.scss"

// tslint:disable-next-line: no-unused-expression
new Service.Firebase()

const UserDashboardPage = React.lazy(() => import("pages/User/Dashboard"))
const EditGroupPage = React.lazy(() => import("pages/EditGroup"))
const CreateProduct = React.lazy(() => import("modules/create-product/exports/create-product"))
const ExportPage = React.lazy(() => import("pages/Export"))
const AuthPage = React.lazy(() => import("pages/Auth"))
const AccountVerification = React.lazy(() => import("pages/AccountVerification"))
const SettingsPage = React.lazy(() => import("pages/Settings"))
const UserMyGroupsPage = React.lazy(() => import("modules/list-groups/exports/member-groups"))
const ActiveCampaignRules = React.lazy(() => import("modules/active-campaign/exports/rules"))
const CreatePost = React.lazy(() => import("modules/create-post/exports/create-post"))
const EventLive = React.lazy(() => import("modules/post-detail/exports/live"))
const PostDetail = React.lazy(() => import("modules/post-detail/exports/post-detail"))
const UserGroupsOrderPage = React.lazy(() => import("pages/User/GroupsOrder"))
const UserAffiliatePage = React.lazy(() => import("pages/User/Affiliate"))
const UserCreditCardPage = React.lazy(() => import("pages/User/PaymentMethod/CreditCard"))
const RefundPage = React.lazy(() => import("pages/User/Refund"))
const CheckLog = React.lazy(() => import("pages/CheckLog"))
const TermsOfUsePage = React.lazy(() => import("pages/TermsOfUse"))
const PrivacyPolicyPage = React.lazy(() => import("pages/PrivacyPolicy"))
const MissingPage = React.lazy(() => import("pages/404"))
const SubscriptionPaymentMethodPage = React.lazy(() => import("pages/Subscription/PaymentMethod"))
const SubscriptionPlanIntervalPage = React.lazy(() => import("pages/Subscription/PlanInterval"))
const AccountBankAccount = React.lazy(() => import("pages/AccountBankAccount"))
const GetStarted = React.lazy(() => import("pages/GetStarted"))
const AutomaticAuth = React.lazy(() => import("pages/AutomaticAuth"))
const GroupAffiliate = React.lazy(() => import("pages/Group/Affiliate"))
const ReviewPage = React.lazy(() => import("pages/Review"))
const LogoutPage = React.lazy(() => import("pages/Logout"))
const CheckoutResolver = React.lazy(() => import("pages/Checkout/CheckoutResolver"))
const CoProductionPage = React.lazy(() => import("modules/co-production/exports/co-production-page"))
const SalesDashboardPage = React.lazy(() => import("modules/sales/exports/sales-dashboard-page"))
const VerificationTelegramPage = React.lazy(
  () => import("modules/verification-telegram/exports/verification-telegram-page"),
)
const FirstExperience = React.lazy(() => import("pages/FirstExperience"))

const history = createBrowserHistory()

export const getHistory = () => history

class AppComponent extends React.Component {
  static contextType = Session.Context

  public componentDidMount() {
    const urlSearch = new URLSearchParams(window.location.search)
    const campaign = urlSearch.get("utm_campaign")
    const trackingParams: Record<string, string | null> = {
      campaign: campaign ? decodeURI(campaign) : null,
      source: urlSearch.get("utm_source"),
      medium: urlSearch.get("utm_medium"),
    }

    if (trackingParams.campaign || trackingParams.source || trackingParams.medium) {
      Analytics.track(Events.TRACKING_REDIRECT, trackingParams)
    }
  }

  public shouldShowCookieConsent = !window.location.pathname.includes(routes.CHECKOUT.LINK)

  public render() {
    return (
      <Translation>
        {() => (
          <>
            {this.shouldShowCookieConsent && <CookieConsent />}
            <Router history={history}>
              <React.Suspense fallback={<div />}>
                <Switch>
                  <Route exact={true} path={routes.AUTOMATIC_AUTH.PATH} component={AutomaticAuth} />
                  <Route exact={true} path={routes.MISSING_PAGE.PATH} component={MissingPage} />
                  <Route exact={true} path={routes.EDIT_PRODUCT.PATH} component={EditGroupPage} />
                  <Route exact={true} path={routes.CREATE_HUB_POST.PATH} component={CreatePost} />
                  <Route exact={true} path={routes.EVENT_LIVE.PATH} component={EventLive} />
                  <Route exact={true} path={routes.POST_DETAIL.PATH} component={PostDetail} />
                  {/**@deprecated: Remove this redirect when users stop sharing this url */}
                  <Route
                    exact={true}
                    path={routes.DETAIL_HUB_POST.PATH}
                    component={() => {
                      const postId = window.location.pathname.split("/").pop()
                      window.location.href = routes.POST_DETAIL.LINK + "/" + postId
                      return null
                    }}
                  />
                  <Route exact={true} path="/integrations/:id/rules" component={ActiveCampaignRules} />
                  <Route
                    exact={true}
                    path={routes.HOME.PATH}
                    component={() => {
                      if (isDebug()) {
                        history.push(routes.USER_GROUPS.PATH)
                        return null
                      }
                      window.location.href = getSiteUrl("")
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.PRICING.PATH}
                    component={() => {
                      window.location.href = getSiteUrl("/pricing")
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.ABOUT_US.PATH}
                    component={() => {
                      window.location.href = getSiteUrl("/about_us")
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.TERMS_OF_USE.PATH} component={TermsOfUsePage} />
                  <Route exact={true} path={routes.PRIVACY_POLICY.PATH} component={PrivacyPolicyPage} />
                  <Route exact={true} path={routes.SETTINGS.PATH} component={SettingsPage} />
                  <Route exact={true} path={routes.DASHBOARD.PATH} component={UserDashboardPage} />
                  <Route exact={true} path={routes.EXPORT.PATH} component={ExportPage} />
                  <Route exact={true} path={routes.AFFILIATE.PATH} component={UserAffiliatePage} />
                  <Route exact={true} path={routes.CO_PRODUCTION.PATH} component={CoProductionPage} />
                  <Route exact={true} path={routes.SALES_DASHBOARD.PATH} component={SalesDashboardPage} />
                  <Route exact={true} path={routes.USER_CREDIT_CARD.PATH} component={UserCreditCardPage} />
                  <Route exact={true} path={routes.REFUND.PATH} component={RefundPage} />
                  <Route exact={true} path={routes.FIRST_EXPERIENCE.PATH} component={FirstExperience} />

                  {/**@todo: Remove this when every site redirects to /settings */}
                  <Route
                    exact={true}
                    path={routes.NOTIFICATION_GROUP.PATH}
                    component={() => {
                      history.push(routes.SETTINGS.PATH)
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.USER_GROUPS.PATH} component={UserMyGroupsPage} />
                  <Route exact={true} path={routes.GROUPS_ORDER.PATH} component={UserGroupsOrderPage} />
                  <Route exact={false} path={routes.GET_STARTED.PATH} component={GetStarted} />
                  <Route path={routes.AUTH.PATH} component={AuthPage} />
                  <Route path={routes.LOGOUT.PATH} component={LogoutPage} />
                  <Route path={routes.CREATE_GROUP.PATH} component={CreateProduct} />

                  {/**@todo: Remove this when every site redirects to /become-a-creator */}
                  <Route
                    path={"/create_draft"}
                    component={() => {
                      history.push(routes.CREATE_GROUP.LINK)
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.GROUP_AFFILIATE.PATH} component={GroupAffiliate} />
                  <Route exact={true} path={routes.REVIEW.PATH} component={ReviewPage} />
                  <Route exact={true} path={routes.CHECKOUT.PATH} component={CheckoutResolver} />
                  <Route exact={false} path={routes.ACCOUNT_VERIFICATION.PATH} component={AccountVerification} />
                  <Route exact={true} path={routes.ACCOUNT_VERIFICATION_DONE.PATH} component={AccountVerification} />
                  <Route exact={true} path={routes.ACCOUNT_BANK_ACCOUNT.PATH} component={AccountBankAccount} />
                  <Route path={routes.CHECKLOG.PATH} component={CheckLog} />
                  <Route
                    path={routes.INTEGRATIONS_ZAPIER.PATH}
                    component={() => {
                      window.location.href = routes.INTEGRATIONS_ZAPIER?.EXTERNAL_URL ?? ""
                      return null
                    }}
                  />
                  <Route
                    path={routes.INTEGRATIONS_WEBHOOK_EVENTS.PATH}
                    component={() => {
                      window.location.href = routes.INTEGRATIONS_WEBHOOK_EVENTS?.EXTERNAL_URL ?? ""
                      return null
                    }}
                  />
                  <Route path={routes.WHITELIST.PATH} component={WhitelistPage} />
                  <Route path={routes.FREE_MEMBERS.PATH} component={FreeMembersPage} />

                  <Route path={routes.SUBSCRIPTION_PAYMENT_METHOD.PATH} component={SubscriptionPaymentMethodPage} />
                  <Route path={routes.SUBSCRIPTION_PLAN_INTERVAL.PATH} component={SubscriptionPlanIntervalPage} />

                  <Route exact={false} path={routes.TELEGRAM_VERIFICATION.PATH} component={VerificationTelegramPage} />

                  {/* Those routes needs to be the last ones, because they will only be reached if none of above matches */}
                  <Route
                    path={routes.USER_PROFILE_DEPRECATED.PATH}
                    component={() => {
                      window.location.href = `${getSiteUrl(window.location.pathname)}`
                      return null
                    }}
                  />
                  <Route
                    path={routes.USER_PROFILE.PATH}
                    component={() => {
                      window.location.href = `${getSiteUrl(window.location.pathname)}`
                      return null
                    }}
                  />
                  <Route component={MissingPage} />
                </Switch>
              </React.Suspense>
            </Router>
            <TermsDialog />
          </>
        )}
      </Translation>
    )
  }
}

const App = Session.withAuthentication(AppComponent)
export default App
