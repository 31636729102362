import * as React from "react"
import { Popup } from "semantic-ui-react"

interface IProps {
  text?: string | null
  timeout?: number | null
}

interface IState {
  isOpen: boolean
}

class ActionPopup extends React.Component<IProps, IState> {
  public state = { isOpen: false }
  private timeout?: NodeJS.Timeout

  public handleOpen = () => {
    this.setState({ isOpen: true })
    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false })
    }, this.props.timeout ?? 2000)
  }

  public handleClose = () => {
    this.setState({ isOpen: false })
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  public render() {
    if (!this.props.text || this.props.text === "") {
      return <>{this.props.children}</>
    }
    return (
      <Popup
        open={this.state.isOpen}
        trigger={this.props.children}
        content={this.props.text}
        on="click"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        hideOnScroll={true}
      />
    )
  }
}

export default ActionPopup
