import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "@chatpay/components"
import { Document } from "@chatpay/common"
// import * as routes from 'constants/routes';

export enum CheckLogActionTypes {
  LOAD = "CHECKLOG_LOAD",
  LOAD_SUCCESS = "CHECKLOG_LOAD_SUCCESS",
  LOAD_FAILURE = "CHECKLOG_LOAD_FAILURE",
}

export interface ILoadAction {
  type: CheckLogActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: CheckLogActionTypes.LOAD_SUCCESS
  myCheckLogs: Document.CheckLog[]
  group: Document.Group
}

export interface ILoadFailureAction {
  type: CheckLogActionTypes.LOAD_FAILURE
  reason: string
}

export const load =
  (groupId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: CheckLogActionTypes.LOAD,
    })
    const api = new API.Group()

    try {
      const myCheckLogs = await api.getCheckLog(groupId)
      const group = await api.fetch(groupId)
      dispatch(loadSuccess(myCheckLogs!, group!))
    } catch (e: any) {
      dispatch(loadFailure(e))
    }
  }

export const loadSuccess = (myCheckLogs: Document.CheckLog[], group: Document.Group): ILoadSuccessAction => {
  return {
    type: CheckLogActionTypes.LOAD_SUCCESS,
    myCheckLogs,
    group,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: CheckLogActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type CheckLogAction = ILoadAction | ILoadSuccessAction | ILoadFailureAction
