import { Modal, Button } from "semantic-ui-react"
import { Text } from "typography"
import styled from "styled-components"

export const EditGroupQuickActionButton = styled(Button)`
  height: 36px;
`

export const ModalTitle = styled(Text.Big)`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 43px;
  color: #000000;
  flex: none;
  text-align: left;
`

export const ModalTitleCenter = styled(Text.Big)`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 43px;
  color: #000000;
  flex: none;
  text-align: center;
`

export const ModalDescription = styled(Text.Small)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-align: left;
`

export const ModalHighlightText = styled(Text.Small)`
  flex: none;
  text-align: left;
  font-weight: 700 !important;
`

export const CloseButton = styled.img`
  position: absolute;
  left: 35px;
  right: 0px;
  cursor: pointer;
  z-index: 5;
`

export const MaybeLaterLink = styled.a`
  font-size: 16px;
  font-family: var(--secondary-font);
  line-height: 22px;
  letter-spacing: -0.01em;
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
  margin-right: 8px;
  padding: 12px 16px;
  text-underline-position: under;
  &:hover {
    color: #000000;
    text-decoration: underline;
  }
`

export const ButtonGroup = styled.div`
  text-align: left;
`

export const ModalContent = styled(Modal.Content)`
  padding: 48px !important;
`
