import { Document, Interface, Type } from "@chatpay/common"

/**
 * price: preço calculado total (installments)
 * value: valor do item (grupo ou plano)
 */
export const treat = (
  prices?: Type.Sellable.Prices | null,
  sellable?: Document.Sellable | null,
  membershipFee?: Interface.Sellable.IMembershipFee | null,
  installments: number | null = 1,
): [
  price: number,
  value: number,
  hasMembershipFee: boolean | undefined,
  membershipFee: Interface.Sellable.IMembershipFee | null | undefined,
  pricesList: number[],
] => {
  const hasMembershipFee = prices?.["membershipFee"] ? Array.isArray(prices?.["membershipFee"]) : undefined
  const sellableId = hasMembershipFee ? "membershipFee" : sellable?.id ?? ""
  const price = prices?.[sellableId]?.[(installments ?? 1) - 1] ?? 0
  const value = prices?.[sellable?.id ?? ""]?.[0] ?? 0

  return [price, value, hasMembershipFee, membershipFee, prices?.[sellableId] ?? []]
}
