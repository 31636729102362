export const Review = {
  Input: {
    terrible: "Terrible",
    bad: "Bad",
    ok: "Ok",
    good: "Good",
    great: "Great",
  },
  EditPicture: {
    title: "Add a profile photo so we can put your rating on the creator's profile",
  },
  EditReview: {
    title: "How was your experience at {{groupName}}?",
    input: "Write a review for {{groupName}}",
    placeholder:
      "{{firstName}} is an excellent creator. He's always on hand to answer questions and motivate us to complete the challenge. I don't know a better group!",
    remaining: "{{count}} characters remaining.",
    stillNeeds: "Type more {{count}} characters.",
    exceeded: "You have exceeded the character limit.",
    blankText: "Text cannot be blank.",
    required: "You must write at least {{count}} characters.",
    Tip: {
      title: "How to write a great review:",
      li1: "1. Be kind and honest",
      li2: "2. Mention the creator's name",
      li3: "3. Highlight your experience on the first day",
      li4: "4. Mention what you liked about the group",
      li5: "5. Suggest improvements to the creator",
      li6: "6. Write a sentence for future buyers",
    },
  },
  onlyForMembers: "Only group members can take a review.",
  thankYou: "Thank you for submitting your review!",
  button: "Done",
  reviews: "reviews",
  seeAll: "See all {{count}} reviews",
}
