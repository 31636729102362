"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Common = require("../../Interfaces/Gateway/Common");

var _Models = require("../../Models");

var _Gateway = require("../Gateway/Gateway");

var _ = require("../");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var userFixture = function userFixture(args) {
  var _args$lastName, _args$firstName, _args$gateways$defaul, _args$gateways, _args$notificationsSe, _args$notificationsSe2, _args$ims$whatsapp, _args$ims, _args$ims$telegram, _args$ims2;

  var digits = function digits() {
    return _faker["default"].datatype.number({
      min: 100,
      max: 999
    });
  };

  var lastName = (_args$lastName = args === null || args === void 0 ? void 0 : args.lastName) !== null && _args$lastName !== void 0 ? _args$lastName : _faker["default"].name.lastName();
  var firstName = (_args$firstName = args === null || args === void 0 ? void 0 : args.firstName) !== null && _args$firstName !== void 0 ? _args$firstName : _faker["default"].name.firstName();
  var userGateway = (_args$gateways$defaul = args === null || args === void 0 ? void 0 : (_args$gateways = args.gateways) === null || _args$gateways === void 0 ? void 0 : _args$gateways["default"]) !== null && _args$gateways$defaul !== void 0 ? _args$gateways$defaul : _Gateway.Carrier.iugu;
  var notificationChannel = (_args$notificationsSe = args === null || args === void 0 ? void 0 : (_args$notificationsSe2 = args.notificationsSettings) === null || _args$notificationsSe2 === void 0 ? void 0 : _args$notificationsSe2.channelType) !== null && _args$notificationsSe !== void 0 ? _args$notificationsSe : _faker["default"].random.objectElement(_.NotificationChannel);
  var whatsAppImId = (_args$ims$whatsapp = args === null || args === void 0 ? void 0 : (_args$ims = args.ims) === null || _args$ims === void 0 ? void 0 : _args$ims.whatsapp) !== null && _args$ims$whatsapp !== void 0 ? _args$ims$whatsapp : _faker["default"].phone.phoneNumber("############");
  var telegramImId = (_args$ims$telegram = args === null || args === void 0 ? void 0 : (_args$ims2 = args.ims) === null || _args$ims2 === void 0 ? void 0 : _args$ims2.telegram) !== null && _args$ims$telegram !== void 0 ? _args$ims$telegram : _faker["default"].datatype.number({
    min: 100000,
    max: 9999999
  }).toString();
  return new _.User(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    birthdate: _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.past(20)),
    document: "".concat(digits(), ".").concat(digits(), ".").concat(digits(), "-00"),
    email: _faker["default"].internet.email(),
    telegramCode: _faker["default"].git.commitSha(),
    // the value is just a SHA-1 hash, nothing to do with actual git
    firstName: firstName,
    lastName: lastName,
    username: _faker["default"].internet.userName(firstName, lastName),
    gender: _faker["default"].random.objectElement(_.Gender),
    address: {
      local: {
        city: _faker["default"].address.city(),
        country: _faker["default"].address.countryCode(),
        state: _faker["default"].address.stateAbbr(),
        number: _faker["default"].datatype.number().toString(),
        neighborhood: _faker["default"].address.city(),
        street: _faker["default"].address.streetAddress(),
        complement: _faker["default"].address.secondaryAddress(),
        zip: _faker["default"].address.zipCode()
      },
      charge: {
        city: _faker["default"].address.city(),
        country: _faker["default"].address.countryCode(),
        state: _faker["default"].address.stateAbbr(),
        number: _faker["default"].datatype.number().toString(),
        neighborhood: _faker["default"].address.city(),
        street: _faker["default"].address.streetAddress(),
        complement: _faker["default"].address.secondaryAddress(),
        zip: _faker["default"].address.zipCode()
      }
    },
    picture: _faker["default"].image.imageUrl(),
    phoneNumber: _faker["default"].phone.phoneNumber(),
    about: _faker["default"].commerce.productDescription(),
    isEmailVerified: _faker["default"].datatype["boolean"](),
    isUsingPassword: _faker["default"].datatype["boolean"](),
    isCompleted: _faker["default"].datatype["boolean"](),
    isAdmin: _faker["default"].datatype["boolean"](),
    groupCount: _faker["default"].datatype.number(8),
    ims: {
      whatsapp: whatsAppImId,
      telegram: telegramImId,
      telegramUsername: _faker["default"].internet.userName(firstName, lastName)
    },
    termsAcceptedAt: _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.past(1)),
    gateways: {
      carriers: _defineProperty({}, userGateway, {
        accountId: _faker["default"].random.alphaNumeric(10),
        customerId: _faker["default"].random.alphaNumeric(10),
        status: _faker["default"].random.objectElement(_Common.Validation.Status),
        currency: _faker["default"].random.objectElement(_Models.Currency)
      }),
      "default": userGateway
    },
    referralId: (0, _utils.firebaseIdFixture)(),
    country: _faker["default"].address.countryCode(),
    currency: _faker["default"].finance.currencyCode(),
    locale: _faker["default"].random.objectElement(_Models.Locale),
    notificationsSettings: {
      channelType: notificationChannel,
      channel: _defineProperty({}, notificationChannel, _faker["default"].random.arrayElement([whatsAppImId, telegramImId])),
      options: {
        purchased: _faker["default"].datatype["boolean"](),
        bankSlipGenerated: _faker["default"].datatype["boolean"](),
        checked: _faker["default"].datatype["boolean"](),
        memberJoined: _faker["default"].datatype["boolean"](),
        nonMemberJoined: _faker["default"].datatype["boolean"](),
        whitelisted: _faker["default"].datatype["boolean"](),
        removed: _faker["default"].datatype["boolean"](),
        adminJoined: _faker["default"].datatype["boolean"](),
        postCreated: _faker["default"].datatype["boolean"]()
      }
    },
    autoAcceptRefundDays: _faker["default"].datatype.number(7)
  }, args));
};

var _default = userFixture;
exports["default"] = _default;