import { Document } from "@chatpay/common"
import { API } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"

export enum ActivitiesActionTypes {
  LOAD = "DASHBOARD_LOAD_ACTIVITIES",
  LOAD_SUCCESS = "DASHBOARD_LOAD_ACTIVITIES_SUCCESS",
  LOAD_FAILURE = "DASHBOARD_LOAD_ACTIVITIES_FAILURE",
  CHANGE_PAGE = "DASHBOARD_CHANGE_ACTIVITY_PAGE",
  CHANGE_PAGE_SUCCESS = "DASHBOARD_CHANGE_ACTIVITY_PAGE_SUCCESS",
  CHANGE_PAGE_FAILURE = "DASHBOARD_CHANGE_ACTIVITY_PAGE_FAILURE",
  UPDATE_MEMBERS = "DASHBOARD_UPDATE_MEMBERS",
}

export interface ILoadAction {
  type: ActivitiesActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: ActivitiesActionTypes.LOAD_SUCCESS
  members: Document.GroupMember[] | null
  groups: Document.Group[]
}

export interface ILoadFailureAction {
  type: ActivitiesActionTypes.LOAD_FAILURE
  reason: Error
}

export interface IChangeActivitiesPageAction {
  type: ActivitiesActionTypes.CHANGE_PAGE
}

export interface IChangeActivitiesPageSuccessAction {
  type: ActivitiesActionTypes.CHANGE_PAGE_SUCCESS
  members: Document.GroupMember[]
  concat?: boolean
}

export interface IChangeActivitiesPageFailureAction {
  type: ActivitiesActionTypes.CHANGE_PAGE_FAILURE
  reason: Error
}

export interface IUpdateMembersAction {
  type: ActivitiesActionTypes.UPDATE_MEMBERS
  members: Document.GroupMember[]
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: ActivitiesActionTypes.LOAD,
  })

  const group = window.location.href.split("&group=")
  try {
    const members = await new API.GroupMember().fetch(group[1] ? { by: "group.id", value: group[1] } : undefined)
    const groups = await new API.Group().userAllGroups()
    dispatch(loadSuccess(members!, groups!))
  } catch (e: any) {
    dispatch(loadFailure(e))
  }
}

export const loadSuccess = (members: Document.GroupMember[] | null, groups: Document.Group[]): ILoadSuccessAction => {
  return {
    type: ActivitiesActionTypes.LOAD_SUCCESS,
    members,
    groups,
  }
}

export const loadFailure = (reason: Error): ILoadFailureAction => {
  return {
    type: ActivitiesActionTypes.LOAD_FAILURE,
    reason,
  }
}

export const changePage =
  (
    startAfter?: firebase.firestore.Timestamp,
    groupId?: string,
    createdAt?: [Date, Date],
    orderDirection?: "desc" | "asc",
    status?: Document.GroupMemberStatus,
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: ActivitiesActionTypes.CHANGE_PAGE,
    })

    const startDate = createdAt ? createdAt![0] : undefined
    if (startDate) {
      startDate.setHours(0, 0, 0, 0)
    }

    const endDate = createdAt ? createdAt![1] : undefined
    if (endDate) {
      endDate.setHours(23, 59, 59, 999)
    }

    try {
      const members = await new API.GroupMember().fetch(
        groupId ? { by: "group.id", value: groupId } : undefined,
        undefined,
        startAfter,
        startDate,
        endDate,
        orderDirection,
        status,
      )
      window.scrollTo(0, 0)
      dispatch(changePageSuccess(members!, startAfter ? true : false))
    } catch (e: any) {
      dispatch(changePageFailure(e))
    }
  }

export const changePageSuccess = (
  members: Document.GroupMember[],
  concat?: boolean,
): IChangeActivitiesPageSuccessAction => {
  return {
    type: ActivitiesActionTypes.CHANGE_PAGE_SUCCESS,
    members,
    concat,
  }
}

export const changePageFailure = (reason: Error): IChangeActivitiesPageFailureAction => {
  return {
    type: ActivitiesActionTypes.CHANGE_PAGE_FAILURE,
    reason,
  }
}

export const updateMembers = (members: Document.GroupMember[]): IUpdateMembersAction => {
  return {
    type: ActivitiesActionTypes.UPDATE_MEMBERS,
    members,
  }
}

export type ActivitiesAction =
  | ILoadAction
  | ILoadSuccessAction
  | ILoadFailureAction
  | IChangeActivitiesPageAction
  | IChangeActivitiesPageSuccessAction
  | IChangeActivitiesPageFailureAction
  | IUpdateMembersAction
