import { Dropdown, Modal } from "semantic-ui-react"
import styled from "styled-components"
import { Text } from "typography"

export const DropdownGroupStatusButton = styled(Dropdown)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 4px;
  font-family: var(--secondary-font);

  @media only screen and (max-width: 767px) {
    margin-right: 0px;
    width: 100% !important;
  }

  width: auto;

  .loader {
    &:not(.disabled) {
      height: 14px !important;
    }
  }

  &.loading {
    width: auto;
    @media only screen and (max-width: 767px) {
      width: 100% !important;
    }

    .divider.text,
    .status-icon,
    .dropdown-icon {
      opacity: 0 !important;
    }
  }

  .menu {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 24px 32px rgb(0 0 0 / 4%) !important;
    border-radius: 8px !important;
    width: auto !important;
    min-width: auto !important;
    right: 0;
    left: inherit !important;
    top: calc(100% + 10px) !important;
    font-family: var(--secondary-font);

    @media only screen and (max-width: 767px) {
      right: inherit !important;
      left: 0 !important;
      width: 100% !important;
    }

    .item {
      height: auto !important;
      max-width: 447px;
      float: left !important;
      width: 100%;

      @media only screen and (max-width: 767px) {
        overflow: hidden;
      }

      &:not(.regular) {
        padding: 16px 24px !important;
      }

      img {
        vertical-align: middle !important;
        margin: 0px !important;
        padding: 0px !important;
        width: 20px !important;
      }

      .text {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.01em;
        padding-left: 16px;
        color: #000000;
        font-weight: bold !important;
      }

      .description {
        font-weight: normal !important;
        width: 100%;
        float: left !important;
        margin-top: 5px !important;
        padding: 0 11px 0 21px;
        max-width: 100%;
        overflow: hidden;
        white-space: normal !important;
        min-width: 400px;
        color: #636363 !important;
        font-family: var(--secondary-font);

        @media only screen and (max-width: 767px) {
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
`

export const ModalTitle = styled(Text.Big)`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 43px;
  color: #000000;
  flex: none;
  text-align: left;
`

export const ModalTitleCenter = styled(Text.Big)`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 43px;
  color: #000000;
  flex: none;
  text-align: center;
`

export const ModalDescription = styled(Text.Small)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`

export const MaybeLaterLink = styled.a`
  font-size: 16px;
  font-family: var(--secondary-font);
  line-height: 22px;
  letter-spacing: -0.01em;
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
  margin-right: 8px;
  padding: 12px 16px;
  text-underline-position: under;
  &:hover {
    color: #000000;
    text-decoration: underline;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  button {
    text-align: left;
  }
`

export const CloseButton = styled.img`
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: pointer;
`
export const ModalContent = styled(Modal.Content)`
  padding: 48px !important;
`
