import React from "react"
import { Button } from "semantic-ui-react"
import { FLAG } from "@chatpay/components"
import { useFlags } from "hooks"

export interface IActionSection {
  loading?: boolean
  hidden?: boolean
  active?: boolean
  onSaveClick: () => any
  onCancelClick: () => any
  groupId?: string
}

export const Action: React.FunctionComponent<IActionSection> = (props) => {
  const { onSaveClick, onCancelClick, hidden, active, loading, groupId } = props

  const { isEnabledByFlag } = useFlags()
  const isUpsellCreatorUIEnabled = isEnabledByFlag(FLAG.CHECKOUT.IS_UPSELL_CREATOR_UI_ENABLED, { id: groupId })

  if (hidden) {
    return null
  }

  return (
    <div style={{ width: "100%", margin: !isUpsellCreatorUIEnabled ? "1em" : "1em 0 0 0" }}>
      <div style={{ float: "right" }}>
        {!loading ? <Button size="small" basic={true} content="Cancelar" onClick={() => onCancelClick()} /> : null}
        <Button
          disabled={active === false || loading}
          loading={loading}
          size="small"
          color="green"
          content="Salvar"
          onClick={() => onSaveClick()}
        />
      </div>
    </div>
  )
}
