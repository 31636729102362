import { Document, PaymentHelper, Type, Model } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { Spacer } from "components"
import * as Prices from "components/Helpers/Prices"
import i18n from "i18next"
import * as React from "react"
import { Segment } from "semantic-ui-react"
import "./PurchaseDetail.scss"

import { PurchaseDetailRow as Row } from "./PurchaseDetailRow"

interface IProps {
  discount?: number
  group?: Document.Group | null
  plan?: Document.Plan | null
  installments?: number | null
  method?: Document.PaymentMethod
  isLoading?: boolean
  prices?: Type.Sellable.Prices | null
  isCustomPlans?: boolean | null
  plansOriginal?: Document.Plan[] | null
  children?: React.ReactNode
  couponCode?: string
  isRecurrentCoupon?: boolean
  proRate?: number | null
}

export const PurchaseDetail = (props: IProps) => {
  const getMembershipFeeText = (amount: number, currency: string): string => {
    const { plan, group, prices } = props

    const [, , , membershipFee] = Prices.treat(prices, plan ?? group, plan?.membershipFee ?? group?.membershipFee)

    // se não tiver plano ou membershipFee, não tem necessidade de mostrar textos
    if (!plan || !membershipFee) {
      return ""
      // quando não tem membershipFee.days, o padrão é que ele dure 1 mês
    } else if (!membershipFee.days) {
      return `${i18n.t("purchaseDetail.afterFirstMonthWillCharge")} ${i18n.t(
        `intervalDescription.interval.${plan.interval}`,
      )} ${i18n.t("purchaseDetail.theValueOf")} ${Service.Global.Price.currencyFormat(amount, currency)}`
      // quando o membershipFee.days é igual a "plan", ele dura o tempo do plano selecionado
    } else if (membershipFee.days === "plan") {
      return `${i18n.t("purchaseDetail.afterFirst")} ${i18n.t(`intervalDescription.name.${plan.interval}`)}${i18n.t(
        "purchaseDetail.willBeCharged",
      )} ${i18n.t(`intervalDescription.interval.${plan.interval}`)} ${i18n.t(
        "purchaseDetail.theValueOf",
      )} ${Service.Global.Price.currencyFormat(amount, currency)}`
      // caso não condiza com nenhuma das opções cima, o membershipFee.days é um number de quantos dias ele dura
    } else {
      return `${i18n.t("purchaseDetail.afterTheFirsts")} ${membershipFee.days} ${i18n.t(
        "purchaseDetail.daysWillBeCharged",
      )} ${i18n.t(`intervalDescription.interval.${plan.interval}`)} ${i18n.t(
        "purchaseDetail.theValueOf",
      )} ${Service.Global.Price.currencyFormat(amount, currency)}`
    }
  }

  const getDateForPlan = (): string => {
    const { plan, group, prices } = props

    const [, , , membershipFee] = Prices.treat(prices, plan ?? group, plan?.membershipFee ?? group?.membershipFee)

    // se não tiver plano ou membershipFee, não tem necessidade de algum texto
    if (!plan || !membershipFee) {
      return ""
    }

    const options = { month: "short", day: "numeric" } as Intl.DateTimeFormatOptions
    const date = new Date()
    let months = 0
    // se tivermos um membershipFee.days e ele não for igual a "plan", significa que ele dura por X dias
    let days = membershipFee.days && membershipFee.days !== "plan" ? membershipFee.days : 0
    // quando não possui membershipFee.days, ele dura por 1 mês
    if (!membershipFee.days) {
      months = 1
      // quando o membershipFee.days é igual a "plan", ele dura o tempo do plano selecionado
    } else if (membershipFee.days === "plan") {
      months = plan?.intervalDescription.count ?? 0
    }
    // aqui é pego então o tempo que esse membershipFee irá durar, pra só depois ser cobrado o plano
    date.setMonth(date.getMonth() + months)
    date.setDate(date.getDate() + days)

    return date.toLocaleDateString(undefined, options)
  }

  const getPlanOriginal = (plan: Document.Plan) => {
    const { plansOriginal } = props
    return plansOriginal?.find((planOriginal) => planOriginal.id === plan.id)
  }

  const {
    group,
    plan,
    installments,
    prices,
    isCustomPlans,
    children,
    discount,
    couponCode,
    isRecurrentCoupon = false,
  } = props

  const [price, value, hasMembershipFee, membershipFee] = Prices.treat(
    prices,
    plan ?? group,
    plan?.membershipFee ?? group?.membershipFee,
    installments,
  )

  const currency = (group?.currency as string) ?? Model.Currency.brl
  // Preço do membershipFee
  const membershipPrice = membershipFee?.price
  // Preço a ser pago pelo usuário antes de qualquer desconto ou taxa de parcelamento
  const interest = Math.max(0, price - (membershipPrice ?? value))
  // Preço a ser pago pelo usuário considerando cupons de desconto
  const total = PaymentHelper.applyDiscount(price, props.discount ?? 0)

  const couponDiscount = Math.max(0, price - total)

  return (
    <>
      <Segment style={{ padding: "0" }}>
        {plan && (
          <Row
            text={
              plan
                ? `${i18n.t("purchaseDetail.subscription")} ${
                    membershipFee ? `\n(${i18n.t("purchaseDetail.startsIn")} ${getDateForPlan()})` : ""
                  }`
                : i18n.t("purchaseDetail.membership")
            }
            amount={plan.price}
            currency={currency}
            plan={plan}
            additionalInformation={
              isCustomPlans === true && plan?.price !== getPlanOriginal(plan)?.price
                ? `${i18n.t(
                    "purchaseDetail.plansAdditionalInformation",
                  )} ${getDateForPlan()}${Service.Global.Price.currencyFormat(
                    getPlanOriginal(plan)?.price ?? 0,
                    currency,
                  )}`
                : null
            }
          />
        )}

        <Row
          text={membershipFee?.description ?? i18n.t("purchaseDetail.membershipFee")}
          amount={membershipPrice}
          currency={currency}
          lineThrough={!hasMembershipFee}
          additionalInformation={
            hasMembershipFee === false ? `${i18n.t("purchaseDetail.membershipFeeAdditionalInformation")}` : null
          }
        />

        {children}

        <Row text={i18n.t("purchaseDetail.interest")} amount={interest} currency={currency} />

        <Row
          text={`${couponCode?.toUpperCase()}: (${discount}%)`}
          amount={couponDiscount}
          currency={currency}
          hasDiscountSymbol={true}
          additionalInformation={
            group?.hasPlans
              ? couponDiscount > 0 && isRecurrentCoupon === false
                ? i18n.t("purchaseDetail.couponOnePlanInterval") +
                  " " +
                  i18n.t(`intervalDescription.name.${plan?.interval}`)
                : i18n.t("purchaseDetail.couponByRecurrent")
              : null
          }
        />

        <Row text={i18n.t("purchaseDetail.total")} amount={total} currency={currency} total={true} />
      </Segment>

      {membershipFee && <div className="cp text">{getMembershipFeeText(value, currency)}</div>}
      <Spacer />
    </>
  )
}

PurchaseDetail.Row = Row
