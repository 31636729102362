import { Interface, Model } from "@chatpay/common"
import { API } from "@chatpay/components"
import { InputLabel } from "components/Fields/InputLabel"
import * as Forms from "components/Forms"
import * as Base from "components/Forms/Base"
import * as React from "react"
import * as CurrencyFormat from "react-currency-format"
import { Translation } from "react-i18next"
import countryList from "react-select-country-list"
import { DropdownItemProps, DropdownProps, Form, Grid, Input } from "semantic-ui-react"

// tslint:disable-next-line: no-empty-interface
export interface IData extends Interface.Address {}
export const EmptyData = Interface.EmptyAddress

interface IProps<I extends IData> extends Base.Props<I> {
  onlyBrasil?: boolean
}
interface IState<T extends IData> extends Base.State<T> {
  isLoading: boolean
  isZIPLoaded: boolean
}

export class AddressForm extends Base.Form<IProps<IData>, IState<IData>> {
  public state: Readonly<IState<IData>> = {
    ...this.state,
    isLoading: false,
    isZIPLoaded: false,
    data: { country: Model.Country.br } as any,
  }
  /**
   * @member countries is a dropdown item with values of all possible countries.
   */
  protected countries: DropdownItemProps[]

  protected formGridRef = React.createRef<Forms.Grid>()

  /**
   * Country is defaulted to BR aka Brazil in address state.
   */
  public static DefaultCountry = Model.Country.br

  constructor(props: Readonly<IProps<IData>>) {
    super(props)
    /**
     * Formats correct Country dropdown data.
     */
    const countriesArray = countryList().getData()

    if (props.onlyBrasil && !props.data?.country) {
      this.countries = [{ key: Model.Country.br, text: "Brasil", value: Model.Country.br }]
    } else {
      this.countries = countriesArray.map((e: any) => {
        if (e.value === Model.Country.br) {
          e.label = "Brasil"
        }
        return {
          key: e.value,
          text: e.label,
          value: e.value,
        }
      })
    }
  }

  public componentDidMount() {
    this.fixAutoCompleteOff()
  }

  protected formatField(name: string, value: any, isValid: boolean): any {
    if (name === "zip" && isValid && this.state.data.country === AddressForm.DefaultCountry) {
      this.updateAddressFromZIP(value)
    }
    return value
  }

  private preferred(preffered?: string | null, fallback?: string | null): string {
    return (preffered ? preffered : fallback) ?? ""
  }

  private async updateAddressFromZIP(zip: string) {
    try {
      /** Start loading address */
      this.setState({ isLoading: true })
      /**
       * Returns an @interface Address instance with the address represented
       * by the zipcode passed in as a parameter.
       */
      const address: Interface.Address = await API.ZIPCode.factory().brazil(zip.numbers())

      const data = this.state.data

      const addressData: Interface.Address = {
        complement: this.preferred(address.complement, data?.complement),
        neighborhood: this.preferred(address.neighborhood, data?.neighborhood),
        number: this.preferred(address.number, data?.number),
        street: this.preferred(address.street, data?.street),
        country: AddressForm.DefaultCountry,
        city: address.city,
        state: address.state,
        zip: address.zip,
      }

      this.setState(
        {
          isLoading: false,
          data: addressData,
          isZIPLoaded: true,
        },
        () => {
          this.performValidation(true, () => {
            if (!this.isFieldValid("number")) {
              this.setFocus("number")
            }
          })
        },
      )
    } catch (e) {
      this.setState({
        error: !!(e as Error),
        isLoading: false,
        isZIPLoaded: false,
      })
    }
  }

  /**
   * @function onCountryChange
   * @description changes country selection based on form dropdown.
   */
  public onCountryChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    /**
     * @function fieldChanged will add the selected country to the current form state.
     */
    this.fieldChanged("country", data.value, true, null, undefined, () => {
      /**
       * @callback
       * after our state update this callback function will set neighborhood state
       * and zipcode to null for countries other than Brazil.
       */
      this.setState({
        data: { ...this.state.data, neighborhood: "", zip: "", number: "" },
      })
    })
  }

  /**
   * @function onStateChange
   * @description changes country selection based on form dropdown.
   */
  public onStateChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    /**
     * @function fieldChanged will add the selected bazilian state to the current form state.
     */
    this.fieldChanged("state", data.value, true, null, undefined)
  }

  public render() {
    const { isLoading, isDisabled } = this.state
    /**
     * @member currentAddress is the address state.
     */
    const currentAddress: Interface.Address = this.state.data

    /**
     * @member isInternational is a boolean to whether
     * current country state is Brazilian.
     */
    const isInternational = currentAddress?.country && currentAddress?.country !== AddressForm.DefaultCountry

    /**
     * @member stateOptions is a list of every
     * state from brazil.
     */
    const stateOptions = Object.keys(Interface.BrazilianState).map((it) => ({
      key: it,
      value: it,
      text: it,
    }))

    return (
      <Translation>
        {(t) => (
          <div className="CreditCardForm">
            <Forms.Grid ref={this.formGridRef}>
              <Grid.Row columns={this.props.onlyBrasil ? 1 : 2}>
                {!this.props.onlyBrasil ? (
                  <Grid.Column>
                    <InputLabel title={t("checkout.Country")}>
                      <Form.Select
                        data-testid="countrySelect"
                        disabled={isLoading}
                        upward={true}
                        search={true}
                        type="hidden"
                        id="Form"
                        value={currentAddress?.country ?? Model.Country.br}
                        name="country"
                        fluid={true}
                        options={this.countries}
                        closeOnBlur={true}
                        onChange={this.onCountryChange}
                      />
                    </InputLabel>
                  </Grid.Column>
                ) : null}
                <Grid.Column>
                  {isInternational ? (
                    <InputLabel title={t("checkout.PostalCode")}>
                      <CurrencyFormat
                        fluid={true}
                        error={!this.isFieldValid("zip")}
                        name="zip"
                        type="tel"
                        required={true}
                        disabled={isLoading || isDisabled}
                        loading={isLoading}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        customInput={Input}
                        value={currentAddress?.zip ?? ""}
                      />
                    </InputLabel>
                  ) : (
                    <InputLabel title={t("checkout.Zip")}>
                      <CurrencyFormat
                        data-testid={"input-zip"}
                        fluid={true}
                        error={!this.isFieldValid("zip")}
                        name="zip"
                        type="tel"
                        required={true}
                        pattern="\d{5}-\d{3}"
                        disabled={isLoading || isDisabled}
                        loading={isLoading}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        customInput={Input}
                        format={`#####-###`}
                        value={currentAddress?.zip ?? ""}
                      />
                    </InputLabel>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={isInternational ? 1 : 2}>
                <Grid.Column>
                  <InputLabel title={t("checkout.Address")}>
                    <Input
                      fluid={true}
                      name="street"
                      error={!this.isFieldValid("street")}
                      loading={isLoading}
                      required={true}
                      onBlur={this.onFieldBlur}
                      disabled={isLoading || isDisabled}
                      onChange={this.onFieldChange}
                      value={currentAddress?.street ?? ""}
                    />
                  </InputLabel>
                </Grid.Column>
                {isInternational ? null : (
                  <Grid.Column>
                    <InputLabel title={t("checkout.Neighborhood")}>
                      <Input
                        fluid={true}
                        name="neighborhood"
                        error={!this.isFieldValid("neighborhood")}
                        required={true}
                        loading={isLoading}
                        onBlur={this.onFieldBlur}
                        disabled={isLoading || isDisabled}
                        onChange={this.onFieldChange}
                        value={currentAddress?.neighborhood ?? ""}
                      />
                    </InputLabel>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row columns={isInternational ? 1 : 2}>
                {isInternational ? null : (
                  <Grid.Column>
                    <InputLabel title={t("checkout.Number")}>
                      <Input
                        fluid={true}
                        data-testid={"number"}
                        name="number"
                        error={!this.isFieldValid("number")}
                        required={true}
                        disabled={isLoading || isDisabled}
                        focus={!this.isFieldValid("number")}
                        onBlur={this.onFieldBlur}
                        onChange={this.onFieldChange}
                        value={currentAddress?.number ?? ""}
                      />
                    </InputLabel>
                  </Grid.Column>
                )}
                <Grid.Column>
                  <InputLabel title={t("checkout.Complement")}>
                    <Input
                      fluid={true}
                      name="complement"
                      disabled={isLoading || isDisabled}
                      onChange={this.onFieldChange}
                      value={currentAddress?.complement ?? ""}
                    />
                  </InputLabel>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InputLabel title={t("checkout.City")}>
                    <Input
                      data-testid={"input-city"}
                      fluid={true}
                      name="city"
                      error={!this.isFieldValid("city")}
                      required={true}
                      loading={isLoading}
                      onBlur={this.onFieldBlur}
                      disabled={isLoading || isDisabled}
                      onChange={this.onFieldChange}
                      value={currentAddress?.city ?? ""}
                    />
                  </InputLabel>
                </Grid.Column>
                <Grid.Column>
                  {isInternational ? (
                    <InputLabel title={t("checkout.Providence")}>
                      <Input
                        fluid={true}
                        name="state"
                        error={!this.isFieldValid("state")}
                        required={true}
                        loading={isLoading}
                        onBlur={this.onFieldBlur}
                        disabled={isLoading || isDisabled}
                        onChange={this.onFieldChange}
                        value={currentAddress?.state ?? ""}
                      />
                    </InputLabel>
                  ) : (
                    <InputLabel title={t("checkout.State")}>
                      <Form.Select
                        fluid={true}
                        name="state"
                        error={!this.isFieldValid("state")}
                        required={true}
                        loading={isLoading}
                        disabled={isLoading || isDisabled}
                        onChange={this.onStateChange}
                        value={currentAddress?.state ?? ""}
                        options={stateOptions}
                      />
                    </InputLabel>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Forms.Grid>
          </div>
        )}
      </Translation>
    )
  }
}

export default AddressForm
