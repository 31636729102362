import { Value } from "firebase/remote-config"
import { get, gt, gte, includes, isEqual, isNil, lt, lte } from "lodash"

type WhereFilterOp = "<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "not-in" | "array-contains-any"

type FlagValue = {
  field: string
  op?: WhereFilterOp
  value: unknown
}

type Flag = {
  name: string
  type: "release" | "ops"
  value: FlagValue[] | boolean
  author?: string
  description: string
}

export const FLAG = {
  PROFILE: {
    IS_ACTIVE_CAMPAIGN_ENABLED: "isActiveCampaignEnabled",
  },
  CHECKOUT: {
    IS_PIX_ENABLED: "isPixEnabled",
    IS_BANKSLIP_ENABLED: "isBankslipEnabled",
    IS_CREDIT_CARD_ENABLED: "isCreditCardEnabled",
    IS_PIX_UNSTABLE_MESSAGE_ENABLED: "isPixUnstableMessageEnabled",
    USE_ASYNC_CHECKOUT: "useAsyncCheckout",
    IS_NEW_CHECKOUT_ENABLED: "isNewCheckoutEnabled",

    /**
     * Enables the new creator UI, that allow creators to use upsell in
     * their strategy.
     */
    IS_UPSELL_CREATOR_UI_ENABLED: "isUpsellCreatorUIEnabled",
  },
  USER_SUBSCRIPTION_MODAL: {
    IS_CHANGE_PLAN_ENABLED: "isChangePlanEnabled",
  },
  JOIN_GROUP: {
    IS_TELEGRAM_BLOCKED: "isTelegramBlocked",
  },
  BILLING: {
    IS_V2_ENABLED: "isBillingV2Enabled",
  },

  /**
   * When the user logs in and access the creator mode, they go to this area.
   *
   * Example (in devel environment): https://app.hubla.dev/dashboard#products
   */
  DASHBOARD: {
    IS_REPORTS_ENABLED: "isReportsEnabled",

    IS_CLIENTS_ENABLED: "isClientsEnabled",

    /**
     * Enables order bump, allowing creators to enable this option in their
     * dashboard. Details in go/order-bump-figma.
     */
    IS_ORDER_BUMP_ENABLED: "isOrderBumpEnabled",
    /**
     * Enable the use of product filtering in both the charts and the listing
     * of invoices within the creator's dashboard.
     */
    IS_FILTER_BY_PRODUCT_ENABLED: "isFilterByProductEnabled",
  },
  CREATE_PRODUCT: {
    IS_PRODUCT_COHORT_ENABLED: "isProductCohortEnabled",
  },
}

export class FeatureFlag {
  public constructor(private readonly flags: Record<string, Value>) {}

  public isEnabled(flag: string, data?: unknown) {
    const flagsAsJSON = Object.values(this.flags).map((value) => {
      try {
        return JSON.parse(value.asString())
      } catch (error) {
        return {}
      }
    })

    const operators: Record<WhereFilterOp, (value: any, other: any) => boolean> = {
      "==": isEqual,
      "!=": (value, other) => !isEqual(value, other),
      "<=": lte,
      ">": gt,
      ">=": gte,
      "<": lt,
      "array-contains": (value, other) => includes(value, other),

      /**
       * @todo
       */
      "array-contains-any": () => false,
      "not-in": () => false,
      in: (value, other) => includes(other, value),
    }

    const flagByName: Flag | undefined = flagsAsJSON.find(({ name }) => isEqual(flag, name))

    /**
     * @description local fallback to critical flags when remote config does not fetch them
     */
    if (isNil(flagByName) && this.isEnabledByDefault(flag)) {
      return true
    }

    if (isNil(flagByName)) {
      return false
    }

    return typeof flagByName.value === "boolean"
      ? flagByName.value
      : !Array.isArray(flagByName.value)
      ? false
      : flagByName.value.every(({ field, op, value }) => {
          const operator = operators[op ?? "=="]

          return operator(get(data, field), value)
        })
  }

  private isEnabledByDefault(flag: string) {
    return [
      FLAG.CHECKOUT.IS_BANKSLIP_ENABLED,
      FLAG.CHECKOUT.IS_CREDIT_CARD_ENABLED,
      FLAG.CHECKOUT.IS_PIX_ENABLED,
    ].includes(flag)
  }
}
