import { Reducer } from "redux"
import { Document } from "@chatpay/common"
import { ActivitiesAction, ActivitiesActionTypes } from "./ActivitiesActions"
import { IActivitiesState } from "./ActivitiesState"

const initialActivitiesState: IActivitiesState = {
  isLoading: true,
  isPaginationDisabled: true,
  error: undefined,
  members: [],
  groups: [],
}

export const ActivitiesReducer: Reducer<IActivitiesState, ActivitiesAction> = (
  state: IActivitiesState = initialActivitiesState,
  action: ActivitiesAction,
) => {
  switch (action.type) {
    case ActivitiesActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
      }
    case ActivitiesActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPaginationDisabled: false,
        members: action.members,
        groups: action.groups,
      }
    case ActivitiesActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isPaginationDisabled: true,
        error: action.reason,
      }
    case ActivitiesActionTypes.CHANGE_PAGE:
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: true,
      }
    case ActivitiesActionTypes.CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        isPaginationDisabled: false,
        isLoading: false,
        members: action.concat ? state.members?.concat(action.members) ?? [] : action.members,
      }
    case ActivitiesActionTypes.CHANGE_PAGE_FAILURE:
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: false,
        error: action.reason,
      }
    case ActivitiesActionTypes.UPDATE_MEMBERS:
      let newMembers: Document.GroupMember[] = state.members!

      action.members.forEach((member) => {
        // newMembers = newMembers.filter((it) => it.id !== member.id)
        // newMembers.unshift(member)
        var idx = newMembers.findIndex((it) => it.id === member.id)
        if (idx !== -1) {
          newMembers[idx] = member
        }
      })

      return {
        ...state,
        members: newMembers.filter((_) => true),
      }
    default:
      return state
  }
}
