import { Document } from "@chatpay/common"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import { EmojiObject } from "components/Fields/InputEmoji"
import React, { useEffect, useState } from "react"
import { Button, Form, InputOnChangeData } from "semantic-ui-react"
import { Text } from "typography"
import * as Yup from "yup"
import "./Advantages.scss"

type keys = "advantages"

interface IForm {
  advantages: Document.GroupAboutAdvantage[]
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const schema = Yup.object().shape({
  advantages: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        emoji: Yup.string(),
        text: Yup.string()
          .min(5, "Texto muito curto!")
          .max(50, "Texto muito longo!")
          .matches(/\S+/, "Texto não pode ficar em branco"),
      }),
    )
    .min(2, "Você precisa adicionar pelo menos dois itens")
    .max(6, "O máximo de itens é 6"),
})

const validateField = (path: string, data: any) => {
  let error: string = ""
  try {
    data && Yup.reach(schema, "advantages" + path).validateSync(data)
  } catch (e) {
    error = !e.path ? e.message : ""
  }
  return error
}

const hasErrors = (data: Partial<IForm>) => {
  let error: boolean = false
  try {
    data.advantages && Yup.reach(schema, "advantages").validateSync(data.advantages)
  } catch (e) {
    error = !!e.message
  }
  return error
}

const Advantages: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  useEffect(() => {
    setForm(value)
  }, [value])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    onChange?.(newData, valid)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return (
      disabled?.advantages ||
      ((data.advantages ?? []).length > 1 && (data.advantages ?? []).length < 7 && !hasErrors(data))
    )
  }

  const updateAdvantages = (action: (newAdvantages: any[]) => void) => {
    const newAdvantages = (form?.advantages ?? []).map((a) => ({ ...a }))
    action(newAdvantages)
    setFormValue("advantages", newAdvantages)
  }

  const onAdvantageEmojiUpdate = (data: EmojiObject, idx: number) => {
    updateAdvantages((advantages) => (advantages![idx].emoji = data.emoji))
  }

  const onAdvantageTextUpdate = (data: InputOnChangeData, idx: number) => {
    updateAdvantages((advantages) => (advantages![idx].text = data.value))
  }

  const onRemoveItem = (idx: number) => updateAdvantages((advantages) => advantages!.splice(idx, 1))

  const onAddItem = () => updateAdvantages((advantages) => advantages!.push({ emoji: "🚀", text: "" }))

  return (
    <React.Fragment>
      <Form>
        <Fields.InputLabel title="Vantagens">
          <Spacer />
          {(form?.advantages ?? []).map((it, idx) => {
            return (
              <div key={idx} style={{ marginBottom: "1em" }}>
                <Form.Group style={{ margin: "0" }}>
                  <Fields.InputEmoji content={it.emoji} onChange={(data) => onAdvantageEmojiUpdate(data, idx)} />
                  <Form.Input
                    id="advantages"
                    width={14}
                    value={it.text ?? ""}
                    placeholder="e.g Area de membros exclusiva"
                    onChange={(_, data) => onAdvantageTextUpdate(data, idx)}
                  />
                  <Button icon="trash alternate" basic={true} size="small" onClick={() => onRemoveItem(idx)} />
                </Form.Group>
                <div style={{ textAlign: "right" }}>
                  <Text.Tiny color="red">{validateField(`[${idx}].text`, it.text)}</Text.Tiny>
                </div>
              </div>
            )
          })}
          <Text.Tiny color="red">{validateField("", form?.advantages)}</Text.Tiny>
          <div style={{ textAlign: "right" }}>
            <Button content="Adicionar" basic={true} size="small" onClick={onAddItem} />
          </div>
        </Fields.InputLabel>
      </Form>
    </React.Fragment>
  )
}

export { Advantages as Form }
export type { IProps, IForm }
