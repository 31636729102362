import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { TextField } from "@mui/material"
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"

import { Grid, Radio, RadioGroup, Button, useMediaQuery, Stack, Text, useUtility } from "@hub-la/design-system"
import { Firebase } from "@chatpay/components/lib/Service"
import { Service } from "@chatpay/components"
import { Document, Model } from "@chatpay/common"

import { FUNNEL_STRATEGY_URL } from "./constants"
import { ExtraOfferProduct, Pagination } from "./types"
import defaultGroupAvatar from "../../../../assets/images/default-group-avatar.png"
import { Forms } from "components"
import i18n from "i18n"
import { HttpClient, HttpMethod } from "@hub-la/fe-core-http-client"

/**
 * Minimun and maximun price currently accepted by Iugu. This is NOT in cents.
 */
const MIN_IUGU_PRICE = parseInt(process.env.REACT_APP_IUGU_MIN_PRICE ?? "0", 10)
const MAX_IUGU_PRICE = parseInt(process.env.REACT_APP_IUGU_MAX_PRICE ?? "0", 10)

function UpsellModalActionButtonsCancelButton({ cancel }) {
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width:600px)")

  return (
    <Grid item xs="auto">
      <Button fullWidth={isMobile} variant="text" onClick={cancel}>
        {t("OffersPane.UpsellModal.cancelButton")}
      </Button>
    </Grid>
  )
}

/**
 * Creates the common modal action buttons, to be used in some steps of the
 * modal.
 */
export function UpsellModalActionButtons({
  nextStep,
  cancel,
  confirmButtonDisabled,
  confirmButtonText,
}: {
  nextStep: () => void
  cancel: () => void
  confirmButtonDisabled?: boolean
  confirmButtonText?: string
}): JSX.Element {
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width:600px)")
  const isDesktop = !isMobile

  return (
    <Grid
      item
      container
      direction={{ sm: "row", xs: "column" }}
      justifyContent={{ sm: "flex-end", xs: "center" }}
      alignItems={{ sm: "center" }}
      spacing={6}
      marginY={2}
    >
      {/* Desktop version should have the "cancel" button before the "next". */}
      {isDesktop && <UpsellModalActionButtonsCancelButton cancel={cancel} />}
      <Grid item xs="auto">
        <Button
          fullWidth={isMobile}
          disabled={confirmButtonDisabled ?? false}
          hierarchy="primary"
          variant="filled"
          onClick={nextStep}
        >
          {confirmButtonText ?? t("OffersPane.UpsellModal.nextButton")}
        </Button>
      </Grid>
      {/* Mobile version should have the "next" button after the "cancel". */}
      {isMobile && <UpsellModalActionButtonsCancelButton cancel={cancel} />}
    </Grid>
  )
}

/** Allows us to check if a request for extra offer products returned error. */
type ExtraOfferProductOrError = ExtraOfferProduct & { statusCode: number; errorCode: string }

export const FUNNEL_STRATEGY_API = {
  CREATE: async (
    { type, mainOfferId, extraOfferId, plans }: ExtraOfferProduct,
    httpClient: HttpClient,
  ): Promise<ExtraOfferProductOrError> => {
    return await (
      await httpClient.request({
        url: FUNNEL_STRATEGY_URL.CREATE,
        method: HttpMethod.POST,
        body: {
          type,
          mainOfferId,
          extraOfferId,
          plans,
        },
      })
    ).data
  },

  READ: async (
    mainOfferId: string,
    type: "upsell" | "order-bump",
    httpClient: HttpClient,
  ): Promise<ExtraOfferProductOrError> => {
    return await (
      await httpClient.request({
        url: FUNNEL_STRATEGY_URL.READ(type, mainOfferId),
        method: HttpMethod.GET,
      })
    ).data
  },

  UPDATE: async (
    { id, type, plans, isEnabled }: ExtraOfferProduct,
    httpClient: HttpClient,
  ): Promise<ExtraOfferProductOrError> => {
    return await (
      await httpClient.request({
        url: FUNNEL_STRATEGY_URL.UPDATE(id),
        method: HttpMethod.PATCH,
        body: {
          type,
          plans,
          isEnabled,
        },
      })
    ).data
  },

  DELETE: async ({ id }: ExtraOfferProduct, httpClient: HttpClient): Promise<ExtraOfferProductOrError> => {
    return await (
      await httpClient.request({
        url: FUNNEL_STRATEGY_URL.DELETE(id),
        method: HttpMethod.DELETE,
      })
    ).data
  },
}

export interface PriceTextEditProps {
  label?: string
  currency: { value: number; symbol?: string; decimalSymbol?: string; thousandsSeparatorSymbol?: string }
  savePrice?: (params: { priceInCents: number; error: string | null }) => void
  style?: { [key: string]: any }
  parentError?: string | null
}

export function PriceTextEdit({ label, currency, savePrice, style, parentError }: PriceTextEditProps) {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(
    Service.Global.Price.currencyFormat(currency.value / 100, currency.symbol ?? Model.Currency.brl),
  )
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (parentError) {
      setError(parentError)
      return
    }

    setError(null)
  }, [parentError])

  return (
    <TextField
      fullWidth
      error={!!error}
      inputRef={(input) => input && editing && input.focus()}
      variant="outlined"
      label={label}
      value={value}
      style={style}
      helperText={error ?? ""}
      onFocus={() => setEditing(true)}
      onBlur={() => setEditing(false)}
      onChange={(event) => {
        const inputPrice = event.target.value
        let priceInCents = parseInt(inputPrice.replace(/\D/g, ""))

        if (!inputPrice.match(/,/) || inputPrice.match(/,$/)) {
          // If original input doesn't have a comma (i.e. decimal values) or has
          // it as the last char, multiply it by 100.
          priceInCents *= 100
        } else if (inputPrice.match(/,[0-9]$/)) {
          // If original input has only one number after the comma, multiply it
          // by 10.
          priceInCents *= 10
        }

        const priceError = checkPriceError(priceInCents / 100, currency.symbol)
        let newError: string | null
        if (priceError) {
          newError = priceError
        } else if (parentError) {
          newError = parentError
        } else {
          newError = null
        }
        setError(newError)
        setValue(inputPrice)

        savePrice && savePrice({ priceInCents, error: newError })
      }}
      InputProps={{
        inputComponent: React.forwardRef((props, ref) => (
          <TextMaskCustom
            symbol={Service.Global.Price.currencySymbol(currency.symbol ?? Model.Currency.brl)}
            innerRef={ref}
            {...props}
          />
        )),
      }}
      // Align the helper text to the left side of the input field.
      sx={{ "& .MuiFormHelperText-root": { marginLeft: 0 } }}
    />
  )
}

interface TextMaskCustomProps {
  symbol: string
  decimalSymbol?: string
  thousandsSeparatorSymbol?: string
  [key: string]: any
}

function TextMaskCustom({ innerRef, symbol, decimalSymbol, thousandsSeparatorSymbol, ...other }: TextMaskCustomProps) {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => innerRef(ref ? ref.inputElement : null)}
      mask={createNumberMask({
        prefix: symbol,
        decimalSymbol: decimalSymbol ?? ",",
        thousandsSeparatorSymbol: thousandsSeparatorSymbol ?? ".",
        allowDecimal: true,
      })}
    />
  )
}

/**
 * Warnings that we need to display to the user.
 */
export function getWarningMessage(warning: string): JSX.Element {
  const availableWarnings = new Map([
    /**
     * Happens when a product is being used as upsell but it is not currently selling
     * (it was disabled by the user after creating the upsell).
     */
    [
      "EXTRA_OFFER_ID_IS_NOT_AVAILABLE",
      <WarningWithMessage message={i18n.t("OffersPane.errors.extraOfferIdIsNotAvailable")} />,
    ],

    /**
     * Happens when a product with membership fee active is chosen to be used as
     * upsell.
     */
    [
      "EXTRA_OFFER_WITH_MEMBERSHIP_FEE_ACTIVE",
      <WarningWithMessage message={i18n.t("OffersPane.errors.extraOfferWithMembershipFeeActive")} />,
    ],
  ])

  // Either return an element for that warning or nothing.
  return availableWarnings.get(warning) || <></>
}

function WarningWithMessage({ message }: { message: string }) {
  const { palette } = useUtility()

  return (
    <Stack
      direction="row"
      spacing={3}
      p={3}
      mt={4}
      style={{
        backgroundColor: palette("errorContainer"),
        border: `1.5px solid ${palette("onErrorContainer")}`,
        borderRadius: "12px",
        color: palette("onErrorContainer"),
      }}
    >
      <ErrorOutlineIcon />
      <Text variant="body2">{message}</Text>
    </Stack>
  )
}

/**
 * Creates a material table that supports selecting one of the rows using radio
 * buttons. It also has pagination support. For more details, check
 * go/order-bump-figma.
 */
export function TableWithRadioButtons({
  products,
  pagination,
  updateExtraOfferProduct,
  setSelected,
  buildPriceDisplayText,
}: {
  products: Document.Group[]
  pagination: Pagination
  updateExtraOfferProduct: (id: string) => void
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  buildPriceDisplayText: (product: Document.Group) => string
}) {
  const { t } = useTranslation()

  return (
    <RadioGroup>
      <TableContainer component="div">
        <Table
          // Checked this using DevTools. It is the ideal minWidth.
          sx={{ minWidth: 892.02, "& .MuiTableCell-root": { padding: "12px" } }}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Text variant="body1">{t("OffersPane.table.products")}</Text>
              </TableCell>
              <TableCell>
                <Text variant="body1">{t("OffersPane.table.price")}</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              /**
               * For example, if we have 9 products with 5 per page, the
               * indexes to slice from are calculated like so:
               *
               * - first page:
               *  from: 0 * 5 = 0
               *  to: 1 * 5 = 5
               *
               * - second page:
               *  from: 1 * 5 = 5
               *  to: 2 * 5 = 10
               *
               * Since `slice` includes the first and excludes the
               * second, this gives us exactly what we want.
               */
              .slice(pagination.page * pagination.rowsPerPage, (pagination.page + 1) * pagination.rowsPerPage)
              .map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Grid container justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <Radio
                          label=""
                          value={product.id}
                          onClick={() => {
                            updateExtraOfferProduct(product.id)
                            setSelected(product.id)
                          }}
                        />
                      </Grid>
                      <Grid item marginRight={16}>
                        <img
                          style={{ border: "1.5px solid #E0E4D8", borderRadius: "4px" }}
                          width={48}
                          height={36}
                          src={product.picture || defaultGroupAvatar}
                          alt={product.name}
                        />
                      </Grid>
                      <Grid item>{product.name}</Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>{buildPriceDisplayText(product)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter
            sx={{
              "& .MuiTableCell-footer": {
                borderBottom: 0,
              },
            }}
          >
            <TableRow>
              <TablePagination
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                count={pagination.count}
                onPageChange={pagination.handleChangePage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    display: "none",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    marginBottom: "2px",
                  },
                  "& .MuiTablePagination-toolbar": {
                    padding: "8px 0 16px 0",
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </RadioGroup>
  )
}

export function calculateEntryFee(
  group: Document.Group | undefined,
  product: Document.Product | undefined,
): Partial<Forms.GroupForm.EntryFee.IForm> {
  return {
    isEnabled: !group?.isPublished
      ? group?.membershipFee !== null && group?.membershipFee !== undefined
      : product?.membershipFee !== null && product?.membershipFee !== undefined,
    entryFee: !group?.isPublished
      ? group?.membershipFee?.price ?? undefined
      : product?.membershipFee?.price ?? undefined,
    installments: !group?.isPublished
      ? group?.installments
        ? Number(group?.installments)
        : undefined
      : product?.installments
      ? Number(product?.installments)
      : undefined,
    renewalToleranceDays: !group?.isPublished
      ? group?.membershipFee?.renewalToleranceDays ?? undefined
      : product?.membershipFee?.renewalToleranceDays ?? undefined,
  }
}

/**
 * Verifies if the product is valid and can be listed in the UI.
 */
export function isValidProduct(src: Document.Group) {
  const deactivated = !src.isEnabled || src.isDeleted
  const notForSale = !src.isSelling
  const expired = src.endAt && new Date().getTime() > src.endAt.toDate().getTime()
  const maxCapacity = src.maxMemberCount > 0 && src.memberCount.total >= src.maxMemberCount

  if (deactivated || notForSale || expired || maxCapacity) {
    return false
  }

  return true
}

export function Warning({
  warning,
  style,
  marginTop,
  marginBottom,
}: {
  warning?: string
  style?: React.CSSProperties | undefined
  marginTop?: number
  marginBottom?: number
}): JSX.Element {
  const { palette } = useUtility()

  // Should never happen, as we check for type order bump before.
  if (!warning) {
    return <></>
  }

  return (
    <Stack
      direction="row"
      spacing={3}
      p={3}
      marginTop={marginTop}
      marginBottom={marginBottom}
      style={{
        border: `1.5px solid ${palette("surfaceVariant")}`,
        borderRadius: "12px",
        color: palette("onSurface"),

        ...style,
      }}
    >
      <ErrorOutlineIcon />
      <Text variant="body2">{warning}</Text>
    </Stack>
  )
}

const checkPriceError = (price: number, currency?: string): string | undefined => {
  const tooLow = `${i18n.t("InputPlan.errors.higher")} ${Service.Global.Price.currencyFormat(
    MIN_IUGU_PRICE,
    currency ?? Model.Currency.brl,
  )}`
  const tooHigh = `${i18n.t("InputPlan.errors.lower")} ${Service.Global.Price.currencyFormat(
    MAX_IUGU_PRICE,
    currency ?? Model.Currency.brl,
  )}`
  return price < MIN_IUGU_PRICE ? tooLow : price > MAX_IUGU_PRICE ? tooHigh : undefined
}
