import React from "react"
import { Document } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { useTranslation } from "react-i18next"

type Props = {
  text: string
  amount?: number
  currency?: string
  total?: boolean
  plan?: Document.Plan
  lineThrough?: boolean
  additionalInformation?: string | null
  hasDiscountSymbol?: boolean
}

export const PurchaseDetailRow: React.FunctionComponent<Props> = ({
  text,
  amount,
  currency,
  total,
  plan,
  lineThrough,
  additionalInformation,
  hasDiscountSymbol,
}: Props) => {
  const { t } = useTranslation()
  return amount && amount !== 0 ? (
    <>
      <div className={`purchase-detail-cell ${additionalInformation ? "with-additional-information" : ""} `}>
        <div className={`cp text purchase-detail-text ${total ? "bold black" : ""}`} style={{ flex: "1" }}>
          {text}
        </div>

        {currency && (
          <span className={`${lineThrough ? "oldPrice" : ""} cp text ${total ? "bold black" : ""}`}>
            {hasDiscountSymbol ? "(-) " : ""}
            {Service.Global.Price.currencyFormat(amount, currency)}
          </span>
        )}

        {plan && (
          <span
            className={`${lineThrough ? "oldPrice" : ""} cp text ${total ? "bold black" : ""}`}
            style={{ marginLeft: "4px" }}
          >
            / {t(`intervalDescription.name.${plan.interval}`)}
          </span>
        )}
      </div>
      {additionalInformation ? (
        <div className="additional-information">
          <div className="additional-information-content">{additionalInformation}</div>
        </div>
      ) : null}
    </>
  ) : null
}
