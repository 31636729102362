import React from "react"
import "./MyGroupsStatus.scss"

const colors = {
  olive: "rgba(205, 220, 57, .35)",
  lightpurple: "rgba(103, 58, 183, .35)",
  lightorange: "rgba(255, 152, 0, .35)",
  watergreen: "rgba(0, 188, 212, .35)",
  purple: "rgba(156, 39, 176, .35)",
  darkblue: "rgba(3, 169, 244, .35)",
  red: "rgba(244, 67, 54, .35)",
  darkpurple: "rgba(63, 81, 181, .35)",
  orange: "rgba(255, 87, 34, .35)",
  yellow: "rgba(255, 193, 7, .35)",
  brown: "rgba(121, 85, 72, .35)",
  grey: "rgba(96, 125, 139, .35)",
  darkwatergreen: "rgba(0, 150, 136, .35)",
  pink: "rgba(233, 30, 99, .35)",
  darkgreen: "rgba(76, 175, 80, .35)",
  blue: "rgba(33, 150, 243, .35)",
  lightergreen: "rgba(139, 195, 74, .35)",
  lightyellow: "rgba(255, 235, 59, .35)",
}

function handleColor(str: string) {
  return colors[str]
}

function myGroupsStatus(status: { status: string; color: string }) {
  return (
    <div
      className={`cp text black compact mygroups-status-view`}
      style={{
        backgroundColor: handleColor(status.color),
      }}
    >
      {status.status}
    </div>
  )
}
export default myGroupsStatus
