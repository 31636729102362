import { API, Service } from "@chatpay/components"
import { useEffect, useState, useMemo } from "react"
import { Document } from "@chatpay/common"

export const useDashboard = () => {
  const [groups, setGroups] = useState<Document.Group[]>([])
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false)

  const fetchGroups = async () => {
    setIsLoadingGroups(true)
    try {
      const groups = await new API.Group().myGroups()
      setGroups(groups as Document.Group[])
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingGroups(false)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  const activeGroups = useMemo(
    () =>
      groups.filter(
        (it) => !it.isDraft && (it.isSelling || it.isWaitlisted) && it.owner?.id === Service.Firebase.currentUser?.id!,
      ),
    [groups],
  )

  const inactiveGroups = useMemo(
    () =>
      groups.filter(
        (it) => !it.isDraft && !it.isSelling && !it.isWaitlisted && it.owner?.id === Service.Firebase.currentUser?.id!,
      ),
    [groups],
  )

  const draftGroups = useMemo(
    () => groups.filter((it) => it.isDraft && it.owner?.id === Service.Firebase.currentUser?.id!),
    [groups],
  )

  return {
    groups: activeGroups.concat(inactiveGroups),
    fetchGroups,
    activeGroups,
    inactiveGroups,
    draftGroups,
    isLoadingGroups,
  }
}
