import { useFlags } from "hooks"
import React from "react"

export const withFlags =
  <P extends { isEnabledByFlag: (flag: string) => boolean }>(Component: React.ComponentType<P>) =>
  (props: any) => {
    const { isEnabledByFlag } = useFlags()

    return <Component {...props} isEnabledByFlag={isEnabledByFlag} />
  }
