import React, { ComponentClass } from "react"
import Context, { IState } from "./Context"
import { IProps as IContextProps, withFirebase } from "../Service/withFirebase"
import firebase from "firebase/compat/app"
import { Document } from "@chatpay/common"
import * as API from "../API"

import { Analytics } from "../Analytics"

export const withAuthentication = (
  Component: ComponentClass,
  userUpdated?: (user: Document.User | null, oldUser?: Document.User) => void,
): any => {
  class WithAuthentication extends React.Component<IContextProps, IState> {
    private unsubscribe?: firebase.Unsubscribe
    public state: Readonly<IState> = {
      authUser: undefined,
      accessToken: undefined,
    }

    public static getDerivedStateFromProps(_props: IContextProps, state: IState) {
      const authUserDataString = localStorage.getItem("authUser")
      const authUserData = authUserDataString && JSON.parse(authUserDataString)
      const authUser = authUserData && new Document.User(authUserData)
      const accessToken = authUserData?.accessToken ?? null

      if (state.authUser !== authUser || state.accessToken !== accessToken) {
        return {
          authUser,
          accessToken: authUserData?.accessToken ?? null,
        }
      }
      return null
    }

    private onAuthUpdate = (user?: Document.User, accessToken?: string) => {
      userUpdated?.(user ?? null, this.state.authUser)

      if (user) {
        if (localStorage.getItem("authUser") === null) {
          Analytics.identify(user)
        }
        localStorage.setItem("authUser", JSON.stringify({ ...user.toData(), accessToken }))
      } else {
        localStorage.removeItem("authUser")
      }

      this.setState({
        authUser: user,
        accessToken,
      })
    }

    public async componentDidMount() {
      this.unsubscribe = await this.props.firebase.onAuthUserListener(this.onAuthUpdate)

      const isAtAutoAuth = window.location.pathname.includes("/autoauth")

      if (isAtAutoAuth) {
        return
      }

      const token = await new API.Auth().getToken()

      if (!token) {
        return
      }

      this.props.firebase.doSignInWithCustomToken(token)
    }

    public componentWillUnmount() {
      this.unsubscribe?.()
    }

    public render() {
      return (
        <Context.Provider value={this.state}>
          <Component {...this.props} />
        </Context.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
