import { BillingAPI, Document } from "@chatpay/common"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API, Service } from "@chatpay/components"

export enum AffiliateActionTypes {
  LOAD = "AFFILIATE_LOAD",
  LOAD_SUCCESS = "AFFILIATE_LOAD_SUCCESS",
  LOAD_FAILURE = "AFFILIATE_LOAD_FAILURE",
  LOAD_INVOICES = "AFFILIATE_LOAD_INVOICES_INVOICES",
  LOAD_INVOICES_SUCCESS = "AFFILIATE_LOAD_INVOICES_INVOICES_SUCCESS",
  LOAD_INVOICES_FAILURE = "AFFILIATE_LOAD_INVOICES_INVOICES_FAILURE",
  LOAD_SALES = "AFFILIATE_LOAD_SALES_INVOICES",
  LOAD_SALES_SUCCESS = "AFFILIATE_LOAD_SALES_INVOICES_SUCCESS",
  LOAD_SALES_FAILURE = "AFFILIATE_LOAD_SALES_INVOICES_FAILURE",
  CHANGE_PAGE = "AFFILIATE_CHANGE_INVOICE_PAGE",
  CHANGE_PAGE_SUCCESS = "AFFILIATE_CHANGE_INVOICE_PAGE_SUCCESS",
  CHANGE_PAGE_FAILURE = "AFFILIATE_CHANGE_INVOICE_PAGE_FAILURE",
}

export interface ILoadAction {
  type: AffiliateActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: AffiliateActionTypes.LOAD_SUCCESS
  myAffiliates: Document.Affiliate[]
}

export interface ILoadFailureAction {
  type: AffiliateActionTypes.LOAD_FAILURE
  reason: string
}

export interface ILoadInvoicesAction {
  type: AffiliateActionTypes.LOAD_INVOICES
}

export interface ILoadInvoicesSuccessAction {
  type: AffiliateActionTypes.LOAD_INVOICES_SUCCESS
  invoicesData: BillingAPI.IDashboard.ListSalesOutput
}

export interface ILoadInvoicesFailureAction {
  type: AffiliateActionTypes.LOAD_INVOICES_FAILURE
  reason: string
}

export interface ILoadSalesAction {
  type: AffiliateActionTypes.LOAD_SALES
}

export interface ILoadSalesSuccessAction {
  type: AffiliateActionTypes.LOAD_SALES_SUCCESS
  chartData: BillingAPI.IDashboard.ChartOutput[]
}

export interface ILoadSalesFailureAction {
  type: AffiliateActionTypes.LOAD_SALES_FAILURE
  reason: string
}

export interface IChangeInvoicePageAction {
  type: AffiliateActionTypes.CHANGE_PAGE
  query: string
}

export interface IChangeInvoicePageSuccessAction {
  type: AffiliateActionTypes.CHANGE_PAGE_SUCCESS
  invoicesData: BillingAPI.IDashboard.ListSalesOutput
  chartData: BillingAPI.IDashboard.ChartOutput[]
  query: string
}

export interface IChangeInvoicePageFailureAction {
  type: AffiliateActionTypes.CHANGE_PAGE_FAILURE
  reason: string
}

const last30Days = () => {
  var startDate = new Date()
  startDate.setDate(startDate.getDate() - 30)
  startDate.setHours(0, 0, 0, 0)
  var endDate = new Date()
  endDate.setHours(23, 59, 59, 999)
  const last30Days = [startDate, endDate] as [Date, Date]
  return last30Days
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: AffiliateActionTypes.LOAD,
  })
  try {
    const affiliates = await new API.Affiliate().getAffiliates()
    const myAffiliatesSorted = (affiliates ?? []).sort((a, b) => b?.createdAt?.toMillis()! - a?.createdAt?.toMillis()!)
    dispatch(loadSuccess(myAffiliatesSorted!))
  } catch (e) {
    dispatch(loadFailure(e))
  }
}

export const loadSuccess = (myAffiliates: Document.Affiliate[]): ILoadSuccessAction => {
  return {
    type: AffiliateActionTypes.LOAD_SUCCESS,
    myAffiliates,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: AffiliateActionTypes.LOAD_FAILURE,
    reason,
  }
}

export const loadInvoices = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: AffiliateActionTypes.LOAD_INVOICES,
  })
  const userApi = new API.User()
  const transactionApi = new API.Transaction()
  try {
    // This healthCheck is just to ensure that user has AccessToken loaded
    await userApi.getById(Service.Firebase.currentUser?.id!) // HealthCheck for AccessToken

    const invoicesData = await transactionApi.list(
      1,
      10,
      BillingAPI.IDashboard.DashboardStatus.paid,
      last30Days(),
      undefined,
      undefined,
      undefined,
      true,
      "affiliate",
    )
    dispatch(loadInvoicesSuccess(invoicesData!))
  } catch (e) {
    dispatch(loadInvoicesFailure(e))
  }
}

export const loadInvoicesSuccess = (
  invoicesData: BillingAPI.IDashboard.ListSalesOutput,
): ILoadInvoicesSuccessAction => {
  return {
    type: AffiliateActionTypes.LOAD_INVOICES_SUCCESS,
    invoicesData,
  }
}

export const loadInvoicesFailure = (reason: string): ILoadInvoicesFailureAction => {
  return {
    type: AffiliateActionTypes.LOAD_INVOICES_FAILURE,
    reason,
  }
}

export const loadSales = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: AffiliateActionTypes.LOAD_SALES,
  })
  try {
    const chartData = await new API.Transaction().chart(last30Days(), undefined, true, "affiliate")
    dispatch(loadSalesSuccess(chartData))
  } catch (e) {
    dispatch(loadSalesFailure(e))
  }
}

export const loadSalesSuccess = (chartData: BillingAPI.IDashboard.ChartOutput[]): ILoadSalesSuccessAction => {
  return {
    type: AffiliateActionTypes.LOAD_SALES_SUCCESS,
    chartData,
  }
}

export const loadSalesFailure = (reason: string): ILoadSalesFailureAction => {
  return {
    type: AffiliateActionTypes.LOAD_SALES_FAILURE,
    reason,
  }
}

export const changePage =
  (
    clickedPage: number,
    status?: BillingAPI.IDashboard.DashboardStatus,
    createdAt?: [Date, Date],
    query?: string,
    sortedBy?: "ASC" | "DESC",
    groupId?: string,
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    const startDate = createdAt ? createdAt![0] : undefined
    if (startDate) {
      startDate.setHours(0, 0, 0, 0)
    }
    const endDate = createdAt ? createdAt![1] : undefined
    if (endDate) {
      endDate.setHours(23, 59, 59, 999)
    }
    const createdAtFilter = startDate && endDate ? ([startDate, endDate] as [Date, Date]) : undefined

    dispatch({
      type: AffiliateActionTypes.CHANGE_PAGE,
      clickedPage,
      query: JSON.stringify({ clickedPage, status, createdAt: createdAtFilter, query, sortedBy, groupId }),
    })

    try {
      const invoicesData = await new API.Transaction().list(
        clickedPage,
        10,
        status,
        createdAtFilter,
        query,
        sortedBy,
        groupId,
        true,
        "affiliate",
      )
      const chartData = await new API.Transaction().chart(createdAtFilter!, groupId!, true, "affiliate")

      dispatch(
        changePageSuccess(
          invoicesData!,
          chartData!,
          JSON.stringify({ clickedPage, status, createdAt, query, sortedBy, groupId }),
        ),
      )
    } catch (e) {
      dispatch(changePageFailure(e))
    }
  }

export const changePageSuccess = (
  invoicesData: BillingAPI.IDashboard.ListSalesOutput,
  chartData: BillingAPI.IDashboard.ChartOutput[],
  query: string,
): IChangeInvoicePageSuccessAction => {
  return {
    type: AffiliateActionTypes.CHANGE_PAGE_SUCCESS,
    invoicesData,
    chartData,
    query,
  }
}

export const changePageFailure = (reason: string): IChangeInvoicePageFailureAction => {
  return {
    type: AffiliateActionTypes.CHANGE_PAGE_FAILURE,
    reason,
  }
}

export type AffiliateAction =
  | ILoadAction
  | ILoadSuccessAction
  | ILoadFailureAction
  | ILoadInvoicesAction
  | ILoadInvoicesSuccessAction
  | ILoadInvoicesFailureAction
  | ILoadSalesAction
  | ILoadSalesSuccessAction
  | ILoadSalesFailureAction
  | IChangeInvoicePageAction
  | IChangeInvoicePageSuccessAction
  | IChangeInvoicePageFailureAction
