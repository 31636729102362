import { Document, Interface } from "@chatpay/common"
import { Session } from "@chatpay/components"
import { CPLoader } from "components/CPLoader"
import React from "react"
import { Modal } from "semantic-ui-react"

const Info = React.lazy(() => import("pages/AccountVerification/Info"))

interface IProps {
  open?: boolean
  forceGateway?: Document.GatewayCarrier
  title?: string
  subtitle?: string
  onClose?: any
}
export const AccountVerification: React.FunctionComponent<IProps> = (props) => {
  return (
    <Session.Context.Consumer>
      {(state) => (
        <Modal
          basic={true}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={props.open ?? state.authUser?.defaultGateway?.status !== Interface.Gateway.Common.Status.accepted}
          size="fullscreen"
          dimmer={"inverted"}
          content={
            state.authUser?.defaultGateway?.status === undefined ||
            state.authUser?.defaultGateway?.status === Interface.Gateway.Common.Status.rejected ||
            state.authUser?.defaultGateway?.status === Interface.Gateway.Common.Status.created ? (
              <Info
                onClose={props.onClose}
                isModal={true}
                forceGateway={props.forceGateway}
                title={props.title}
                subtitle={props.subtitle}
                showErrorIfNeeded={true}
              />
            ) : state.authUser?.defaultGateway?.status === Interface.Gateway.Common.Status.pending ? (
              <CPLoader />
            ) : null
          }
        />
      )}
    </Session.Context.Consumer>
  )
}
