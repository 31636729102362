import * as React from "react"
import { Segment } from "semantic-ui-react"

interface IProps {
  title: string
  className?: string
}

export const Section: React.FunctionComponent<IProps> = (props) => {
  return (
    <>
      <div className="cp text black bold">{props.title}</div>
      <Segment className={props.className} style={{ padding: "1em", marginBottom: "2em" }}>
        {props.children}
      </Segment>
    </>
  )
}
