import { Document } from "@chatpay/common"
import { Fields, Spacer } from "components"
import { TextArea } from "components/TextArea"
import React, { useEffect, useState } from "react"
import { Dropdown, DropdownProps, Form, InputOnChangeData, TextAreaProps } from "semantic-ui-react"
import { Text } from "typography"
import * as Yup from "yup"
import "./Description.scss"

type keys = "description"

interface IForm {
  description: Document.GroupAboutSection[]
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const schema = Yup.object().shape({
  description: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        title: Yup.string().max(50, "Título muito longo!"),
        text: Yup.string().max(700, "Texto muito longo!"),
      }),
    ),
})

const defaultOptions = [
  { key: 0, text: "O que você vai aprender", value: "O que você vai aprender" },
  { key: 1, text: "Resultados que você pode esperar", value: "Resultados que você pode esperar" },
  { key: 2, text: "Para quem é este grupo?", value: "Para quem é este grupo?" },
  { key: 3, text: "Regras do grupo", value: "Regras do grupo" },
  { key: 4, text: "Desafios que você irá enfrentar", value: "Desafios que você irá enfrentar" },
  { key: 5, text: "Como funciona a interação", value: "Como funciona a interação" },
]

const validateField = (path: string, data: any) => {
  let error: string = ""
  try {
    data && Yup.reach(schema, "description" + path).validateSync(data)
  } catch (e) {
    error = !e.path ? e.message : ""
  }
  return error
}

const hasErrors = (data: Partial<IForm>) => {
  let error: boolean = false
  try {
    data.description && Yup.reach(schema, "description").validateSync(data.description)
  } catch (e) {
    error = !!e.message
  }
  return error
}

const Description: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  useEffect(() => {
    setForm(value)
  }, [value])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    onChange?.(newData, valid)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.description || !hasErrors(data)
  }

  const updateDescription = (action: (newDescription: any[]) => void) => {
    const newDescription = (form?.description ?? []).map((a) => ({ ...a }))
    action(newDescription)
    setFormValue("description", newDescription)
  }

  const onDescriptionTitleUpdate = (data: DropdownProps | InputOnChangeData, idx: number) => {
    updateDescription((description) => (description![idx].title = String(data.value)))
  }

  const onDescriptionTextUpdate = (data: TextAreaProps, idx: number) => {
    updateDescription((description) => (description![idx].text = String(data.value)))
  }

  return (
    <React.Fragment>
      <Form>
        <React.Fragment>
          <Fields.InputLabel title={form?.description ? form?.description[0].title : "Sobre o grupo"}>
            <TextArea
              id="section-0"
              value={form?.description ? form?.description[0].text : ""}
              placeholder="Escreva uma descrição bem detalhada e explique de uma forma simples e clara qual o intuito do seu produto"
              onChange={(_, data) => onDescriptionTextUpdate(data, 0)}
            />
            <div style={{ textAlign: "right", marginBottom: "1em" }}>
              <Text.Tiny color="red">
                {validateField(`[0].text`, form?.description ? form?.description[0].text : "")}
              </Text.Tiny>
            </div>
          </Fields.InputLabel>
          <Spacer />
        </React.Fragment>
        <React.Fragment>
          <Dropdown
            text={form?.description ? form?.description[1].title : "O que você vai aprender"}
            className="title"
            onChange={(_, data) => onDescriptionTitleUpdate(data, 1)}
            options={defaultOptions}
          />
          <TextArea
            id="section-1"
            value={form?.description ? form?.description[1].text : ""}
            placeholder="Conte para seu cliente o que vocês oferecem dentro do seu produto e todas as vantagens dele participar"
            onChange={(_, data) => onDescriptionTextUpdate(data, 1)}
          />
          <div style={{ textAlign: "right", marginBottom: "1em" }}>
            <Text.Tiny color="red">
              {validateField(`[1].text`, form?.description ? form?.description[1].text : "")}
            </Text.Tiny>
          </div>
          <Spacer />
        </React.Fragment>
        <React.Fragment>
          <Dropdown
            text={form?.description ? form?.description[2].title : "O que você vai aprender"}
            className="title"
            onChange={(_, data) => onDescriptionTitleUpdate(data, 2)}
            options={defaultOptions}
          />
          <TextArea
            id="section-2"
            value={form?.description ? form?.description[2].text : ""}
            placeholder="Explique um pouco sobre o cronograma do seu produto, o que é esperado de cada membro ou até mesmo datas e eventos importantes"
            onChange={(_, data) => onDescriptionTextUpdate(data, 2)}
          />
          <div style={{ textAlign: "right", marginBottom: "1em" }}>
            <Text.Tiny color="red">
              {validateField(`[2].text`, form?.description ? form?.description[2].text : "")}
            </Text.Tiny>
          </div>
          <Spacer />
        </React.Fragment>
        <React.Fragment>
          <Fields.InputLabel title="Escreva mais uma sessão">
            <Form.Input
              value={form?.description ? form?.description[3].title : ""}
              placeholder="Escreva um título para sua sessão"
              onChange={(_, data) => onDescriptionTitleUpdate(data, 3)}
            />
            <div style={{ textAlign: "right", marginBottom: "1em" }}>
              <Text.Tiny color="red">
                {validateField(`[3].title`, form?.description ? form?.description[3].title : "")}
              </Text.Tiny>
            </div>
            <TextArea
              id="section-3"
              value={form?.description ? form?.description[3].text : ""}
              placeholder="Escreva algo interessante que possa ajudar o seu cliente"
              onChange={(_, data) => onDescriptionTextUpdate(data, 3)}
            />
            <div style={{ textAlign: "right", marginBottom: "1em" }}>
              <Text.Tiny color="red">
                {validateField(`[3].text`, form?.description ? form?.description[3].text : "")}
              </Text.Tiny>
            </div>
          </Fields.InputLabel>
          <Spacer />
        </React.Fragment>
      </Form>
    </React.Fragment>
  )
}

export { Description as Form }
export type { IProps, IForm }
