import { Interface } from "@chatpay/common"
import i18n from "i18n"

export function groupNameWithSequentialId(
  groupName: string,
  productSettings: Interface.Product.ProductSettingsForOffer,
): string {
  let groupNameEdit = groupName

  if (productSettings?.communityType === Interface.Product.CommunityType.cohort) {
    groupNameEdit = `${groupName} - ${i18n.t("editGroup.groups.accordion.title")} ${productSettings.sequentialId}`
  }

  return groupNameEdit
}
