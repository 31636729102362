import React from "react"
import TelegramButtonImage from "assets/notification/telegram.svg"

interface IProps {
  onClick?: () => void
}

export const TelegramNotification: React.FunctionComponent<IProps> = (props) => {
  return <img alt="" style={{ cursor: "pointer" }} src={TelegramButtonImage} onClick={props.onClick} />
}
