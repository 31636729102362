import { Document } from "@chatpay/common"
import { Button, Dropdown, Table } from "semantic-ui-react"
import styled from "styled-components"
import { Text } from "typography"

const red = { background: "#FCF1EF", text: "#9E392E" }
const green = { background: "#F3F9F2", text: "#497940" }
export const MainTitle = styled.div<{ isMobile: boolean }>`
  width: 100%;
  float: left;
  display: flex;
  align-items: end;
  flex-direction: row;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "space-between")};
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "unset")};

  .export-buttom {
    float: right;
    padding: 4px 8px !important;
    margin: 5px 5px 0 0 !important;
    height: 32px;
  }
`

export const DropdownExport = styled(Dropdown)`
  float: right;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 5px 5px 0 0;
  text-align: center;
  display: flex;
  height: 32px;
  align-items: center;

  display: flex !important;
  align-items: center !important;
  padding: 0px !important !important;
  height: 36px !important;

  .divider.text {
    display: none !important;
  }

  img {
    margin: auto !important;
  }
`

export const DropdownStatus = styled(Dropdown)<{ isMobile: boolean }>`
  height: 36px !important;
  line-height: 28px;
  width: ${({ isMobile }) => (isMobile ? "80%" : "")};

  i {
    float: right;
    line-height: 28px !important;
  }
  .menu {
    width: ${({ isMobile }) => (isMobile ? "100%" : "")};
  }
`

export const ConversionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-flow: row;
  width: ${({ isMobile }) => (isMobile ? "100%" : "")};
  justify-content: ${({ isMobile }) => (isMobile ? "space-between" : "unset")};
`
export const Tools = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "")};
`
export const ConversionBox = styled.div`
  display: flex;
  flex-direction: column;

  &.arrow {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
  }
`

export const ConversionBoxAmount = styled(Text.Big)`
  font-size: 32px;
`
export const ConversionBoxAmountTitle = styled(Text.Tiny)`
  font-size: 14px;
`

export const StatusTag = styled.div<{ status: Document.WaitlistMemberStatus; isDesktop: boolean }>`
  background-color: ${({ status }) =>
    status === Document.WaitlistMemberStatus.notMember ? red.background : green.background};
  color: ${({ status }) => (status === Document.WaitlistMemberStatus.notMember ? red.text : green.text)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  padding: 2px 4px;
  white-space: nowrap;
  float: "left";
  position: ${({ isDesktop }) => !isDesktop && "absolute"};
  top: ${({ isDesktop }) => !isDesktop && "15px"};
  right: ${({ isDesktop }) => !isDesktop && "0"};
  font-family: var(--secondary-font) !important;
  }
`
export const PhoneCell = styled.div`
  white-space: nowrap;
`

export const DateCell = styled.div<{ isDesktop: boolean }>`
  white-space: nowrap;
  position: ${({ isDesktop }) => !isDesktop && "absolute"};
  bottom: ${({ isDesktop }) => !isDesktop && "30px"};
  right: ${({ isDesktop }) => !isDesktop && "0"};
  opacity: ${({ isDesktop }) => !isDesktop && "0.38"};
`

export const TableHeadCollumn = styled.div<{ width: number | undefined }>`
  width: ${({ width }) => (width ? `${width}px` : "")};
`

export const TableEmptyRow = styled(Table.Row)`
  background-color: #f3f3f3 !important;
  padding: 17px; ;
`

export const EmptyMessage = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  margin-bottom: ${({ isMobile }) => isMobile && "20px"};
`
export const EmptyMessageIcon = styled.img`
  width: 22px;
  height: 14px;
  padding-right: 9px;
`
export const EmptyMessageText = styled(Text.Small)`
  font-size: 14px;
`

export const TableEmptyCell = styled(Table.Cell)``

export const EmptyContaier = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 766px) {
    flex-direction: column;
  }
`
export const ResourceButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0px 20px !important;
`

export const WaitlistRow = styled(Table.Row)`
  > td {
    font-family: var(--secondary-font) !important;
  }
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`
