import _ from "lodash"
import { Reducer } from "redux"
import { AffiliatesAction, AffiliatesActionTypes } from "./AffiliatesActions"
import { IAffiliatesState } from "./AffiliatesState"

const initialAffiliatesState: IAffiliatesState = {
  isLoading: true,
  error: null,
  column: "",
  affiliateRank: [],
  direction: undefined,
}

export const AffiliatesReducer: Reducer<IAffiliatesState, AffiliatesAction> = (
  state: IAffiliatesState = initialAffiliatesState,
  action: AffiliatesAction,
) => {
  const { column, direction, affiliateRank } = state

  switch (action.type) {
    case AffiliatesActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
      }
    case AffiliatesActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        affiliateRank: action.affiliateRank.sort((a, b) => (b?.totalSold! as number) - (a?.totalSold! as number)),
      }
    case AffiliatesActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isPaginationDisabled: true,
      }
    case AffiliatesActionTypes.SORT:
      if (column !== action.clickedColumn) {
        return {
          ...state,
          column: action.clickedColumn,
          affiliateRank: _.sortBy(affiliateRank, [action.clickedColumn]),
          direction: "ascending",
        }
      }
      return {
        ...state,
        affiliateRank: affiliateRank.reverse(),
        direction: direction === "ascending" ? "descending" : "ascending",
      }
    default:
      return state
  }
}
