import { Model } from "@chatpay/common"
import { GroupAvatar } from "components/Avatar"
import myGroupsStatus from "components/Status/MyGroupsStatus"
import i18n from "i18n"
import * as React from "react"
import { Icon, Placeholder } from "semantic-ui-react"
import "./GroupInfo.scss"

export interface IProps {
  groupId?: string
  label?: "active" | "draft" | "disabled"
  picture?: string | null
  name?: string
  createdAt?: string
  detail?: string | JSX.Element
  rightDetail?: string | React.ReactElement
  rightButton?: any
  isLoading?: boolean
  className?: string | null
  size?: "medium" | "small"
  isGroup?: boolean
  isVisible?: boolean
}

interface IState {
  isPublishing: boolean
}

const labels = {
  active: { status: i18n.t("myGroups.labels.active"), color: Model.Colors.darkgreen },
  draft: { status: i18n.t("myGroups.labels.draft"), color: Model.Colors.lightorange },
  disabled: { status: i18n.t("myGroups.labels.disabled"), color: Model.Colors.grey },
}

export class GroupInfo extends React.Component<IProps, IState> {
  private onClick = async (event: any) => {
    event.preventDefault()
    event.persist()
    event.nativeEvent.stopImmediatePropagation()
    event.stopPropagation()
  }

  public state: Readonly<IState> = {
    isPublishing: false,
  }

  public render() {
    const { picture, name, detail, rightDetail, rightButton, isLoading, size, className, createdAt, isVisible, label } =
      this.props

    return (
      <div
        className={`group-info${className ? " " + className : ""}`}
        style={isLoading ? { display: "block" } : undefined}
      >
        {isLoading ? (
          <Placeholder>
            <Placeholder.Header image={true}>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        ) : (
          <>
            <div className="avatar-content">
              <GroupAvatar picture={picture} size={size} />
            </div>
            <div className="detail-content">
              <span className="cp text black big bold Name">{name}</span>
              {!isVisible ? (
                <span style={{ marginLeft: "0.5em" }}>
                  <Icon name="eye slash outline" color="black" />
                </span>
              ) : (
                ""
              )}
              <div style={{ paddingBottom: "5px" }} />
              {createdAt ? (
                <div>
                  <span className="text">{i18n.t("Created")}</span>
                  <span className="text"> {i18n.t("At")} </span>
                  <span className="text">{createdAt}</span>
                </div>
              ) : null}
              <div style={{ height: "5px" }} />
              {label ? myGroupsStatus(labels[label]) : null}
              {detail ? <div className="text">{detail}</div> : null}
            </div>
            <div className="right-content">{rightDetail ? <div className="text small">{rightDetail}</div> : null}</div>
            <div className="right-button" onClick={this.onClick}>
              {rightButton ? <>{rightButton}</> : null}
            </div>
          </>
        )}
      </div>
    )
  }
}
