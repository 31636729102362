export const Sales = {
  balanceInvoices: {
    total: "Total Líquido",
    personal: "Seu faturamento",
    partnership: "Faturamento dos parceiros",
    chargeback: "Reembolsos e Contestações",
  },
  chart: {
    checkbox: {
      sales: "Vendas",
      renews: "Renovações",
      refunds: "Reembolsos",
    },
    tooltip: {
      sales: "Venda(s)",
      renew: "Renovação",
      renews: "Renovações",
      refunds: "Reembolso(s)",
    },
  },
  searchField: {
    placeholder: "Buscar...",
  },
  invoices: {
    title: "Faturas",
    loading: "Carregando...",
    export: {
      button: "Exportar",
      modal: {
        title: "Exportar faturas",
        cancel: "Cancelar",
        confirm: "Exportar",
        status: {
          all: "Todas",
          paid: "Paga",
          pending: "Pendente",
          expired: "Expirada",
        },
        fileType: "Tipo de arquivo",
      },
    },
  },
  invoiceDetails: {
    customer: {
      name: "Cliente",
      email: "E-mail",
      cellphone: "Celular",
    },
    paymentLabel: "Dados de pagamento",
    commissionTotalValueInCents: "Valor total da comissão",
    commissionTotalValuePercent: "Porcentagem total da comissão",
    payment: {
      createdAt: "Data de criação",
      paidAt: "Data de pagamento",
      methodLabel: "Método de pagamento",
      type: "Tipo de pagamento",
      recurrence: "Recorrência",
      affiliateCommission: "Comissão de afiliados",
      productPrice: "Valor do produto",
      couponDiscount: "Cupom de desconto",
      couponName: "Cupom utilizado",
      companyFee: "Taxa da Hubla",
      companyFeeTooltipText:
        "Esta incluso nesta compra a Taxa de serviço da Hubla e os custos da transação e do grupo. Dúvidas?",
      companyFeeTooltipLink: "Saiba mais aqui.",
      otherFee: "Outras taxas",
      total: "Valor Líquido",
      hasMembershipFee: "Possui taxa de adesão",
      hasMembershipFeeTrue: "Sim",
      hasMembershipFeeFalse: "Não",
      netValueCents: "Valor líquido",
      totalValueCents: "Valor total",
    },
    product: "Produto",
    group: "Grupo",
    logsLabel: "Histórico",
    logs: {
      title: "Título",
      description: "Descrição",
      createdAt: "Data de criação",
      loading: "Buscando histórico da fatura...",
      notFound: "Nenhum histórico de fatura encontrado...",
    },
  },
}
