import { BillingAPI, IDashboard } from "@chatpay/common"
import { Reducer } from "redux"
import { formatBasedOnLocale } from "utils/DateUtils"
import { AffiliateAction, AffiliateActionTypes } from "./AffiliateActions"
import { IAffiliateState } from "./AffiliateState"

const initialAffiliateState: IAffiliateState = {
  isLoaded: false,
  error: null,
  myAffiliates: [],
  isLoading: { graphic: false, list: false },
  isPaginationDisabled: true,
  chart: [],
  invoices: { count: 0, results: [], currentPage: 1, perPage: 10, lastPage: 1 },
  totalPages: 0,
  currentQuery: undefined,
}

export const AffiliateReducer: Reducer<IAffiliateState, AffiliateAction> = (
  state: IAffiliateState = initialAffiliateState,
  action: AffiliateAction,
) => {
  let newChart: BillingAPI.IDashboard.ChartOutput[] = []
  switch (action.type) {
    case AffiliateActionTypes.LOAD:
      return {
        ...state,
      }
    case AffiliateActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        myAffiliates: action.myAffiliates,
      }
    case AffiliateActionTypes.LOAD_FAILURE:
      return {
        ...state,
      }
    case AffiliateActionTypes.LOAD_INVOICES:
      return {
        ...state,
        isLoading: { ...state.isLoading, list: true },
      }
    case AffiliateActionTypes.LOAD_INVOICES_SUCCESS:
      if (state.currentQuery) {
        return { ...state, isLoading: { ...state.isLoading, list: false } }
      }

      return {
        ...state,
        isLoading: { ...state.isLoading, list: false },
        isPaginationDisabled: false,
        invoices: action.invoicesData,
        totalPages: Math.ceil(action.invoicesData.count / 10),
      }
    case AffiliateActionTypes.LOAD_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, list: false },
        isPaginationDisabled: true,
      }
    case AffiliateActionTypes.LOAD_SALES:
      return {
        ...state,
        isLoading: { ...state.isLoading, graphic: true },
      }
    case AffiliateActionTypes.LOAD_SALES_SUCCESS:
      newChart = action.chartData.map((it) => ({
        ...it,
        date: formatBasedOnLocale(it.date),
      }))
      return {
        ...state,
        isLoading: { ...state.isLoading, graphic: false },
        chart: newChart,
      }
    case AffiliateActionTypes.LOAD_SALES_FAILURE:
      generateFakeChart(newChart)
      return {
        ...state,
        isLoading: { ...state.isLoading, graphic: false },
        chart: newChart,
      }
    case AffiliateActionTypes.CHANGE_PAGE:
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: { ...state.isLoading, list: true },
        currentQuery: action.query,
      }
    case AffiliateActionTypes.CHANGE_PAGE_SUCCESS:
      if (action.query !== state.currentQuery) {
        return { ...state }
      }
      newChart = action.chartData.map((it) => ({
        ...it,
        date: formatBasedOnLocale(it.date),
      }))
      return {
        ...state,
        isPaginationDisabled: false,
        isLoading: { ...state.isLoading, list: false },
        invoices: action.invoicesData,
        chart: newChart,
        totalPages: Math.ceil(action.invoicesData.count / 10),
      }
    case AffiliateActionTypes.CHANGE_PAGE_FAILURE:
      generateFakeChart(newChart)
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: { ...state.isLoading, list: false },
        chart: newChart,
      }
    default:
      return state
  }
}

function generateFakeChart(newChart: IDashboard.ChartOutput[]) {
  for (let i = 30; i >= 0; i--) {
    const today = new Date()
    newChart.push({
      date: formatBasedOnLocale(today.setDate(today.getDate() - i)),
      payments: { amount: 0, count: 0 },
      renews: { amount: 0, count: 0 },
      refunds: { amount: 0, count: 0 },
    })
  }
}
