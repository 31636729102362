import { FeatureFlag, FLAG } from "../Service/FeatureFlag"
import Firebase from "../Service/Firebase"

export interface IState {
  isLoading: boolean
  error?: Error | null
}

export const DefaultState = {
  isLoading: false,
}

class API extends Firebase {
  protected async getBillingUrl(): Promise<string> {
    const pattern = /\/(v[0-9]{1})\//gi
    const version = pattern.exec(window?.location.pathname)?.last().toUpperCase()

    const flags = await this.getAllConfigs()
    const isBillingV2Enabled = new FeatureFlag(flags).isEnabled(FLAG.BILLING.IS_V2_ENABLED)

    const url =
      process.env[`REACT_APP_BILLING_URL_${version}`] ?? isBillingV2Enabled
        ? process.env.REACT_APP_BILLING_URL_V2!
        : process.env.REACT_APP_BILLING_URL_V1!

    return url
  }

  public static factory<T>(this: new () => T): T {
    return new this()
  }
}

export default API
