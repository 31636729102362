import { Document, Interface, getDate } from "@chatpay/common"
import { API } from "@chatpay/components"
import { Button, Snackbar, Stack, Switch, Text, Tooltip, styled, useUtility } from "@hub-la/design-system"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { useIsMobile } from "hooks/use-is-mobile"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { DatePickerProps, DayValue } from "react-modern-calendar-datepicker"
import { Dropdown, DropdownItemProps, Input, Label } from "semantic-ui-react"
import { groupNameWithSequentialId } from "utils/groupNameWithSequentialId"

const DatePicker = styled(MuiDatePicker)`
  width: 100%;
`

interface IProps {
  coupon?: Document.Coupon.Document
  onSuccess: (coupon: Document.Coupon.Document, type: "update" | "create") => any
  onCancelled: () => any
}

interface ICoupon {
  code: string
  discount: number
  plan?: Document.Coupon.Item
  groups: string[]
  quantity: number
  recurrent: boolean
}

export const Coupon: React.FunctionComponent<IProps> = (props: IProps) => {
  const COUPON_CHAR_LIMIT = 15

  const [couponLength, setCouponLength] = useState<number>(0)
  const [plan, setPlan] = useState<Document.Coupon.Item | undefined>(undefined)
  const [myGroups, setMyGroups] = useState<Array<Document.Group>>([])
  const [hasLimit, setHasLimit] = useState<boolean>(false)
  const [hasExpiration, setHasExpiration] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState<Date | null>(null)
  const [isRecurrent, setIsRecurrent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const { t } = useTranslation()

  const isMobile = useIsMobile()
  const { palette } = useUtility()

  const { register, errors, handleSubmit, setValue, triggerValidation } = useForm<ICoupon>()

  useEffect(() => {
    register({ name: "code" }, { required: true })
    register({ name: "discount" }, { required: true })
    register({ name: "plan" }, { required: false })
    register({ name: "groups" }, { required: false })
    register({ name: "quantity" }, { required: false })
    register({ name: "recurrent" }, { required: false })

    if (props.coupon) {
      const { coupon } = props
      const plan = Object.values(coupon.items ?? {}).find((i) => i.type === "Plan")

      setCouponLength(coupon.code.length)
      setPlan(plan)
      setHasLimit(coupon.limit.total > 0)
      setHasExpiration(coupon.expiresAt !== null)
      setExpirationDate(getDate(coupon.expiresAt))
      setIsRecurrent(coupon.isRecurrent)

      setValue("code", coupon.code)
      setValue("discount", coupon.discount)
      setValue("plan", plan)
      setValue("groups", Object.keys(coupon.items ?? {}))
      setValue("quantity", coupon.limit.total)
      setValue("recurrent", coupon.isRecurrent)
    }

    new API.Group()
      .userAllGroups()
      .then((groups: Document.Group[] | null) => setMyGroups(groups ?? []))
      .catch((error: Error) => setError(error))
  }, [register, props, setValue])

  const onFormSubmit = async (data: ICoupon) => {
    setIsLoading(true)

    const valid = /[A-Z0-9-]{5,15}/.test(data.code)
    if (!valid) {
      setError(new Error(t("couponForm.code.error")))
      setIsLoading(false)
      return
    }

    const makeItems = async () => {
      const plans = (await Promise.all(data.groups.map((id) => new API.Group().plans(id)))).flat()

      const getPlanId = (groupId: string): string | undefined =>
        plans.find(
          (plan: Document.Plan) => plan.parentId === groupId && plan.interval === (data.plan as unknown as string),
        )?.id

      const hasPlanSelected = !!data.plan && (data.plan as unknown as string) !== "single" && plans.length !== 0

      return data.groups.reduce((res, groupId: string) => {
        const id = hasPlanSelected ? getPlanId(groupId) : groupId

        if (!id) {
          return res
        }

        return {
          ...res,
          [id]: hasPlanSelected ? "Plan" : "Group",
        }
      }, {})
    }

    const quantity: number = hasLimit ? data.quantity : 0

    // adds 1 day then removes 1 second, so the expiration date is set to the end of the day
    const expiration =
      hasExpiration && expirationDate ? new Date(expirationDate.setDate(expirationDate.getDate() + 1) - 1000) : null

    if (expiration && expiration < new Date()) {
      setError(new Error(t("couponForm.expiration.error")))
      setIsLoading(false)
      return
    }

    try {
      const items = await makeItems()

      const makeCouponData = (): Interface.Coupon.Function.ICreate => ({
        code: data.code,
        discount: data.discount,
        items,
        limit: { total: Number(quantity), user: 1, recurrency: 0 },
        expiresAt: expiration,
        isRecurrent: data.recurrent,
      })

      const newCoupon = await new API.Coupon().create(makeCouponData())

      setIsLoading(false)
      props.onSuccess(new Document.Coupon.Document(newCoupon), props.coupon ? "update" : "create")
    } catch (error: any) {
      setIsLoading(false)
      setError(error)
    }
  }

  const handleCode = (event: React.ChangeEvent<HTMLInputElement>, value: string): string => {
    const result = value.trim().toUpperCase().substring(0, COUPON_CHAR_LIMIT)

    setCouponLength(result.length)
    event.currentTarget.value = result
    return result
  }

  const handleDiscount = (event: React.ChangeEvent<HTMLInputElement>, value: string): number | undefined => {
    let intString = Number(value.replace(/\D/g, "")).toString()
    if (intString.length > 4) {
      intString = intString.substring(0, 4)
    }
    let result = (Number(intString) / 100).toFixed(2)
    if (Number(result) > 80) {
      result = "80.00"
    }
    event.currentTarget.value = result
    return Number(result)
  }

  const handlePlan = (_event: React.SyntheticEvent<HTMLElement, Event>, value: string): string | undefined => {
    setPlan({ name: value, type: "Plan" })
    return value
  }

  const handleGroups = (_event: React.SyntheticEvent<HTMLElement, Event>, value: string[]): string[] | null => {
    return value.length === 0 ? null : value
  }

  const handleLimited = (checked: boolean): void => {
    setHasLimit(checked)
  }

  const handleQuantity = (event: React.ChangeEvent<HTMLInputElement>, value: string): number => {
    let result = Number(value.replace(/\D/g, "")).toString()
    if (result.length > 6) {
      result = result.substring(0, 6)
    }
    event.currentTarget.value = result
    return Number(result)
  }

  const handleExpires = (checked: boolean): void => {
    setHasExpiration(checked)
  }

  const handleExpiration = (value: Date): void => {
    setExpirationDate(new Date(value))
  }

  const handleRecurrent = (checked: boolean): void => {
    setIsRecurrent(checked)
    setValue("recurrent", checked)
  }

  const FieldCallbacks = {
    code: handleCode,
    discount: handleDiscount,
    plan: handlePlan,
    groups: handleGroups,
    quantity: handleQuantity,
  }

  const handleFieldChange = async (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLElement, Event>
      | React.ChangeEvent<HTMLInputElement>
      | DatePickerProps<DayValue>,
    data: any,
  ) => {
    const { name, checked, value } = data
    setValue(name, FieldCallbacks[name](event, value, checked))
    await triggerValidation(name)
  }

  const filterGroups = (): Document.Group[] => {
    let g: Document.Group[] = []
    if (!plan) {
      g = myGroups
    } else {
      g = myGroups.filter((g) => {
        if (!g.hasPlans || !g.plansForCreation) {
          return plan?.name === "single" ? true : false
        }
        return plan?.name === "all"
          ? g
          : (g.plansForCreation ?? []).map((p) => p.interval).some((p) => p === plan?.name)
      })
    }
    return g
  }

  const getPlanOptions = (): DropdownItemProps[] => {
    return Object.keys(Document.PlanInterval)
      .map((plan) => {
        return { key: plan ?? "", text: t(`Plans.${plan}`), value: plan }
      })
      .concat([
        { key: "all", text: t("Plans.all"), value: "all" },
        { key: "single", text: t("Plans.single"), value: "single" },
      ])
  }

  return (
    <Stack direction="column" width="100%" spacing={2}>
      <Text pb={4} variant="h4">
        {t("coupon.header.create")}
      </Text>

      <Text>{`${t("couponForm.code.title")} (${COUPON_CHAR_LIMIT - couponLength} ${t("couponForm.code.more")})`}</Text>
      <Input
        name="code"
        placeholder={t("couponForm.code.placeholder")}
        defaultValue={props.coupon?.code}
        fluid={true}
        required={true}
        onChange={(event, data) => handleFieldChange(event, data)}
        error={errors.code ? true : false}
      />

      <Text>{t("couponForm.discount.title")}</Text>
      <Input
        label={<Label basic={true}>%</Label>}
        labelPosition="left"
        name="discount"
        data-testid="discount"
        defaultValue={props.coupon?.discount ? props.coupon.discount + "%" : undefined}
        placeholder="0.00"
        fluid={true}
        required={true}
        onChange={(event, data) => handleFieldChange(event, data)}
        error={errors.discount ? true : false}
      />

      <Text>{t("couponForm.plan.title")}</Text>
      <Dropdown
        name="plan"
        fluid={true}
        multiple={false}
        data-testid="plan"
        selection={true}
        defaultValue={Object.values(props.coupon?.items ?? {}).find((i) => i.type === "Plan")?.name ?? "all"}
        options={getPlanOptions()}
        onChange={(event, data) => handleFieldChange(event, data)}
      />

      <Dropdown
        name="groups"
        placeholder={t("couponForm.groups.placeholder")}
        fluid={true}
        multiple={true}
        data-testid="group"
        selection={true}
        search={true}
        defaultValue={Object.keys(props.coupon?.items ?? {})}
        options={filterGroups().map((group: Document.Group) => {
          return {
            key: group.id ?? "",
            text: groupNameWithSequentialId(group.name, group?.productSettings),
            value: group.id ?? "",
          }
        })}
        onChange={(event, data) => handleFieldChange(event, data)}
      />

      {/** Determines if the coupon has a limited use */}
      <Stack direction="row" alignItems="center" pt={4}>
        <Switch
          marginLeft={0}
          name="limited"
          label={t("couponForm.limit.checkbox")}
          direction="left"
          checked={hasLimit}
          onChange={(event) => handleLimited(event.target.checked)}
        />
        <Tooltip
          arrow
          trigger={isMobile ? "click" : "hover"}
          title={<>{t("couponForm.limit.helpPopup." + (hasLimit ? "checked" : "unchecked"))}</>}
        >
          <InfoOutlinedIcon sx={{ color: palette("onSurfaceVariant") }} />
        </Tooltip>
      </Stack>
      {hasLimit && (
        <Input
          name="quantity"
          type={"number"}
          label={t("couponForm.limit.quantity") + ":"}
          defaultValue={props.coupon?.limit.total}
          fluid={true}
          size={"small"}
          required={true}
          onChange={(event, data) => handleFieldChange(event, data)}
          error={!!errors.quantity}
        />
      )}

      {/** Determines the coupon expiration date */}
      <Switch
        marginLeft={0}
        name="expires"
        label={t("couponForm.expiration.checkbox")}
        direction="left"
        checked={hasExpiration}
        onChange={(event) => handleExpires(event.target.checked)}
      />
      {hasExpiration && (
        <Stack py={2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Data"
              slotProps={{ textField: { error: false } }}
              disablePast
              format="DD/MM/YYYY"
              value={moment(expirationDate)}
              onChange={(value: any) => handleExpiration(value)}
            />
          </LocalizationProvider>
        </Stack>
      )}

      {/** Determines if the coupon is recurrent */}
      {plan?.name !== "single" && (
        <Stack direction="row" alignItems="center">
          <Switch
            marginLeft={0}
            name="recurrent"
            label={t("couponForm.recurrence.checkbox")}
            direction="left"
            checked={isRecurrent}
            onChange={(event) => handleRecurrent(event.target.checked)}
          />
          <Tooltip
            arrow
            trigger={isMobile ? "click" : "hover"}
            title={<>{t(`couponForm.recurrence.helpPopup.${isRecurrent ? "checked" : "unchecked"}`)}</>}
          >
            <InfoOutlinedIcon sx={{ color: palette("onSurfaceVariant") }} />
          </Tooltip>
        </Stack>
      )}

      <Stack direction="row" spacing={2} pt={6} alignSelf="end">
        <Button hierarchy="secondary" onClick={props.onCancelled}>
          {t("couponForm.button.cancel")}
        </Button>
        <Button onClick={handleSubmit(onFormSubmit)} loading={isLoading}>
          {t("couponForm.button.generate")}
        </Button>
      </Stack>

      {error && (
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          onClose={() => setError(undefined)}
          closeable={false}
          open={!!error}
          variant="negative"
        >
          {error.message}
        </Snackbar>
      )}
    </Stack>
  )
}

export default Coupon
