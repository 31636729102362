import { Document, Interface } from "@chatpay/common"
import { Filter } from "Service/DB"
import { DB } from "../Service"
import API from "./API"

class Affiliate extends API implements Interface.Affiliate.Function.ITemplate {
  private db = new DB(Document.Affiliate)

  public async getLink(data: Interface.Affiliate.Function.IGetLinkParams): Promise<Interface.Affiliate.Function.ILink> {
    return (await this.call(Interface.Affiliate.Function.Name.getLink, data)).data
  }

  private async call(func: Interface.Affiliate.Function.Name, params?: Interface.Affiliate.Function.Params) {
    return await this.callFunction(`affiliate/${func}`, params)
  }

  public async getAffiliates(): Promise<Document.Affiliate[] | null> {
    try {
      if (!API.currentUser?.id) {
        return null
      }

      return await this.db.get({
        where: [
          {
            field: "user.id",
            op: "==",
            value: API.currentUser?.id,
          },
          { field: "isEnabled", op: "==", value: true },
          { field: "isDeleted", op: "==", value: false },
        ],
        order: {
          by: "createdAt",
          direction: "desc",
        },
      })
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public async getMyAffiliates(): Promise<Document.Affiliate[] | null> {
    if (!API.currentUser?.id) {
      return null
    }

    const getAffiliatesByField = (field: string): Filter => ({
      where: [
        { field, op: "==", value: API.currentUser?.id },
        { field: "isEnabled", op: "==", value: true },
      ],
    })

    try {
      return (
        await Promise.all([
          this.db.get(getAffiliatesByField("owner")).catch(() => []),
          this.db.get(getAffiliatesByField("group.owner")).catch(() => []),
        ])
      ).flat()
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Affiliate
