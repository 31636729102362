import { Spacer } from "components"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Image } from "semantic-ui-react"
import { Text } from "typography"

interface IProps {
  onChange?: (rating: number) => void
}

export const InputRating: React.FC<IProps> = (props) => {
  const { onChange } = props
  const { t } = useTranslation()
  const [rating, setRating] = useState<number>(0)

  const onRatingChange = (rating: number) => {
    setRating(rating)
    onChange?.(rating)
  }

  const texts = {
    1: t("Review.Input.terrible"),
    2: t("Review.Input.bad"),
    3: t("Review.Input.ok"),
    4: t("Review.Input.good"),
    5: t("Review.Input.great"),
  }

  return (
    <React.Fragment>
      <Grid columns="equal" textAlign="center">
        {_.range(5).map((_, idx) => (
          <Grid.Column key={idx}>
            <div onClick={() => onRatingChange(idx + 1)}>
              <Image
                style={{ margin: "auto", cursor: "pointer" }}
                src={
                  rating > idx ? require("assets/images/star-enabled.svg") : require("assets/images/star-disabled.svg")
                }
              />
            </div>
            <Spacer size="small" />
            <Text.Small>{texts[idx + 1]}</Text.Small>
          </Grid.Column>
        ))}
      </Grid>
    </React.Fragment>
  )
}
