import { Document, Events } from "@chatpay/common"
import firebase from "firebase/compat"
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider } from "./Tracker"
import * as braze from "@braze/web-sdk"
import { Firebase } from "../Service"

const ALLOWED_EVENTS_TO_START_SESSION = new Set([
  Events.PURCHASE.FINISH_PURCHASE_SUBMITTED,
  Events.PRODUCT.COMMUNITY_TYPE_CLICKED,
  Events.REFUND.REQUESTED,
  Events.CONTENT.CONTENT_POST_SUBMITTED,
  Events.RECEIVER.RECEIVER_INVITE_SUBMITTED,
  Events.RECEIVER.INVITE_ACCEPT_SUCCESSFUL,
  Events.CONTENT.CONTENT_POST_SUBMITTED,
  Events.REFUND.REQUESTED,
  Events.MEMBER_DASHBOARD.PAGE_VIEWED,
  Events.CREATOR_DASHBOARD.PAGE_VIEWED,
])

const ALLOWED_EVENTS_TO_SEND_EVENTS = new Set([
  `${Events.INVOICE.REINSTATED}User`,
  `${Events.INVOICE.REINSTATED}Seller`,
  `${Events.INVOICE.PROTESTED}User`,
  `${Events.INVOICE.PROTESTED}Seller`,
  Events.PAYMENT.FAILED,
  Events.PAYMENT.RECEIVED,
  Events.REFUND.ACCEPTED,
  Events.REFUND.REJECTED,
  Events.REFUND.REACTIVATED,
  Events.REFUND.CANCELED,
  Events.REFERRAL.REFERRER_NEW_REFERRAL,
  Events.REFERRAL.REFERRED_FIRST_SALE,
  Events.RECEIVER.INVITE_REJECT_SUCCESSFUL,
  Events.RECEIVER.INVITED_SUCCESSFUL,
  Events.RECEIVER.INVITED_REJECTED,
  Events.RECEIVER.INVITE_ACCEPT_SUCCESSFUL,
  Events.GROUP.PUBLISH_SUCCESSFUL,
  Events.PAYMENT.COLLECTED,
  Events.PURCHASE.FINISH_PURCHASE_SUCCESSFUL,
  Events.PURCHASE.FINISH_PURCHASE_REJECTED,
  Events.PURCHASE.FINISH_PURCHASE_FAILED,
  Events.PURCHASE.FINISH_PURCHASE_SUBMITTED,
  Events.CREATOR_DASHBOARD.GROUP_STATUS_SUMITTED,
  Events.PRODUCT.COMMUNITY_TYPE_CLICKED,
  Events.REFUND.REQUESTED,
  Events.MEMBER_DASHBOARD.PAGE_VIEWED,
  Events.CREATOR_DASHBOARD.PAGE_VIEWED,
])

export class BrazeProvider implements IAnalyticsProvider {
  init = () => {
    braze.initialize(process.env.REACT_APP_BRAZE_TOKEN!, {
      baseUrl: process.env.REACT_APP_BRAZE_DATA_PLANE_URL!,
    })
    braze.automaticallyShowInAppMessages()
  }

  type = (): AnalyticsProviderType => {
    return AnalyticsProviderType.BRAZE
  }

  track = (event: string) => {
    const user = Firebase.currentUser
    if (ALLOWED_EVENTS_TO_START_SESSION.has(event) && user) {
      braze.changeUser(user.id)
      braze.openSession()
    }
    if (ALLOWED_EVENTS_TO_SEND_EVENTS.has(event) && user) {
      braze.logCustomEvent(event)
    }
  }

  page(name: string) {
    const user = Firebase.currentUser
    if (ALLOWED_EVENTS_TO_START_SESSION.has(name) && user) {
      braze.changeUser(user.id)
      braze.openSession()
    }
    if (ALLOWED_EVENTS_TO_SEND_EVENTS.has(name) && user) {
      braze.logCustomEvent(name)
    }
  }

  identify = (user?: Document.User | null) => {}
  alias = (id: string) => {}
  setCurrentScreen = (screen: string, params?: AnalyticsEventParams) => {}
  identifyFB(user: firebase.User): void {}
  reset(): void {}
}
