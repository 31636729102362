"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = exports.IListPaginatedFilterField = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["get"] = "get";
  Name["create"] = "create";
  Name["list"] = "list";
  Name["update"] = "update";
  Name["setInfo"] = "setInfo";
  Name["setResourceTypesIds"] = "setResourceTypesIds";
  Name["setEntryFee"] = "setEntryFee";
  Name["setWelcome"] = "setWelcome";
  Name["setAboutAssets"] = "setAboutAssets";
  Name["setAboutDescription"] = "setAboutDescription";
  Name["setAboutAdvantages"] = "setAboutAdvantages";
  Name["setAboutFaq"] = "setAboutFaq";
  Name["setAffiliate"] = "setAffiliate";
  Name["setPrice"] = "setPrice";
  Name["publish"] = "publish";
  Name["listGroups"] = "listGroups";
  Name["getCurrentOffer"] = "getCurrentOffer";
  Name["saveOrder"] = "saveOrder";
  Name["getAllAvailableInCreatorsPage"] = "getAllAvailableInCreatorsPage";
})(Name || (exports.Name = Name = {}));

var IListPaginatedFilterField;
exports.IListPaginatedFilterField = IListPaginatedFilterField;

(function (IListPaginatedFilterField) {
  IListPaginatedFilterField["status"] = "status";
})(IListPaginatedFilterField || (exports.IListPaginatedFilterField = IListPaginatedFilterField = {}));