import React from "react"
import { Button, Dialog, DialogActions, DialogDescription, DialogTitle, Text } from "@hub-la/design-system"
import { useIsMobile } from "../hooks/use-is-mobile"
import { useTranslation } from "react-i18next"

type Props = {
  open: boolean
  onClose: () => void
}

export const ProductDoesNotHaveGroupIMOrContentDialog = ({ open, onClose }: Props) => {
  const isMobile = useIsMobile()

  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} size="medium">
      <>
        <DialogTitle>
          <Text variant="h3" color="onSurface">
            {t("productDoesNotHaveGroupIMOrContentDialog.title")}
          </Text>
        </DialogTitle>
        <DialogDescription>
          <Text variant="body2" color="onSurface">
            {t("productDoesNotHaveGroupIMOrContentDialog.subtitle")}
          </Text>
        </DialogDescription>

        <DialogActions align="right">
          <Button variant="filled" hierarchy="primary" onClick={onClose} fullWidth={isMobile}>
            {t("productDoesNotHaveGroupIMOrContentDialog.buttonLabel")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
