import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal, Button, Grid, Divider } from "semantic-ui-react"
import { Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import {
  ModalTitle,
  ModalTitleCenter,
  ModalDescription,
  ModalHighlightText,
  MaybeLaterLink,
  ButtonGroup,
  ModalContent,
} from "./GroupQuickActionStyled"
import { GroupButtonType } from "./GroupQuickActionButton"
import { Group } from "@chatpay/common"

export interface IGroupQuickActionModalProps extends GroupButtonType {
  showModal: boolean
  loading: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => Promise<Group>
  modalNodeName: string
}

const GroupQuickActionModal: React.FC<IGroupQuickActionModalProps> = (props: IGroupQuickActionModalProps) => {
  const { t } = useTranslation()
  const { showModal, setShowModal, handleSubmit, modalNodeName, type, loading } = props
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false)

  const ModalCustomContent = (): JSX.Element => {
    if (type === "delete") {
      return <ModalHighlightText>{t(`editGroup.publishButton.${modalNodeName}.warningMessage`)}</ModalHighlightText>
    }

    return <ModalDescription>{t(`editGroup.publishButton.${modalNodeName}.description`)}</ModalDescription>
  }

  const ConfirmModal = (): JSX.Element => {
    if (isConfirmModal)
      return (
        <Modal open={isConfirmModal} style={{ textAlign: "center" }}>
          <ModalContent>
            <Spacer size="big" />
            <Grid.Row>
              <Grid.Column computer={12} mobile={16}>
                <ModalTitleCenter>{t(`editGroup.publishButton.${modalNodeName}.confirmDelete`)}</ModalTitleCenter>
                <Spacer size="small" />
                <ModalDescription style={{ textAlign: "center" }}>
                  {t(`editGroup.publishButton.${modalNodeName}.confirmDeleteDescription`)}
                </ModalDescription>
              </Grid.Column>
            </Grid.Row>
            <Spacer size="big" />
            <Button
              content={t(`editGroup.publishButton.${modalNodeName}.confimButton`)}
              size="small"
              color="green"
              onClick={async () => {
                await handleSubmit()
                setShowModal(false)
                setIsConfirmModal(false)
              }}
              loading={loading}
            />
          </ModalContent>
        </Modal>
      )
    return <></>
  }

  return (
    <>
      {showModal && (
        <Modal open={showModal} style={{ textAlign: "center" }}>
          <ModalContent>
            <div style={{ display: "flex", justifyContent: "flex-end" }} title={t("close")}>
              <CloseIcon onClick={() => setShowModal(false)} />
            </div>
            <Grid.Row>
              <Grid.Column computer={15} mobile={16}>
                <ModalTitle>{t(`editGroup.publishButton.${modalNodeName}.title`)}</ModalTitle>
                <Spacer size="small" />
                <ModalDescription>{t(`editGroup.publishButton.${modalNodeName}.subTitle`)}</ModalDescription>
                <Spacer size="small" />
                <ModalCustomContent />
                <Spacer size="medium" />
                <Divider />
                <ButtonGroup>
                  <MaybeLaterLink onClick={() => setShowModal(false)}>
                    {t("editGroup.publishButton.maybeLater")}
                  </MaybeLaterLink>
                  <Button
                    content={t(`editGroup.publishButton.${modalNodeName}.btnNext`)}
                    size="small"
                    color="green"
                    onClick={() => {
                      setIsConfirmModal(true)
                      setShowModal(false)
                    }}
                    loading={loading}
                  />
                </ButtonGroup>
              </Grid.Column>
            </Grid.Row>
          </ModalContent>
        </Modal>
      )}
      <ConfirmModal />
    </>
  )
}

export { GroupQuickActionModal }
