import React, { useState } from "react"
import { Document, Events, Interface, GroupStepHelpers } from "@chatpay/common"
import { Analytics, API, Service } from "@chatpay/components"
import { DropdownItemProps, Loader, Modal, Button, Divider, Container, Grid } from "semantic-ui-react"
import "./GroupStatusButton.scss"
import isSellingIcon from "../../../assets/icons/waitlist/isSelling.svg"
import isWaitlistedIcon from "../../../assets/icons/waitlist/isWaitlisted.svg"
import isDraftIcon from "../../../assets/icons/waitlist/isDraft.svg"
import isNotEnabledIcon from "../../../assets/icons/waitlist/isNotEnabled.svg"
import dropdownIcon from "../../../assets/icons/waitlist/dropdown.svg"
import { Spacer, DotsLoading } from "components"
import { CloseIcon } from "components/CloseIcon"
import i18n from "i18n"

import {
  ButtonContainer,
  DropdownGroupStatusButton,
  MaybeLaterLink,
  ModalTitle,
  ModalDescription,
  ModalContent,
  ModalTitleCenter,
} from "./GroupStatusButtonStyledComponents"
import { GroupPublishButton } from "../GroupPublishButton"
import { GroupPublishButtonOriginPage } from "../GroupPublishButton/GroupPublishButton"
import { ProductDoesNotHaveGroupIMOrContentDialog } from "modules/product-header/presentation/components/product-does-not-have-group-im-or-content-dialog"
import { useCheckProductHasContentOrOfferHasGroupIMResource } from "modules/product-header/presentation/hooks/use-check-product-has-content-or-offer-has-group-im"

export interface IProps {
  group: Document.Group
  product: Document.Product
  statusChanged?: () => void
  accountVerificationChanged?: (status: boolean) => any
  readOnly?: boolean
  borderless?: boolean
  resourceDisabled?: string
}

const GroupStatusButton: React.FunctionComponent<IProps> = (props) => {
  const groupStepHelpers = new GroupStepHelpers()
  const { group, borderless, readOnly, product, accountVerificationChanged, resourceDisabled } = props
  const productHasContentOrOfferHasGroupIMResource = useCheckProductHasContentOrOfferHasGroupIMResource({
    productId: product.id,
    offerResourcesType: group.groupResourcesType,
    isOfferDraft: group.isDraft,
    isOfferWaitlisted: group.isWaitlisted,
    isOfferSelling: group.isSelling,
    isOfferPublished: group.isPublished,
  })

  /** @todo we should refactor all these booleans to a unique state, this current approach is hard to understand and maintain */
  const [
    productHasNoContentOrOfferHasNoGroupIMResourceDialogOpen,
    setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen,
  ] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isOfferWaitlistWarning, setIsOfferWaitlistWarning] = useState<boolean>(false)
  const [isOfferActivationWarning, setIsOfferActivationWarning] = useState<boolean>(false)
  const [isOfferPublish, setIsOfferPublish] = useState<boolean>(false)
  const [isOfferPublishCohort, setIsOfferPublishCohort] = useState<boolean>(false)
  const [isOfferWaitlisted, setIsOfferWaitlisted] = useState<boolean>(false)
  const [isOfferNotSellingVerificationWarning, setIsOfferNotSellingVerificationWarning] = useState<boolean>(false)
  const [IsAccountVerificationPendingWarning, setIsAccountVerificationPendingWarning] = useState<boolean>(false)

  const [isAccountVerificationCreatedOrRejectedWarning, setIsAccountVerificationCreatedOrRejectedWarning] =
    useState<boolean>(false)
  const [isOfferConfirmInactiveWarning, setIsOfferConfirmInactiveWarning] = useState<boolean>(false)

  const [showModalResourceDisabled, setShowModalResourceDisabled] = useState<boolean>(false)

  const isDesktop = window.matchMedia("(min-width: 992px)").matches

  const waitlistTracker = (event, params) => {
    Analytics.track(event, {
      ...params,
      device: isDesktop ? "desktop" : "mobile",
    })
  }

  const productStatusTracker = (
    event: string,
    product: Document.Product | null | undefined,
    group: Document.Group,
    status: Document.GroupStepStatus,
  ) => {
    const isDesktop = window.matchMedia("(min-width: 992px)").matches
    Analytics.track(event, {
      productId: product?.id,
      userId: product?.owner?.id,
      device: isDesktop ? "desktop" : "mobile",
      productType: product?.type,
      offerID: group.id,
      statusType: status,
      statusOrigin: groupStepHelpers.getGroupStepStatus(group),
    })
  }

  async function handleChangeOfferStatus(status: Document.GroupStepStatus) {
    setIsLoading(true)
    await new API.Group().changeStatus({
      groupId: group.id,
      status: status,
    })
    setIsLoading(false)
    props.statusChanged && props.statusChanged()
  }

  async function handleExceptions(status: Document.GroupStepStatus) {
    console.log("handleExceptions - status", status)

    const currentStatus = Service.Firebase.currentUser?.defaultGateway?.status ?? 0

    // check product has at least a content or group before being enabled just when enabling product
    if (!productHasContentOrOfferHasGroupIMResource && Document.GroupStepStatus.isSelling === status) {
      setIsLoading(false)
      setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen(true)
      return
    }

    // handle account verification
    const shouldRenderAccountVerificationCreatedOrRejectWarning =
      currentStatus === Interface.Gateway.Common.Status.created ||
      currentStatus === Interface.Gateway.Common.Status.rejected

    if (shouldRenderAccountVerificationCreatedOrRejectWarning) {
      setIsAccountVerificationCreatedOrRejectedWarning(true)
      setIsLoading(false)
      return
    }

    //check if acount verification is pending
    if (currentStatus === Interface.Gateway.Common.Status.pending) {
      setIsAccountVerificationPendingWarning(true)
      setIsLoading(false)
      return
    }

    // show waitlist alert Warning
    if (
      status === Document.GroupStepStatus.isWaitlisted &&
      product?.type === Interface.Product.CommunityType.cohort &&
      group.isSelling &&
      group.botsId.length
    ) {
      setIsOfferWaitlistWarning(true)
      setIsLoading(false)
      return
    }

    // disable offer saled from product cohort type
    if (status === Document.GroupStepStatus.isNotSelling && product?.type === Interface.Product.CommunityType.cohort) {
      setIsOfferNotSellingVerificationWarning(true)
      setIsLoading(false)
      return
    }

    // handle group activation
    const shouldRenderIfAccountAccepted = currentStatus === Interface.Gateway.Common.Status.accepted
    if (
      shouldRenderIfAccountAccepted &&
      (status === Document.GroupStepStatus.isSelling || status === Document.GroupStepStatus.isWaitlisted)
    ) {
      if (status === Document.GroupStepStatus.isSelling) {
        if (product?.type === Interface.Product.CommunityType.cohort) {
          setIsOfferPublishCohort(true)
        } else {
          setIsOfferPublish(true)
        }
      }
      if (status === Document.GroupStepStatus.isWaitlisted) {
        setIsOfferWaitlisted(true)
      }
      setIsOfferActivationWarning(true)
      setIsLoading(false)
      return
    }

    handleChangeOfferStatus(status)

    if (group.unavailable.isOn === true) {
      return Document.GroupStepStatus.isUnavailable
    }
  }

  function statusToDropdownItem(statusArray: Document.GroupStepStatus[]): DropdownItemProps[] {
    return statusArray.map((status, index) => ({
      key: index,
      selected: false,
      text: i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.action`),
      description: i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.description`),
      icon: <img src={getIconByStatus(status)} alt={"group icon"} className="menu-icon" />,
      value: status,
      onClick: async () => {
        if (resourceDisabled && status === Document.GroupStepStatus.isSelling) {
          setShowModalResourceDisabled(true)
        } else {
          if (status === Document.GroupStepStatus.isWaitlisted) {
            waitlistTracker(Events.CREATOR_DASHBOARD.GROUP_STATUS_SUMITTED, {
              groupId: group.id,
              creatorId: group.owner?.id,
              newStatus: status,
            })
          }
          productStatusTracker(Events.PRODUCT.STATUS_CLICKED, product, group, status)
          setIsLoading(true)
          handleExceptions(status)
        }
      },
    }))
  }

  function getOptionsByGroupStatus(status: Document.GroupStepStatus): DropdownItemProps[] {
    switch (status) {
      case Document.GroupStepStatus.isDraft: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
      case Document.GroupStepStatus.isSelling: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isNotSelling])
      }
      case Document.GroupStepStatus.isWaitlisted: {
        return statusToDropdownItem([Document.GroupStepStatus.isSelling, Document.GroupStepStatus.isNotEnabled])
      }
      case Document.GroupStepStatus.isNotEnabled: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
      case Document.GroupStepStatus.isUnavailable: {
        return statusToDropdownItem([])
      }
      default: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
    }
  }

  if (readOnly) {
    const status = groupStepHelpers.getGroupStepStatus(group)
    const normalizeClassName = (str) => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

    return (
      <span className={`group-status-label ${normalizeClassName(status)}`}>
        {i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.current`)}
      </span>
    )
  }

  return (
    <>
      <ProductDoesNotHaveGroupIMOrContentDialog
        open={productHasNoContentOrOfferHasNoGroupIMResourceDialogOpen}
        onClose={() => setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen(false)}
      />

      {isOfferActivationWarning && (
        <GroupPublishButton
          product={product}
          originPage={GroupPublishButtonOriginPage.productStatusButton}
          group={group}
          statusChanged={() => {
            setIsOfferActivationWarning(false)
            setIsOfferPublish(false)
            setIsOfferPublishCohort(false)
            setIsOfferWaitlisted(false)
            props.statusChanged && props.statusChanged()
          }}
          closed={() => {
            setIsOfferActivationWarning(false)
            setIsOfferPublish(false)
            setIsOfferPublishCohort(false)
            setIsOfferWaitlisted(false)
            props.statusChanged && props.statusChanged()
          }}
          justModal={isOfferActivationWarning}
          isPublish={isOfferPublish}
          isPublishCohort={isOfferPublishCohort}
          isWaitlisted={isOfferWaitlisted}
        />
      )}

      <DropdownGroupStatusButton
        className={`${isLoading && "loading"} dropdown-waitlist ${borderless ? "" : "border"} group-status-button ${
          readOnly ? "readOnly" : ""
        }`}
        data-testid="dropdown-status"
        disabled={isLoading}
        size="small"
        text={i18n.t(`Dashboard.Waitlist.GroupStatus.${groupStepHelpers.getGroupStepStatus(group)}.current`)}
        icon={
          <>
            {isLoading && (
              <Loader className="main-icon" size="mini" style={{ height: "14px !important" }}>
                Loading
              </Loader>
            )}
            <img
              src={getIconByStatus(groupStepHelpers.getGroupStepStatus(group))}
              alt={"group icon"}
              className="main-icon status-icon"
            />
            <img src={dropdownIcon} alt={"group icon"} className="dropdown-icon" />
          </>
        }
        loading={isLoading}
        onClick={(event) => {
          if (event.target.value === Document.GroupStepStatus.isWaitlisted) {
            waitlistTracker(Events.CREATOR_DASHBOARD.GROUP_STATUS_CLICKED, {
              groupId: group.id,
              creatorId: group.owner?.id,
              oldStatus: groupStepHelpers.getGroupStepStatus(group),
            })
          }
          event.preventDefault()
          event.persist()
          event.nativeEvent.stopImmediatePropagation()
          event.stopPropagation()
        }}
        options={getOptionsByGroupStatus(groupStepHelpers.getGroupStepStatus(group))}
        item={true}
      />

      <Modal open={isOfferWaitlistWarning}>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }} title={i18n.t("close")}>
            <CloseIcon onClick={() => setIsOfferWaitlistWarning(false)} />
          </div>
          <Spacer size={isDesktop ? "big" : "small"} />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitle>{i18n.t("GroupStatusButton.offerWaitlistWarning.modal.title")}</ModalTitle>
              <Spacer size="small" />
              <ModalDescription
                dangerouslySetInnerHTML={{ __html: i18n.t("GroupStatusButton.offerWaitlistWarning.modal.description") }}
              />
              <Spacer size={isDesktop ? "big" : "small"} />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={12} mobile={16}>
                  <ButtonContainer>
                    <MaybeLaterLink onClick={() => setIsOfferWaitlistWarning(false)}>
                      {i18n.t("GroupStatusButton.offerWaitlistWarning.modal.buttons.maybeLater")}
                    </MaybeLaterLink>
                    <Button
                      content={i18n.t("GroupStatusButton.offerWaitlistWarning.modal.buttons.createWaitlist")}
                      size="small"
                      color="green"
                      onClick={async () => {
                        await handleChangeOfferStatus(Document.GroupStepStatus.isWaitlisted)
                        setIsOfferWaitlistWarning(false)
                      }}
                      loading={isLoading}
                    />
                  </ButtonContainer>
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </ModalContent>
      </Modal>

      <Modal open={isOfferConfirmInactiveWarning} style={{ textAlign: "center" }}>
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitleCenter>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.confirm.title`)}
              </ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.confirm.description`)}
              </ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
          <Button
            content="Fechar"
            size="small"
            color="green"
            onClick={() => setIsOfferConfirmInactiveWarning(false)}
            loading={isLoading}
          />
        </ModalContent>
      </Modal>

      <Modal
        open={isOfferNotSellingVerificationWarning}
        onMount={async () => {
          await handleChangeOfferStatus(Document.GroupStepStatus.isNotSelling)
          setIsOfferNotSellingVerificationWarning(false)
          setIsOfferConfirmInactiveWarning(true)
        }}
        style={{ textAlign: "center" }}
      >
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <DotsLoading />
              <ModalTitleCenter>{i18n.t("GroupStatusButton.offerNotSellingWarning.modal.title")}</ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.description`)}
              </ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
        </ModalContent>
      </Modal>

      <Modal open={isAccountVerificationCreatedOrRejectedWarning}>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }} title={i18n.t("close")}>
            <CloseIcon onClick={() => setIsAccountVerificationCreatedOrRejectedWarning(false)} />
          </div>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitle>{i18n.t("GroupStatusButton.accountVerificationInput.modal.title")}</ModalTitle>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t("GroupStatusButton.accountVerificationInput.modal.description")}
              </ModalDescription>
              <Spacer size="big" />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={12} mobile={16}>
                  <ButtonContainer>
                    <MaybeLaterLink onClick={() => setIsAccountVerificationCreatedOrRejectedWarning(false)}>
                      {i18n.t("GroupStatusButton.accountVerificationInput.modal.buttons.maybeLater")}
                    </MaybeLaterLink>
                    <Button
                      content={i18n.t("GroupStatusButton.accountVerificationInput.modal.buttons.checkAccount")}
                      size="small"
                      color="green"
                      onClick={async () => accountVerificationChanged && accountVerificationChanged(true)}
                      loading={isLoading}
                    />
                  </ButtonContainer>
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </ModalContent>
      </Modal>

      <Modal open={IsAccountVerificationPendingWarning} style={{ textAlign: "center" }}>
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitleCenter>{i18n.t(`GroupStatusButton.accountVerificationPending.modal.title`)}</ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t("GroupStatusButton.accountVerificationPending.modal.description")}
              </ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
          <Button
            content="Fechar"
            size="small"
            color="green"
            onClick={() => setIsAccountVerificationPendingWarning(false)}
            loading={isLoading}
          />
        </ModalContent>
      </Modal>

      <Modal open={showModalResourceDisabled} style={{ textAlign: "center" }}>
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitleCenter>
                {i18n.t("GroupStatusButton.resourceDisabled.modal.title", { resource_name: resourceDisabled })}
              </ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription
                dangerouslySetInnerHTML={{
                  __html: i18n.t("GroupStatusButton.resourceDisabled.modal.description", {
                    resource_name: resourceDisabled,
                  }),
                }}
              ></ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
          <Button content="Fechar" size="small" color="green" onClick={() => setShowModalResourceDisabled(false)} />
        </ModalContent>
      </Modal>
    </>
  )
}

export const getIconByStatus = (status: Document.GroupStepStatus) => {
  const icons = {
    [Document.GroupStepStatus.isSelling]: isSellingIcon,
    [Document.GroupStepStatus.isWaitlisted]: isWaitlistedIcon,
    [Document.GroupStepStatus.isDraft]: isDraftIcon,
    [Document.GroupStepStatus.isNotSelling]: isNotEnabledIcon,
    [Document.GroupStepStatus.isNotEnabled]: isNotEnabledIcon,
  }
  return icons[status]
}

export default GroupStatusButton
