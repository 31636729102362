export const EditNotification = {
  SectionTitle: "Notificações",
  SectionSubtitle:
    "Receba notificações sobre atividades dos membros no seu app Hubla, confirmações de pagamentos, entre outros.",
  SectionText: "Receber notificações sobre",
  adminJoined: "Admin entrou no grupo",
  memberJoined: "Um membro entrou em seu grupo",
  nonMemberJoined: "Um usuário não identificado entrou em seu grupo",
  purchased: "Vendas do grupo",
  removed: "Um usuário foi removido do grupo",
  whitelisted: "Um usuário foi adicionado à whitelist",
  DownloadApp: "Baixar o App",
}
