import * as Address from "./Address"
import * as Document from "./Document"
import * as CreditCard from "./CreditCard"
import * as Base from "./Base"
import * as UserInfo from "./UserInfo"
import * as Withdraw from "./Withdraw"
import * as BankAccount from "./BankAccount"
import * as Coupon from "./Coupon"
import { AdminAddress } from "./AddressSecond"
import * as GroupForm from "./GroupForm"
import * as UserForm from "./UserForm"
import { Section } from "./Section/Section"

import { FormGrid as Grid } from "./FormGrid"

const Forms = {
  AdminAddress,
  Base,
  Address,
  CreditCard,
  Document,
  Grid,
  Withdraw,
  UserInfo,
  BankAccount,
  Coupon,
}

export {
  Forms,
  AdminAddress,
  GroupForm,
  UserForm,
  Base,
  Address,
  CreditCard,
  Document,
  Grid,
  Withdraw,
  UserInfo,
  BankAccount,
  Coupon,
  Section,
}
