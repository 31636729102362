import { Reducer } from "redux"
import { WithdrawAction, WithdrawActionTypes } from "./WithdrawActions"
import { IWithdrawState } from "./WithdrawState"

const initialWithdrawState: IWithdrawState = {
  isOpen: false,
  isLoading: false,
  error: null,
  status: 0,
}

export const WithdrawReducer: Reducer<IWithdrawState, WithdrawAction> = (
  state: IWithdrawState = initialWithdrawState,
  action: WithdrawAction,
) => {
  switch (action.type) {
    case WithdrawActionTypes.OPEN:
      return {
        ...state,
        isOpen: true,
        error: null,
      }
    case WithdrawActionTypes.CLOSE:
      return {
        ...state,
        isOpen: false,
      }
    case WithdrawActionTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case WithdrawActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 1,
      }
    case WithdrawActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.reason,
      }
    default:
      return state
  }
}
