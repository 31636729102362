import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"

export class CheckProductHasContent {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(productId: string | undefined): Promise<boolean> {
    if (!productId) {
      return false
    }

    const response: HttpResponse<boolean> = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.makeUrl(productId),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data ?? false
    }

    return false
  }

  private makeUrl(productId) {
    return `${Envs.BFF_URL}/hub/public/sections/check-preview?productId=${productId}`
  }
}
