"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["get"] = "get";
  Name["getUserProfile"] = "getUserProfile";
  Name["getReferredUsers"] = "getReferredUsers";
  Name["setCurrentStep"] = "setCurrentStep";
  Name["setPicture"] = "setPicture";
  Name["setCountry"] = "setCountry";
  Name["setBuckets"] = "setBuckets";
  Name["balance"] = "balance";
  Name["update"] = "update";
  Name["adminUpdate"] = "adminUpdate";
  Name["updateWppId"] = "updateWppId";
  Name["connectTelegram"] = "connectTelegram";
  Name["connectTelegramWithCode"] = "connectTelegramWithCode";
  Name["usernameGenerate"] = "usernameGenerate";
  Name["usernameValidate"] = "usernameValidate";
  Name["telegramCode"] = "telegramCode";
  Name["acceptTerms"] = "acceptTerms";
  Name["registerHubspotContact"] = "registerHubspotContact";
  Name["generateTelegramCode"] = "generateTelegramCode";
  Name["validateTelegramCode"] = "validateTelegramCode";
  Name["updateVerificationTelegramStep"] = "updateVerificationTelegramStep";
})(Name || (exports.Name = Name = {}));