import * as Forms from "components/Forms"
import React from "react"
import { Grid, Input } from "semantic-ui-react"
import { Base } from ".."
import { InputLabel } from "components/Fields"
import { Service } from "@chatpay/components"
import i18n from "i18n"
import { isEqual } from "lodash"
import { Email } from "@chatpay/common"

export interface IData {
  firstName: string
  lastName: string
  email?: string
}
export type Data = IData

interface IProps<I extends IData> extends Base.Props<I> {}
interface IState<T extends IData> extends Base.State<T> {}

export class UserInfoForm extends Base.Form<IProps<IData>, IState<IData>> {
  protected formGridRef = React.createRef<Forms.Grid>()

  public componentDidMount() {
    if (this.getUserEmail() && this.state.data) {
      this.state.data.email = this.getUserEmail() as string
    }
  }

  private getUserEmail = (): string | null | undefined => Service.Firebase.currentUser?.email

  private shouldAskEmail = (): boolean => !this.getUserEmail()

  protected validateField = (name: string, value: unknown, isValid: boolean = true) => ({
    name,
    isValid: isEqual(name, "email") ? Email.isValid(value) : isValid,
  })

  public render() {
    return (
      <div className="UserInfoForm">
        <Forms.Grid ref={this.formGridRef}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <InputLabel title={i18n.t("UserInfo.name")}>
                <Input key={1} name="firstName" fluid={true} required={true} onChange={this.onFieldChange} />
              </InputLabel>
            </Grid.Column>
            <Grid.Column>
              <InputLabel title={i18n.t("UserInfo.lastname")}>
                <Input key={2} name="lastName" fluid={true} required={true} onChange={this.onFieldChange} />
              </InputLabel>
            </Grid.Column>
          </Grid.Row>

          {this.shouldAskEmail() && (
            <Grid.Row>
              <Grid.Column>
                <InputLabel title={i18n.t("UserInfo.email")}>
                  <Input key={3} name="email" type="email" fluid={true} required={true} onChange={this.onFieldChange} />
                </InputLabel>
              </Grid.Column>
            </Grid.Row>
          )}
        </Forms.Grid>
      </div>
    )
  }
}
