import { Interface, Model } from "@chatpay/common"
import { InputLabel } from "components/Fields"
import * as Forms from "components/Forms"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Grid, Input, InputOnChangeData } from "semantic-ui-react"

export interface IProps {
  countries: Interface.Country[]
  value?: Partial<Interface.Address>
  disabled?: boolean
  onChange?: (value: Partial<Interface.Address>, valid: boolean) => any
}

const Address: React.FunctionComponent<IProps> = (props) => {
  const { countries, value, disabled, onChange } = props

  const { t } = useTranslation()

  const [formData, setFormData] = useState<Partial<Interface.Address>>()

  useEffect(() => setFormData(value), [value, setFormData])

  const handleFormUpdate = (name: string, value: string) => {
    const newData: Partial<Interface.Address> = {
      ...formData,
      [name]: value,
    }

    if (onChange) {
      onChange(newData, true)
    }

    setFormData(newData)
  }

  const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data
    let result = value
    if (isBR) {
      result = value.replace(/ˆ[0-9]/g, "").replace("-", "")
      result = result.length > 5 ? [result.slice(0, 5), "-", result.slice(5, result.length)].join("") : result
      result = result.substring(0, 9)
    }
    handleFormUpdate("zip", result)
  }

  // If country is Brazil
  const isBR = formData?.country === Model.Country.br

  return (
    <Forms.Grid>
      <Grid.Row key={0} columns={2}>
        <Grid.Column>
          <InputLabel title={t("checkout.Country")}>
            <Form.Select
              disabled={disabled}
              upward={false}
              search={true}
              type="hidden"
              id="Form"
              value={formData?.country}
              name="country"
              fluid={true}
              options={countries.map((country) => ({ key: country.id, text: country.name, value: country.id }))}
              closeOnBlur={true}
              onChange={(_, data) => handleFormUpdate("country", data.value as Model.Country)}
            />
          </InputLabel>
        </Grid.Column>
        <Grid.Column>
          <InputLabel title={t("checkout.PostalCode")}>
            <Input
              fluid={true}
              name="zip"
              required={true}
              disabled={disabled}
              onChange={(event, data) => handlePostalCode(event, data)}
              value={formData?.zip}
            />
          </InputLabel>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={!isBR ? 1 : 2} key={2}>
        <Grid.Column>
          <InputLabel title={t("checkout.Address")}>
            <Input
              fluid={true}
              name="street"
              required={true}
              disabled={disabled}
              onChange={(_, data) => handleFormUpdate("street", data.value)}
              value={formData?.street}
            />
          </InputLabel>
        </Grid.Column>
        {!isBR ? null : (
          <Grid.Column>
            <InputLabel title={t("checkout.Neighborhood")}>
              <Input
                fluid={true}
                name="neighborhood"
                required={true}
                disabled={disabled}
                onChange={(event, data) => handleFormUpdate("neighborhood", data.value)}
                value={formData?.neighborhood}
              />
            </InputLabel>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row columns={!isBR ? 1 : 2}>
        {!isBR ? null : (
          <Grid.Column>
            <InputLabel title={t("checkout.Number")}>
              <Input
                fluid={true}
                name="number"
                required={true}
                disabled={disabled}
                onChange={(event, data) => handleFormUpdate("number", data.value)}
                value={formData?.number}
              />
            </InputLabel>
          </Grid.Column>
        )}
        <Grid.Column>
          <InputLabel title={t("checkout.Complement")}>
            <Input
              fluid={true}
              name="complement"
              disabled={disabled}
              onChange={(event, data) => handleFormUpdate("complement", data.value)}
              value={formData?.complement}
            />
          </InputLabel>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <InputLabel title={t("checkout.City")}>
            <Input
              fluid={true}
              name="city"
              required={true}
              disabled={disabled}
              onChange={(event, data) => handleFormUpdate("city", data.value)}
              value={formData?.city}
            />
          </InputLabel>
        </Grid.Column>
        <Grid.Column>
          <InputLabel title={!isBR ? t("checkout.Providence") : t("checkout.State")}>
            <Input
              fluid={true}
              name="state"
              required={true}
              disabled={disabled}
              onChange={(event, data) => handleFormUpdate("state", data.value)}
              value={formData?.state}
            />
          </InputLabel>
        </Grid.Column>
      </Grid.Row>
    </Forms.Grid>
  )
}

export { Address }
