import React, { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import * as Fields from "components/Fields"

export interface IForm {
  days: number
}

export interface IProps {
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>) => any
}

const CollectionRule: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange } = props

  const [form, setForm] = useState<Partial<IForm>>()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const onDaysUpdate = (event, data) => {
    const newData: Partial<IForm> = {
      days: data.value,
    }
    if (onChange) {
      onChange(newData)
    }
    setForm(newData)
  }

  return (
    <Form>
      <Fields.InputLabel title="Remover usuários __ dias após o vencimento">
        <Form.Dropdown
          fluid={true}
          value={form?.days ?? 5}
          selection={true}
          options={[
            { id: 0, text: "5", value: 5 },
            { id: 0, text: "6", value: 6 },
            { id: 0, text: "7", value: 7 },
            { id: 0, text: "8", value: 8 },
            { id: 0, text: "9", value: 9 },
            { id: 1, text: "10", value: 10 },
          ]}
          onChange={(event, data) => onDaysUpdate(event, data)}
        />
      </Fields.InputLabel>
      <div>
        Por exemplo: Um usuário assinou o grupo dia 1 de Agosto, a assinatura dele vence dia 1 de Setembro e ele é
        removido do grupo/canal X dias após a assinatura vencer, caso não pague.
      </div>
    </Form>
  )
}

export { CollectionRule as Form }
