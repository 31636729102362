import { Refund } from "@chatpay/common"
import _ from "lodash"
import { Reducer } from "redux"
import { RefundsAction, RefundsActionTypes } from "./RefundsActions"
import { IRefundsState } from "./RefundsState"

const initialRefundsState: IRefundsState = {
  isLoading: true,
  error: null,
  column: "",
  refundList: [],
  direction: undefined,
}

export const RefundsReducer: Reducer<IRefundsState, RefundsAction> = (
  state: IRefundsState = initialRefundsState,
  action: RefundsAction,
) => {
  const { column, direction, refundList } = state

  switch (action.type) {
    case RefundsActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
      }
    case RefundsActionTypes.LOAD_SUCCESS:
      const newRefundList = action.refundList
        .filter((it) => it.status === Refund.Status.created)
        .sort((a, b) => b.createdAt?.toMillis()! - a.createdAt?.toMillis()!)
        .concat(
          action.refundList
            .filter((it) => it.status !== Refund.Status.created)
            .sort((a, b) => b.createdAt?.toMillis()! - a.createdAt?.toMillis()!),
        )

      return {
        ...state,
        isLoading: false,
        refundList: newRefundList,
      }
    case RefundsActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isPaginationDisabled: true,
      }
    case RefundsActionTypes.SORT:
      if (column !== action.clickedColumn) {
        return {
          ...state,
          column: action.clickedColumn,
          refundList: _.sortBy(refundList, [action.clickedColumn]),
          direction: "ascending",
        }
      }
      return {
        ...state,
        refundList: refundList.reverse(),
        direction: direction === "ascending" ? "descending" : "ascending",
      }
    default:
      return state
  }
}
