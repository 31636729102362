import API from "./API"
import { DB } from "../Service"
import { Document, Usage, Interface } from "@chatpay/common"

class Coupon extends API implements Interface.Coupon.Function.ITemplate {
  private db = new DB(Document.Coupon.Document)

  public async create(data: Interface.Coupon.Function.ICreate): Promise<Document.Data<Document.Coupon.Document>> {
    return (await this.call(Interface.Coupon.Function.Name.create, data))?.data
  }
  public async update(data: Interface.Coupon.Function.IUpdate): Promise<Document.Data<Document.Coupon.Document>> {
    return (await this.call(Interface.Coupon.Function.Name.update, data))?.data
  }
  public async delete(data: Interface.Coupon.Function.IDelete): Promise<Document.Data<Document.Coupon.Document>> {
    return (await this.call(Interface.Coupon.Function.Name.delete, data))?.data
  }
  public async retrieve(
    data: Interface.Coupon.Function.IRetrieve,
  ): Promise<Interface.Coupon.Function.IRetrieveResponse> {
    return (await this.call(Interface.Coupon.Function.Name.retrieve, data))?.data
  }

  private async call(func: Interface.Coupon.Function.Name, params?: Interface.Coupon.Function.Params) {
    return await this.callFunction(`coupon/${func}`, params)
  }

  // QUERIES
  public async my(): Promise<Document.Coupon.Document[]> {
    if (!API.currentUser?.id) {
      return []
    }

    return this.db.get({
      where: [
        { field: "userId", op: "==", value: API.currentUser.id },
        { field: "isDeleted", op: "==", value: false },
      ],
    })
  }

  public async usedByMe(): Promise<Document.Coupon.Document[]> {
    if (!API.currentUser?.id) {
      return []
    }

    return this.db.getParentSubCollection(Usage.Document, {
      where: {
        field: "user.id",
        op: "==",
        value: API.currentUser.id,
      },
    })
  }

  public async usage(couponId: string): Promise<Usage.Document[]> {
    if (!API.currentUser?.id) {
      return []
    }
    return this.db.getSubCollection(Usage.Document, couponId)
  }
}

export default Coupon
