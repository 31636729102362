import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/analytics"
import "firebase/compat/storage"
import i18next from "i18next"
import { Document, TimestampInject } from "@chatpay/common"
import { TimestampFactoryImpl } from "../Helpers/TimestampFactoryImpl"
import _ from "lodash"
import DB, { Unsubscriber } from "./DB"
import { ensureInitialized, fetchAndActivate, getAll, getRemoteConfig, getValue } from "firebase/remote-config"
import * as API from "../API"

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_GA,
}

TimestampInject.TimestampFactory = new TimestampFactoryImpl()

if (!firebase.apps.length) {
  firebase.initializeApp(config)

  getRemoteConfig().settings.minimumFetchIntervalMillis = 600000

  firebase.auth().useDeviceLanguage()
  if (location.port === "3008") {
    console.log("USING EMULATOR FUNCTIONS")
    firebase.functions().useEmulator("localhost", 5001)
  }
}
class Firebase {
  private userUpdateUnsubscriber?: Unsubscriber
  private static instance?: Firebase
  public static get shared(): Firebase {
    if (!this.instance || firebase.apps.length === 0) {
      this.instance = new this()
    }
    return this.instance
  }

  protected auth = firebase.auth()
  protected analytics = firebase.analytics()
  protected firestore = firebase.firestore()
  protected functions = firebase.functions()
  protected storage = firebase.storage()
  protected remoteConfig = getRemoteConfig()

  // *** STORAGE API ***
  public saveFile = async (file: File, remotePath: string, remoteFileName: string): Promise<string> => {
    const remoteRef = this.storage.ref(remotePath).child(remoteFileName)
    return remoteRef.put(file).then((_) => remoteRef.getDownloadURL())
  }
  public saveData = async (data: string, remotePath: string, remoteFileName: string): Promise<string> => {
    const remoteRef = this.storage.ref(remotePath).child(remoteFileName)
    return remoteRef.putString(data, "data_url").then((_) => remoteRef.getDownloadURL())
  }

  // *** HTTPS API ***
  public callFunction = async (name: string, data?: any) => {
    try {
      return await this.functions.httpsCallable(`${name}/${i18next.language}`, { timeout: 200000 })(data)
    } catch (e: any) {
      if (e?.message?.includes("ETIMEDOUT") || e?.message?.toLowerCase().includes("timeout")) {
        throw new Error("Tempo de execução excedido. Tente novamente mais tarde")
      }
      throw e
    }
  }

  // *** Auth API ***
  public doCreateUserWithEmailAndPassword = (email: string, password: string) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }
  public doSignInWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> => {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  public doSignInWithCustomToken = async (token: string): Promise<firebase.auth.UserCredential> => {
    return this.auth.signInWithCustomToken(token)
  }

  public doSignOut = async (): Promise<void> => {
    return await this.auth.signOut()
  }

  public doPasswordReset = (email: string) => {
    return this.auth.sendPasswordResetEmail(email)
  }

  public doSendEmailVerification = () => {
    if (!this.auth.currentUser) {
      return null
    }
    return this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT || "",
    })
  }

  public doPasswordUpdate = (password: string) => {
    if (!this.auth.currentUser) {
      return null
    }
    return this.auth.currentUser.updatePassword(password)
  }

  public getExperimentBucket(name: string): string {
    const bucket = getValue(this.remoteConfig, name).asString()
    return bucket === "" ? "control" : bucket
  }

  public async getAllConfigs(): Promise<{ [key: string]: firebase.remoteConfig.Value }> {
    return new Promise((resolve) =>
      fetchAndActivate(this.remoteConfig).then(() => {
        resolve(getAll(this.remoteConfig))
      }),
    )
  }

  /**
   * Get the value of users who analytics is activated in debug mode.
   * @param callback function to be called when the remoteconfig is loaded.
   */
  public getAnalyticsDebugRemoteConfig(callback: Function): void {
    ensureInitialized(this.remoteConfig).then(() => {
      let users = getValue(this.remoteConfig, "analytics_tracker_debug_users").asString()
      callback(users)
    })
  }

  public static get currentUser(): Document.User | null {
    const dataString = localStorage.getItem("authUser")
    const data = dataString && JSON.parse(dataString)
    return data && new Document.User(data)
  }

  public static get accessToken(): string | null {
    const dataString = localStorage.getItem("authUser")
    const data = dataString && JSON.parse(dataString)
    return data?.accessToken ?? null
  }

  public onAuthUserListener = async (aCallback: (user?: Document.User, accessToken?: string) => void) => {
    const callback = async (user?: Document.User, accessToken?: string) => {
      aCallback(user, accessToken)
    }

    return this.auth.onAuthStateChanged(
      async (authUser) => {
        if (!authUser) {
          this.userUpdateUnsubscriber?.unsubscribe()
          delete this.userUpdateUnsubscriber
          await callback()
          return
        }

        const idToken = await authUser?.getIdToken(true)
        await new API.Auth().setToken(idToken)

        try {
          this.userUpdateUnsubscriber = new DB(Document.User).listen(authUser.uid, async (user, error) => {
            if (!user || user.isDeleted || error) {
              await this.doSignOut()
              return
            }
            await callback(user, await authUser.getIdToken())
          })
        } catch {
          callback()
        }
      },
      () => callback(),
    )
  }
}

export default Firebase
