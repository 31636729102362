import { BillingAPI } from "@chatpay/common"
import { Reducer } from "redux"
import { SubscriptionsAction, SubscriptionsActionTypes } from "./SubscriptionsActions"
import { ISubscriptionsState } from "./SubscriptionsState"

const initialSubscriptionsState: ISubscriptionsState = {
  isLoading: { graphic: false, list: false },
  isPaginationDisabled: true,
  error: null,
  subscriptions: { count: 0, results: [] },
  chart: { count: 0, result: [] },
  totalPages: 0,
  subscriptionsDataPoints: [{ label: "", x: 0 }],
  currentQuery: undefined,
}

export const SubscriptionsReducer: Reducer<ISubscriptionsState, SubscriptionsAction> = (
  state: ISubscriptionsState = initialSubscriptionsState,
  action: SubscriptionsAction,
) => {
  const perPage = 10

  switch (action.type) {
    case SubscriptionsActionTypes.LOAD_CHART:
      return {
        ...state,
        isLoading: { ...state.isLoading, graphic: true  },
      }
    case SubscriptionsActionTypes.LOAD_CHART_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, graphic: false },
        chart: action.chartData,
        subscriptionsDataPoints: chartDataToDatapoints(action.chartData),
      }

    case SubscriptionsActionTypes.LOAD_CHART_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, grafic: false },
        isPaginationDisabled: true,
      }
    case SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: { ...state.isLoading, list: true },
      }
    case SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isPaginationDisabled: false,
        isLoading: { ...state.isLoading, list: false },
        subscriptions: action.subscriptionsData,
        totalPages: Math.ceil(action.subscriptionsData.count / perPage),
      }
    case SubscriptionsActionTypes.LOAD_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, list: false },
      }
    case SubscriptionsActionTypes.CHANGE_PAGE:
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: { ...state.isLoading, list: true, grafic: true },
        currentQuery: action.query,
      }
    case SubscriptionsActionTypes.CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        isPaginationDisabled: false,
        isLoading: { ...state.isLoading, list: false },
        subscriptions: action.subscriptionsData,
        totalPages: Math.ceil(action.subscriptionsData.count / perPage),
      }
    case SubscriptionsActionTypes.CHANGE_PAGE_FAILURE:
      return {
        ...state,
        isPaginationDisabled: true,
        isLoading: { ...state.isLoading, list: false },
      }
    default:
      return state
  }
}

function chartDataToDatapoints(chartData: BillingAPI.ISubscription.ChartOutput) {
  let subscriptionsDataPoints: { label: string; x: number }[] = []
  chartData.result.forEach((chart, index) => {
    subscriptionsDataPoints.push({
      label: chart.date,
      x: chart.count,
    })
  })
  return subscriptionsDataPoints
}
