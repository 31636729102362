import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

export default function confirmPopup(
  action: () => void,
  title?: string,
  message?: string,
  label?: string,
  onCancel?: () => void,
) {
  confirmAlert({
    title: title ? title : "",
    message: message ? message : "Essa ação não pode ser desfeita, deseja continuar?",
    buttons: [
      {
        label: "Voltar",
        onClick: onCancel ?? (() => console.log("User Canceled")),
      },
      {
        label: label ? label : "Sim",
        onClick: action,
      },
    ],
  })
}
