import { HttpClient } from "@hub-la/fe-core-http-client"
import { Product } from "modules/check-product-has-content/domain/dtos/product"
import { CheckProductHasContent } from "../../usecases/check-product-has-content"
import { useQuery } from "react-query"
import { useContainer } from "hooks/use-container"

export const useCheckProductHasContent = (product: Product) => {
  const container = useContainer()

  const productId = product?.id
  const isQueryEnabled = !!productId

  return useQuery<boolean, Error>(
    ["", productId],
    () => new CheckProductHasContent(container.get(HttpClient)).execute(productId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      refetchOnMount: true,
      enabled: isQueryEnabled,
      initialData: false,
    },
  )
}
