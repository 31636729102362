export const YOUTUBE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

export function youtubeParser(url: string) {
  const match = url.match(YOUTUBE_REGEX)
  return match && match[7].length === 11 ? match[7] : false
}

type Quality = "low" | "medium" | "high" | "max"

export function youtubeThumbnail(url: string, quality: Quality = "medium") {
  const videoId = youtubeParser(url)
  const res = {
    low: "sddefault",
    medium: "mqdefault",
    high: "hqdefault",
    max: "maxresdefault",
  }
  return `https://img.youtube.com/vi/${videoId}/${res[quality]}.jpg`
}

export function youtubeEmbed(url: string) {
  const videoId = youtubeParser(url)
  return `https://www.youtube.com/embed/${videoId}`
}
