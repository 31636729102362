import * as React from "react"
import { CPColors } from "styles/Colors"
import { Button, Dropdown } from "semantic-ui-react"
import { SemanticShorthandContent } from "semantic-ui-react/dist/commonjs/generic"
import "./DropdownButton.scss"

export type DropdownButtonItem = {
  key: string
  title: SemanticShorthandContent
  block: () => void
}

interface IProps {
  items: DropdownButtonItem[]
  color?: CPColors
  isLoading?: boolean
  isDisabled?: boolean
  joinButton?: any
  dropdownOnly?: boolean
  pointing?: string
  borderless?: boolean
  className?: string
}

function getDropdownItems(functions: DropdownButtonItem[]) {
  return functions.map((it) => <Dropdown.Item key={it.key} value={it.key} text={it.title} onClick={it.block} />)
}

export const DropdownButton: React.FC<IProps> = (props) => {
  const { color, items, isLoading, isDisabled, joinButton, dropdownOnly } = props
  if (items.length === 0) return <></>

  return (
    <div className={joinButton ? "dropdown-button-with-join" : ""}>
      {joinButton ?? null}
      <div className="dropdown-button">
        {items[0].title === "render" || dropdownOnly || isLoading ? (
          <></>
        ) : (
          <Button
            color={color ?? undefined}
            basic={true}
            disabled={isDisabled || isLoading}
            loading={isLoading}
            size="small"
            content={items[0].title}
            onClick={items[0].block}
            style={{ width: "-webkit-fill-available" }}
          />
        )}

        {items.length > 1 || dropdownOnly || isLoading ? (
          <Dropdown
            trigger={
              <Button
                color={color ?? undefined}
                loading={isLoading}
                size="small"
                icon="ellipsis horizontal"
                basic={true}
                className={props.borderless ? "borderless" : ""}
              />
            }
            disabled={isDisabled || isLoading}
            icon={null}
            floating={true}
            closeOnBlur={true}
            closeOnEscape={true}
            pointing={props.pointing ? (props.pointing as any) : "top right"}
            onClick={(event: any) => {
              event.preventDefault()
              event.persist()
              event.nativeEvent.stopImmediatePropagation()
              event.stopPropagation()
            }}
          >
            <Dropdown.Menu>{getDropdownItems(items)}</Dropdown.Menu>
          </Dropdown>
        ) : null}
      </div>
    </div>
  )
}
