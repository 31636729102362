import { Document, Refund } from "@chatpay/common"
import { API } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum RefundActionTypes {
  LOAD = "REFUND_LOAD",
  LOAD_SUCCESS = "REFUND_LOAD_SUCCESS",
  LOAD_FAILURE = "REFUND_LOAD_FAILURE",
  REFUND = "REFUND_REFUND",
  CANCEL = "REFUND_CANCEL",
  REACTIVATE = "REFUND_REACTIVATE",
  ACTION_SUCCESS = "REFUND_ACTION_SUCCESS",
  ACTION_FAILURE = "REFUND_ACTION_FAILURE",
}

export interface ILoadAction {
  type: RefundActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: RefundActionTypes.LOAD_SUCCESS
  groups: Document.Group[]
  refunds: Refund.Document[]
}

export interface ILoadFailureAction {
  type: RefundActionTypes.LOAD_FAILURE
  reason: Error
}

export interface IRefundAction {
  type: RefundActionTypes.REFUND
}

export interface ICancelAction {
  type: RefundActionTypes.CANCEL
}

export interface IReactivateAction {
  type: RefundActionTypes.REACTIVATE
}

export interface IActionSuccessAction {
  type: RefundActionTypes.ACTION_SUCCESS
}

export interface IActionFailureAction {
  type: RefundActionTypes.ACTION_FAILURE
  reason: Error
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: RefundActionTypes.LOAD,
  })

  try {
    const [groups, refunds] = await Promise.all([
      new API.Group().myPurchasedGroups(),
      new API.Refund().fetch(API.RefundFetchWho.user),
    ])
    dispatch(loadSuccess(groups ?? [], refunds))
  } catch (e) {
    dispatch(loadFailure(e as Error))
  }
}

export const loadSuccess = (groups: Document.Group[], refunds: Refund.Document[]): ILoadSuccessAction => {
  return {
    type: RefundActionTypes.LOAD_SUCCESS,
    groups,
    refunds,
  }
}

export const loadFailure = (reason: Error): ILoadFailureAction => {
  return {
    type: RefundActionTypes.LOAD_FAILURE,
    reason,
  }
}

export const refund =
  (transactionId: string, description: string, autoWithdraw: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: RefundActionTypes.REFUND,
    })

    try {
      await new API.Refund().request({ transactionId, description, autoWithdraw })
      dispatch(actionSuccess())
    } catch (e) {
      dispatch(actionFailure(e as Error))
    }
  }

export const cancel =
  (refundId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: RefundActionTypes.CANCEL,
    })
    try {
      await new API.Refund().cancel({ id: refundId })
      dispatch(actionSuccess())
    } catch (e) {
      dispatch(actionFailure(e as Error))
    }
  }

export const reactivate =
  (refundId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: RefundActionTypes.REACTIVATE,
    })

    try {
      await new API.Refund().reactivate({ id: refundId })
      dispatch(actionSuccess())
    } catch (e) {
      dispatch(actionFailure(e as Error))
    }
  }

export const actionSuccess = (): IActionSuccessAction => {
  return {
    type: RefundActionTypes.ACTION_SUCCESS,
  }
}

export const actionFailure = (reason: Error): IActionFailureAction => {
  return {
    type: RefundActionTypes.ACTION_FAILURE,
    reason,
  }
}

export type RefundAction =
  | ILoadAction
  | ILoadSuccessAction
  | ILoadFailureAction
  | IRefundAction
  | ICancelAction
  | IReactivateAction
  | IActionSuccessAction
  | IActionFailureAction
