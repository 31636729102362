import * as React from "react"
import { Input, Select, DropdownProps } from "semantic-ui-react"
import { Model } from "@chatpay/common"
import { InputLabel } from "../InputLabel"
import ReactDOM from "react-dom"
import i18n from "i18n"

interface IProps {
  value?: string
  disabled?: boolean
  fluid?: boolean
  account?: string
  onChange?: (component: InputBank, input: HTMLSelectElement) => void
}

interface IState {
  value?: string
  isDefaultValue: boolean
}

const Banks = (code?: keyof Model.BrazilianBank.Bank, account?: string) =>
  Object.keys(Model.BrazilianBank.Model)
    .map((v, idx) => {
      const key = v as keyof typeof Model.BrazilianBank.Model
      const data = Model.BrazilianBank.Bank(key, key === code ? account : undefined)
      return {
        key: idx,
        text: `${key} - ${data.name}`,
        value: key,
      }
    })
    .flat()
    .sort((a, b) => {
      if (a.value > b.value) {
        return 1
      }
      if (a.value === b.value) {
        return 0
      }
      return -1
    })

class InputBank extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    isDefaultValue: true,
  }

  private inputRef = React.createRef<Input>()

  public get input(): HTMLSelectElement | undefined | null {
    const element = this.inputRef.current ? (ReactDOM.findDOMNode(this.inputRef.current) as HTMLElement) : null
    return element?.querySelector("select") as HTMLSelectElement
  }

  public get name(): string | undefined | null {
    return this.inputRef.current?.props.name
  }

  public get value(): string {
    return this.state.value ?? ""
  }

  public get isValid(): boolean {
    return true
  }

  public get validity(): ValidityState | null | undefined {
    return { valid: this.isValid } as any
  }

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    if ((props.value && (!state.value || state.value !== props.value)) || (!props.value && !state.value)) {
      return {
        value: !state.isDefaultValue ? state.value : props.value ?? Banks[0]?.value,
        isDefaultValue: !props.value,
      }
    }
    return null
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.value === prevProps.value) {
      return
    }
    if (this.props.onChange) {
      this.props.onChange(this, this.input!)
    }
  }

  private onChange = (_, data: DropdownProps) => {
    const value = data.value as string
    this.setState(
      {
        value,
        isDefaultValue: false,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this, this.input!)
        }
      },
    )
  }

  public render() {
    const { fluid = true, disabled, account } = this.props

    const { value } = this.state

    return (
      <InputLabel title="Banco">
        <Select
          fluid={fluid}
          compact={true}
          disabled={disabled}
          onChange={this.onChange}
          options={Banks(value as keyof Model.BrazilianBank.Bank, account)}
          value={value}
        />
        {value === "237" ? (
          <div className="cp text small" style={{ marginTop: "1em" }}>
            {i18n.t("AccountBankAccount.bradescoHint")}{" "}
            <a
              href="https://banco.bradesco/html/classic/atendimento/rede-de-atendimento/index.shtm"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t("AccountBankAccount.bradescoHintHere")}
            </a>
          </div>
        ) : value === "104" ? (
          <div className="cp text small" style={{ marginTop: "1em" }}>
            {i18n.t("AccountBankAccount.caixaHint")}{" "}
          </div>
        ) : null}
      </InputLabel>
    )
  }
}

export default InputBank
