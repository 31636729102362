import { API } from "@chatpay/components"
import { Document } from "@chatpay/common"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum AffiliatesActionTypes {
  LOAD = "DASHBOARD_LOAD_AFFILIATES",
  LOAD_SUCCESS = "DASHBOARD_LOAD_AFFILIATES_SUCCESS",
  LOAD_FAILURE = "DASHBOARD_LOAD_AFFILIATES_FAILURE",
  SORT = "DASHBOARD_SORT_AFFILIATES",
}

export interface ILoadAction {
  type: AffiliatesActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: AffiliatesActionTypes.LOAD_SUCCESS
  affiliateRank: Document.Affiliate[]
}

export interface ILoadFailureAction {
  type: AffiliatesActionTypes.LOAD_FAILURE
  reason: string
}

export interface ISortAction {
  type: AffiliatesActionTypes.SORT
  clickedColumn: string
}

export const sort = (clickedColumn: string): ISortAction => {
  return {
    type: AffiliatesActionTypes.SORT,
    clickedColumn,
  }
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: AffiliatesActionTypes.LOAD,
  })

  try {
    const affiliateRank = await new API.Affiliate().getMyAffiliates()

    dispatch(loadSuccess(affiliateRank!))
  } catch (e: any) {
    dispatch(loadFailure(e))
  }
}

export const loadSuccess = (affiliateRank: Document.Affiliate[]): ILoadSuccessAction => {
  return {
    type: AffiliatesActionTypes.LOAD_SUCCESS,
    affiliateRank,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: AffiliatesActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type AffiliatesAction = ILoadAction | ILoadSuccessAction | ILoadFailureAction | ISortAction
