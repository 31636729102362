export const Review = {
  Input: {
    terrible: "Terrível",
    bad: "Ruim",
    ok: "Ok",
    good: "Boa",
    great: "Ótima",
  },
  EditPicture: {
    title: "Adicione uma foto de perfil para colocarmos sua avaliação no perfil do criador",
  },
  EditReview: {
    title: "Como foi a sua experiência no {{groupName}}?",
    input: "Escreva uma avaliação para o {{groupName}}",
    placeholder:
      "{{firstName}} é um excelente criador. Sempre está à disposição para responder dúvidas e nos motivar a completar o desafio. Não conheço um melhor grupo!",
    remaining: "{{count}} caracteres restantes.",
    stillNeeds: "Escreva mais {{count}} caracteres.",
    exceeded: "Você ultrapassou o limite de caracteres.",
    blankText: "Texto não pode ficar em branco.",
    required: "Você precisa escrever pelo menos {{count}} caracteres.",
    Tip: {
      title: "Como escrever uma ótima avaliação:",
      li1: "1. Seja gentil e honesto",
      li2: "2. Mencione o nome do criador",
      li3: "3. Destaque sua experiência no primeiro dia",
      li4: "4. Mencione o que você gostou do grupo",
      li5: "5. Sugira melhorias para o criador",
      li6: "6. Escreva uma frase para futuros compradores",
    },
  },
  onlyForMembers: "Apenas membros do grupo podem fazer uma avaliação.",
  thankYou: "Obrigado por enviar sua avaliação!",
  button: "Pronto",
  reviews: "avaliações",
  seeAll: "Ver todas {{count}} avaliações",
}
