import Picker from "emoji-picker-react"
import React, { useState } from "react"
import { Button, Icon, Popup } from "semantic-ui-react"
import "./InputEmoji.scss"

export type EmojiObject = {
  // The emoji symbol. May vary across OSs, in some it may not be visible to you.
  emoji: string
  // The actual emoji unicode.
  unified: string
  // The currently selected skin tone, regardless if the current emoji has one or not.
  activeSkinTone: string
  // If the currently selected emoji has a skin tone modifier, originalUnified will hold the "neutral" code.
  originalUnified: string | undefined
  // An array of one or more descriptive names for the emoji.
  names: string[]
}

interface IProps {
  content?: string
  onChange?: (data: EmojiObject) => void
}

export const InputEmoji: React.FC<IProps> = (props) => {
  const { content, onChange } = props

  const [open, setOpen] = useState<boolean>(false)

  const onEmojiClick = (_, emojiObject: EmojiObject) => {
    setOpen(false)
    if (onChange) {
      onChange(emojiObject)
    }
  }

  return (
    <div>
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        trigger={
          <Button
            className="emoji"
            placeholder={"🚀"}
            basic={true}
            size="small"
            icon={true}
            labelPosition="right"
            onClick={() => setOpen(true)}
          >
            {content}
            <Icon name="angle down" />
          </Button>
        }
        content={<Picker disableAutoFocus onEmojiClick={onEmojiClick} />}
        on="click"
      />
    </div>
  )
}
