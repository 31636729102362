import { Document, Interface } from "@chatpay/common"
import { CheckResourcesHasGroupIM } from "../../../check-resources-has-group-im/exports/check-resources-has-group-im"

type Props = {
  group: Document.Group
  selectedStatus: Document.GroupStepStatus | undefined
}

export const useShowMessageActivateSalesResourceType = ({ group, selectedStatus }: Props): boolean => {
  const offerHasGroupIM = new CheckResourcesHasGroupIM().execute(group.groupResourcesType)

  if (offerHasGroupIM && selectedStatus === Document.GroupStepStatus.isSelling) {
    // offer reactivating sales with product type other than cohort (permanent or channel)
    if (!group.isPublished && group.productSettings.communityType !== Interface.Product.CommunityType.cohort) {
      return true
    }
    // new offer activating sales with product type cohort
    if (group.isPublished && group.productSettings.communityType === Interface.Product.CommunityType.cohort) {
      return true
    }
  }

  return false
}
