import * as React from "react"
import { Grid, Dropdown, DropdownProps } from "semantic-ui-react"
import { InputLabel } from "components/Fields/InputLabel"
import * as Base from "components/Forms/Base"
import * as Forms from "components/Forms"
import * as CurrencyFormat from "react-currency-format"

import "./Withdraw.scss"

interface IData {
  account: string
  agency: string
}

export type Data = IData

export const EmptyData: IData = {
  account: "",
  agency: "",
}

const bank = [
  { key: 1, text: "001 -> Banco do Brasil", value: "001" },
  { key: 2, text: "033 -> Santander", value: "033" },
  { key: 3, text: "104 -> Caixa Econômica", value: "104" },
  { key: 4, text: "237 -> Bradesco", value: "237" },
  { key: 5, text: "341 -> Itaú", value: "341" },
  { key: 6, text: "041 -> Banrisul", value: "041" },
  { key: 7, text: "748 -> Sicredi", value: "748" },
  { key: 8, text: "756 -> Sicoob", value: "756" },
  { key: 9, text: "077 -> Inter", value: "077" },
  { key: 10, text: "070 -> BRB", value: "070" },
  { key: 11, text: "085 -> Via Credi", value: "085" },
  { key: 12, text: "655 -> Neon", value: "655" },
  { key: 13, text: "260 -> Nubank", value: "260" },
  { key: 14, text: "290 -> Pagseguro", value: "290" },
  { key: 15, text: "212 -> Banco Original", value: "212" },
  { key: 16, text: "422 -> Safra", value: "422" },
  { key: 17, text: "746 -> Modal", value: "746" },
  { key: 18, text: "021 -> Banestes", value: "021" },
]

const accountType = [
  { key: 1, text: "Conta Corrente", value: "cc" },
  { key: 2, text: "Conta Poupança", value: "cp" },
]

interface IProps<I extends Data> extends Base.Props<I> {}
interface IState<T extends Data> extends Base.State<T> {
  value: string
}

export class WithdrawForm extends Base.Form<IProps<Data>, IState<Data>> {
  protected formGridRef = React.createRef<Forms.Grid>()

  private handleChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data
    this.setState({ value: (value as string) ?? "" })
  }

  private accountFormat(value) {
    switch (value) {
      case "001":
        return "########-#"
      case "033":
        return "########-#"
      case "104":
        return "####-#"
      case "237":
        return "#######-#"
      case "341":
        return "#####-#"
      case "041":
        return "#########-#"
      case "748":
        return "######"
      case "756":
        return "#########-#"
      case "077":
        return "#########-#"
      case "070":
        return "#########-#"
      case "085":
        return "###########-#"
      case "655":
        return "#######-#"
      case "260":
        return "##########-#"
      case "290":
        return "########-#"
      case "212":
        return "#######-#"
      case "422":
        return "########-#"
      case "746":
        return "#########-#"
      case "021":
        return "########-#"
    }
  }
  private agencyFormat(value) {
    switch (value) {
      case "001":
        return "####-#"
      case "237":
        return "####-#"
      default:
        return "####"
    }
  }
  private accountFormatPlaceHolder(value) {
    switch (value) {
      case "001":
        return "00000000-D"
      case "033":
        return "00000000-D"
      case "104":
        return "XXX00000000-D (X: Operação)"
      case "237":
        return "0000000-D"
      case "341":
        return "00000-D"
      case "041":
        return "000000000-D"
      case "748":
        return "00000D"
      case "756":
        return "000000000-D"
      case "077":
        return "000000000-D"
      case "070":
        return "000000000-D"
      case "085":
        return "00000000000-D"
      case "655":
        return "0000000-D"
      case "260":
        return "0000000000-D"
      case "290":
        return "00000000-D"
      case "212":
        return "0000000-D"
      case "422":
        return "00000000-D"
      case "746":
        return "000000000-D"
      case "021":
        return "00000000-D"
      default:
        return "000000-D"
    }
  }
  private agencyFormatPlaceHolder(value) {
    switch (value) {
      case "001":
        return "0000-D"
      case "237":
        return "0000-D"
      default:
        return "0000"
    }
  }

  public render() {
    const { isDisabled, value } = this.state

    return (
      <div className="WithdrawForm">
        <Forms.Grid ref={this.formGridRef}>
          <Grid.Row>
            <Grid.Column>
              <InputLabel title="Banco">
                <Dropdown onChange={this.handleChange} fluid={true} options={bank} selection={true} value={value} />
              </InputLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <InputLabel title="Tipo de Conta">
                <Dropdown fluid={true} options={accountType} selection={true} />
              </InputLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <InputLabel title="Conta">
                <CurrencyFormat
                  fluid={true}
                  type="tel"
                  name="account"
                  format={this.agencyFormat(value)}
                  placeholder={this.agencyFormatPlaceHolder(value)}
                  mask={`_`}
                  required={true}
                  disabled={isDisabled}
                  onChange={this.onFieldChange}
                  onBlur={this.onFieldBlur}
                />
              </InputLabel>
            </Grid.Column>
            <Grid.Column>
              <InputLabel title="Agencia">
                <CurrencyFormat
                  fluid={true}
                  type="tel"
                  name="agency"
                  format={this.accountFormat(value)}
                  placeholder={this.accountFormatPlaceHolder(value)}
                  mask={`_`}
                  required={true}
                  disabled={isDisabled}
                  onChange={this.onFieldChange}
                  onBlur={this.onFieldBlur}
                />
              </InputLabel>
            </Grid.Column>
          </Grid.Row>
        </Forms.Grid>
      </div>
    )
  }
}
