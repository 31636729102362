import React, { useEffect, useState } from "react"
import * as Fields from "components/Fields"

type keys = "description"

interface IForm {
  description: string
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const Description: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  useEffect(() => {
    setForm(value)
  }, [value])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    if (onChange) {
      onChange(newData, valid)
    }
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.description || data.description !== (undefined || "")
  }

  const onEditorUpdate = (data) => {
    setFormValue("description", data)
  }

  return (
    <Fields.Editor
      value={form?.description}
      placeholder="Descreva sobre o seu produto..."
      onEditorChange={(data) => onEditorUpdate(data)}
    />
  )
}

export { Description as Form }
export type { IProps, IForm }
