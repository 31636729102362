import { Button } from "semantic-ui-react"
import styled from "styled-components"
import { Text } from "typography"

const InfoBoxMarginLeftItems = "24px"

export const InfoBox = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;

  .placeholder {
    margin-top: 0px !important;
    &.BackArrow {
      width: 24px;
      height: 24px;
    }
    &.InfoBoxPicture {
      width: 32px;
      height: 32px;
      margin-left: 24px;
    }
    &.InfoBoxTextTitle,
    &.InfoBoxTextSubtitle {
      width: 100%;
      float: left;
      min-width: 100px;
    }
  }
`
export const InfoBoxPicture = styled.img`
  margin-left: ${InfoBoxMarginLeftItems};
  width: 32px;
  height: 32px;
`

export const BackArrow = styled.img`
  width: 24px;
  height: 24px;
`

export const InfoBoxTextContainer = styled.div`
  display: flex;
  align-items: baseline;
  align-content: center;
  flex-direction: column;
  margin-left: ${InfoBoxMarginLeftItems};
`

export const InfoBoxTextTitle = styled(Text.Big)`
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
`
export const InfoBoxTextSubtitle = styled(Text.Tiny)`
  padding-top: 5px;
  color: #666666;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`
export const NameNotRegistered = styled(Text.Tiny)`
  color: #9b9b9b;
`

export const FreeMembersHeadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 766px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ButtonGenerateInviteLink = styled(Button)`
  @media only screen and (max-width: 766px) {
    margin-top: 20px !important;
  }
`
