import React from "react"
import { Document, Interface } from "@chatpay/common"
import { getSiteHost, Service, Session } from "@chatpay/components"
import { routes } from "@chatpay/common"
import { Redirect } from "react-router-dom"

const authorized = (path: string, condition: (user: Document.User) => boolean) => {
  const redirectTo = path !== window.location.pathname ? window.location.pathname : undefined
  const authRoute = {
    path,
  }
  return Session.withAuthorization(condition, redirectTo ? { ...authRoute, state: { redirectTo } } : authRoute)
}

export const withRedirect =
  (config: { redirectCondition: (props: any) => boolean; redirectTo: string }) =>
  (Component: Session.GComponent) =>
  (props: any) => {
    const { redirectCondition, redirectTo } = config
    if (redirectCondition(props)) {
      return <Redirect to={redirectTo} />
    }
    return <Component {...props} />
  }

export const withUserCompleted = (component: Session.GComponent) => {
  return authorized(routes.GET_STARTED.PATH, (user: Document.User) => !!user && user.isCompleted)(component)
}

export const withUserAuthenticated = (component: Session.GComponent) => {
  return authorized(routes.AUTH.PATH, (user: Document.User) => !!user)(component)
}

export const isMobileDevice = () => {
  return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1
}
export const isDebug = () => {
  return process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" || process.env.REACT_APP_CUSTOM_NODE_ENV === "development"
}

export const hasAcceptedAccount = () => {
  return (
    Service.Firebase.currentUser?.defaultGateway?.status === Interface.Gateway.Common.Validation.Status.accepted ||
    isDebug()
  )
}

export const getSiteUrl = (path: string = "") => {
  const siteHost = getSiteHost()
  return (path || "").substring(0, 1) === "/" ? `${siteHost}${path}` : `${siteHost}/${path}`
}

export const getGroupUrl = (path?: string) => {
  return getSiteUrl("/g/") + path
}

export const cookieDomain = (): string => {
  return process.env.REACT_APP_COOKIE_DOMAIN ?? ".hub.la"
}
