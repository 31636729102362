import { Refund } from "@chatpay/common"
import { API } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum RefundsActionTypes {
  LOAD = "DASHBOARD_LOAD_REFUNDS",
  LOAD_SUCCESS = "DASHBOARD_LOAD_REFUNDS_SUCCESS",
  LOAD_FAILURE = "DASHBOARD_LOAD_REFUNDS_FAILURE",
  SORT = "DASHBOARD_SORT_REFUNDS",
}

export interface ILoadAction {
  type: RefundsActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: RefundsActionTypes.LOAD_SUCCESS
  refundList: Refund.Document[]
}

export interface ILoadFailureAction {
  type: RefundsActionTypes.LOAD_FAILURE
  reason: string
}

export interface ISortAction {
  type: RefundsActionTypes.SORT
  clickedColumn: string
}

export const sort = (clickedColumn: string): ISortAction => {
  return {
    type: RefundsActionTypes.SORT,
    clickedColumn,
  }
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: RefundsActionTypes.LOAD,
  })

  try {
    const refundList = await new API.Refund().fetch(API.RefundFetchWho.owner)

    dispatch(loadSuccess(refundList!))
  } catch (e) {
    dispatch(loadFailure(e))
  }
}

export const loadSuccess = (refundList: Refund.Document[]): ILoadSuccessAction => {
  return {
    type: RefundsActionTypes.LOAD_SUCCESS,
    refundList,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: RefundsActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type RefundsAction = ILoadAction | ILoadSuccessAction | ILoadFailureAction | ISortAction
