import { Reducer } from "redux"
import { ValidateWhatsappAction, ValidateWhatsappActionTypes } from "./ValidateWhatsappActions"
import { IValidateWhatsappState } from "./ValidateWhatsappState"

const initialValidateWhatsappState: IValidateWhatsappState = {
  isOpen: false,
  isLoading: false,
  error: null,
}

export const ValidateWhatsappReducer: Reducer<IValidateWhatsappState, ValidateWhatsappAction> = (
  state: IValidateWhatsappState = initialValidateWhatsappState,
  action: ValidateWhatsappAction,
) => {
  switch (action.type) {
    case ValidateWhatsappActionTypes.OPEN:
      return {
        ...state,
        isOpen: true,
      }
    case ValidateWhatsappActionTypes.CLOSE:
      return {
        ...state,
        isOpen: false,
      }
    case ValidateWhatsappActionTypes.VALIDATE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case ValidateWhatsappActionTypes.VALIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case ValidateWhatsappActionTypes.VALIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.reason,
      }
    default:
      return state
  }
}
