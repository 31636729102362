import { Reducer } from "redux"
import { GetAffiliateAction, GetAffiliateActionTypes } from "./GetAffiliateActions"
import { IGetAffiliateState } from "./GetAffiliateState"

const initialGetAffiliateState: IGetAffiliateState = {
  group: null,
  isLoading: false,
  links: null,
  error: null,
}

export const GetAffiliateReducer: Reducer<IGetAffiliateState, GetAffiliateAction> = (
  state: IGetAffiliateState = initialGetAffiliateState,
  action: GetAffiliateAction,
) => {
  switch (action.type) {
    case GetAffiliateActionTypes.LOAD:
      return {
        ...state,
        group: action.group,
        isLoading: true,
        code: null,
        error: null,
      }
    case GetAffiliateActionTypes.LOAD_SUCCESS:
      const hasAffiliate = action.hasAffiliate

      return {
        ...state,
        isLoading: false,
        links: hasAffiliate ? action.links : null,
        error: !hasAffiliate ? "Link de afiliado indisponível. Entre em contato com o suporte." : null,
      }
    case GetAffiliateActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        code: undefined,
        error: action.reason,
      }
    default:
      return state
  }
}
