import { palette, spacing, Stack, styled, Text } from "@hub-la/design-system"
import React from "react"

const OptionWrapper = styled("div")<{ active: boolean }>`
  padding: ${spacing(6)};
  border: 1.5px solid ${(props) => (props.active ? palette("primary") : palette("outline"))};
  background: ${(props) => (props.active ? palette("surfaceVariant") : palette("surface"))};
  margin: 0 0 ${spacing(2)} 0;
  border-radius: 12px;
  max-width: 600px;
  cursor: pointer;
`

export const Option: React.FC<{
  title: string
  text: string
  icon: any
  active: boolean
  onClick: () => void
  ["data-testid"]?: string
}> = (props) => {
  const { title, text, icon, active, onClick, "data-testid": dataTestId } = props
  return (
    <OptionWrapper active={active} onClick={onClick}>
      <Stack direction="row" spacing={2} alignItems="center" data-testid={dataTestId}>
        <Stack direction="column" spacing={2} flex={1}>
          <Text variant="h4">{title}</Text>
          <Text maxWidth="360px" variant="body2">
            {text}
          </Text>
        </Stack>
        <img src={icon} alt={title} style={{ width: "100%", maxWidth: "40px", maxHeight: "32px" }} />
      </Stack>
    </OptionWrapper>
  )
}
