import * as Fields from "components/Fields"
import React, { useEffect, useState } from "react"
import { DropdownItemProps, Form as SemanticForm } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { Model } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { Spacer } from "components"
import { withFlags } from "containers/withFlags"
import { FLAG } from "hooks"
import { MenuItem, Select, Stack, Switch, Text } from "@hub-la/design-system"
import { PriceTextEdit, Warning } from "pages/EditGroup/Panes/offers-pane/helper"
import { FormControl } from "@mui/material"

const MIN_PRICE = parseInt(process.env.REACT_APP_IUGU_MIN_PRICE ?? "0", 10)
const MAX_PRICE = parseInt(process.env.REACT_APP_IUGU_MAX_PRICE ?? "0", 10)

export interface IForm {
  isEnabled: boolean
  entryFee?: number
  installments?: number
  renewalToleranceDays?: number
}

export interface IProps {
  currency?: Model.Currency
  value?: Partial<IForm>
  groupId: string
  onChange?: (data: Partial<IForm>, valid: boolean) => any
  isEnabledByFlag: (flag: string, data?: unknown) => boolean
  canEnable?: boolean
}

interface IErrors {
  price: string
}

const EntryFee: React.FunctionComponent<IProps> = (props) => {
  const { groupId, value, onChange, currency, isEnabledByFlag, canEnable } = props

  const isUpsellCreatorUIEnabled = isEnabledByFlag(FLAG.CHECKOUT.IS_UPSELL_CREATOR_UI_ENABLED, {
    id: groupId,
  })

  const isOrderBumpEnabled = isEnabledByFlag(FLAG.DASHBOARD.IS_ORDER_BUMP_ENABLED, {
    id: groupId,
  })

  const [form, setForm] = useState<Partial<IForm>>()
  const [errors, setErrors] = useState<Partial<IErrors>>()

  const { t } = useTranslation()

  const calcInstallments = (price: number): number => {
    const max = Math.floor(price / parseInt(process.env.REACT_APP_IUGU_MIN_INSTALLMENT_PRICE ?? "3", 10))
    return max > 12 ? 12 : max
  }

  // installment options
  const [instOptions, setInstOptions] = useState<DropdownItemProps[]>()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const entryFee = form?.entryFee

  useEffect(() => {
    const installments = calcInstallments(form?.entryFee ?? 0)
    const inst = [{ key: 0, text: t("GroupForm.entryFee.installments.disabled"), value: 1 }]
    for (let i = 2; i <= installments; i++) {
      inst.push({ key: i, text: t("GroupForm.entryFee.installments.upTo") + ` ${i}x`, value: i })
    }
    setInstOptions(inst)
  }, [entryFee, form, t])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    if (onChange) {
      onChange(newData, valid)
    }
    setForm(newData)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    const errors = {
      price: data.entryFee ? checkPriceError(data.entryFee) : undefined,
    }

    setErrors(errors)

    return (
      data.entryFee !== undefined &&
      data.entryFee !== 0 &&
      !isNaN(data.entryFee) &&
      (checkPriceError(data.entryFee) ? false : true)
    )
  }

  const checkPriceError = (price: number): string | undefined => {
    const tooLow = `${t("InputPlan.errors.higher")} ${Service.Global.Price.currencyFormat(
      MIN_PRICE,
      currency ?? Model.Currency.brl,
    )}`
    const tooHigh = `${t("InputPlan.errors.lower")}  ${Service.Global.Price.currencyFormat(
      MAX_PRICE,
      currency ?? Model.Currency.brl,
    )}`
    return price < MIN_PRICE ? tooLow : price > MAX_PRICE ? tooHigh : undefined
  }

  // If the feature flag is off, show all inputs as it is today.
  // If the feature flag is on, then only show the inputs when the user
  // activates the entry fee (expected behavior for the new UI).
  const showInputs = !isUpsellCreatorUIEnabled || form?.isEnabled === true

  return isOrderBumpEnabled ? (
    MaterialEntryFee(form, isOrderBumpEnabled, canEnable, setFormValue, showInputs, currency, instOptions, errors)
  ) : (
    <SemanticForm>
      {isUpsellCreatorUIEnabled ? (
        <Switch
          checked={form?.isEnabled ?? false}
          onClick={() => setFormValue("isEnabled", !form?.isEnabled)}
          label={t("GroupForm.entryFee.entryFee")}
          direction="left"
          ml={1}
          mb={3}
        />
      ) : (
        <SemanticForm.Checkbox
          toggle={true}
          data-testid="entryFeeEnabled"
          checked={form?.isEnabled ?? false}
          onChange={() => setFormValue("isEnabled", !form?.isEnabled)}
        />
      )}

      {showInputs && (
        <Fields.InputLabel title={errors?.price ? errors.price : t("GroupForm.entryFee.entryFee")}>
          <Fields.Price
            currency={Service.Firebase.currentUser?.currency ?? undefined}
            disabled={!form?.isEnabled}
            fluid={true}
            data-testid="entryFee"
            error={errors?.price !== undefined}
            placeholder="0,00"
            value={form?.entryFee ?? 0}
            onFieldChange={(_, data) => setFormValue("entryFee", data.price)}
          />
        </Fields.InputLabel>
      )}
      {!isUpsellCreatorUIEnabled && <div>{t("GroupForm.entryFee.text")}</div>}

      {showInputs && currency === Model.Currency.brl && (
        <Fields.InputLabel title={t("GroupForm.entryFee.chooseInstallments")}>
          <SemanticForm.Dropdown
            disabled={!form?.isEnabled}
            fluid={true}
            data-testid="chooseInstallments"
            value={form?.installments ?? 1}
            selection={true}
            options={instOptions ?? []}
            onChange={(_, data) => setFormValue("installments", data.value)}
          />
        </Fields.InputLabel>
      )}

      <Spacer size="small" />

      <div>{t(`GroupForm.entryFee.renewalTolerance.textMembershipFee`)}</div>
    </SemanticForm>
  )
}

export const Form = withFlags(EntryFee)

/**
 * Creates the new entry fee component that is going to be used after the order
 * bump project rolls out.
 *
 * TODO: after order bump rolls out, we can replace the original, `SemanticForm
 * component with this one, moving it back up there.
 */
function MaterialEntryFee(
  form: Partial<IForm> | undefined,
  isOrderBumpEnabled: boolean,
  canEnable: boolean | undefined,
  setFormValue: (name: string, value: any) => void,
  showInputs: boolean,
  currency: Model.Currency | undefined,
  instOptions: DropdownItemProps[] | undefined,
  error: Partial<IErrors> | undefined,
): React.ReactElement<any, any> | null {
  const { t } = useTranslation()

  return (
    <Stack>
      <Switch
        checked={form?.isEnabled ?? false}
        disabled={isOrderBumpEnabled && !canEnable}
        onClick={() => setFormValue("isEnabled", !form?.isEnabled)}
        label={t("GroupForm.entryFee.entryFee")}
        direction="left"
        ml={1}
        mb={3}
      />

      {!canEnable && <Warning warning={t("GroupForm.entryFee.warnings.entryFeeWithOrderBump")} marginBottom={4} />}

      <Text variant="body2" color="onSurfaceVariant">
        {t("OffersPane.EntryFeeSwitch.title")}
      </Text>

      <Spacer />

      <Text variant="body2" color="onSurfaceVariant">
        {t("OffersPane.EntryFeeSwitch.subtitle")}
      </Text>

      {showInputs && (
        <Stack>
          <Spacer />
          <PriceTextEdit
            currency={{
              value: (form && form.entryFee && form.entryFee * 100) || 0,
              symbol: Service.Firebase.currentUser?.currency ?? undefined,
            }}
            label={t("GroupForm.entryFee.entryFee")}
            savePrice={({ priceInCents }) => setFormValue("entryFee", priceInCents / 100)}
            parentError={error?.price ?? ""}
            data-testid="entryFee"
          />

          <Spacer size="small" />

          {currency === Model.Currency.brl && (
            <FormControl fullWidth>
              <Select
                label={t("OffersPane.UpsellModal.SetupOffer.oneTimePayment.installments")}
                size="normal"
                value={form?.installments ?? 1}
                data-testid="chooseInstallments"
                onChange={(evt) => {
                  const installments = parseInt(evt.target.value as string)
                  setFormValue("installments", installments)
                }}
              >
                {instOptions &&
                  instOptions.map((opt) => (
                    <MenuItem key={opt.key} value={opt.value?.toString() ?? ""}>
                      {opt.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      )}
    </Stack>
  )
}
