import { Document } from "@chatpay/common"

export class CheckResourcesHasGroupIM {
  /**
   * Checks if resources have group IM
   * @see {@link  Document.ResourceType}
   */
  public execute(resourcesType: Document.ResourceType[] | undefined) {
    if (!resourcesType) {
      return false
    }

    return resourcesType?.some((resourceType) => {
      if (!resourceType) {
        return false
      }

      return [
        Document.ResourceType.whatsapp,
        Document.ResourceType.telegram,
        Document.ResourceType.telegram_channel,
      ].includes(resourceType)
    })
  }
}
