export const EditNotification = {
  SectionTitle: "Notifications",
  SectionSubtitle: "Receive notifications about activities in your Hubla app, payment updates, and others.",
  SectionText: "Receive notifications about",
  adminJoined: "Admin joined group",
  memberJoined: "A group member joined your group",
  nonMemberJoined: "A non-identified user joined your group",
  purchased: "User purchased your group",
  removed: "User has been removed from your group",
  whitelisted: "User has been added to the group's whitelist",
  DownloadApp: "Download the app",
}
