import { API, Service } from "@chatpay/components"
import { Spacer } from "components"
import i18n from "i18n"
import * as React from "react"
import { Button, Icon, Modal } from "semantic-ui-react"

interface IState {
  open?: boolean
}

class TermsDialogComponent extends React.Component<{}, IState> {
  public state: Readonly<IState> = {
    open: undefined,
  }

  private onAcceptClick = async () => {
    this.setState({
      open: false,
    })
    try {
      await API.User.factory().acceptTerms()
    } catch (e) {
      console.error(e)
    }
  }

  public render() {
    const authUser = Service.Firebase.currentUser
    return (
      <Modal
        open={this.state.open !== undefined ? this.state.open : !!(authUser && !authUser?.termsAcceptedAt)}
        size="small"
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Content>
          <div className="cp text big black bold">{i18n.t("termsOfUse.title")}</div>
          <Spacer size="small" />
          <div className="cp text">
            {i18n.t("termsOfUse.subtitle")}{" "}
            <a href={process.env.REACT_APP_TERMS_OF_USE} rel="noopener noreferrer" target="_blank">
              {i18n.t("termsOfUse.term")}
            </a>{" "}
            .
          </div>
          <Spacer />
          <Button size="small" floated="right" color="green" onClick={this.onAcceptClick}>
            <Icon name="checkmark" /> {i18n.t("termsOfUse.accept")}
          </Button>
          <Spacer />
          <Spacer size="tiny" />
        </Modal.Content>
      </Modal>
    )
  }
}

export default TermsDialogComponent
